import { Box, FormControl, Input, InputLabel, Stack } from "@mui/material";
import { useState } from "react";
import { checkDoublePattern, checkHoursPattern } from "../../utils/commons";

const FormAguaRiegoCampañaComponent = ({position, setPosition, state, setState}) => {

    const [errors, setErrors] = useState({});

    const handleNext = (e) => {
        e.preventDefault();
        if(validateForm()){
            setPosition(4);
        }
    };

    const handlePrev = (e) => {
        e.preventDefault();
        setPosition(2);
    }

    const handleChangeLimiteLV = (e) => {
        if(checkHoursPattern(e)){
            setState({...state, limiteLV: e});
        }
    }

    const handleChangeLimiteSD = (e) => {
        if(checkHoursPattern(e)){
            setState({...state, limiteSD: e});
        }
    }

    /*const handleChangeDotacionRiego = (e) => {
        if(checkDoublePattern(e)){
            setState({...state, dotacionRiego: e});
        }
    }*/

    const handleChangeConductividad = (e) => {
        if(checkDoublePattern(e)){
            setState({...state, conductividad: e});
        }
    }

    const validateForm = () =>{
        setErrors({});
        let inputIds = "";
        let valid = true;
        if(state.limiteLV === undefined || state.limiteLV === ''){
            setErrors(errors => ({...errors, errorLimiteLV: true, errorLimiteLVMsg: ""}));
            inputIds = inputIds ? inputIds +",limiteLV" : "limiteLV";
            valid = false;
        }
        if(state.limiteSD === undefined || state.limiteSD === ''){
            setErrors(errors => ({...errors, errorLimiteSD: true, errorLimiteSDMsg: ""}));
            inputIds = inputIds ? inputIds +",limiteSD" : "limiteSD";
            valid = false;
        }
        /*if(state.dotacionRiego === undefined || state.dotacionRiego === ''){
            setErrors(errors => ({...errors, errorDotacionRiego: true, errorDotacionRiegoMsg: ""}));
            inputIds = inputIds ? inputIds +",dotacionRiego" : "dotacionRiego";
            valid = false;
        }else if(!(checkDoublePattern(state.dotacionRiego))){
            setErrors(errors => ({...errors, errorNRamales: true, errorNRamalesMsg: "Debe ser un valor numérico."}));
            inputIds = inputIds ? inputIds +",dotacionRiego" : "dotacionRiego";
            valid = false;
        }*/
        /*if(state.conductividad === undefined || state.conductividad === ''){
            setErrors(errors => ({...errors, errorConductividad: true, errorConductividadMsg: ""}));
            inputIds = inputIds ? inputIds +",conductividad" : "conductividad";
            valid = false;
        }*/
        if((state.conductividad !== undefined || state.conductividad !== '') && !(checkDoublePattern(state.conductividad))){
            setErrors(errors => ({...errors, errorConductividad: true, errorConductividadMsg: "Debe ser un valor numérico."}));
            inputIds = inputIds ? inputIds +",conductividad" : "conductividad";
            valid = false;
        }
        if(inputIds !== ""){
            try{
                let elem = document.getElementById(inputIds.split(",")[0]);
                elem.scrollIntoView({behavior: "smooth"});
                elem.focus();
            }catch(err){

            }
        }
        return valid;
    }

    return(
        <>
        <Box className={position !== 3 && 'hide'}>
        <h2>Límite de horas de Riego por Tarifa</h2>
            <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                    <InputLabel htmlFor="limiteLV">Límite horas de riego Lunes - Viernes*</InputLabel>
                    <Input
                        id="limiteLV"
                        className="t4b-finca-sector-inputs"
                        label="Limite Lunes - Viernes"
                        required
                        name="limiteLV"
                        value={state.limiteLV}
                        onChange={(e) => handleChangeLimiteLV(e.target.value)}
                        error={errors.errorLimiteLV}
                    />
                    {errors.errorLimiteLVMsg && <div className="errorInput">{errors.errorLimiteLVMsg}</div>}
            </FormControl>
            <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                    <InputLabel htmlFor="limiteSD">Límite horas de riego Sábado - Domingo*</InputLabel>
                    <Input
                        id="limiteSD"
                        className="t4b-finca-sector-inputs"
                        label="Limite Sábado - Domingo"
                        required
                        name="limiteSD"
                        value={state.limiteSD}
                        onChange={(e) => handleChangeLimiteSD(e.target.value)}
                        error={errors.errorLimiteSD}
                    />
                    {errors.errorLimiteSDMsg && <div className="errorInput">{errors.errorLimiteSDMsg}</div>}
            </FormControl>
            
            <h2>Agua de Riego para la Campaña</h2>
            {/*<FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                    <InputLabel htmlFor="dotacionRiego">Dotación de Agua para Riego de la Campaña*</InputLabel>
                    <Input
                        id="dotacionRiego"
                        className="t4b-finca-sector-inputs"
                        label="Dotación de agua de riego de la Campaña"
                        required
                        name="dotacionRiego"
                        value={state.dotacionRiego}
                        onChange={(e) => handleChangeDotacionRiego(e.target.value)}
                        error={errors.errorDotacionRiego}
                    />
                    {errors.errorDotacionRiegoMsg && <div className="errorInput">{errors.errorDotacionRiegoMsg}</div>}
            </FormControl>*/}
            <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                    <InputLabel htmlFor="conductividad">Conductividad eléctrica del agua para riego (dS/m)</InputLabel>
                    <Input
                        id="conductividad"
                        className="t4b-finca-sector-inputs"
                        label="Conductividad eléctrica del agua para riego"
                        name="conductividad"
                        value={state.conductividad}
                        onChange={(e) => handleChangeConductividad(e.target.value)}
                        error={errors.errorConductividad}
                    />
                    {errors.errorConductividadMsg && <div className="errorInput">{errors.errorConductividadMsg}</div>}
            </FormControl>

            <Stack spacing={2} direction="row" justifyContent="space-between" className="tic4bio-sectores-btns">
                <button className="btn white" onClick={(e) => handlePrev(e)}>Anterior</button>
                <button className="btn green" onClick={(e) => handleNext(e)} style={{margin: "16px 0"}}>Siguiente</button>
            </Stack>
        </Box>
            
        </>
    );
}

export default FormAguaRiegoCampañaComponent;