import { Box, CircularProgress, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useAuth } from "../context/Auth";
import { instance } from "../utils/axios-config";
import { useEffect, useState } from "react";
import ModalInfoBiodiversidad from "../components/modals/ModalInfoBiodiversidad";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ModalSelectBio from "../components/modals/ModalSelectBio";
import zonas from "../assets/Iconos_biodiversidad/zonas.png";
import vegetacion from "../assets/Iconos_biodiversidad/vegetacion.png";
import vertebrados from "../assets/Iconos_biodiversidad/vertebrados.png";
import invertebrados from "../assets/Iconos_biodiversidad/invertebrados.png";
import mejorasbio from "../assets/Iconos_biodiversidad/mejorasbio.svg";
import { Link, useLocation, useParams } from "react-router-dom";
import ModalInfo from "../components/ModalInfo";
import ComunidadesBioCarousel from "../components/carousels/ComunidadesBioCarousel";
import ModalOptionsBio from "../components/modals/ModalOptionsBio";
import ActionMessage from "../components/ActionMessage";
import Mensajes from "../utils/mensajes";
import ModalMejoraBiodiversidad from "../components/modals/ModalMejoraBiodiversidad";

const BiodiversidadPage = () => {

    const {sessionToken} = useAuth();
    const {state} = useLocation();
    const { id } = useParams();

    const [ loading, setLoading ] = useState(true);
    const [ loadingData, setLoadingData ] = useState(false);
    const [ fincas, setFincas ] = useState([]);
    const [ finca, setFinca ] = useState({id: ""});
    const [ comunidades, setComunidades ] = useState([]);
    const [ anos, setAnos ] = useState([]);
    const [ selectedBio, setSelectedBio ] = useState({id: ""});

    const [ openModal, setOpenModal ] = useState(false);
    const [ openModalMejora, setOpenModalMejora ] = useState(false);
    const [ openModalSelect, setOpenModalSelect ] = useState(false);
    const [ openModalOptions, setOpenModalOptions ] = useState(false);

    const [ msg, setMsg ] = useState( state ? state : undefined);
    const [ open, setOpen ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState({title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado. Por favor, vuelve a intentarlo más tarde."});


    useEffect(() => {
        document.title = "Mi biodiversidad";
        loadFincas();
    }, []);


    const loadFincas = async () => {
        let buildUrl = `/fincas/fincasByDataType/finca`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await instance.get(buildUrl).then(function(resp) {
                setFincas(resp.data);
                let foundFinca = resp.data.find(f => f.id === +id);
                if(foundFinca){
                    handleLoadComunidades(foundFinca.id, resp.data);
                }
            }).catch(function(error) {
                setOpen(true);
                setError(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    contentText: "Algo ha fallado al intentar recuperar la información de las fincas. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setLoading(false);
            setOpen(true);
            setError(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                contentText: "Algo ha fallado al intentar recuperar la información de las fincas. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const handleLoadComunidades = async (fincaId, fincasList) => {
        let fincaFound;
        if(fincasList){
            fincaFound = fincasList.find(f => f.id === fincaId);
        }else{
            fincaFound = fincas.find(f => f.id === fincaId);
        }
        let buildUrl = `/biodiversidad/comunidad-bio/${fincaFound.id}`;
        setFinca(fincaFound);
        try{
            setLoadingData(true);
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await instance.get(buildUrl).then(function(resp) {
                let uniqueYears = new Set();

                resp.data.forEach(item => {
                    item.biovegetales.forEach(bv => {
                        const year = bv.ano;
                        year && uniqueYears.add(year);
                    });
                    item.biovertebrados.forEach(bv => {
                        const year = bv.ano;
                        year && uniqueYears.add(year);
                    });
                    item.bioinvertebrados.forEach(bv => {
                        const year = bv.ano;
                        year && uniqueYears.add(year);
                    });
                });

                const sortedYears = Array.from(uniqueYears).sort();
                setAnos(sortedYears);
                setComunidades(resp.data);
            }).catch(function(error) {
                setOpen(true);
                setError(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    contentText: "Algo ha fallado al intentar recuperar la información de las comunidades. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoadingData(false);
            });
        }catch(error){
            setLoadingData(false);
            setOpen(true);
            setError(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                contentText: "Algo ha fallado al intentar recuperar la información de las comunidades. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const handleOpenModalBio = () => {
        setOpenModal(true);
    }

    const handleOpenModalBioMejora = () => {
        setOpenModalMejora(true);
    }

    const handleOpenModalSelectBio = () => {
        setOpenModalSelect(true);
    }

    const selectBio = (bio) => {
        setOpenModalOptions(true);
        setSelectedBio(bio);
    }

    const deleteBiodiversidad = async (item) => {
        let buildUrl;
        switch(item.tipoBio){
            case "biovegetal":
                buildUrl = `/biodiversidad/vegetal/delete/${item.id}`;
                break;
            case "bioinvertebrado":
                buildUrl = `/biodiversidad/invertebrados/delete/${item.id}`;
                break;
            case "biovertebrado":
                buildUrl = `/biodiversidad/vertebrados/delete/${item.id}`;
                break;
        }

        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await instance.delete(buildUrl).then(function(resp) {
                setMsg({type: "action-delete", text: "Se ha eliminado la biodiversidad correctamente."});
                if(item.tipoBio === "biovegetal"){
                    setComunidades(prevData => {
                        const newData = prevData.map(c => {
                            if (c.id === item.comunidad_id) {
                              return { ...c, biovegetales: c.biovegetales.filter(bv => bv.id !== item.id) };
                            }
                            return c;
                          });
                          return newData;
                    });
                }else if(item.tipoBio === "bioinvertebrado"){
                    setComunidades(prevData => {
                        const newData = prevData.map(c => {
                            if (c.id === item.comunidad_id) {
                              return { ...c, bioinvertebrados: c.bioinvertebrados.filter(bv => bv.id !== item.id) };
                            }
                            return c;
                          });
                          return newData;
                    });
                }else{
                    setComunidades(prevData => {
                        const newData = prevData.map(c => {
                            if (c.id === item.comunidad_id) {
                              return { ...c, biovertebrados: c.biovertebrados.filter(bv => bv.id !== item.id) };
                            }
                            return c;
                          });
                          return newData;
                    });
                }
                setOpenModalOptions(false);
            }).catch(function(resp) {
                setErrorMsg({open: false, title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado al intentar eliminar la biodiversidad. Por favor, vuelve a intentarlo más tarde."});
            });
        }catch(error){
            setOpen(true);
            setErrorMsg({open: false, title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado al intentar eliminar la biodiversidad. Por favor, vuelve a intentarlo más tarde."});
        }
    }


    return(
        <>
            <div className="tic4bio-home">
                {msg &&
                    <ActionMessage msg={msg} setMsg={setMsg}/>
                }
            {loading ? 
                (
                    <Box p={3} align="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <div>
                            <button className="btn green" onClick={handleOpenModalSelectBio} style={{fontSize: "16px", fontWeight: "600", display: "flex", alignItems: "center", justifyContent: "center"}}><AddCircleOutlineIcon className="add"/>Nuevo cálculo de biodiversidad</button>
                            <h1 className="tic4bio-home-title">Mi Biodiversidad</h1>
                            <Box component="form" style={{}} noValidate>
                                {!error ?
                                    <>
                                        {(fincas.length > 0) ?
                                            <>
                                                <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                                    <InputLabel htmlFor="almazara">Finca*</InputLabel>
                                                    <Select
                                                        className="t4b-finca-sector-inputs"
                                                        id="finca"
                                                        label="finca"
                                                        required
                                                        MenuProps={{ disableScrollLock: true }}
                                                        value={finca.id}
                                                        onChange={(event) => { handleLoadComunidades(event.target.value); }}
                                                        >
                                                        {fincas.map((f, index) => (
                                                            <MenuItem key={index} value={f.id}>
                                                                {f.unidadProductiva} - {f.nombre}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                                {loadingData ?
                                                    
                                                    <Box p={3} align="center">
                                                        <CircularProgress />
                                                    </Box>
                                                    :
                                                    <>
                                                        {comunidades.length > 0 ?
                                                            <>
                                                                {anos.length > 0 ?
                                                                    <ComunidadesBioCarousel comunidades={comunidades} anos={anos} func={selectBio}/>
                                                                    :
                                                                    <p>Actualmente no tiene ninguna biodiversidad creada en ninguna de sus comunidades. Para poder visualizarlas, primero debe añadir alguna.</p>
                                                                }
                                                            </>
                                                            :
                                                            <>
                                                                {finca?.id &&
                                                                    <p>No tiene ninguna comunidad añadida para esa finca. Primero debe tener al menos una creada.</p>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                <p><Mensajes type="comunidades"/></p>
                                            </>
                                        }
                                    </>
                                    :
                                    <><p>Se ha producido un error inesperado. Vuelva a intentarlo más tarde.</p></>
                                }
                            </Box>
                            <div>

                                <ul className="nodots">
                                    <li>
                                        <Link className="enlace" to={"/biodiversidad/vegetal/detalles"}>
                                            <div className="tic4bio-seccion tic4bio-biodiversidad-img">
                                                <img src={vegetacion} alt={"Imagen vegetación"}/>
                                                <h3>Vegetación</h3>
                                            </div> 
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="enlace" to={"/biodiversidad/vertebrados/detalles"}>
                                            <div className="tic4bio-seccion tic4bio-biodiversidad-img">
                                                <h3>Vertebrados</h3>
                                                <img src={vertebrados} alt={"Imagen vertebrados"}/>
                                            </div> 
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="enlace" to={"/biodiversidad/invertebrados/detalles"}>
                                            <div className="tic4bio-seccion tic4bio-biodiversidad-img">
                                                <img src={invertebrados} alt={"Imagen invertebrados"}/>
                                                <h3>Invertebrados</h3>
                                            </div> 
                                        </Link>
                                    </li>
                                </ul>
                                <Link style={{textDecoration: "none"}} to={"/biodiversidad/comunidades"}>
                                    <div className="btn white" style={{fontSize: "16px", fontWeight: "600", display: "flex", alignItems: "center", justifyContent: "center", boxSizing: "border-box"}}><img src={zonas} style={{width: "40px"}}/>Comunidades / Zonas</div>
                                </Link>
                            </div>
                            <button className="btn green" onClick={handleOpenModalBio} style={{fontSize: "16px", fontWeight: "600"}}>¿Qué necesitas saber para analizar la biodiversidad?</button>
                            <button className="btn green" onClick={handleOpenModalBioMejora} style={{fontSize: "16px", fontWeight: "600", display: "flex", alignItems: "center", justifyContent: "center", boxSizing: "border-box", gap: "6px"}}><img src={mejorasbio} style={{width: "40px"}}/>Mejora de la biodiversidad</button>
                        </div>
                    </>
                )
            }
            </div>
            <ModalSelectBio open={openModalSelect} setOpen={setOpenModalSelect}/>
            <ModalOptionsBio open={openModalOptions} setOpen={setOpenModalOptions} item={selectedBio} func={deleteBiodiversidad}/>
            <ModalInfoBiodiversidad open={openModal} setOpen={setOpenModal}/>
            <ModalMejoraBiodiversidad open={openModalMejora} setOpen={setOpenModalMejora}/>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.title} contentText={errorMsg.content}/>
        </>
    );
}

export default BiodiversidadPage;