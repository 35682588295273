import { useEffect, useState } from "react";
import { instance } from '../utils/axios-config.js';
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useLocation, useParams, Link } from 'react-router-dom';
import ModalInfo from '../components/ModalInfo.js';
import { useAuth } from "../context/Auth.js";
import { groupByUnidad } from "../utils/commons.js";
import ActionMessage from "../components/ActionMessage.js";
import ModalTip from "../components/ModalTip.js";
import DoughnutUnidad from "../components/charts/DoughnutUnidad.js";
import Mensajes from "../utils/mensajes";

const HuellaUnidadesPage = () => {

    const {sessionToken} = useAuth();
    const { id } = useParams();
    const {state} = useLocation();

    const [ unidades, setUnidades ] = useState([]);
    const [ unidad, setUnidad ] = useState({id: ""});
    
    const [ huellas, setHuellas ] = useState([]);
    const [ huella, setHuella ] = useState({campana: ""});
    const [ loading, setLoading ] = useState(true);
    const [ loadingHuellas, setLoadingHuellas ] = useState(false);
    const [ msg, setMsg ] = useState(() => {
        if(state && state.type){
            return state;
        }else{
            return null;
        }
    });
    const [ openTip, setOpenTip ] = useState(false);
    const [ msgTip, setMsgTip ] = useState({open: false, content: ''});
    const [ open, setOpen ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState({open: false, title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado. Por favor, vuelve a intentarlo más tarde."});
    
    const [ data, setData ] = useState({
        labels: ['HA Azul', 'HA Verde'],
        datasets: [
            {
              label: 'Huella de agua',
              data: [],
              backgroundColor: [
                'rgba(122,154,200, 0.8)',
                'rgba(128, 198, 6, 0.8)',
              ],
              borderColor: [
                  'rgba(122,154,200, 0.8)',
                  'rgba(128, 198, 6, 0.8)',
              ],
              borderWidth: 2
            },
        ],
    });
    const [ extra, setExtra ] = useState({value: 0, text: "", labelText: ""});

    const [ dataAceite, setDataAceite ] = useState({
        labels: ['HA Azul', 'HA Verde'],
        datasets: [
            {
              label: 'Huella de agua',
              data: [],
              backgroundColor: [
                'rgba(122,154,200, 0.8)',
                'rgba(128, 198, 6, 0.8)',
              ],
              borderColor: [
                  'rgba(122,154,200, 0.8)',
                  'rgba(128, 198, 6, 0.8)',
              ],
              borderWidth: 2
            },
        ],
    });
    const [ extraAceite, setExtraAceite ] = useState({value: 0, text: "", labelText: ""});


    useEffect(() => {
        
        loadPageData();
        
    }, []);


    const loadPageData = async () => {
        let buildUrl = `/fincas/unidades`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await instance.get(buildUrl).then(function(resp) {
                let groupUnidades = groupByUnidad(resp.data);
                let unidades = Object.entries(groupUnidades);
                setUnidades(unidades);
                
                //let unidadFind = unidades[0][1].find((u) => u.id === +id);

                let unidadFind;
                for (let i = 0; i < unidades.length; i++) {
                    const [unitName, items] = unidades[i];
                    for (let j = 0; j < items.length; j++) {
                        if (items[j].id === +id) {
                            unidadFind = unidades[i];
                            break;
                        }
                    }
                    if (unidadFind) break;
                }

                unidadFind = unidadFind[1][0];

                if(unidadFind){
                    setUnidad(unidadFind);
                    loadHuellasOnChange(unidadFind.unidadProductiva);
                }

            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar la información de las unidades productivas. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setLoading(false);
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar la información de las unidades productivas. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }


    const loadHuellasOnChange = async (unidadProductiva) => {
        try{
            let buildUrl = `/huella/unidades-list/` + unidadProductiva;
            setLoadingHuellas(true);
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
            await instance.get(buildUrl).then(function(resp) {
                
                let huellas = resp.data;
                setHuellas(huellas);

                if(state?.campana){
                    let huellaAux = huellas.find((h) => h.campana === state.campana);
                    setHuella(huellaAux);
                    updateDataCharts(huellaAux);
                }
                
            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar las campañas de la unidad productiva y el sector. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoadingHuellas(false);
            });
            setLoadingHuellas(false);
        }catch(error){
            setLoadingHuellas(false);
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar los sectores de la unidad productiva y el sector. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }


    const handleLoadHuella = async (event) => {
        let unidad = null;
        unidades.forEach((u) => {
            const objects = u[1];
            const found = objects.find((obj) => obj.id === event.target.value);
            if (found) {
            unidad = found;
            }
        });
        setUnidad(unidad);
        setHuella({campana: ""});
        loadHuellasOnChange(unidad.unidadProductiva);
    }

    const handleHuellaSelect = (event) => {
        let huella = huellas.find((h) => h.campana === event.target.value);
        updateDataCharts(huella);
    }

    const updateDataCharts = (huella) => {
        setHuella(huella);
        
        setData(prevState => ({
            ...prevState,
            datasets: prevState.datasets.map(dataset => ({
                ...dataset,
                data: [huella.LKG_Aceituna_Azul, huella.LKG_Aceituna_Verde]
            })),
        }));
        setExtra({value: huella.LKG_Aceituna_Total, text: " L/kg aceituna", labelText: "L/kg"});

        setDataAceite(prevState => ({
            ...prevState,
            datasets: prevState.datasets.map(dataset => ({
                ...dataset,
                data: [huella.LKG_Aceite_Azul, huella.LKG_Aceite_Verde]
            })),
        }));
        setExtraAceite({value: huella.LKG_Aceite_Total, text: " L/L Aceite", labelText: "L/L Aceite"});
    }
   
    return(
        <div className="width100">
            <div className="tic4bio-huella-section">UNIDAD PRODUCTIVA</div>

            <div className="tic4bio-home">
                {msg?.type &&
                    <ActionMessage msg={msg} setMsg={setMsg}/>
                }
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    {loading ? 
                        (
                            <Box p={3} align="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <div>
                                    <h1 className="tic4bio-home-title">Huella de Agua</h1>
                                    {(unidades.length > 0) ?
                                        <>
                                            <p>Seleccione la Unidad productiva del resumen de huella que desea visualizar*</p>
                                            <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                <InputLabel htmlFor="unidad-select">Unidades*</InputLabel>
                                                <Select
                                                    className="t4b-finca-sector-inputs"
                                                    id="unidad-select"
                                                    label="unidad"
                                                    required
                                                    MenuProps={{ disableScrollLock: true }}
                                                    disabled={loadingHuellas}
                                                    value={unidad.id}
                                                    onChange={(event) => { handleLoadHuella(event); }    
                                                    }
                                                    >
                                                    {unidades.map((unidad, index) => (
                                                    <MenuItem key={index} value={unidad[1][0].id}>
                                                        {unidad[0]}
                                                    </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                                       
                                            {loadingHuellas ? (
                                                <Box p={3} align="center">
                                                    <CircularProgress />
                                                </Box>
                                                ) : (
                                                    <>
                                                        {unidad.id &&
                                                            <>
                                                                {(huellas.length > 0) ?
                                                                    <>
                                                                        <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                                            <InputLabel htmlFor="sector-select">Campañas*</InputLabel>
                                                                            <Select
                                                                                className="t4b-finca-sector-inputs"
                                                                                id="sector-select"
                                                                                label="Sector"
                                                                                required
                                                                                MenuProps={{ disableScrollLock: true }}
                                                                                value={huella.campana}
                                                                                onChange={(event) => { handleHuellaSelect(event) }
                                                                                }>
                                                                            
                                                                                {huellas.map((huella, index) => (
                                                                                    <MenuItem key={index} value={huella.campana}>
                                                                                        {huella.campana}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                        {huella.campana !== "" &&
                                                                            <>
                                                                                {huella.almazaras.length > 0 && huella.fincas.length > 0 ?
                                                                                    <>
                                                                                        <div className="chart-container">
                                                                                            <div className="chart-pos-center">
                                                                                                <h3>Huella de agua de unidad productiva: {huella.nombre}</h3>
                                                                                                <DoughnutUnidad data={data} extra={extra}/>
                                                                                                <DoughnutUnidad data={dataAceite} extra={extraAceite}/>
                                                                                            </div>
                                                                                            <div>
                                                                                                <h4>Información de huella de agua:</h4>
                                                                                                <div className="chart-summary-box">
                                                                                                    <table className="chart-summary" style={{marginTop: "30px", marginBottom: "46px"}}>
                                                                                                        <thead>
                                                                                                            <tr className="chart-summary-head">
                                                                                                                <th style={{border: 0}}></th>
                                                                                                                <th style={{backgroundColor:"#5b9bd5",borderTop: "2px solid #000",borderBottom: "2px solid #000",borderRight: "1px solid #000"}}>HA Azul</th>
                                                                                                                <th style={{backgroundColor:"#5b9bd5",borderTop: "2px solid #000",borderBottom: "2px solid #000",borderLeft: "1px solid #000"}}>HA Verde</th>
                                                                                                                <th style={{backgroundColor:"#5b9bd5",borderTop: "2px solid #000",borderBottom: "2px solid #000",borderLeft: "1px solid #000"}}>HA</th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody className="chart-striped">
                                                                                                            <tr>
                                                                                                                <td style={{borderTop: "2px solid", borderbottom: "2px solid"}}>
                                                                                                                    <span>Finca</span><span>(L/Kg Aceituna)</span>
                                                                                                                </td>
                                                                                                                <td style={{borderTop: "2px solid", borderbottom: "2px solid"}}>
                                                                                                                    {huella.HA_Finca_Azul}
                                                                                                                </td>
                                                                                                                <td style={{borderTop: "2px solid", borderbottom: "2px solid"}}>
                                                                                                                    {huella.HA_Finca_Verde}
                                                                                                                </td>
                                                                                                                <td style={{borderTop: "2px solid", borderbottom: "2px solid"}}>
                                                                                                                    {(huella.HA_Finca_Azul + huella.HA_Finca_Verde)?.toFixed(2)}
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td style={{borderTop: "2px solid", borderbottom: "2px solid"}}>
                                                                                                                    <span>Almazara</span><span>(L/Kg Aceituna)</span>
                                                                                                                </td>
                                                                                                                <td style={{borderTop: "2px solid", borderbottom: "2px solid"}}>
                                                                                                                    {huella.almazaras[0].huellaAguaKg?.toFixed(2)}
                                                                                                                </td>
                                                                                                                <td style={{borderTop: "2px solid", borderbottom: "2px solid"}}>
                                                                                                                    -
                                                                                                                </td>
                                                                                                                <td style={{borderTop: "2px solid", borderbottom: "2px solid"}}>
                                                                                                                    {huella.almazaras[0].huellaAguaKg?.toFixed(2)}
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td style={{borderTop: "2px solid"}}>
                                                                                                                    <span>Unidad Productiva</span><span>(L/Kg Aceituna)</span>
                                                                                                                </td>
                                                                                                                <td style={{borderTop: "2px solid"}}>
                                                                                                                    {huella.LKG_Aceituna_Azul}
                                                                                                                </td>
                                                                                                                <td style={{borderTop: "2px solid"}}>
                                                                                                                    {huella.LKG_Aceituna_Verde}
                                                                                                                </td>
                                                                                                                <td style={{borderTop: "2px solid"}}>
                                                                                                                    {huella.LKG_Aceituna_Total}
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span>(L/Kg Aceite)</span>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {huella.LKG_Aceite_Azul}
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {huella.LKG_Aceite_Verde}
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    {huella.LKG_Aceite_Total}
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td style={{borderBottom: "2px solid"}}>
                                                                                                                    <span>(L/L Aceite)</span>
                                                                                                                </td>
                                                                                                                <td style={{borderBottom: "2px solid"}}>
                                                                                                                    {huella.LL_Aceite_Azul}
                                                                                                                </td>
                                                                                                                <td style={{borderBottom: "2px solid"}}>
                                                                                                                    {huella.LL_Aceite_Verde}
                                                                                                                </td>
                                                                                                                <td style={{borderBottom: "2px solid"}}>
                                                                                                                    {huella.LL_Aceite_Total}
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </tbody>
                                                                                                    </table>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div>
                                                                                                <h4>Información adicional:</h4>
                                                                                                <ul>
                                                                                                    <li>Productividad del agua Unidad Productiva (€/m³): {huella.HA_Extendida_EURM} €/m³ ({huella.HA_Extendida_EURL} €/l)</li>
                                                                                                    <li>% HA azul respecto de HA unidad productiva: {huella.HA_Azul_Porcentaje}%</li>
                                                                                                    <li>% HA verde respecto de HA unidad productiva: {huella.HA_Verde_Porcentaje}%</li>
                                                                                                    <li>Fracción de HA correspondiente al cultivo: {huella.HA_Cultivo_Porcentaje}%</li>
                                                                                                    <li>Fracción de HA correspondiente a la almazara: {huella.HA_Almazara_Porcentaje}%</li>
                                                                                                </ul>
                                                                                            </div>
                                                                                            
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <p>El periodo seleccionado para la unidad productiva no posee o bien, la huella de agua de una almazara, o no tiene la huella de agua de al menos uno de los sectores asociados a la finca.</p>
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <p>Parece que la unidad productiva seleccionada no tiene Campañas asociadas. Necesitas al menos una campaña para poder visualizar su detalle.</p>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                )
                                            }
                                                       
                                        </>
                                        :
                                        <p><Mensajes type="unidades" /></p>
                                    }
                                    
                                </div>
                            </>
                        )
                    }
                </Box>
            </div>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.title} contentText={errorMsg.content}/>
            <ModalTip open={openTip} setOpen={setOpenTip} contentText={msgTip.content}/>
        </div>
    );
} 
export default HuellaUnidadesPage;