import { Box, CircularProgress, FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useAuth } from "../../context/Auth";
import { instance } from "../../utils/axios-config";
import ModalTip from "../ModalTip";
import ModalInfo from "../ModalInfo";
import Mensajes from "../../utils/mensajes";
import dayjs from "dayjs";

const FormBioStepOneComponent = ({position, setPosition, stateForm, setStateForm}) => {

    const {sessionToken} = useAuth();

    const [fincas, setFincas] = useState([]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(false);
    const [openTip, setOpenTip] = useState(false);
    const [ msgTip, setMsgTip ] = useState({open: false, content: ''});
    const [errors, setErrors] = useState({});
    const [errorMsg, setErrorMsg] = useState({
        contentTitle: "¡Vaya! Parece que algo ha ido mal.",
        contentText: ""
    });


    useEffect(() => {
        loadFincas();
    }, []);

    const loadFincas = async () => {
        let buildUrl = `/fincas/fincasByDataType/finca`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await instance.get(buildUrl).then(function(resp) {
                setFincas(resp.data);
            }).catch(function(error) {
                setOpen(true);
                setError(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    contentText: "Algo ha fallado al intentar recuperar la información de las fincas. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setLoading(false);
            setOpen(true);
            setError(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                contentText: "Algo ha fallado al intentar recuperar la información de las fincas. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const handleSelectFinca = (event) => {
        let finca = fincas.find((a) => a.id === event.target.value);
        setStateForm(prevData => ({
            ...prevData,
            finca: finca,
            comunidad: {id: ""}}));
    }

    const checkErrorFecha = () => {
        let result = false;
        if(errors.errorFecha){
            result = true;
        }
        return result;
    }

    const handleOpenTip = (type) => {
        setOpenTip(true);
        let _html = '';
        if(type === 0){
            _html = '<div class="modaltip"><h4>El usuario deberá realizar un inventario del <b>orden taxonómico de los invertebrados</b> presentes en su finca e introducir información sobre <b>el nº de individuos</b> de cada uno.<br> Con esta información se determinará un <b>índice de abundancia de especies adaptado para estimar la abundancia de órdenes</b> de invertebrados presentes en la finca. Su cálculo requiere de un mayor esfuerzo en la fase de toma de datos ( contar individuos de cada órden), pero el indicador final que se obtiene es de gran utilidad para evaluar la evolución de biodiversidad entre campañas.</h4></div>';
        }else{
            _html = '<div class="modaltip"><h4>El usuario solamente deberá realizar un inventario del <b>orden taxonómico</b> de los invertebrados presentes en su finca. No será necesario introducir información sobre el nº de individuos de cada uno.<br> El indicador de riqueza específica (S), informa sobre el nº de órdenes de especies presentes en la comunidad. Es más sencillo de cuantificar, pero menos útil a la hora de evaluar mejoras de biodiversidad entre campañas.</h4></div>';
        }
        setMsgTip((msg) => ({
            ...msg,
            content: _html
        }));
    }

    const validateForm = () =>{
        setErrors({});
        let inputIds = "";
        let valid = true;
        if(stateForm.finca.id === undefined || stateForm.finca.id === ''){
            setErrors(errors => ({...errors, errorFinca: true, errorFincaMsg: ""}));
            inputIds = inputIds ? inputIds +",finca" : "finca";
            valid = false;
        }
        if(stateForm.fecha === undefined || stateForm.fecha === ""){
            setErrors(errors => ({...errors, errorFecha: true, errorFechaMsg: ""}));
            inputIds = inputIds ? inputIds +",fecha" : "fecha";
            valid = false;
        }

        const fechaActual = new Date();
        const fechaSoloDia = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), fechaActual.getDate());

        if(stateForm.fecha !== "" && stateForm.fecha !== ""){
            const dateStart = new Date(stateForm.fecha);
            const dateStartSoloDia = new Date(dateStart.getFullYear(), dateStart.getMonth(), dateStart.getDate());
            if(fechaSoloDia < dateStartSoloDia){
                setErrors(errors => ({...errors, errorFecha: true, errorFechaMsg: "La fecha no puede ser superior a la actual."}));
                inputIds = inputIds ? inputIds +",fecha" : "fecha";
                valid = false;
            }
        }

        if(stateForm.tipoAnalisis === ""){
            setErrors(errors => ({...errors, errorTipoAnalisis: true, errorTipoAnalisisMsg: "Por favor, seleccione el tipo de análisis que va a realizar."}));
            inputIds = inputIds ? inputIds +",tipoAnalisis" : "tipoAnalisis";
            valid = false;
        }

        if(inputIds !== ""){
            try{
                let elem = document.getElementById(inputIds.split(",")[0]);
                elem.scrollIntoView({behavior: "smooth"});
                elem.focus();
            }catch(err){

            }
        }
        return valid;
    }

    const handleChangeTipoAnalisis = (v) => {
        setStateForm(prevData => ({
            ...prevData,
            tipoAnalisis: v,
            metodoMuestreo: {
                muestreosuperficiesuelo: false,
                trampasparaadultos: false
            },
            comunidad: {id: ""},
            muestreos: {}
        }
        ));
    }

    const handleNext = (e) => {
        e.preventDefault();
        if(validateForm()){
            setPosition(1)
        }
    };

    return(
        <>
        <Box className={position !== 0 && 'hide'}>
            {loading ? 
                (
                    <Box p={3} align="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {!error ? 
                            <>
                                <h1 className="tic4bio-home-title">Nueva Biodiversidad Invertebrados</h1>
                                {(fincas.length > 0) ?
                                    <>
                                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                            <InputLabel htmlFor="finca">Finca*</InputLabel>
                                            <Select
                                                className="t4b-finca-sector-inputs"
                                                id="finca"
                                                label="Finca"
                                                required
                                                MenuProps={{ disableScrollLock: true }}
                                                value={stateForm.finca.id}
                                                onChange={(event) => { handleSelectFinca(event); }}
                                                error={errors.errorFinca}
                                                >
                                                {fincas.map((finca, index) => (
                                                <MenuItem key={index} value={finca.id}>
                                                    {finca.unidadProductiva} - {finca.nombre}
                                                </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.errorFinca && <div className="errorMsg">{errors.errorFincaMsg}</div>}
                                        </FormControl>

                                        
                                        <FormControl sx={{width: "100%", marginTop: "30px", marginBottom: "30px"}} variant="standard">
                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                                <DatePicker
                                                    label="Fecha*"
                                                    id="fecha"
                                                    value={dayjs(stateForm.fecha)}
                                                    slotProps={{
                                                        textField: {
                                                        error: checkErrorFecha(),
                                                        },
                                                    }}
                                                    onChange={(newValue) => {
                                                        const selectedDate = dayjs(newValue).format('YYYY-MM-DD');
                                                        setStateForm(prevData => ({
                                                            ...prevData,
                                                            fecha: selectedDate}
                                                        ));
                                                    }}
                                                />
                                                <p className="tip">Los muestreos para el estudio de biodiversidad es aconsejable realizarlos una vez por campaña, en primavera.</p>
                                            </LocalizationProvider>
                                            {errors.errorFecha && <div className="errorMsg">{errors.errorFechaMsg}</div>}
                                        </FormControl>

                                        <FormControl sx={{width: "100%", marginBottom: "20px"}} className="radio-green radio-column" variant="standard">
                                            <FormLabel id="tipoanalisis-label">Tipo de análisis de biodiversidad:</FormLabel>
                                            <RadioGroup
                                                name="tipoAnalisis"
                                                id="tipoAnalisis"
                                                aria-labelledby="tipoAnalisis-label"
                                                value={stateForm.tipoAnalisis}
                                                onChange={(e) => { handleChangeTipoAnalisis(e.target.value) }}
                                            >
                                                <div className="radio-tip">
                                                    <FormControlLabel value="1" control={<Radio />} label="Análisis básico" /><span className="modaltip-icon" onClick={() => { handleOpenTip(1) }}>?</span>
                                                </div>
                                                <div className="radio-tip">
                                                    <FormControlLabel value="0" control={<Radio />} label="Análisis avanzado" /><span className="modaltip-icon" onClick={() => { handleOpenTip(0) }}>?</span>
                                                </div>
                                            </RadioGroup>
                                            {errors.errorTipoAnalisis && <div className="errorMsg">{errors.errorTipoAnalisisMsg}</div>}
                                        </FormControl>

                                        
                                        <Stack spacing={2} direction="row" justifyContent="space-between" className="tic4bio-sectores-btns">
                                            <button className="btn green" onClick={(e) => handleNext(e)} style={{margin: "16px 0"}}>Siguiente</button>
                                        </Stack>
                                    </>
                                    :
                                    <>
                                        <p><Mensajes type="vegetal"/></p>
                                    </>
                                }
                            </>
                            : 
                            <p>
                                Se ha producido un error inesperado. Vuelva a intentarlo más tarde.
                            </p>
                        }
                    </>
                )
            }
        </Box>
        <ModalTip open={openTip} setOpen={setOpenTip} contentText={msgTip.content}/>
        <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.contentTitle} contentText={errorMsg.contentText}/>
        </>
    )
}

export default FormBioStepOneComponent;