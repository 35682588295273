import { useEffect, useState, Fragment } from "react";
import { instance } from '../utils/axios-config.js';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { useParams, useLocation, Link } from 'react-router-dom';
import ModalInfo from '../components/ModalInfo';
import sectores from '../assets/lateralmenu/MisUnidades.png';
import riego from '../assets/lateralmenu/AñadirRiegoManual.png';
import ha from '../assets/lateralmenu/HuelladeAgua.png';
import bio from '../assets/lateralmenu/Biodiversidad.png';
import EditIcon from '@mui/icons-material/Edit';
import ModalEditDotacionFinca from "../components/modals/ModalEditDotacionFinca.js";
import ActionMessage from "../components/ActionMessage.js";




const DetallesFincaPage = () => {

    const { id } = useParams();
    const location  = useLocation();
    const nombreUnidad = location.state;
    const [ loading, setLoading ] = useState(true);
    const [ open, setOpen ] = useState(false);
    const [ openEdit, setOpenEdit ] = useState(false);
    const [ finca, setFinca] = useState();
    const [ error, setError ] = useState(false);
    const [showData, setShowData] = useState(false);
    const [ msg, setMsg ] = useState();
    const [errorMsg, setErrorMsg] = useState({
        contentTitle: "¡Vaya! Parece que algo ha ido mal.",
        contentText: ""
    });

    useEffect(() => {
        loadUnidadData();
    }, []);

    const loadUnidadData = async () => {
        let buildUrl = `/fincas/finca/${id}`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
            await instance.get(buildUrl).then(function(resp) {
                setFinca(resp.data[0]);
            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg({
                    contentTitle: "¡Vaya! Parece que algo ha ido mal.",
                    contentText: "Algo ha fallado al intentar recuperar las Fincas de la Unidad. Por favor, vuelve a intentarlo más tarde."
                });
                setError(true);
            }).finally(()=>{
                setLoading(false);
            });
            
            setLoading(false);
        }catch(error){
            setOpen(true);
            setErrorMsg({
                contentTitle: "¡Vaya! Parece que algo ha ido mal.",
                contentText: "Algo ha fallado al intentar recuperar las Fincas de la Unidad. Por favor, vuelve a intentarlo más tarde."
            });
            setError(true);
            setLoading(false);
        }
    }

    return(
        <>
         <div className="tic4bio-home">
            {msg &&
                <>
                    <ActionMessage msg={msg} setMsg={setMsg}/>
                </>
            }
                    {loading ? 
                        (
                            <Box p={3} align="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                {error ?
                                    (
                                        <p className="tic4bio-fincas-empty">Se ha producido un error al recuperar el detalle. Por favor, inténtelo de nuevo más tarde.</p>
                                    ):(
                                        <>
                                            <div>
                                                <h3 className="tic4bio-home-title sectores">Unidad Productiva:</h3>
                                                <h1 style={{color: "#80C606"}}>{nombreUnidad}</h1>
                                                <button className="button detalles-finca" onClick={() => setShowData(!showData)}>{finca.dataType === "industria" ? "Detalles Industria" : "Detalles Finca"}</button>
                                            </div>
                                            {showData && (
                                                <div className="tic4bio-sectores detalles-finca">
                                                    {finca.dataType !== "industria" &&
                                                        <IconButton
                                                            size="large"
                                                            edge="start"
                                                            className="editDotacion"
                                                            aria-label="Editar dotación"
                                                            onClick={() => {setOpenEdit(true)}}
                                                            >
                                                            <span>Editar Dotación de riego</span>
                                                            <EditIcon />
                                                        </IconButton>
                                                    }
                                                    <div className="tic4bio-sectores detalles-finca-container">
                                                        <p>Nombre: <span>{finca.nombre}</span></p>
                                                        <p>Provincia: <span>{finca.provincia}</span></p>
                                                        <p>Municipio: <span>{finca.municipio}</span></p>
                                                        {finca.dataType !== "industria" &&
                                                            <>
                                                                <p>Latitud: <span>{finca.latitud}</span></p>
                                                                <p>Dotación de Riego: <span>{finca.dotacionRiego} m<sup>3</sup>/ha</span></p>
                                                                <p>Cota de Localidad: <span>{finca.cotaLocalidad}</span></p>
                                                            </>
                                                        }
                                                    </div>
                                                </div>
                                            )}
                                            {finca.dataType === "finca" && (
                                                <Fragment>
                                                    <Link className="enlace" to={"/listaSectores/" + finca.id}>
                                                        <div className="tic4bio-seccion">
                                                            <img style={{marginBottom: "20px"}} src={sectores} alt={"Imagen sectores"}/>
                                                            <h3 className="sectores">Sectores</h3>
                                                        </div> 
                                                    </Link>
                                                </Fragment>
                                            )}
                                            {finca.dataType === "finca" && (
                                                <Fragment>
                                                    <Link className="enlace" to={"/anadirRiego/" + finca.id} state={nombreUnidad}>
                                                        <div className="tic4bio-seccion">
                                                            <img src={riego} alt={"Imagen riego"}/>
                                                            <h3 className="sectores">Añadir Riego Manualmente</h3>
                                                        </div> 
                                                    </Link>
                                                </Fragment>
                                            )}
                                            <Fragment>
                                                <Link className="enlace" to={(finca.dataType === "finca" ? "/lista-huellas/" : "/lista-huellas-almazara/") + finca.id}>
                                                    <div className="tic4bio-seccion">
                                                        <img src={ha} alt={"Imagen ha"}/>
                                                        <h3 className="sectores">Huella de Agua</h3>
                                                    </div> 
                                                </Link> 
                                            </Fragment>
                                            {finca.dataType === "finca" &&
                                                <Fragment>
                                                    <Link className="enlace" to={"/biodiversidad/" + finca.id}>
                                                        <div className="tic4bio-seccion">
                                                            <img src={bio} alt={"Imagen riego"}/>
                                                            <h3 className="sectores">Biodiversidad</h3>
                                                        </div> 
                                                    </Link>
                                                </Fragment>
                                            }
                                        </>
                                    )
                                }
                            </>
                        )
                    }
            </div>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.contentTitle} contentText={errorMsg.contentText}/>
            {finca &&
                <ModalEditDotacionFinca open={openEdit} setOpen={setOpenEdit} finca={finca} setFinca={setFinca} setMsg={setMsg} setOpenError={setOpen} setErrorMsg={setErrorMsg}/>
            }
    </>
    );
} 
export default DetallesFincaPage;