import { useEffect, useState } from "react";
import FormNuevaIndustriaComponent from "../components/formsIndustria/FormNuevaIndustria";
import FormMaquinariaIndustriaComponent from "../components/formsIndustria/FormMaquinariaIndustria";
import { useLocation, useNavigate } from "react-router-dom";

const NuevaIndustriaPage = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const nuevaUnidad = location.state;

    useEffect(() => {
        if(!nuevaUnidad){
            navigate("/nuevaUnidadProductiva");
        }
    }, []);

    const [ position, setPosition ] = useState(0);
    const [dataForm, setDataForm] = useState({
        nombre : "",
        provincia : "",
        municipio : "",
        provincia_id : "",
        municipio_id: "",
        capacidadMolturacion: "",
        centrifugadora: false,
        decantor: false,
        batidora: false,
        molino: false,
        ducha: false,
        lavadora: false,
        contadorGeneral: false,
        dataType: 'industria',
        unidadProductiva: nuevaUnidad
    });

    return(
        <>
            
            <FormNuevaIndustriaComponent position={position} setPosition={setPosition} dataForm={dataForm} setDataForm={setDataForm}/>

            
            <FormMaquinariaIndustriaComponent position={position} setPosition={setPosition} dataForm={dataForm} setDataForm={setDataForm}/>
            
        </>
    );
}
export default NuevaIndustriaPage;