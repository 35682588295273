import { Box, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/Auth";
import { instance } from "../../utils/axios-config";
import ModalInfo from "../ModalInfo";

const FormBioStepTwoComponent = ({position, setPosition, stateForm, setStateForm}) => {

    const {sessionToken} = useAuth();

    const [comunidades, setComunidades] = useState([]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorMsg, setErrorMsg] = useState({
        contentTitle: "¡Vaya! Parece que algo ha ido mal.",
        contentText: ""
    });


    useEffect(() => {
        loadComunidades();
    }, []);

    const loadComunidades = async () => {
        let buildUrl = `/biodiversidad/comunidad-list/${stateForm.finca.id}`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await instance.get(buildUrl).then(function(resp) {
                setComunidades(resp.data);
            }).catch(function(error) {
                setOpen(true);
                setError(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    contentText: "Algo ha fallado al intentar recuperar la información de las comunidades. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setLoading(false);
            setOpen(true);
            setError(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                contentText: "Algo ha fallado al intentar recuperar la información de las comunidades. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const handleSelectComunidad = (event) => {
        let comunidad = comunidades.find((a) => a.id === event.target.value);
        setStateForm(prevData => ({
            ...prevData,
            comunidad: comunidad}));
    }


    const validateForm = () =>{
        setErrors({});
        let inputIds = "";
        let valid = true;
        if(stateForm.comunidad.id === undefined || stateForm.comunidad.id === ''){
            setErrors(errors => ({...errors, errorComunidad: true, errorComunidadMsg: ""}));
            inputIds = inputIds ? inputIds +",comunidad" : "comunidad";
            valid = false;
        }

        if(inputIds !== ""){
            try{
                let elem = document.getElementById(inputIds.split(",")[0]);
                elem.scrollIntoView({behavior: "smooth"});
                elem.focus();
            }catch(err){

            }
        }
        return valid;
    }

    const handlePrev = (e) => {
        e.preventDefault();
        setPosition(0);
    }

    const handleNext = (e) => {
        e.preventDefault();
        if(validateForm()){
            setPosition(2);
        }
    };

    return(
        <>
        <Box className={position !== 1 && 'hide'}>
            {loading ? 
                (
                    <Box p={3} align="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {!error ? 
                            <>
                                <h1 className="tic4bio-home-title">Nueva Biodiversidad Vertebrados</h1>
                                <p style={{color: "rgba(0, 0, 0, 0.6)", margin: "42px 0px"}}>Finca seleccionada: <span style={{color: "#80C606", fontSize: "20px", fontWeight: "700"}}>{stateForm.finca.nombre}</span></p>
                                {(comunidades.length > 0) ?
                                    <>
                                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                            <InputLabel htmlFor="comunidad">Comunidad vegetal*</InputLabel>
                                            <Select
                                                className="t4b-finca-sector-inputs"
                                                id="comunidad"
                                                label="Comunidad"
                                                required
                                                MenuProps={{ disableScrollLock: true }}
                                                value={stateForm.comunidad.id}
                                                onChange={(event) => { handleSelectComunidad(event); }}
                                                error={errors.errorComunidad}
                                                >
                                                {comunidades.map((comunidad, index) => (
                                                <MenuItem key={index} value={comunidad.id}>
                                                    {comunidad.nombre}
                                                </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.errorComunidad && <div className="errorMsg">{errors.errorComunidadMsg}</div>}
                                        </FormControl>

                                    </>
                                    :
                                    <>
                                        <p>No tiene ninguna comunidad creada para esta finca. Para crear un nuevo cálculo de biodiversidad, debe tener al menos una asociada.</p>
                                    </>
                                }
                                <Stack spacing={2} direction="row" justifyContent="space-between" className="tic4bio-sectores-btns">
                                    <button className="btn white" onClick={(e) => handlePrev(e)}>Anterior</button>
                                    {(comunidades.length > 0) &&
                                        <button className="btn green" onClick={(e) => handleNext(e)} style={{margin: "16px 0"}}>Siguiente</button>
                                    }
                                </Stack>
                            </>
                            : 
                            <p>
                                Se ha producido un error inesperado. Vuelva a intentarlo más tarde.
                            </p>
                        }
                    </>
                )
            }
        </Box>
        <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.contentTitle} contentText={errorMsg.contentText}/>
        </>
    )
}

export default FormBioStepTwoComponent;