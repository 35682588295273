import { useEffect, useState } from "react";
import { useAuth } from "../context/Auth";
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import ModalInfo from "../components/ModalInfo";
import { Chart, CategoryScale, LogarithmicScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { instance } from "../utils/axios-config";
import ChartAdvanced from "../components/charts/ChartAdvanced";

Chart.register(CategoryScale, LogarithmicScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AvanzadoPage = () => {

    const {sessionToken} = useAuth();
    const [ loading, setLoading ] = useState(true);
    const [ loadingSectores, setLoadingSectores ] = useState(false);
    const [ loadingChart, setLoadingChart ] = useState(false);
    const [ fincas, setFincas ] = useState([]);
    const [ finca, setFinca ] = useState({id: ""});
    const [ sectores, setSectores ] = useState([]);
    const [ sector, setSector ] = useState({id: ""});
    const [ fechaInicio, setFechaInicio] = useState("");
    const [ fechaFin, setFechaFin] = useState("");
    const [ data, setData] = useState([]);
    const [ errorDates, setErrorDates ] = useState(false);
    const [ open, setOpen ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState({title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado. Por favor, vuelve a intentarlo más tarde."});
    

    useEffect(() => {
        document.title = "Avanzado";
        loadFincas();
    }, []);

    const loadFincas = async () => {
        let buildUrl = `/fincas/fincasByDataType/finca`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await instance.get(buildUrl).then(function(resp) {
                setFincas(resp.data);
            }).catch(function(error) {
                setOpen(true);
                setError(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar la información de las fincas. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setLoading(false);
            setOpen(true);
            setError(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar la información de las fincas. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const loadSectoresOnChange = async (idFinca) => {
        let buildUrl = `/sectores/listall/${idFinca}`;
        try{
            setLoadingSectores(true);
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
            await instance.get(buildUrl).then(function(resp) {
                let sectores = resp.data.sectores.filter(elemento => elemento.tipoPlantacion !== "Secano");
                setSectores(sectores);
            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar los sectores de la finca. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoadingSectores(false);
            });
        }catch(error){
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar los sectores de la finca. Por favor, vuelve a intentarlo más tarde."
            }));
            setLoadingSectores(false);
        }
    }

    const loadInfoChart = async (idSector, startDate, endDate) => {
        let buildUrl = `/sectores/avanzado/${idSector}/${startDate}/${endDate}`;
        try{
            setLoadingChart(true);
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
            await instance.get(buildUrl).then(function(resp) {
                setData(resp.data);
            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar la información del gráfico. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoadingChart(false);
            });
        }catch(error){
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar la información del gráfico. Por favor, vuelve a intentarlo más tarde."
            }));
            setLoadingChart(false);
        }
    }

    const handleSelectFinca = (fincaId) => {
        let fincaFound = fincas.find((item) => item.id === fincaId);
        if(fincaFound){
            setFinca(fincaFound);
            loadSectoresOnChange(fincaId);
        }
    }

    const handleFechaInicioSelect = (sc, ec) => {
        setFechaInicio(sc);
        DateValidation(sc, ec);
    }

    const handleFechaFinSelect = (sc, ec) => {
        setFechaFin(ec);
        DateValidation(sc, ec);
    }

    const handleSectorSelect = (sectorId) => {
        if(sector.id !== sectorId){
            let sectorFound = sectores.find(s => s.id === sectorId);
            setSector({id: sectorFound.id, tipoUsuario_id: sectorFound.tipoUsuario_id});
            setFechaInicio("");
            setFechaFin("");
        }
    }

    const DateValidation = (startDate, endDate) => {
        if(startDate && endDate){
            let sd = new Date(startDate);
            let ed = new Date(endDate);
            if(sd > ed){
                setErrorDates(true);
            }else{
                setErrorDates(false);
                loadInfoChart(sector.id, startDate, endDate);
            }
        }
    }

    return(
        <>
            <div className="width100">
                <div className="tic4bio-huella-section">AVANZADO</div>
                <div className="tic4bio-home">
                    {loading ? 
                        (
                            <Box p={3} align="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <Box component="form" noValidate sx={{ mt: 1 }}>
                                    <>
                                    {!error ?
                                        <>
                                            <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                <InputLabel htmlFor="finca-select">Fincas*</InputLabel>
                                                <Select
                                                    className="t4b-finca-sector-inputs"
                                                    id="finca-select"
                                                    label="Finca"
                                                    required
                                                    MenuProps={{ disableScrollLock: true }}
                                                    disabled={loading || loadingSectores || loadingChart}
                                                    value={finca.id}
                                                    onChange={(e) => { handleSelectFinca(e.target.value) }    
                                                    }
                                                    >
                                                    
                                                    
                                                    {fincas.map((finca, index) => (
                                                        <MenuItem key={index} value={finca.id}>
                                                            {finca.unidadProductiva} - {finca.nombre}
                                                        </MenuItem>
                                                    ))}
                                                
                                                    
                                                </Select>
                                            </FormControl>
                                            {loadingSectores ? (
                                                <Box p={3} align="center">
                                                    <CircularProgress />
                                                </Box>
                                            ) : (
                                                <>
                                                    {finca.id && 
                                                        <>
                                                            {sectores.length > 0 ? 
                                                                <>
                                                                    <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                                        <InputLabel htmlFor="sector-select">Sectores*</InputLabel>
                                                                        <Select
                                                                            className="t4b-finca-sector-inputs"
                                                                            id="sector-select"
                                                                            label="Sector"
                                                                            required
                                                                            MenuProps={{ disableScrollLock: true }}
                                                                            value={sector.id}
                                                                            disabled={loadingSectores || loading || loadingChart}
                                                                            onChange={(event) => { handleSectorSelect(event.target.value) }
                                                                            }>
                                                                        
                                                                            {sectores.map((sector, index) => (
                                                                            <MenuItem key={index} value={sector.id}>
                                                                                {sector.nombre}
                                                                            </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                    {sector.id &&
                                                                        <>
                                                                            <div style={{marginBottom: "30px"}}>
                                                                                <div className="avanzado-dates">
                                                                                    <FormControl sx={{width: "100%", marginTop: "30px", marginBottom: "10px"}} variant="standard">
                                                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                                                                            <DatePicker
                                                                                                label="Fecha de inicio*"
                                                                                                id="fecha-inicio"
                                                                                                value={dayjs(fechaInicio)}
                                                                                                slotProps={{
                                                                                                    textField: {
                                                                                                    error: errorDates,
                                                                                                    },
                                                                                                }}
                                                                                                disabled={loadingChart}
                                                                                                onChange={(newValue) => {
                                                                                                    const selectedDate = dayjs(newValue).format('YYYY-MM-DD');
                                                                                                    handleFechaInicioSelect(selectedDate, fechaFin);
                                                                                                    //setFechaInicio(selectedDate);
                                                                                                }}
                                                                                            />
                                                                                        </LocalizationProvider>
                                                                                    </FormControl>
                                                                                    <FormControl sx={{width: "100%", marginTop: "30px", marginBottom: "10px"}} variant="standard">
                                                                                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                                                                            <DatePicker
                                                                                                label="Fecha de fin*"
                                                                                                id="fecha-fin"
                                                                                                value={dayjs(fechaFin)}
                                                                                                slotProps={{
                                                                                                    textField: {
                                                                                                    error: errorDates,
                                                                                                    },
                                                                                                }}
                                                                                                disabled={loadingChart}
                                                                                                onChange={(newValue) => {
                                                                                                    const selectedDate = dayjs(newValue).format('YYYY-MM-DD');
                                                                                                    handleFechaFinSelect(fechaInicio, selectedDate);
                                                                                                    //setFechaFin(selectedDate);
                                                                                                }}
                                                                                            />
                                                                                        </LocalizationProvider>
                                                                                    </FormControl>
                                                                                </div>
                                                                                {errorDates && <p className="errorMsg">La fecha de fin no puede ser menor que la fecha de inicio.</p>}
                                                                                </div>
                                                                            {fechaInicio && fechaFin && errorDates === false &&
                                                                                <>
                                                                                {loadingChart ? (
                                                                                    <Box p={3} align="center">
                                                                                        <CircularProgress />
                                                                                    </Box>
                                                                                ) : (
                                                                                    <ChartAdvanced fechaInicio={fechaInicio} fechaFin={fechaFin} data={data} typeUser={sector.tipoUsuario_id}/>
                                                                                )
                                                                                }
                                                                                </>
                                                                            }
                                                                        
                                                                        </>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    <p>No existen sectores para la finca seleccionada.</p>
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            )}
                                        </>
                                        :
                                        <>
                                            <p>Se ha producido un error inesperado. Vuelva a intentarlo más tarde.</p>
                                        </>
                                    }
                                    </>
                                </Box>
                            </>
                        )
                    }
                </div>
            </div>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.title} contentText={errorMsg.content}/>
        </>
    );
}

export default AvanzadoPage;