import { Box, CircularProgress, FormControl, Input, InputLabel, Stack } from "@mui/material";
import { useState } from "react";
import { useAuth } from "../../context/Auth";
import { useNavigate, useParams } from "react-router-dom";
import { instance } from "../../utils/axios-config";
import { checkDoublePattern, checkIntPattern } from "../../utils/commons";
import ModalInfo from "../ModalInfo";

const FormEndComponent = ({position, setPosition, state, setState}) => {

    const {sessionToken} = useAuth();
    const {id} = useParams();
    const navigate = useNavigate();

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState({title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado al intentar crear el sector. Por favor, vuelve a intentarlo más tarde."});


    const handlePrev = (e) => {
        e.preventDefault();
        setPosition(4);
    }

    const handleSend = async (e) => {
        e.preventDefault();
        if(validateForm()){
            try {
                setLoading(true);
                const data = formatStateData();
                instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
                await instance.post(`/sectores/create-sector/${id}`, data).then(() => {
                    setLoading(false);
                    navigate("/listaSectores/" + id, {state: {type: "action-new", text: "Se ha creado el sector correctamente."}});
                });
            } catch (error) {
                if(error.response?.data.message === "error sensores"){
                    setErrorMsg((prevErrorMsg) => ({
                        ...prevErrorMsg,
                        content: "Los sensores del sector no se encuentran actualmente disponibles y no se puede obtener la información necesaria actualmente para crear el sector y realizar el cálculo para la programación de riego, por favor intentelo de nuevo más tarde."
                    }));
                }else if(error.response?.data.message === "error municipio"){
                    setErrorMsg((prevErrorMsg) => ({
                        ...prevErrorMsg,
                        content: "Se ha producido un error al intentar recuperar la información del municipio del servicio climático de la AEMET necesaria para la creación y el cálculo de la programación de riego del sector, puede deberse un fallo temporal del propio servicio, por favor, inténtelo de nuevo más tarde."
                    }));
                }else if(error.response?.data.message === "error precipitacion"){
                    setErrorMsg((prevErrorMsg) => ({
                        ...prevErrorMsg,
                        content: "Se ha producido un error al intentar recuperar la información de las precipitaciones del servicio de la AEMET necesaria para la creación, puede deberse un fallo temporal del propio servicio, por favor, inténtelo de nuevo más tarde."
                    }));
                }else if(error.response?.data.message === "error prediccion"){
                    setErrorMsg((prevErrorMsg) => ({
                        ...prevErrorMsg,
                        content: "Se ha producido un error al intentar recuperar las predicciones del servicio de la AEMET necesaria para la creación, puede deberse un fallo temporal del propio servicio, por favor, inténtelo de nuevo más tarde."
                    }));
                }else if(error.response?.data.message === "error rosetta"){
                    setErrorMsg((prevErrorMsg) => ({
                        ...prevErrorMsg,
                        content: "Se ha producido un error al intentar realizar el cálculo mediante el análisis manual del suelo. Esto puede deberse a una posible indisposición temporal del servicio externo a la aplicación. Por favor, inténtelo de nuevo más tarde, o elija otra estrategia de captura de datos del suelo."
                    }));
                }else if(error.response?.data.message === "error historico"){
                    setErrorMsg((prevErrorMsg) => ({
                        ...prevErrorMsg,
                        content: "Se ha producido un error al intentar recuperar el histórico de datos relacionados con la estación asociada. Esto puede deberse a una indisponibilidad temporal del servicio de la Junta de Andalucía. Por favor, inténtelo de nuevo más tarde."
                    }));
                }else{
                    setErrorMsg((prevErrorMsg) => ({
                        ...prevErrorMsg,
                        content: "Algo ha fallado al intentar crear el sector. Por favor, vuelve a intentarlo más tarde."
                    }));
                }
                setOpen(true);
                setLoading(false);
            }
        }
    }

    const formatStateData = () => {
        let data = {
            nombre: state.nombre,
            hRiegoAplicadas: state.hRiegoAplicadas ? parseInt(state.hRiegoAplicadas) : null,
            tipoUsuario: state.tipoUsuario,
            metodoCalculoPE: state.metodoCalculoPE,
            tipoCultivo: state.tipoCultivo,
            distanciaArboles: parseFloat(state.distanciaArboles?.replace(',', '.')),
            distanciaLineas: parseFloat(state.distanciaLineas?.replace(',', '.')),
            diametroCopa: parseFloat(state.diametroCopa?.replace(',', '.')),
            seto: state.seto === "true" ? true : state.seto === "false" ? false : null,
            tipoRiego: state.tipoRiego,
            nRamales: state.nRamales ? parseInt(state.nRamales) : null,
            distanciaGoteros: state.distanciaGoteros ? parseFloat(state.distanciaGoteros?.replace(',', '.')) : null,
            nGoteros: state.nGoteros ? parseInt(state.nGoteros): null,
            caudalGotero: parseFloat(state.caudalGotero?.replace(',', '.')),
            superficieSector: parseFloat(state.superficieSector?.replace(',', '.')),
            tipoEstrategia: state.tipoEstrategia,
            riegoACubrir: state.riegoACubrir ? parseFloat(state.riegoACubrir): null,
            limiteLV: parseInt(state.limiteLV),
            limiteSD: parseInt(state.limiteSD),
           //dotacionRiego: parseFloat(state.dotacionRiego),
            conductividad: parseFloat(state.conductividad?.replace(',', '.')),
            textura: state.analisis === "textura" ? state.textura : null,
            analisis: state.analisis,
            ptArena: state.analisis === "analisis" ? (state.ptArena ? parseFloat(state.ptArena?.replace(',', '.')) : null) : null,
            ptLimo: state.analisis === "analisis" ? (state.ptLimo ? parseFloat(state.ptLimo?.replace(',', '.')) : null) : null,
            ptArcilla: state.analisis === "analisis" ? (state.ptArcilla ? parseFloat(state.ptArcilla?.replace(',', '.')) : null) : null,
            densidad: state.analisis === "analisis" ? (state.densidad ? parseFloat(state.densidad?.replace(',', '.')) : null) : null,
            pmp: state.analisis === "manual" ? (state.pmp ? parseFloat(state.pmp?.replace(',', '.')) : null) : null,
            cc: state.analisis === "manual" ? (state.cc ? parseFloat(state.cc?.replace(',', '.')) : null) : null,
            humedadSueloInicial: state.humedadSueloInicial,
            tipoPlantacion: state.tipoPlantacion,
            distanciaFinRamal: state.distanciaContador ? parseFloat(state.distanciaContador?.replace(',', '.')) : null,
            usuario: state.usuario,
            clave: state.clave,
            codigoEstacion: state.codigoEstacion,
            idEstacion: state.idEstacion,
        };

        return data;
    }

    const handleChangeHorasAplicadas = (e) => {
        if(checkIntPattern(e)){
            setState({...state, hRiegoAplicadas: e});
        }
    }

    const handleChangeDistanciaContador = (e) => {
        if(checkDoublePattern(e)){
            setState({...state, distanciaContador: e});
        }
    }


    const validateForm = () =>{
        setErrors({});
        let inputIds = "";
        let valid = true;
        
        if(state.tipoUsuario !== "Usuario 4.0" && (state.hRiegoAplicadas !== undefined || state.hRiegoAplicadas !== '') && !(checkIntPattern(state.hRiegoAplicadas))){
            setErrors(errors => ({...errors, errorHRiegoAplicadas: true, errorHRiegoAplicadasMsg: "Debe ser un valor numérico."}));
            inputIds = inputIds ? inputIds +",hRiegoAplicadas" : "hRiegoAplicadas";
            valid = false;
        }
        if(state.tipoUsuario === "Usuario 4.0" && (state.distanciaContador === undefined || state.distanciaContador === '') && !(checkDoublePattern(state.distanciaContador))){
            setErrors(errors => ({...errors, errorDistanciaContador: true, errorDistanciaContadorMsg: "Debe ser un valor numérico."}));
            inputIds = inputIds ? inputIds +",distanciaContador" : "distanciaContador";
            valid = false;
        }
        if(inputIds !== ""){
            try{
                let elem = document.getElementById(inputIds.split(",")[0]);
                elem.scrollIntoView({behavior: "smooth"});
                elem.focus();
            }catch(err){

            }
        }
        return valid;
    }

    return(
        <>
            <Box className={position !== 5 && 'hide'}>
                {state.tipoUsuario !== "Usuario 4.0" ?
                    <>
                        <h2>Riego aplicado</h2>
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="hRiegoAplicadas">Horas de Riego aplicadas hasta la fecha</InputLabel>
                            <Input
                                id="hRiegoAplicadas"
                                label="Horas de riego aplicadas hasta la fecha"
                                value={state.hRiegoAplicadas}
                                onChange={(e) => handleChangeHorasAplicadas(e.target.value)}
                                name="hRiegoAplicadas"
                                error={errors.errorHRiegoAplicadas}
                            />
                            {errors.errorHRiegoAplicadasMsg && <div className="errorInput">{errors.errorHRiegoAplicadasMsg}</div>}
                        </FormControl>
                    </>
                    :
                    <>
                        <h2>Datos del contador del ramal</h2>
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="distanciaContador">Distancia del contador al final del ramal (m)*</InputLabel>
                            <Input
                                id="distanciaContador"
                                label="Distancia del contador al final del ramal (m)"
                                value={state.distanciaContador}
                                onChange={(e) => handleChangeDistanciaContador(e.target.value)}
                                name="distanciaContador"
                                error={errors.errorDistanciaContador}
                            />
                            {errors.errorDistanciaContadorMsg && <div className="errorInput">{errors.errorDistanciaContadorMsg}</div>}
                        </FormControl>
                    </>
                }
                
                {loading ?
                    <Box align="center" sx={{my: 2}}>
                        <CircularProgress />
                    </Box>
                    :
                    <Stack spacing={2} direction="row" justifyContent="space-between" className="tic4bio-sectores-btns">
                        <button className="btn white" onClick={(e) => handlePrev(e)}>Anterior</button>
                        <button className="btn green" onClick={(e) => handleSend(e)} style={{margin: "16px 0"}}>Crear</button>
                    </Stack>
                }
            </Box>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.title} contentText={errorMsg.content}/>
        </>
    );
}

export default FormEndComponent;