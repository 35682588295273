import { Box, CircularProgress } from "@mui/material";
import PPagination from "../components/Pagination";
import { Link } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import { instance } from "../utils/axios-config";
import ModalInfo from "../components/ModalInfo";

const PrediccionPage = () => {

    useEffect(() => {
    }, []);

    /*const loadData = async (startPage = 1) => {
        try {
            let buildUrl = `/sectores/listAll?page=${startPage}`;
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`};
            
            await instance.get(buildUrl).then(function(resp) {
                setSectores(resp.data.sectores);
                setTotalPages(resp.data.pages);
            }).catch(function(resp) {
                setOpen(true);
                setError(true);
            }).finally(()=>{
                setLoading(false);
            });
        } catch (error) {
            setOpen(true);
            setLoading(false);
        }
    }*/

    return(
        <>
            <div className="tic4bio-home">
                <h1 className="tic4bio-home-title">Selecciona un sector para ver la predicción climática en su ubicación.</h1>
            </div>
        </>
    );
}

export default PrediccionPage;