import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

function BarChartHAPorcentajeCultivoAlmazaraEvolucion({ startCampana, endCampana, campanas, data, finca }) {

  const chartRef = useRef(null);
  const navigate = useNavigate();
  const [ chartInfo, setChartInfo ] = useState();

  const backgroundColors = [
    'rgba(255 ,192 ,0 , 0.8)',
    'rgba(140, 228, 46, 0.8)',
  ];

  const borderColors = [
    'rgba(255 ,192 ,0 , 1)',
    'rgba(140, 228, 46, 1)',
  ];

  useEffect(() => {
    chooseOption();
  }, [startCampana, endCampana]);

  useEffect(() => {
    const chart = chartRef.current;

    const handleClick = (event) => {
      const canvasPosition = {
        x: event.offsetX,
        y: event.offsetY,
      };

      const dataYLabels = chart.scales.y.ticks;
      
      // Calcular la posición de las etiquetas en el eje Y
      dataYLabels.forEach((label, index) => {
        const yLabelPosition = chart.scales.y.getPixelForTick(index);
        const labelHeight = 20; // Altura aproximada de la etiqueta

        // Verificar si el clic está dentro del rango de la etiqueta
        if (canvasPosition.y >= yLabelPosition - labelHeight / 2 && canvasPosition.y <= yLabelPosition + labelHeight / 2) {

          let fincaId;
          if (finca.fincas.length > 0) {
            fincaId = finca.fincas[0].id;
          } else {
            fincaId = finca.almazaras[0].id;
          }
          navigate(`/huella-unidades/${fincaId}`, {
            state: { campana: label.label.join(' ') }
          });
        }
      });
    };

    if (chart) {
      chart.canvas.addEventListener('click', handleClick);
    }

    return () => {
      if (chart?.canvas) {
        chart.canvas.removeEventListener('click', handleClick);
      }
    };
  }, [chartRef, chartInfo]);

  const generateCampanas = () => {
    const indiceInicio = campanas.indexOf(startCampana);
    const indiceFin = campanas.indexOf(endCampana);

    let listCampanas = campanas.slice(indiceInicio, indiceFin + 1);

    let labelsCampanas = listCampanas.map((str) => {
      if (str.includes(' ')) {
        return str.split(' ');
      } else {
        return [str];
      }
    });

    return {labels: labelsCampanas, labelsFilter: listCampanas};
  }

  const generateDataSets = (subData) => {
    let labels = [{key: "HA_Cultivo_Porcentaje", value: "Porcentaje de agua de cultivo"}, {key: "HA_Almazara_Porcentaje", value: "Porcentaje de agua de Almazara"}];
    const result = labels.map((label, index) => ({
      label: label.value,
      data: Object.values(subData).map((campanaData) => campanaData[label.key]),
      backgroundColor: backgroundColors[index],
      borderColor: borderColors[index],
      borderWidth: 1,
    }));
    return result;
  
  }

  const chooseOption = () => {
    let subData = data.unidad.infoUnidad;

    const labelsCampanas = generateCampanas();

    const filteredData = Object.keys(subData)
    .filter((key) => labelsCampanas.labelsFilter.includes(key))
    .reduce((obj, key) => {
      obj[key] = subData[key];
      return obj;
    }, {});

    let someData = Object.values(filteredData).some(c => c.HA_Cultivo_Porcentaje);
    
    if(someData){
      const info = {
        labels: labelsCampanas.labels,
        datasets: generateDataSets(filteredData)
      }
  
      setChartInfo(info);
    }else{
      setChartInfo();
    }

  }

  const options = {
    indexAxis: 'y',
    layout: {
      padding: {
          top: 0,
          bottom: 100,
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      }
    }
  };

    return (
      <>
        {chartInfo &&
          <>
              <div>
                <h3 style={{textAlign: "center", textDecoration: "underline", color: "#80C606", fontSize: "26px", marginBottom: "40px"}}>Fracción HA correspondiente al cultivo y a la almazara(%)</h3>
                <div style={{minHeight: "600px"}}>
                  <Bar data={chartInfo} options={options} ref={chartRef}/>
                </div>
              </div>
          </>
        }
        {!chartInfo &&
          <p>No existe información disponible para el rango de campañas seleccionadas en el <b>gráfico de fracción HA correspondiente al cultivo y a la almazara(%)</b></p>
        }
      </>
    );
}

export default BarChartHAPorcentajeCultivoAlmazaraEvolucion;