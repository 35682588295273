import { useEffect, useState, Fragment } from "react";
import { instance } from '../utils/axios-config.js';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { Link, useLocation, useParams } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ModalInfo from '../components/ModalInfo.js';
import DeleteIcon from '@mui/icons-material/Delete'; 
import ModalConfirm from '../components/ModalConfirm.js';
import ActionMessage from "../components/ActionMessage.js";
import PPagination from "../components/Pagination.js";
import { convertDate } from "../utils/commons.js";


const HuellasListPage = () => {

    const {state} = useLocation();
    const { id } = useParams();

    const [ huellas, setHuellas ] = useState([]);
    const [ msg, setMsg ] = useState( state ? state : undefined);
    const [ totalPages, setTotalPages ] = useState(1);
    const [ loading, setLoading ] = useState(true);
    const [ open, setOpen ] = useState(false);
    const [ openConfirm, setOpenConfirm ] = useState({
        state: false,
        finca: null
    });
    const [ errorMsg, setErrorMsg ] = useState({open: false, title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado. Por favor, vuelve a intentarlo más tarde."});
    

    useEffect(() => {
        loadPageData();
    }, []);

    const openModalConfirm = (huella) => {
        setOpenConfirm({state: true, finca: huella});
    }


    const loadPageData = async (startPage = 1) => {
        try {
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`};
            
            var huellasResp  = await instance.get(`/huella/list/${id}?page=${startPage}`);

            setHuellas(huellasResp.data.huellas);
            setTotalPages(huellasResp.data.pages !== 0 ? huellasResp.data.pages : 1);
            setLoading(false);
        } catch(error) {
            setOpen(true);
            setLoading(false);
            setErrorMsg({open: true, title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado al intentar recuperar el listado de huellas. Por favor, vuelve a intentarlo más tarde."});
        }
    }

    const deleteHuella = async (idHuella) => {
        let buildUrl = `/huella/delete/${idHuella}`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
        
            await instance.delete(buildUrl).then(function(resp) {
                setMsg({type: "action-delete", text: "Se ha eliminado la huella correctamente."});
                setHuellas(huellas.filter((s) => {return s.id !== idHuella}));
            }).catch(function(resp) {
                setErrorMsg({open: false, title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado al intentar eliminar la huella. Por favor, vuelve a intentarlo más tarde."});
            });
        }catch(error){
            setOpen(true);
            setErrorMsg({open: false, title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado al intentar eliminar la huella. Por favor, vuelve a intentarlo más tarde."});
        }
    }

   
    return(
        <>
         <div className="tic4bio-home">
                {msg &&
                    <ActionMessage msg={msg} setMsg={setMsg}/>
                }
                
                <ul className="tic4bio-sectores">
                    {loading ? 
                        (
                            <Box p={3} align="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <div className="button green" style={{maxWidth: "400px"}}>
                                    <Link to={"/nueva-huella"} state={{type: "finca"}}><AddCircleOutlineIcon className="add"/>CREAR NUEVA HUELLA DE AGUA</Link>
                                </div>
                                <div>
                                    <h1 className="tic4bio-home-title sectores">Mis Huellas de Agua:</h1>
                                </div>
                                {errorMsg.open ? 
                                    <p className="tic4bio-unidades-empty">Se ha producido un error al recuperar el listado de huellas.</p>
                                    :
                                    <>
                                        {huellas.length > 0 ? (
                                            <>
                                                {huellas.map((huella, index) => {
                                                    return (
                                                        <Fragment key={"huellas-" + index}>
                                                            <li className="tic4bio-sector">
                                                            <Link className="details" to={{ pathname: "/huella-cultivos/" + huella.finca_id}} state={{sectorId: huella.sector_id, huellaId: huella.id}}>
                                                                <div>
                                                                    <p className="tic4bio-huella-name">{huella.sector_nombre}: {huella.nombre}</p>
                                                                    <p className="tic4bio-huella-detail">Inicio de campaña: {convertDate(new Date(huella.fechaCampañaAnterior))}</p>
                                                                    <p className="tic4bio-huella-detail">Fin de campaña: {convertDate(new Date(huella.fechaCampañaActual))}</p>
                                                                </div> 
                                                            </Link>
                                                                <IconButton
                                                                    size="large"
                                                                    edge="start"
                                                                    className="delete"
                                                                    aria-label="Eliminar huella"
                                                                    style={{color: "#fa0000"}}
                                                                    onClick={() => {openModalConfirm(huella)}}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </li>
                                                        </Fragment>
                                                    )
                                                })}
                                                <Box sx={{mb: 6, mt: 4}} align="center">
                                                    <PPagination setLoading={setLoading} loadData={loadPageData} totalPages={totalPages}/>
                                                </Box>
                                            </>
                                            )
                                            :
                                            (
                                                <p className="tic4bio-fincas-empty">Aún no se ha añadido ninguna huella.</p>
                                            )
                                        }
                                    </>
                                }
                            </>
                        )
                    }
                </ul>
            </div>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.title} contentText={errorMsg.content}/>
            <ModalConfirm open={openConfirm} setOpen={setOpenConfirm} func={deleteHuella} contentTitle={"Eliminar " + openConfirm.finca?.nombre + "."} contentText={"¿Está seguro que desea eliminar esta huella?"}/>
    </>
    );
} 
export default HuellasListPage;