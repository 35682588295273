import { Autocomplete, Box, CircularProgress, FormControl, Input, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { instanceJA, instance } from '../../utils/axios-config';
import { useAuth } from "../../context/Auth";
import { useNavigate, useLocation } from "react-router-dom";
import ModalInfo from "../ModalInfo";

const FormNuevaFincaComponent = () => {

    const {sessionToken} = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const nuevaUnidad = location.state;

    const [error, setError] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorMsg, setErrorMsg] = useState({title: "¡Vaya! Parece que algo ha ido mal.", content: "Algo ha fallado al intentar recuperar la información del servicio de la AEMET. Por favor, vuelve a intentarlo más tarde."});
    const [open, setOpen] = useState(false);
    const [provincias, setProvincias] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [municipioValue, setMunicipioValue] = useState(null);
    const [estaciones, setEstaciones] = useState([]);
    const [estacionValue, setEstacionValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [dataForm, setDataForm] = useState({
        nombre : "",
        provincia : "",
        municipio : "",
        dotacionRiego : "",
        provincia_id : "",
        estacion_id : "",
        municipio_id: "",
        dataType: "finca",
        unidadProductiva: nuevaUnidad
    });

    const handleChangeProv = (event) => {
        let prov = provincias.find((p) => {return p.id === event.target.value});
        let stcFilter = [];
        let municipiosFilter = [];
        let remove_estacion = false;
        let remove_municipio = false;
        if(prov){
            stcFilter = estaciones.map(e => {
                if(parseInt(e.id.split("-")[0]) === prov?.id){
                    e.match = true;
                }else{
                    e.match = false;
                    if(dataForm.estacion_id === parseInt(e.id.split("-")[1])){
                        remove_estacion = true;
                        setEstacionValue(null);
                    }
                }
                return e;
            });
            
            municipiosFilter = municipios.map(e => {
                if(prov?.id === parseInt(e.id.slice(0,2))){
                    e.match = true;
                }else{
                    e.match = false;
                    if(parseInt(dataForm.municipio_id) === parseInt(e.id)){
                        remove_municipio = true;
                        setMunicipioValue(null);
                    }
                }
                return e;
            });
        }else{
            stcFilter = estaciones.map(e => {
                e.match = true;
                return e;
            });
            municipiosFilter = municipios.map(e => {
                e.match = true;
                return e;
            });
            remove_municipio = true;
            remove_estacion = true;
            setEstacionValue(null);
            setMunicipioValue(null);
        }

        setEstaciones(stcFilter);
        setMunicipios(municipiosFilter);
        setDataForm(dataForm => ({...dataForm, 
            provincia: prov?.nombre ? prov.nombre : "", 
            provincia_id: prov?.id ? prov.id : "",
            estacion_id: (remove_estacion ? "" : dataForm.estacion_id),
            municipio: (remove_estacion ? "" : dataForm.municipio),
            municipio_id: (remove_municipio ? "" : dataForm.municipio_id),
        }));
    };

    const handleChangeEstacion = (event, newValue) => {
        setEstacionValue(newValue);
        setDataForm(dataForm => ({...dataForm, estacion_id: (newValue ? parseInt(newValue.id.split("-")[1]) : "")}));
    };

    const handleChangeMunicipio = (event, newValue) => {
        setMunicipioValue(newValue);
        setDataForm(dataForm => ({...dataForm, municipio: newValue?.nombre, municipio_id: newValue?.id ? newValue.id: null}));
    };

    const handleChangeDotacion = (e) => {
        const regex = /^[0-9\b]+$/;
        if (e.target.value === "" || regex.test(e.target.value)) {
          setDataForm(dataForm => ({...dataForm, dotacionRiego: e.target.value}));
        }
    };

    useEffect(() => {
        if(!nuevaUnidad){
            navigate("/nuevaUnidadProductiva");
        }else{
            loadFormData();
        }
    }, []);

    const loadFormData = async () => {
        let urlEstaciones = "/estaciones";
        let urlAemetMunicipios = "aemet/municipios";

        let allPromises = [];
        allPromises.push(instanceJA.get(urlEstaciones), instance.get(urlAemetMunicipios));
        try{
            await Promise.all(allPromises).then(function(resp) {

                let provs = resp[0].data.map((i)=>{return {id: i.provincia.id, nombre: i.provincia.nombre, match: true}});
                provs = provs.filter( (ele, ind) => ind === provs.findIndex( elem => elem.jobid === ele.jobid && elem.id === ele.id));

                setProvincias(Array.from(new Set(provs)));
                let stations = resp[0].data.map(e => {
                    e.id = e.provincia.id + "-" + e.codigoEstacion;
                    e.match = true;
                    return e;
                }).filter(e => e.activa === true);;
                let municipios = resp[1].data.map(e => {
                    e.match = true;
                    return e;
                });
                setMunicipios(municipios);
                setEstaciones(stations);
            }).catch(function(err) {
                if(err.response?.data.message === "error estaciones"){
                    setErrorMsg((prevErrorMsg) => ({
                        ...prevErrorMsg,
                        content: "Se ha producido un error al intentar recuperar la información de los municipios y provincias del servicio de la AEMET necesaria para la creación, puede deberse un fallo temporal del propio servicio, por favor, inténtelo de nuevo más tarde."
                    }));
                }else if(err.response?.data.message === "error estaciones" || err.message === "error estaciones"){
                    setErrorMsg((prevErrorMsg) => ({
                        ...prevErrorMsg,
                        content: "Se ha producido un error al intentar recuperar las estaciones del servicio de la AEMET necesaria para la creación, puede deberse un fallo temporal del propio servicio, por favor, inténtelo de nuevo más tarde."
                    }));
                }else{
                    setErrorMsg({title: "¡Vaya! Parece que algo ha ido mal.", content: "Algo ha fallado al intentar recuperar la información del servicio de la AEMET. Por favor, vuelve a intentarlo más tarde."});
                }
                setOpen(true);
                setError(true);
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setOpen(true);
            setError(true);
            setErrorMsg({title: "¡Vaya! Parece que algo ha ido mal.", content: "Algo ha fallado al intentar recuperar las estaciones. Por favor, vuelve a intentarlo más tarde."});
        }
    }

    const checkForm = (data) =>{
        let valid = true;
        setErrors({});
        if((data.nombre === undefined || data.nombre === '')){
            setErrors(errors => ({...errors, errorNombre: true, errorNombreMsg: ""}));
            valid = false;
        }
        if(data.provincia === undefined || data.provincia === ''){
            setErrors(errors => ({...errors, errorProvincia: true, errorProvinciaMsg: ""}));
            valid = false;
        }
        if(data.municipio === undefined || data.municipio === '' || municipioValue === null || municipioValue === undefined || municipioValue === ''){
            setErrors(errors => ({...errors, errorMunicipio: true, errorMunicipioMsg: ""}));
            valid = false;
        }
        if(data.dotacionRiego === undefined || data.dotacionRiego === ''){
            setErrors(errors => ({...errors, errorDotacion: true,errorDotacionMsg: ""}));
            valid = false;
        }else if(!(/^[0-9\b]+$/.test(data.dotacionRiego))){
            setErrors(errors => ({...errors, errorDotacion: true,errorDotacionMsg: "La dotación debe ser un valor numérico."}));
            valid = false;
        }
        if(data.estacion_id === undefined || data.estacion_id === ''){
            setErrors(errors => ({...errors, errorEstacionAgroclimatica: true,errorEstacionAgroclimaticaMsg: ""}));
            valid = false;
        }
        return valid;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(checkForm(dataForm)){
            try {
                setLoadingBtn(true);
                instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
                await instance.post(`/fincas/create`, dataForm).then(() => {
                    setLoadingBtn(false);
                    navigate("/home", {state: {type: "action-new", text: "Se ha creado la finca correctamente."}});
                });
            } catch (error) {
                if(error.response?.data.message === "error estaciones"){
                    setErrorMsg((prevErrorMsg) => ({
                        ...prevErrorMsg,
                        content: "Se ha producido un error al intentar recuperar las estaciones del servicio de la AEMET necesaria para la creación, puede deberse un fallo temporal del propio servicio, por favor, inténtelo de nuevo más tarde."
                    }));
                }else{
                    setErrorMsg({title: "¡Vaya! Parece que algo ha ido mal.", content: "Algo ha fallado al intentar crear la finca. Por favor, vuelve a intentarlo más tarde."});
                }
                setOpen(true);
                setLoadingBtn(false);
            }
        }
    }

    return(
        <>
            <div className="tic4bio-home">
                {loading ? 
                    (
                        <Box className="loading">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box component="form" onSubmit={handleSubmit} noValidate>
                            <h1 className="tic4bio-title">DATOS FINCA</h1>
                            {!error ? 
                                <>
                                    <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                        <InputLabel htmlFor="name">Nombre*</InputLabel>
                                        <Input
                                            id="name"
                                            className="t4b-finca-inputs"
                                            label="Nombre de la Finca"
                                            required
                                            name="name"
                                            onChange={(e) => {setDataForm(dataForm => ({...dataForm, nombre: e.target.value}))}}
                                            value={dataForm.nombre}
                                            error={errors.errorNombre}
                                        />
                                        {errors.errorNombreMsg && <div className="errorInput">{errors.errorNombreMsg}</div>}
                                    </FormControl>
                                    <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                        <InputLabel htmlFor="provincia">Provincia*</InputLabel>
                                        <Select
                                            className="t4b-finca-inputs"
                                            id="provincia"
                                            name="provincia"
                                            value={dataForm.provincia_id}
                                            label="Provincia"
                                            onChange={(e) => handleChangeProv(e)}
                                            required
                                            error={errors.errorProvincia}>
                                                <MenuItem value={""}>-</MenuItem>
                                                {provincias.map((item, index) =>{
                                                        return <MenuItem key={"provincia-" + index} value={item.id}>{item.nombre}</MenuItem>
                                                    }
                                                )}
                                        </Select>
                                        {errors.errorProvinciaMsg && <div className="errorInput">{errors.errorProvinciaMsg}</div>}
                                    </FormControl>
                                    <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                        <InputLabel htmlFor="dotacionRiego">Dotación de Riego (m<sup>3</sup>/ha)*</InputLabel>
                                        <Input
                                            id="dotacionRiego"
                                            className="t4b-finca-inputs"
                                            label="Dotación de Riego"
                                            required
                                            name="dotacionRiego"
                                            value={dataForm.dotacionRiego}
                                            onChange={(e) => handleChangeDotacion(e)}
                                            error={errors.errorDotacion}
                                        />
                                        {errors.errorDotacionMsg && <div className="errorInput">{errors.errorDotacionMsg}</div>}
                                    </FormControl>
                                    {dataForm.provincia_id &&
                                        <>
                                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                            <Autocomplete
                                                options={municipios}
                                                renderOption={(props, option) => {
                                                    if(option.match){
                                                        return <li {...props}>{option.nombre}</li>
                                                    }
                                                }}
                                                className="t4b-finca-inputs"
                                                getOptionLabel={(option) => option.nombre}
                                                id="municipio"
                                                onChange={(e, newValue) => handleChangeMunicipio(e, newValue)}
                                                value={municipioValue}
                                                renderInput={(params) => (
                                                    <>
                                                    <TextField {...params} label="Municipio*" variant="standard" />
                                                    </>
                                                )}
                                                sx={{
                                                    ...(errors.errorMunicipio && {
                                                        '& .MuiInput-root:before': {
                                                            borderBottom: "1px solid #d32f2f",
                                                        }
                                                    })
                                                    }}
                                            />
                                            {errors.errorMunicipioMsg && <div className="errorInput">{errors.errorMunicipioMsg}</div>}
                                        </FormControl>
                                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                            <Autocomplete
                                                options={estaciones}
                                                renderOption={(props, option) => {
                                                    if(option.match){
                                                        return <li key={props.id} {...props}>{option.nombre} - cod. {option.codigoEstacion}</li>
                                                    }
                                                }}
                                                className="t4b-finca-inputs"
                                                getOptionLabel={(option) => option.nombre}
                                                id="estacionAgroclimatica"
                                                onChange={(e, newValue) => handleChangeEstacion(e, newValue)}
                                                value={estacionValue}
                                                renderInput={(params) => (
                                                    <>
                                                    <TextField {...params} label="Estación agroclimática*" variant="standard" />
                                                    </>
                                                )}
                                                sx={{
                                                    ...(errors.errorEstacionAgroclimatica && {
                                                        '& .MuiInput-root:before': {
                                                            borderBottom: "1px solid #d32f2f",
                                                        }
                                                    })
                                                    }}
                                            />
                                            {errors.errorEstacionAgroclimaticaMsg && <div className="errorInput">{errors.errorEstacionAgroclimaticaMsg}</div>}
                                        </FormControl>
                                
                                        </>
                                    }
                                    <div className='login-buttons'>
                                        {loadingBtn ?
                                            <Box align="center" sx={{my: 2}}>
                                                <CircularProgress />
                                            </Box>
                                            :
                                            <button type="submit" className="btn green">
                                                <span>Crear</span>
                                            </button>
                                        }
                                    </div>
                                </>
                            : 
                                <p>Se ha producido un error inesperado. Vuelva a intentarlo más tarde.</p>
                            }
                        </Box>
                    )
                }
            </div>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.title} contentText={errorMsg.content}/>
        </>
    );
}

export default FormNuevaFincaComponent;