import { useEffect, useState } from "react";
import { instance } from '../utils/axios-config.js';
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useLocation, useParams, Link } from 'react-router-dom';
import ModalInfo from '../components/ModalInfo.js';
import { useAuth } from "../context/Auth.js";
import ActionMessage from "../components/ActionMessage.js";
import ModalTip from "../components/ModalTip.js";
import lavadoraicon from "../assets/Iconos_almazaras/lavadora.png";
import duchaicon from "../assets/almazara_icons/ducha.png";
import molinoicon from "../assets/almazara_icons/molino.png";
import batidoraicon from "../assets/almazara_icons/batidora.png";
import decantericon from "../assets/almazara_icons/decanter.png";
import centrifugadoraicon from "../assets/almazara_icons/centrifugadora.png";
import warning from "../assets/warning.svg";
import DoughnutAlmazara from "../components/charts/DoughnutAlmazara.js";
import Mensajes from "../utils/mensajes";

const HuellaAlmazarasPage = () => {

    const {sessionToken} = useAuth();
    const { id } = useParams();
    const {state} = useLocation();

    const [ unidades, setUnidades ] = useState([]);
    const [ unidad, setUnidad ] = useState({id: ""});
    
    const [ huellas, setHuellas ] = useState([]);
    const [ huella, setHuella ] = useState({id: ""});
    const [ loading, setLoading ] = useState(true);
    const [ loadingHuellas, setLoadingHuellas ] = useState(false);
    const [ msg, setMsg ] = useState(() => {
        if(state && state.type){
            return state;
        }else{
            return null;
        }
    });
    const [ openTip, setOpenTip ] = useState(false);
    const [ msgTip, setMsgTip ] = useState({open: false, content: ''});
    const [ open, setOpen ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState({open: false, title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado. Por favor, vuelve a intentarlo más tarde."});
    
    const [ data, setData ] = useState({
        labels: ['HA Azul', 'HA Verde'],
        datasets: [
            {
              label: 'Huella de agua',
              data: [],
              backgroundColor: [
                'rgba(122,154,200, 0.8)',
                'rgba(128, 198, 6, 0.8)',
              ],
              borderColor: [
                  'rgba(122,154,200, 0.8)',
                  'rgba(128, 198, 6, 0.8)',
              ],
              borderWidth: 2
            },
        ],
    });

    const [ extra, setExtra ] = useState(0);


    useEffect(() => {
        if(state){
            loadAll();
        }else{
            loadPageData();
        }
    }, []);

    const loadAll = async () => {
        let buildUnidadesUrl = `/fincas/unidades`;
        let buildHuellasUrl = `/huellaalmazara/almazara-list/${id}`;
        instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        try{
            await Promise.all([instance.get(buildUnidadesUrl),instance.get(buildHuellasUrl)]).then(function(responses){

                let unidadesFiltered = filterByIndustria(responses[0].data);
                setUnidades(unidadesFiltered);

                let found = unidadesFiltered.find((obj) => obj.id === +id);
                if (found) {
                    setUnidad(found);
                }

                let huellas = responses[1].data;
                setHuellas(huellas);
                let huella = huellas.find((h) => h.id === +state?.huellaId);
                if(huella){
                    //setHuella(huella); // Poner dentro del updateDataChart
                    updateDataChart(huella);
                }
            }).catch(function(error){
                setLoading(false);
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar los datos. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });

        }catch(error){
            setLoading(false);
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar los datos. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }


    const loadPageData = async () => {
        let buildUrl = `/fincas/unidades`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await instance.get(buildUrl).then(function(resp) {
                let unidadesFiltered = filterByIndustria(resp.data);
                setUnidades(unidadesFiltered);
                
                let unidadFind = unidadesFiltered.find((u) => u.id === +id);
                if(unidadFind){
                    setUnidad(unidadFind);
                    loadHuellasOnChange(unidadFind.id);
                }

            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar la información de las unidades productivas. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setLoading(false);
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar la información de las unidades productivas. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }


    const loadHuellasOnChange = async (fincaId) => {
        try{
            let buildUrl = `/huellaalmazara/almazara-list/` + fincaId;
            setLoadingHuellas(true);
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
            await instance.get(buildUrl).then(function(resp) {
                
                let huellas = resp.data;
                setHuellas(huellas);
                if(state?.huellaId){
                    let huella = huellas.find((h) => h.id === +state?.huellaId);
                    if(huella){
                        //updateDataChart(huella);
                    }
                }
                
            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar las campañas de la unidad productiva y el sector. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoadingHuellas(false);
            });
            setLoadingHuellas(false);
        }catch(error){
            setLoadingHuellas(false);
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar los sectores de la unidad productiva y el sector. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }


    const handleLoadHuella = async (event) => {
        //let unidad = unidades[0][1].find((u) => u.id === event.target.value);
        let unidad = null;
        let found = unidades.find((obj) => obj.id === event.target.value);
        if (found) {
            unidad = found;
        }
            
        setUnidad(unidad);
        setHuella({id: ""});
        loadHuellasOnChange(event.target.value);
    }

    const handleHuellaSelect = (event) => {
        let huella = huellas.find((h) => h.id === event.target.value);
        updateDataChart(huella);
    }

    const updateDataChart = (huella) => {
        setHuella(huella);
        
        setData(prevState => ({
            ...prevState,
            datasets: prevState.datasets.map(dataset => ({
                ...dataset,
                data: [parseFloat(parseFloat(huella.huellaAguaLitros).toFixed(2)), 0]
            })),
        }));
        setExtra(parseFloat(parseFloat(huella.huellaAguaKg).toFixed(2)));
    }


    const showIcon = (equipo) => {
        let imageicon;
        switch (equipo) {
            case 'lavadora':
                imageicon = <img src={lavadoraicon} alt="Icono Lavadora" className="tic4bio-huella-almazara-img"/>;
              break;
            case 'ducha':
                imageicon = <img src={duchaicon} alt="Icono Ducha" className="tic4bio-huella-almazara-img"/>;
              break;
            case 'molino':
                imageicon = <img src={molinoicon} alt="Icono Molino" className="tic4bio-huella-almazara-img"/>;
              break;
            case 'batidora':
                imageicon = <img src={batidoraicon} alt="Icono Batidora" className="tic4bio-huella-almazara-img"/>;
              break;
            case 'decanter':
                imageicon = <img src={decantericon} alt="Icono Decanter" className="tic4bio-huella-almazara-img"/>;
              break;
            default:
                imageicon = <img src={centrifugadoraicon} alt="Icono Centifugadora" className="tic4bio-huella-almazara-img"/>;
              break;
        }
        
        return imageicon;
    }

    const handleOpenTip = (type) => {
        setOpenTip(true);
        if(type==="procesos"){
            setMsgTip((msg) => ({
                ...msg,
                content: '<div class="modaltip"><h4>Cálculo estimado del agua de proceso.</h4><p>La precisión de estos datos depende de la precisión de los datos introducidos en el apartado limpieza.</p></div>'
            }));
        }else{
            setMsgTip((msg) => ({
                ...msg,
                content: '<div class="modaltip"><h4>Volumen de agua empleado en la limpieza de la almazara.</h4><p>La precisión de este valor depende la precisión de los datos introducidos en el apartado de limpieza.</p></div>'
            }));
        }
    }

    const filterByIndustria = (unidades) => {
        let filtered = unidades.filter(u => u.dataType === "industria");
        return filtered;
    }
   
    return(
        <div className="width100">
            <div className="tic4bio-huella-section">ALMAZARA</div>

            <div className="tic4bio-home">
                {msg?.type &&
                    <ActionMessage msg={msg} setMsg={setMsg}/>
                }
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    {loading ? 
                        (
                            <Box p={3} align="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <div>
                                    <h1 className="tic4bio-home-title">Huella de Agua</h1>
                                    {(unidades.length > 0) ?
                                        <>
                                            <p>Seleccione la Unidad productiva de la huella que desea visualizar*</p>
                                            <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                <InputLabel htmlFor="unidad-select">Unidades*</InputLabel>
                                                <Select
                                                    className="t4b-finca-sector-inputs"
                                                    id="unidad-select"
                                                    label="unidad"
                                                    required
                                                    MenuProps={{ disableScrollLock: true }}
                                                    disabled={loadingHuellas}
                                                    value={unidad.id}
                                                    onChange={(event) => { handleLoadHuella(event); }    
                                                    }
                                                    >
                                                    {unidades.map((unidad, index) => (
                                                    <MenuItem key={index} value={unidad.id}>
                                                        {unidad.unidadProductiva}
                                                    </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                                       
                                            {loadingHuellas ? (
                                                <Box p={3} align="center">
                                                    <CircularProgress />
                                                </Box>
                                                ) : (
                                                    <>
                                                        {unidad.id &&
                                                            <>
                                                                {(huellas.length > 0) ?
                                                                    <>
                                                                        <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                                            <InputLabel htmlFor="sector-select">Campañas*</InputLabel>
                                                                            <Select
                                                                                className="t4b-finca-sector-inputs"
                                                                                id="sector-select"
                                                                                label="Sector"
                                                                                required
                                                                                MenuProps={{ disableScrollLock: true }}
                                                                                value={huella.id}
                                                                                onChange={(event) => { handleHuellaSelect(event) }
                                                                                }>
                                                                            
                                                                                {huellas.map((huella, index) => (
                                                                                    <MenuItem key={index} value={huella.id}>
                                                                                        {huella.campana}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                        {huella.id !== "" &&
                                                                            <>
                                                                                <div className="chart-container">
                                                                                    <div className="chart-pos-center">
                                                                                        <h3>Huella agua de almazara</h3>
                                                                                        <DoughnutAlmazara data={data} extra={extra}/>
                                                                                    </div>
                                                                                    <div>
                                                                                        <h4>Información de campaña:</h4>
                                                                                        <ul>
                                                                                            <li>Nº de días de molturación campaña: {huella.nDiasMolturacion}</li>
                                                                                            <li>Consumo de agua medio: {(huella.consumoAguaTotal / huella.nDiasMolturacion)?.toFixed(2)} m<sup>3</sup>/día</li>
                                                                                            <li>Rendimiento medio: {huella.rendimientoMedio} %</li>
                                                                                            <li>Kg aceituna molturados: {huella.kgAceitunaMolturado} Kg</li>
                                                                                            <li>Precio: {huella.precioAceite} €/L aceite <span style={{fontSize: "12px"}}>(envasado sin contar coste del envase ni envasado)</span></li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    <div>
                                                                                        <h4>Registro volúmenes de agua usados:</h4>
                                                                                        <ul>
                                                                                            <li>Contador general: {huella.volumenContadorGeneral} m<sup>3</sup></li>
                                                                                            <li>Volumen de agua de proceso: {!isNaN(huella.volumenAguaProceso) ? (huella.volumenAguaProceso)?.toFixed(2) : "--------"} m<sup>3</sup> <img className="modaltip-triangle" src={warning} onClick={() => { handleOpenTip("procesos") }}/></li>
                                                                                            <li>Volumen de agua de limpieza: {!isNaN(huella.volumenAguaLimpiezaTotal) ? (huella.volumenAguaLimpiezaTotal)?.toFixed(2) : "--------"} m<sup>3</sup> <img className="modaltip-triangle" src={warning} onClick={() => { handleOpenTip("limpiezas") }}/></li>
                                                                                        </ul>

                                                                                        {(huella.consumoAgua.length > 0 && huella.aguaLimpieza.length > 0) &&
                                                                                            <table className="chart-summary" style={{marginTop: "30px", marginBottom: "46px"}}>
                                                                                                <thead>
                                                                                                    <tr className="chart-summary-head">
                                                                                                        <th style={{border: 0}}></th>
                                                                                                        <th style={{backgroundColor:"#5b9bd5",borderTop: "2px solid #000",borderBottom: "2px solid #000",borderRight: "1px solid #000"}}>Contador general (m<sup>3</sup>)</th>
                                                                                                        <th style={{backgroundColor:"#5b9bd5",borderTop: "2px solid #000",borderBottom: "2px solid #000",borderLeft: "1px solid #000"}}>Volumen proceso + limpieza (usuario)</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody className="chart-striped">
                                                                                                    <tr>
                                                                                                        <td style={{borderTop: "2px solid"}}>
                                                                                                            Agua limpieza
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            -
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {huella.volumenAguaLimpiezaTotal.toFixed(2)}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            Agua de proceso
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            -
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {huella.volumenAguaProceso.toFixed(2)}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td style={{borderBottom: "2px solid"}}>
                                                                                                            Total
                                                                                                        </td>
                                                                                                        <td style={{borderBottom: "2px solid"}}>
                                                                                                            {huella.volumenContadorGeneral.toFixed(2)}
                                                                                                        </td>
                                                                                                        <td style={{borderBottom: "2px solid"}}>
                                                                                                            {(huella.volumenAguaLimpiezaTotal + huella.volumenAguaProceso).toFixed(2)}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        }

                                                                                        {huella.consumoAgua.length > 0 &&
                                                                                            <>
                                                                                                <p style={{marginTop: "26px", fontWeight: "500",textAlign: "center"}}>Desglose consumos línea de proceso</p>
                                                                                                <table className="chart-summary">
                                                                                                    <thead>
                                                                                                        <tr className="chart-summary-head">
                                                                                                            <th className="chart-summary-column">Equipo</th>
                                                                                                            <th>Consumo de agua</th>
                                                                                                            <th>Total campaña</th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        {huella.consumoAgua.map((elem, index) => { 
                                                                                                            return(
                                                                                                            <tr key={"consumo-" + index}>
                                                                                                                <td>
                                                                                                                    <div>
                                                                                                                        {showIcon(elem.equipo)}
                                                                                                                        <p className="textUpperCase">{elem.equipo}</p>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <p>{elem.consumoCampana.toFixed(2)} m<sup>3</sup></p>
                                                                                                                </td>
                                                                                                                <td>
                                                                                                                    <p>{((elem.consumoCampana / huella.volumenAguaProceso)*100)?.toFixed(2)} %</p>
                                                                                                                </td>
                                                                                                            </tr>)
                                                                                                        })}
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </>
                                                                                        }
                                                                                        {huella.aguaLimpieza.length > 0 &&
                                                                                            <>
                                                                                                <p style={{marginTop: "26px", fontWeight: "500",textAlign: "center"}}>Desglose consumos limpieza</p>
                                                                                                <table className="chart-summary">
                                                                                                        <thead>
                                                                                                            <tr className="chart-summary-head">
                                                                                                                <th></th>
                                                                                                                <th>Litros</th>
                                                                                                                <th>Porcentaje (%)</th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {huella.aguaLimpieza.map((elem, index) => { 
                                                                                                                return(
                                                                                                                <tr key={"aguaLimpieza-" + index}>
                                                                                                                    <td>
                                                                                                                        <div>
                                                                                                                            <p>{elem.nombre}</p>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        <p>{elem.volumenLimpieza} L</p>
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        <p>{parseFloat(elem.volumenLimpieza) === 0 ? 0 : ((parseFloat(elem.volumenLimpieza)/huella.volumenAguaLimpiezaTotal)*100).toFixed(2)} %</p>
                                                                                                                    </td>
                                                                                                                </tr>)
                                                                                                            })}
                                                                                                        </tbody>
                                                                                                </table>
                                                                                            </>
                                                                                        }
                                                                                    </div>

                                                                                        {(huella.consumoAgua.length > 0 && huella.aguaLimpieza.length > 0) ?
                                                                                            <div>
                                                                                                <h4>Porcentaje de uso de agua:</h4>
                                                                                                <table className="chart-summary" style={{marginTop: "30px", marginBottom: "46px"}}>
                                                                                                    <thead>
                                                                                                        <tr className="chart-summary-head">
                                                                                                            <th style={{border: 0}}></th>
                                                                                                            <th style={{backgroundColor:"#5b9bd5",borderTop: "2px solid #000",borderBottom: "2px solid #000",borderRight: "1px solid #000"}}>Volumen contador general (m<sup>3</sup>)</th>
                                                                                                            <th style={{backgroundColor:"#5b9bd5",borderTop: "2px solid #000",borderBottom: "2px solid #000",borderLeft: "1px solid #000"}}>Volumen proceso + limpieza (usuario)</th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody className="chart-striped">
                                                                                                        <tr>
                                                                                                            <td style={{borderTop: "2px solid"}}>
                                                                                                                Volumen
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {huella.volumenContadorGeneral.toFixed(2)}
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {(huella.volumenAguaLimpiezaTotal + huella.volumenAguaProceso).toFixed(2)}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                Agua de proceso (%)
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {((huella.volumenAguaProceso / huella.volumenContadorGeneral)*100).toFixed(2)}
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {((huella.volumenAguaProceso * 100)/(huella.volumenAguaLimpiezaTotal + huella.volumenAguaProceso)).toFixed(2)}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                Agua limpieza (%)
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {((huella.volumenAguaLimpiezaTotal / huella.volumenContadorGeneral)*100).toFixed(2)}
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                {((huella.volumenAguaLimpiezaTotal * 100)/(huella.volumenAguaLimpiezaTotal + huella.volumenAguaProceso)).toFixed(2)}
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                        <tr>
                                                                                                            <td style={{borderBottom: "2px solid"}}>
                                                                                                                Otros (%)
                                                                                                            </td>
                                                                                                            <td style={{borderBottom: "2px solid"}}>
                                                                                                                {huella.porcentajeOtros.toFixed(2)}
                                                                                                            </td>
                                                                                                            <td style={{borderBottom: "2px solid"}}>
                                                                                                                -
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                            :
                                                                                            <div>
                                                                                                <h4>Porcentaje de uso de agua:</h4>
                                                                                                <ul>
                                                                                                    <li>% agua de proceso: {!isNaN(huella.porcentajeAguaProceso) ? (huella.porcentajeAguaProceso)?.toFixed(2) : "--------"} m<sup>3</sup></li>
                                                                                                    <li>% agua de limpieza: {!isNaN(huella.porcentajeAguaLimpieza) ? (huella.porcentajeAguaLimpieza)?.toFixed(2) : "--------"} m<sup>3</sup></li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        }

                                                                                    <div>
                                                                                        <h4>Huella de agua:</h4>
                                                                                        <ul>
                                                                                            <li>Huella de agua: {!isNaN(huella.huellaAguaKg) ? (huella.huellaAguaKg)?.toFixed(2) : "--------"} L. agua/Kg aceituna</li>
                                                                                            <li>Huella Agua: {!isNaN(huella.huellaAguaLitros) ? (huella.huellaAguaLitros)?.toFixed(2) : "--------"} L. agua/L. aceite</li>
                                                                                            <li>Productividad del agua: {!isNaN(huella.huellaAguaExtendida) ? (huella.huellaAguaExtendida)?.toFixed(2) : "--------"} €/L. aceite</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                    {(huella.consumoAgua.length > 0 && huella.aguaLimpieza.length > 0) && 
                                                                                        
                                                                                        <table className="chart-summary" style={{marginTop: "30px", marginBottom: "46px"}}>
                                                                                            <thead>
                                                                                                <tr className="chart-summary-head">
                                                                                                    <th style={{border: 0}}></th>
                                                                                                    <th style={{backgroundColor:"#5b9bd5",borderTop: "2px solid #000",borderBottom: "2px solid #000",borderRight: "1px solid #000"}}>Volumen contador general (m<sup>3</sup>)</th>
                                                                                                    <th style={{backgroundColor:"#5b9bd5",borderTop: "2px solid #000",borderBottom: "2px solid #000",borderLeft: "1px solid #000"}}>Volumen proceso + limpieza (usuario)</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody className="chart-striped">
                                                                                                <tr>
                                                                                                    <td style={{borderTop: "2px solid"}}>
                                                                                                        HA (l agua /kg aceituna)
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {!isNaN(huella.huellaAguaKg) ? (huella.huellaAguaKg)?.toFixed(2) : "--------"}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {(((huella.volumenAguaLimpiezaTotal + huella.volumenAguaProceso) * 1000)/huella.kgAceitunaMolturado).toFixed(2)}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        HA (l agua /l aceite)
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {!isNaN(huella.huellaAguaLitros) ? (huella.huellaAguaLitros)?.toFixed(2) : "--------"}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {(((huella.volumenAguaLimpiezaTotal + huella.volumenAguaProceso) * 1000)/huella.litrosAceite).toFixed(2)}
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td style={{borderBottom: "2px solid"}}>
                                                                                                        HA extendida (€/L agua)
                                                                                                    </td>
                                                                                                    <td style={{borderBottom: "2px solid"}}>
                                                                                                        {!isNaN(huella.huellaAguaExtendida) ? (huella.huellaAguaExtendida)?.toFixed(2) : "--------"}
                                                                                                    </td>
                                                                                                    <td style={{borderBottom: "2px solid"}}>
                                                                                                        {(huella.precioAceite/((huella.volumenAguaLimpiezaTotal + huella.volumenAguaProceso)/huella.litrosAceite)/1000).toFixed(2)}
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    }
                                                                                </div>

                                                                            </>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <p>Parece que la unidad productiva seleccionada no tiene Campañas asociadas. Necesitas al menos una campaña para poder visualizar su detalle.</p>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                )
                                            }
                                                       
                                        </>
                                        :
                                        <p><Mensajes type="unidades" /></p>
                                    }
                                    
                                </div>
                            </>
                        )
                    }
                </Box>
            </div>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.title} contentText={errorMsg.content}/>
            <ModalTip open={openTip} setOpen={setOpenTip} contentText={msgTip.content}/>
        </div>
    );
} 
export default HuellaAlmazarasPage;