import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { useState } from "react";
import ModalTip from "../ModalTip";
import IndiceBiodiversidadVegetal from "../bars/IndiceBiodiversidadVegetal";
import RadarChart from "../charts/RadarChart";

const DetallesComunidadComponent = ({bioVertebrado, bioVertebrados, handleFechaSelect, infoData}) => {

    const [ openTip, setOpenTip ] = useState(false);
    const [ msgTip, setMsgTip ] = useState({open: false, content: ''});

    const handleOpenModal = (modal) => {
        setOpenTip(true);
        let text = '';
        switch (modal) {
            case "riquezaespecifica":
                text = '<div class="modaltip"><h3>ÍNDICE DE RIQUEZA ESPECÍFICA</h3><div class="modaltip-box">La riqueza específica es una medida de la biodiversidad que se define como el número de especies presentes en una comunidad. Es una medida simple y fácil de calcular, pero puede proporcionar información valiosa sobre la diversidad de una comunidad.</div></div>';
                break;
            case "dominanciasimpson":
                text = '<div class="modaltip"><h3>ÍNDICE DE SIMPSON (D)</h3><div class="modaltip-box">El índice de Simpson es un índice de diversidad que mide la probabilidad de que dos individuos seleccionados al azar de una comunidad pertenezcan a la misma especie.<br> Toma valores de 0 a 1.<br> Un valor alto del índice de Simpson indica que la comunidad es dominada por unas pocas especies, mientras que un valor bajo indica que la comunidad es más diversa y está compuesta por un mayor número de especies con abundancias más equitativas.</div></div>';
                break;
            case "diversidadsimpson":
                text = '<div class="modaltip"><h3>ÍNDICE DE DIVERSIDAD DE SIMPSON (1-D)</h3><div class="modaltip-box">El índice de diversidad de Simpson es un índice <u>inverso</u> al de dominancia de Simpson, por tanto, mide la probabilidad de que dos individuos seleccionados al azar de una comunidad pertenezcan <b>diferentes</b> especies.<br> Toma valores de 0 a 1.<br> Un valor alto del índice de Simpson es indicativo de que la probabilidad de que dos individuos pertenezcan a la misma especie es baja, lo que indica que hay una distribución uniforme y equitativa de las especies dentro de la zona estudiada.</div></div>';
                break;
            case "pielou":
                text = '<div class="modaltip"><h3>ÍNDICE DE PIELOU (J’)</h3><div class="modaltip-box">Mide la equitatividad de una comunidad, es decir, la uniformidad en la abundancia de las especies.<br> Toma valores entre 0 y 1.<br> Valores cercanos a 1 indican que todas las especies son igualmente abundantes y el 0 indica una completa falta de uniformidad y equidad entre especies.</div></div>';
                break;
            case "shannonwiener":
                text = '<div class="modaltip"><h3>ÍNDICE DE SHANNON-WIENER (H’)</h3><div class="modaltip-box">Mide el grado promedio de incertidumbre en predecir a que especie pertenecerá un individuo tomado al azar de la zona, comunidad o finca de estudio. Considera tanto la riqueza en especies como su abundancia.<br> Toma valores entre 0 y ln(S), siendo S el nº de especies.<br> Valores cercanos a cero representan una comunidad con pocas especies y valores próximos a ln(S) representan una comunidad más equilibrada y rica en especies.</div></div>';
                break;
            case "grafico":
                text = '<div class="modaltip"><div class="modaltip-box">Cuanto mayores son los índices de Pielou, Diversidad de Simpson, Riqueza específica  y Shannon-Wiener y menor sea el valor del índice de Simpson, más diversa, homogénea y equilibrada será la biodiversidad de la finca.</div></div>';
                break;
        }
        setMsgTip((msg) => ({
            ...msg,
            content: text
        }));
    }

    return(
        <>
        
            {(bioVertebrados.length > 0) ?
                <>
                    <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                        <InputLabel htmlFor="fecha-select">Fecha*</InputLabel>
                        <Select
                            className="t4b-finca-sector-inputs"
                            id="fecha-select"
                            label="Fecha"
                            required
                            MenuProps={{ disableScrollLock: true }}
                            value={bioVertebrado.id}
                            onChange={(event) => { handleFechaSelect(event) }
                            }>
                        
                            {bioVertebrados.map((bioi, index) => (
                                <MenuItem key={index} value={bioi.id}>
                                    {bioi.fecha}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {bioVertebrado.id !== "" &&
                        <>
                            <div className="chart-container">
                                <h3 style={{textAlign: "center", textDecoration: "underline", color: "#80C606", fontSize: "26px", marginBottom: "40px"}}>Biodiversidad</h3>
                                
                                <div className="b-container">
                                    <div className={bioVertebrado.tipoAnalisis === 1 ? "b-container-green" : "b-container-white"}>
                                        Básico
                                    </div>
                                    <div className={bioVertebrado.tipoAnalisis === 0 ? "b-container-green" : "b-container-white"}>
                                        Avanzado
                                    </div>
                                </div>

                                <p style={{margin: "30px 0", textAlign: "center", fontSize: "18px", fontWeight: 600}}>Riqueza específica (S): {bioVertebrado.riquezaEspecifica} <span className="info-icon" onClick={() => { handleOpenModal("riquezaespecifica") }}>i</span></p>
                                
                                {bioVertebrado.tipoAnalisis === 0 &&
                                    <>

                                        <p style={{margin: "30px 0", textAlign: "center", fontSize: "18px", fontWeight: 600}}>Total de individuos: {bioVertebrado.numEspeciesTotales}</p>
                                    
                                        <div className="chart-box">
                                            <IndiceBiodiversidadVegetal value={bioVertebrado.indiceSimpson} minValue={0} maxValue={1} texto={"ÍNDICE DE DOMINANCIA DE SIMPSON"} />
                                            <div className="chart-indice-info"><span className="info-icon" onClick={() => { handleOpenModal("dominanciasimpson") }}>i</span></div>
                                        </div>
                                        <div className="chart-box">
                                            <IndiceBiodiversidadVegetal value={bioVertebrado.indiceDiversidadSimpson} minValue={0} maxValue={1} texto={"ÍNDICE DE DIVERSIDAD DE SIMPSON"} />
                                            <div className="chart-indice-info"><span className="info-icon" onClick={() => { handleOpenModal("diversidadsimpson") }}>i</span></div>
                                        </div>
                                        <div className="chart-box">
                                            <IndiceBiodiversidadVegetal value={bioVertebrado.indicePielou} minValue={0} maxValue={1} texto={"ÍNDICE EQUIDAD PIELOU"} />
                                            <div className="chart-indice-info"><span className="info-icon" onClick={() => { handleOpenModal("pielou") }}>i</span></div>
                                        </div>
                                        <div className="chart-box">
                                            <IndiceBiodiversidadVegetal value={bioVertebrado.indiceShannonWiener} minValue={0} maxValue={2.30} texto={"ÍNDICE DE SHANNON-WIENER"} riqEsp={bioVertebrado.riquezaEspecifica}/>
                                            <div className="chart-indice-info"><span className="info-icon" onClick={() => { handleOpenModal("shannonwiener") }}>i</span></div>
                                        </div>
                                    </>
                                }
                            </div>

                            {bioVertebrado.tipoAnalisis === 0 &&
                                <>
                                    <div className="chart-container">
                                        <div className="relative">
                                            <h3 style={{textAlign: "center", textDecoration: "underline", color: "#80C606", fontSize: "26px", marginBottom: "40px"}}>Gráfico</h3>
                                            <span className="info-icon" style={{position: "absolute", top: 0, right: 0}} onClick={() => { handleOpenModal("grafico") }}>i</span>
                                        </div>
                                        <RadarChart infoData={infoData}/>
                                    </div>
                                </>
                            }

                            <div className="chart-container">
                                <h3 style={{textAlign: "center", textDecoration: "underline", color: "#80C606", fontSize: "26px", marginBottom: "40px"}}>Datos</h3>
                                {bioVertebrado.tipoAnalisis === 1 ?
                                    <>
                                    <div className="tabla-bio-box">
                                        <table className="tabla-bio" style={{marginbottom: "30px"}}>
                                            <thead>
                                                <tr>
                                                    <th>Especies en la zona "{bioVertebrado.nombre_comunidad}"</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bioVertebrado.especiesTotales.map((item, index) => (
                                                    <tr key={"especie-" + index}>
                                                        <td>{item}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    </>
                                    :
                                    <>
                                    <div className="tabla-bio-box">
                                        <table className="tabla-bio" style={{marginbottom: "30px"}}>
                                            <thead>
                                                <tr>
                                                    <th style={{width: "70%"}}>Especies en la zona "{bioVertebrado.nombre_comunidad}"</th>
                                                    <th style={{width: "30%"}}>IKA( Índice Kilométrico de abundancia de especies)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {bioVertebrado.muestrasFiltradas.map((item, index) => (
                                                    <tr key={"especie-" + index}>
                                                        <td>{item.nombre}</td>
                                                        <td>{item.ika}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    </>
                                }
                            </div>
                        </>
                    }
                </>
                :
                <>
                    <p>Aún no se han añadido ninguna biodiversidad de vertebrados. Necesitas tener al menos una creada para poder visualizar el detalle.</p>
                </>
            }
            <ModalTip open={openTip} setOpen={setOpenTip} contentText={msgTip.content}/>
        </>
    )
}

export default DetallesComunidadComponent;