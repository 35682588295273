import { Box, CircularProgress, FormControl, Stack, TextField } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import redsquare from "../../assets/Iconos_biodiversidad/redsquare.png";
import bluesquare from "../../assets/Iconos_biodiversidad/bluesquare.png";
import terrain from "../../assets/Iconos_biodiversidad/terrain.jpg";
import tinyterrain from "../../assets/Iconos_biodiversidad/tinyterrain.png";
import googlelens from "../../assets/Iconos_biodiversidad/googlelens.png";
import singlelindero from "../../assets/Iconos_biodiversidad/lindero.png";
import multilindero from "../../assets/Iconos_biodiversidad/linderomulti.png";
import TableBasicNoSamplingComponent from "./TableBasicNoSampling";
import TableBasicSamplingComponent from "./TableBasicSampling";
import TableAdvancedNoSamplingComponent from "./TableAdvancedNoSampling";
import TableAdvancedSamplingComponent from "./TableAdvancedSampling";
import { checkDoublePattern } from "../../utils/commons";
import { useAuth } from "../../context/Auth";
import { instance } from "../../utils/axios-config";

const FormBioStepThreeComponent = ({position, setPosition, stateForm, setStateForm}) => {

    const {sessionToken} = useAuth();
    const [activeDrops, setActiveDrops] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if(stateForm.diccionario.length === 0){
            loadDictionary();
        }
    }, []);


    const loadDictionary = async () => {
        let buildUrl = `/diccionario/vegetal`;
        try{
            setLoading(true);
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await instance.get(buildUrl).then(function(resp) {
                setStateForm((prevData) => ({...prevData, diccionario: resp.data}));
            }).catch(function(error) {
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setLoading(false);
        }
    }

    const handleClickDrops = (element) => {
        const isActive = activeDrops.includes(element);
        if (isActive) {
          setActiveDrops(activeDrops.filter(id => id !== element));
        } else {
          setActiveDrops([...activeDrops, element]);
        }
    };

    const printNameText = (text) => {
        let res = "";
        switch (text) {
            case "cubiertavegetal":
                res = "Cubierta vegetal";
                break;
            case "cultivoanualherbaceo":
                res = "Cultivo Anual/Herbáceo";
                break;
            case "bosquesierramatorral":
                res = "Bosque, sierra o matorral";
                break;
            case "lindero":
                res = "Lindero";
                break;
            case "bosqueisla":
                res = "Bosque isla";
                break;
            default:
                res = "Cultivos Leñosos";
                break;
          }
        return res;
    }

    const validateForm = () =>{
        setErrors({});
        let inputIds = "";
        let valid = true;

        
        Object.keys(stateForm.especies).forEach((key, i) =>{
            stateForm.especies[key].map((especie, index) => {
                if(!especie.nombre){
                    setErrors(errors => ({...errors, ["errorEspecie-" + key + "-" + index + "Nombre"]: true, ["errorEspecie-" + key + "-" + index + "NombreMsg"]: ""}));
                    inputIds = inputIds ? inputIds +(",especieNombre-" + key + "-" + index) : ("especieNombre-" + key + "-" + index);
                    valid = false;
                }
                if(!especie.ni && stateForm.tipoAnalisis !== "1"){
                    setErrors(errors => ({...errors, ["errorEspecie-" + key + "-" + index + "NI"]: true, ["errorEspecie-" + key + "-" + index + "NIMsg"]: ""}));
                    inputIds = inputIds ? inputIds +(",especieNI-" + key + "-" + index) : ("especieNI-" + key + "-" + index);
                    valid = false;
                }
            });
        });

        Object.keys(stateForm.muestreos).forEach((key) =>{
            stateForm.muestreos[key].map((muestreo, index) => {
                muestreo.map(((object, j) => {
                    if(object.nombre === ""){
                        setErrors(errors => ({...errors, ["errorEspecie-" + key + "-" + index + "-" + j + "Nombre"]: true, ["errorEspecie-" + key + "-" + index + "-" + j + "NombreMsg"]: ""}));
                        inputIds = inputIds ? inputIds +(",especieNombre-" + key + "-" + index + "-" + j) : ("especieNombre-" + key + "-" + index + "-" + j);
                        valid = false;
                    }
                    if(object.ni === "" && stateForm.tipoAnalisis !== "1"){
                        setErrors(errors => ({...errors, ["errorEspecie-" + key + "-" + index + "-" + j + "NI"]: true, ["errorEspecie-" + key + "-" + index + "-" + j + "NIMsg"]: ""}));
                        inputIds = inputIds ? inputIds +(",especieNI-" + key + "-" + index + "-" + j) : ("especieNI-" + key + "-" + index + "-" + j);
                        valid = false;
                    }
                }))
            });
        });

        Object.entries(stateForm.tipoVegetacion).forEach(([clave, valor]) => {

            if((stateForm.especies[clave] === undefined || stateForm.especies[clave]?.length === 0) && valor === true && (clave === "cultivoslenosos" || clave === "bosquesierramatorral" || clave === "bosqueisla")){
                setErrors(errors => ({...errors, ["errorTipoVegetacion-" + clave  + "-Vacio"]: true, ["errorTipoVegetacion-" + clave + "-VacioMsg"]: "Debe incluir al menos una especie o muestreo"}));
                inputIds = inputIds ? inputIds +(",tipoVegetacionVacio-" + clave ) : ("tipoVegetacionVacio-" + clave);
                valid = false;
            }else if((stateForm.muestreos[clave] === undefined || stateForm.muestreos[clave][0]?.length === 0) && valor === true && !(clave === "cultivoslenosos" || clave === "bosquesierramatorral" || clave === "bosqueisla")){
                setErrors(errors => ({...errors, ["errorTipoVegetacion-" + clave  + "-Vacio"]: true, ["errorTipoVegetacion-" + clave + "-VacioMsg"]: "Debe incluir al menos una especie o muestreo"}));
                inputIds = inputIds ? inputIds +(",tipoVegetacionVacio-" + clave ) : ("tipoVegetacionVacio-" + clave);
                valid = false;
            }
        });

        if(stateForm.tipoVegetacion.lindero && stateForm.tipoAnalisis === "0"){
            if(stateForm.metroslinealesLindero === "" || stateForm.metroslinealesLindero === undefined){
                setErrors(errors => ({...errors, ["errorMetrosLineales"]: true, ["errorMetrosLinealesMsg"]: "Debe indicar la distancia de metros lineales de lindero por muestreo."}));
                inputIds = inputIds ? inputIds +(",metrosLineales" ) : ("metrosLineales");
                valid = false;
            }
        }

        if(inputIds !== ""){
            try{
                let tipoVegetacionList = Object.keys(stateForm.tipoVegetacion).filter(key => {
                    if(stateForm.tipoVegetacion[key] === true){
                        return key;
                    }
                });
                setActiveDrops(tipoVegetacionList);
                let elem = document.getElementById(inputIds.split(",")[0]);
                elem.scrollIntoView({behavior: "smooth"});
                elem.focus();
            }catch(err){

            }
        }
        return valid;
    }

    const changeMetrosLinderos = (value) => {
        if(checkDoublePattern(value)){
            setStateForm((prevData) => ({...prevData, metroslinealesLindero: value}));
        }
    }


    const handlePrev = (e) => {
        e.preventDefault();
        setPosition(1);
    }

    const handleNext = (e) => {
        e.preventDefault();
        if(validateForm()){
            setPosition(3);
            console.log(stateForm);
        }
    };

    return(
        <>
        <Box className={position !== 2 && 'hide'}>
            {loading ?
                <Box p={3} align="center">
                    <CircularProgress />
                </Box>
                :
                <>
                    <h1 className="tic4bio-home-title">Nueva Biodiversidad Vegetal</h1>
                    <p style={{color: "rgba(0, 0, 0, 0.6)", margin: "42px 0px"}}>Finca seleccionada: <span style={{color: "#80C606", fontSize: "20px", fontWeight: "700"}}>{stateForm.finca.nombre}</span></p>
                    <p style={{color: "rgba(0, 0, 0, 0.6)", margin: "42px 0px"}}>Comunidad vegetal seleccionada: <span style={{color: "#80C606", fontSize: "20px", fontWeight: "700"}}>{stateForm.comunidad.nombre}</span></p>
                    
                    <p style={{color: "rgba(0, 0, 0, 0.6)", margin: "42px 0px"}}>Protocolos de muestreo según tipo de vegetación presente en la comunidad:</p>

                    <div>
                        {Object.entries(stateForm.tipoVegetacion).map(([fieldName, fieldValue], index) => {
                            if (fieldValue) {
                                return (
                                    <Fragment key={fieldName + "-" + index} >
                                        <button id={"tipoVegetacionVacio-" + fieldName} className={"dropdown-box green " + (activeDrops.includes(fieldName) ? 'active' : '')} onClick={() => handleClickDrops(fieldName)}>{printNameText(fieldName)}</button>
                                        {((fieldName === "cultivoslenosos" || fieldName === "bosquesierramatorral" || fieldName === "bosqueisla") && activeDrops.includes(fieldName)) &&
                                            <>
                                                {stateForm.tipoAnalisis === "1" ? 
                                                    <>
                                                        {fieldName === "cultivoslenosos" ? 
                                                            <>
                                                                <p>Debe indicar el nombre de los cultivos leñosos presentes en la comunidad seleccionada.</p>
                                                                <p>El icono <a href="https://lens.google/intl/es/" target="_blank" style={{verticalAlign: 'sub', display: 'inline-block', height: '20px'}}><img src={googlelens} alt="Icono Google Lens" /></a> le redirigirá a Google Lens, que le proporcionará ayuda para identificar especies a partir de una foto.</p>
                                                            </>
                                                            :
                                                            <>
                                                                <p>Debe establecer un área de muestreo de 20 m e indicar el nombre de las distintas especies vegetales que identifique. El área de muestreo debe establecerse en una zona representativa de la vegetación presente en toda la comunidad.</p>
                                                                <p>El icono <a href="https://lens.google/intl/es/" target="_blank" style={{verticalAlign: 'sub', display: 'inline-block', height: '20px'}}><img src={googlelens} alt="Icono Google Lens" /></a> le redirigirá a Google Lens, que le proporcionará ayuda para identificar especies a partir de una foto.</p>
                                                            </>
                                                        }
                                                        <TableBasicNoSamplingComponent stateForm={stateForm} setStateForm={setStateForm} tipoVegetacion={fieldName} errors={errors} />
                                                    </>
                                                    :
                                                    <>
                                                        {fieldName === "cultivoslenosos" ? 
                                                            <>
                                                                <p>Debe indicar el nombre de las  distintas especies  leñosas presentes en la comunidad seleccionada, así como el <b>nº de individuos</b> de cada especie.</p>
                                                                <p>El icono <a href="https://lens.google/intl/es/" target="_blank" style={{verticalAlign: 'sub', display: 'inline-block', height: '20px'}}><img src={googlelens} alt="Icono Google Lens" /></a> le redirigirá a Google Lens, que le proporcionará ayuda para identificar especies a partir de una foto.</p>
                                                            </>
                                                            :
                                                            <>
                                                                <p>Debe establecer un área de muestreo de 20 m e indicar el nombre de las distintas especies vegetales que identifique y <b>el nº de individuos</b> de cada uno. El área de muestreo debe establecerse en una zona representativa de la vegetación presente en toda la comunidad.</p>
                                                                <p>El icono <a href="https://lens.google/intl/es/" target="_blank" style={{verticalAlign: 'sub', display: 'inline-block', height: '20px'}}><img src={googlelens} alt="Icono Google Lens" /></a> le redirigirá a Google Lens, que le proporcionará ayuda para identificar especies a partir de una foto.</p>
                                                            </>
                                                        }
                                                        <TableAdvancedNoSamplingComponent stateForm={stateForm} setStateForm={setStateForm} tipoVegetacion={fieldName} errors={errors} />
                                                    </>
                                                }

                                            </>
                                        }
                                        {(!(fieldName === "cultivoslenosos" || fieldName === "bosquesierramatorral" || fieldName === "bosqueisla" || fieldName === "lindero") && activeDrops.includes(fieldName)) &&
                                            <>
                                                {stateForm.tipoAnalisis === "1" ? 
                                                    <>
                                                        <p>Debe indicar el nombre de los cultivos presentes en la comunidad seleccionada.</p> 
                                                        <p>El icono <a href="https://lens.google/intl/es/" target="_blank" style={{verticalAlign: 'sub', display: 'inline-block', height: '20px'}}><img src={googlelens} alt="Icono Google Lens" /></a> le redirigirá a Google Lens, que le proporcionará ayuda para identificar especies a partir de una foto.</p>
                                                        <ol>
                                                            <li>Sobre la cubierta o zona de cultivo (<img src={tinyterrain} style={{width: "40px", display: "inline-block", verticalAlign: "middle"}} alt=""/>) lance o establezca un cuadrante de 1 x 1 m (<img src={redsquare} style={{width: "20px", display: "inline-block", verticalAlign: "middle"}} alt=""/>)</li>
                                                            <div style={{position: "relative", display: "inline-block"}}>
                                                                <img src={terrain} style={{maxWidth: "300px", width: "100%"}} alt=""/>
                                                                <img src={redsquare} style={{width: "34px", position: "absolute", top: "40%", left: "36%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                            </div>
                                                            <li>Observe y anote el nombre de las especies que distinga. Puede ayudarse de la herramienta Google Lens.</li>
                                                            <li>Repita el proceso estableciendo 2 o 3 puntos de muestreos adicionales. Distribuya los puntos de muestreo aleatoriamente sobre el terreno y en zonas representativas. (Evitar zonas con crecimiento anormalmente alto de vegetación, o viceversa, parches del terreno con problemas de nascencia o crecimiento)</li>
                                                            <div style={{position: "relative", display: "inline-block"}}>
                                                                <img src={terrain} style={{maxWidth: "300px", width: "100%"}} alt=""/>
                                                                <img src={redsquare} style={{width: "22px", position: "absolute", top: "40%", left: "32%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                <img src={bluesquare} style={{width: "22px", position: "absolute", top: "32%", left: "52%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                <img src={bluesquare} style={{width: "22px", position: "absolute", top: "50%", left: "48%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                <img src={bluesquare} style={{width: "22px", position: "absolute", top: "40%", left: "64%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                            </div>
                                                            <li>Anotar el nombre de las NUEVAS especies que identifique en cada cuadrante.</li>
                                                        </ol>
                                                        <TableBasicSamplingComponent stateForm={stateForm} setStateForm={setStateForm} tipoVegetacion={fieldName} errors={errors} />
                                                    </>
                                                    :
                                                    <>
                                                        <p>Debe indicar el nombre de los cultivos presentes en la comunidad seleccionada y <b>nº de individuos</b> de cada especie identificada.</p>
                                                        <p>El icono <a href="https://lens.google/intl/es/" target="_blank" style={{verticalAlign: 'sub', display: 'inline-block', height: '20px'}}><img src={googlelens} alt="Icono Google Lens" /></a> le redirigirá a Google Lens, que le proporcionará ayuda para identificar especies a partir de una foto.</p>
                                                        <ol>
                                                            <li>Sobre la cubierta o zona de cultivo (<img src={tinyterrain} style={{width: "40px", display: "inline-block", verticalAlign: "middle"}} alt=""/>) lance o establezca un cuadrante de 1 x 1 m (<img src={redsquare} style={{width: "20px", display: "inline-block", verticalAlign: "middle"}} alt=""/>)</li>
                                                            <div style={{position: "relative", display: "inline-block"}}>
                                                                <img src={terrain} style={{maxWidth: "300px", width: "100%"}} alt=""/>
                                                                <img src={redsquare} style={{width: "34px", position: "absolute", top: "40%", left: "36%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                            </div>
                                                            <li>Observe y anote el nombre de las especies que distinga dentro del cuadrante, así como el nº de individuos/especie . Ayúdese de la herramienta Google Lens.</li>
                                                            <li>Repita el proceso estableciendo 2 o 3 puntos de muestreos adicionales. Distribuya los puntos de muestreo aleatoriamente sobre el terreno y en zonas representativas. (Evitar zonas con crecimiento anormalmente alto de vegetación, o viceversa, parches del terreno con problemas de nascencia o crecimiento)</li>
                                                            <div style={{position: "relative", display: "inline-block"}}>
                                                                <img src={terrain} style={{maxWidth: "300px", width: "100%"}} alt=""/>
                                                                <img src={redsquare} style={{width: "22px", position: "absolute", top: "40%", left: "32%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                <img src={bluesquare} style={{width: "22px", position: "absolute", top: "32%", left: "52%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                <img src={bluesquare} style={{width: "22px", position: "absolute", top: "50%", left: "48%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                <img src={bluesquare} style={{width: "22px", position: "absolute", top: "40%", left: "64%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                            </div>
                                                            <li>Anotar el nombre de las especies que identifique en cada cuadrante, así como el nº individuos/especie (Nº ind.).</li>
                                                        </ol>
                                                        <TableAdvancedSamplingComponent stateForm={stateForm} setStateForm={setStateForm} tipoVegetacion={fieldName} errors={errors} />
                                                    </>
                                                }
                                            </>
                                        }
                                        {fieldName === "lindero" && activeDrops.includes(fieldName) &&
                                            <>
                                                {stateForm.tipoAnalisis === "1" ? 
                                                    <>
                                                        <p>Debe indicar el nombre de los cultivos presentes en la comunidad seleccionada.</p> 
                                                        <p>El icono <a href="https://lens.google/intl/es/" target="_blank" style={{verticalAlign: 'sub', display: 'inline-block', height: '20px'}}><img src={googlelens} alt="Icono Google Lens" /></a> le redirigirá a Google Lens, que le proporcionará ayuda para identificar especies a partir de una foto.</p>
                                                        <ol>
                                                            <li>Sobre la cubierta o zona de cultivo (<img src={tinyterrain} style={{width: "40px", display: "inline-block", verticalAlign: "middle"}} alt=""/>) lance o establezca un cuadrante de 1 x 1 m (<img src={redsquare} style={{width: "20px", display: "inline-block", verticalAlign: "middle"}} alt=""/>)</li>
                                                            <div style={{position: "relative", display: "inline-block"}}>
                                                                <img src={terrain} style={{maxWidth: "300px", width: "100%"}} alt=""/>
                                                                <img src={redsquare} style={{width: "34px", position: "absolute", top: "40%", left: "36%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                            </div>
                                                            <li>Observe y anote el nombre de las especies que distinga. Puede ayudarse de la herramienta Google Lens.</li>
                                                            <li>Repita el proceso estableciendo 2 o 3 puntos de muestreos adicionales. Distribuya los puntos de muestreo aleatoriamente sobre el terreno y en zonas representativas. (Evitar zonas con crecimiento anormalmente alto de vegetación, o viceversa, parches del terreno con problemas de nascencia o crecimiento)</li>
                                                            <div style={{position: "relative", display: "inline-block"}}>
                                                                <img src={terrain} style={{maxWidth: "300px", width: "100%"}} alt=""/>
                                                                <img src={redsquare} style={{width: "22px", position: "absolute", top: "40%", left: "32%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                <img src={bluesquare} style={{width: "22px", position: "absolute", top: "32%", left: "52%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                <img src={bluesquare} style={{width: "22px", position: "absolute", top: "50%", left: "48%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                <img src={bluesquare} style={{width: "22px", position: "absolute", top: "40%", left: "64%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                            </div>
                                                            <li>Anotar el nombre de las NUEVAS especies que identifique en cada cuadrante.</li>
                                                        </ol>
                                                        <TableBasicSamplingComponent stateForm={stateForm} setStateForm={setStateForm} tipoVegetacion={fieldName} errors={errors} />
                                                    </>
                                                    :
                                                    <>
                                                        <p>Debe indicar el nombre de los cultivos presentes en la comunidad seleccionada y <b>nº de individuos</b> de cada especie identificada.</p>
                                                        <p>El icono <a href="https://lens.google/intl/es/" target="_blank" style={{verticalAlign: 'sub', display: 'inline-block', height: '20px'}}><img src={googlelens} alt="Icono Google Lens" /></a> le redirigirá a Google Lens, que le proporcionará ayuda para identificar especies a partir de una foto.</p>
                                                        <ol>
                                                            <li style={{marginBottom: "10px"}}>Indique los metros lineales de lindero que va a examinar. Utilice siempre esta misma distancia en cada muestreo que realice sobre el lindero. </li>
                                                            <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                                                <TextField
                                                                    className="t4b-finca-sector-inputs no-red-label"
                                                                    label="Metros lineales del lindero/muestreo*"
                                                                    id={"metrosLineales"}
                                                                    value={stateForm.metroslinealesLindero}
                                                                    type="number"
                                                                    variant="standard"
                                                                    InputProps={{
                                                                        inputProps: { 
                                                                            max: 99999, min: 1,
                                                                        }
                                                                    }}
                                                                    onChange={(e) => {changeMetrosLinderos(e.target.value)}}
                                                                    name="metrosLineales"
                                                                    error={errors["errorMetrosLineales"]}
                                                                />
                                                                {errors["errorMetrosLineales"] && <p className="errorMsg">{errors["errorMetrosLinealesMsg"]}</p>}
                                                            </FormControl>
                                                            <div style={{position: "relative", display: "inline-block"}}>
                                                                <img src={singlelindero} style={{maxWidth: "300px", width: "100%"}} alt=""/>
                                                            </div>
                                                            <li>Identifique y anote (en la tabla de la parte inferior de esta pantalla) las especies y nº de individuos de cada especie presentes en el muestreo.</li>
                                                            <li>Repita el proceso estableciendo 2 o 3 puntos de muestreo adicionales. Distribuya los puntos de muestreo aleatoriamente sobre el lindero y en zonas representativas del mismo.</li>
                                                            <div style={{position: "relative", display: "inline-block"}}>
                                                                <img src={multilindero} style={{maxWidth: "300px", width: "100%"}} alt=""/>
                                                            </div>
                                                        </ol>
                                                        
                                                        <TableAdvancedSamplingComponent stateForm={stateForm} setStateForm={setStateForm} tipoVegetacion={fieldName} errors={errors} />
                                                    </>
                                                }
                                            </>
                                        }
                                        {errors["errorTipoVegetacion-" + fieldName + "-Vacio"] && <p className="errorMsg">{errors["errorTipoVegetacion-" + fieldName + "-VacioMsg"]}</p>}
                                    </Fragment>
                                );
                            }
                        })}
                    </div>

                    <Stack spacing={2} direction="row" justifyContent="space-between" className="tic4bio-sectores-btns">
                        <button className="btn green" onClick={(e) => handlePrev(e)}>Anterior</button>
                        <button className="btn green" onClick={(e) => handleNext(e)} style={{margin: "16px 0"}}>Siguiente</button>
                    </Stack>
                </>
            }

        </Box>
        </>
    )
}

export default FormBioStepThreeComponent;