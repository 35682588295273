import logotic4bio from '../assets/lateralmenu/logoheadbio.png';
//import logomapa from '../assets/logo-MAPA.png';
import logoue from '../assets/logo-UE.png';
import logojunta from '../assets/logo-juntaAndalucia.png';
import { Link } from "react-router-dom";
import Footer from "../components/footer/Footer";
import { Box } from '@mui/material';


const Error404 = () => {
    //const prevRoute = useLocation();

    const customStyle = {
        textAlign: 'center',
        //color: 'red',
        fontSize: '25px',       
      };

    return (
        <div className="welcome-main">
        <div className="welcome-background">
            <div className="welcome-logos">
                <div className="welcome-logos-top">
                    <img className="welcome-logo-tic4bio" src={logotic4bio} alt={"Imagen Logo Tic4Bio"}/>
                </div>
                <div className="welcome-logos-bottom">
                    <Box className="financian-text">FINANCIAN:</Box>
                    <div className="welcome-logos-bottom-images">
                        <img className="welcome-logo-ue" src={logoue} alt={"Imagen Logo Tic4Bio"}/>
                        <img className="welcome-logo-junta" src={logojunta} alt={"Imagen Logo Tic4Bio"}/>
                    </div>
                </div>
            </div>
            <div className='welcome-text hide'>
                <h2>
                La Junta de Andalucía ha recibido una ayuda de la Unión Europea con cargo al FEADER para el proyecto TIC4BIO, 
                que tiene por objetivo dar soporte a los agricultores para la gestión del consumo de riego de sus fincas, 
                el cálculo de su huella de agua y la toma de decisiones de riego.
                </h2>
                <Link to="https://agriculture.ec.europa.eu/common-agricultural-policy/rural-development_es" ><h3>Comisión Europea de agricultura y desarrollo rural</h3></Link>
             </div>
        </div>
        <div className="tic4bio-home " style={customStyle}> <h1 >Error 404 Página no encontrada</h1>
        <Link to="/">Volver a la página de inicio</Link>
        </div>
       
        <Footer/>
    </div>

            );

}


export default Error404;