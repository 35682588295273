import { Backdrop, CircularProgress } from "@mui/material";

const LoadingBackdrop = ({loading}) => {
    return ( 
        <Backdrop
            sx={{ color: '#fff', zIndex: 9999}}
            open={loading}>

            <CircularProgress style={{'color': 'white'}}/>

        </Backdrop>
     );
}
 
export default LoadingBackdrop;