import logotic4bio from '../assets/lateralmenu/logoheadbio.png';
import logomapa from '../assets/logo-MAPA.png';
import logoue from '../assets/logo-UE.png';
import logojunta from '../assets/logo-juntaAndalucia.png';
import { Link, useLocation } from "react-router-dom";
import Footer from "../components/footer/Footer";
import { Box } from '@mui/material';

const WelcomePage = () => {

    const prevRoute = useLocation();

    return (
        <div className="welcome-main">
            <div className="welcome-background">
                <div className="welcome-logos">
                    <div className="welcome-logos-top">
                        <img className="welcome-logo-tic4bio" src={logotic4bio} alt={"Imagen Logo Tic4Bio"}/>
                    </div>
                    <div className="welcome-logos-bottom">
                        <Box className="financian-text">FINANCIAN:</Box>
                        <div className="welcome-logos-bottom-images">
                            <img className="welcome-logo-ue" src={logoue} alt={"Imagen Logo Tic4Bio"}/>
                            <img className="welcome-logo-junta" src={logojunta} alt={"Imagen Logo Tic4Bio"}/>
                        </div>
                    </div>
                </div>
                <div className='welcome-text hide'>
                    <h2>
                    La Junta de Andalucía ha recibido una ayuda de la Unión Europea con cargo al FEADER para el proyecto TIC4BIO, 
                    que tiene por objetivo dar soporte a los agricultores para la gestión del consumo de riego de sus fincas, 
                    el cálculo de su huella de agua y la toma de decisiones de riego.
                    </h2>
                    <Link to="https://agriculture.ec.europa.eu/common-agricultural-policy/rural-development_es" ><h3>Comisión Europea de agricultura y desarrollo rural</h3></Link>
                 </div>
            </div>
            <div className="welcome-container-wc">
                <div className="welcome-body">
                    <h1>Bienvenido</h1>
                    <h2 className="welcome-body-typography-subtitle">
                        TIC4BIO le abre las puertas para disfrutar todas las ventajas que ofrece.
                    </h2>
                    <div className='welcome-body-buttons'>
                        <div className="button white">
                            <Link to="/login" state={prevRoute.pathname}>Iniciar sesión</Link>
                        </div>
                        <div className="button green">
                            <Link to="/registro" state={prevRoute.pathname}>Registrarse</Link>
                        </div>
                    </div>
                    <div style={{textAlign: "right", color: "gray", fontSize: "14px", fontWeight: 500}}>V. {process.env.REACT_APP_VERSION_FRONT_T4B}</div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}
 
export default WelcomePage;