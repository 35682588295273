import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import DoughnutRiquezaBasico from "../charts/DoughnutRiquezaBasico";
import { useEffect, useState } from "react";
import ModalTip from "../ModalTip";
import IndiceBiodiversidadVegetal from "../bars/IndiceBiodiversidadVegetal";
import RadarChart from "../charts/RadarChart";
import DoughnutEspeciesComunidad from "../charts/DoughnutEspeciesComunidad";
import { partirStringLargo } from "../../utils/commons";

const DetallesFincaCompletaComponent = ({year, bioVertebradosByYear, handleYearSelect, data}) => {

    const [ openTip, setOpenTip ] = useState(false);
    const [ msgTip, setMsgTip ] = useState({open: false, content: ''});
    const [riquezaCompleta, setRiquezaCompleta] = useState({
        riquezaEspecifica: "",
        especiesTotales: [],
        numEspeciesTotales: "",
        isBasic: true,
        comunidades: [],
        finca: {}
    });

    const [ infoData, setInfoData ] = useState({
        labels: ["Índice de Simpson (D)", ["Índice de Diversidad", "de Simpson (1 - D)"], ["Índice de equidad", "de Pielou (J')"]],
        datasets: [
            {
            data: [0, 0, 0],
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(128, 198, 6, 1)',
            borderWidth: 2,
            },
        ],
    });

    const [ nIndividuosComunidad, setNIndividuosComunidad ] = useState({
        labels: ['Bosquejo 1', 'Bosquejo 2', 'Bosquejo 3'],
        datasets: [
            {
              label: 'Nº de individuos',
              data: [0, 0, 0],
              backgroundColor: [
                'rgba(122,154,200, 0.8)',
                'rgba(128, 198, 6, 0.8)',
                'rgba(140, 228, 46, 0.8)'
              ],
              borderColor: [
                  'rgba(255,255,255,1)'
              ],
              borderWidth: 2
            },
        ],
    });

    const [ especiesPorComunidad, setEspeciesPorComunidad ] = useState({
        labels: ['Bosquejo 1', 'Bosquejo 2', 'Bosquejo 3'],
        datasets: [
            {
              label: 'Riqueza específica',
              data: [0, 0, 0],
              backgroundColor: [
                'rgba(122,154,200, 0.8)',
                'rgba(128, 198, 6, 0.8)',
                'rgba(140, 228, 46, 0.8)'
              ],
              borderColor: [
                  'rgba(255,255,255,1)'
              ],
              borderWidth: 2
            },
        ],
    });

    const [ especiesBiodiversidad, setEspeciesBiodiversidad ] = useState({
        labels: ['Bosquejo 1', 'Bosquejo 2', 'Bosquejo 3'],
        datasets: [
          {
            label: 'Índice de biodiversidad de Simpson',
            data: [0.5, 2, 1],
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(128, 198, 6, 1)',
            borderWidth: 2,
          },
          {
            label: "Índice de equidad de Pielou (J')",
            data: [1, 0, 3],
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(255, 99, 132, 1)',
            borderWidth: 2,
          },
        ],
      });

    useEffect(() => {
        if(year){
            calculoRiquezaCompleta();
        }
    }, [year]);

    const backgroundColors = [
        'rgba(255,192,0,0.8)',
        'rgba(237,125,49,0.8)',
        'rgba(112,173,71,0.8)',
        'rgba(255, 99, 132, 0.8)',
        'rgba(54, 162, 235, 0.8)',
        'rgba(255, 206, 86, 0.8)',
        'rgba(75, 192, 192, 0.8)',
        'rgba(153, 102, 255, 0.8)',
    ];

    const chooseColor = (len) => {
        let bgColors = [];
        for(let i=0; i<len; i++){
            bgColors.push(backgroundColors[i % backgroundColors.length]);
        }
        return bgColors;
    }

    const handleOpenModal = (modal) => {
        setOpenTip(true);
        let text = '';
        switch (modal) {
            case "riquezaespecifica":
                text = '<div class="modaltip"><h3>ÍNDICE DE RIQUEZA ESPECÍFICA</h3><div class="modaltip-box">La riqueza específica es una medida de la biodiversidad que se define como el número de especies presentes en una comunidad. Es una medida simple y fácil de calcular, pero puede proporcionar información valiosa sobre la diversidad de una comunidad.</div></div>';
                break;
            case "dominanciasimpson":
                text = '<div class="modaltip"><h3>ÍNDICE DE SIMPSON (D)</h3><div class="modaltip-box">El índice de Simpson es un índice de diversidad que mide la probabilidad de que dos individuos seleccionados al azar de una comunidad pertenezcan a la misma especie.<br> Toma valores de 0 a 1.<br> Un valor alto del índice de Simpson indica que la comunidad es dominada por unas pocas especies, mientras que un valor bajo indica que la comunidad es más diversa y está compuesta por un mayor número de especies con abundancias más equitativas.</div></div>';
                break;
            case "diversidadsimpson":
                text = '<div class="modaltip"><h3>ÍNDICE DE DIVERSIDAD DE SIMPSON (1-D)</h3><div class="modaltip-box">El índice de diversidad de Simpson es un índice <u>inverso</u> al de dominancia de Simpson, por tanto, mide la probabilidad de que dos individuos seleccionados al azar de una comunidad pertenezcan <b>diferentes</b> especies.<br> Toma valores de 0 a 1.<br> Un valor alto del índice de Simpson es indicativo de que la probabilidad de que dos individuos pertenezcan a la misma especie es baja, lo que indica que hay una distribución uniforme y equitativa de las especies dentro de la zona estudiada.</div></div>';
                break;
            case "pielou":
                text = '<div class="modaltip"><h3>ÍNDICE DE PIELOU (J’)</h3><div class="modaltip-box">Mide la equitatividad de una comunidad, es decir, la uniformidad en la abundancia de las especies.<br> Toma valores entre 0 y 1.<br> Valores cercanos a 1 indican que todas las especies son igualmente abundantes y el 0 indica una completa falta de uniformidad y equidad entre especies.</div></div>';
                break;
            case "shannonwiener":
                text = '<div class="modaltip"><h3>ÍNDICE DE SHANNON-WIENER (H’)</h3><div class="modaltip-box">Mide el grado promedio de incertidumbre en predecir a que especie pertenecerá un individuo tomado al azar de la zona, comunidad o finca de estudio. Considera tanto la riqueza en especies como su abundancia.<br> Toma valores entre 0 y ln(S), siendo S el nº de especies.<br> Valores cercanos a cero representan una comunidad con pocas especies y valores próximos a ln(S) representan una comunidad más equilibrada y rica en especies.</div></div>';
                break;
            case "grafico":
                text = '<div class="modaltip"><div class="modaltip-box">Cuanto mayores son los índices de Pielou, Diversidad de Simpson, Riqueza específica  y Shannon-Wiener y menor sea el valor del índice de Simpson, más diversa, homogénea y equilibrada será la biodiversidad de la finca.</div></div>';
                break;
        }
        setMsgTip((msg) => ({
            ...msg,
            content: text
        }));
    }


    const calculoRiquezaCompleta = () => {
        let isBasic = bioVertebradosByYear[year].some(bv => bv.tipoAnalisis === 1);

        let comunidadesData;
        let especiesTotal = totalEspecies();
        if(!isBasic){
            comunidadesData = getComunidadesData(especiesTotal.length);
            setRiquezaCompleta({especiesTotales: especiesTotal, numEspeciesTotales: comunidadesData?.fincaData.numEspeciesTotales, riquezaEspecifica: especiesTotal.length, isBasic: isBasic, comunidades: comunidadesData?.comunidadesData, finca: comunidadesData?.fincaData});
            setInfoData(prevState => ({
                ...prevState,
                datasets: prevState.datasets.map(dataset => ({
                    ...dataset,
                    data: [comunidadesData.fincaData.indiceSimpson, comunidadesData.fincaData.indiceDiversidadSimpson, comunidadesData.fincaData.indicePielou]
                })),
            }));

            let labelsComunidades = comunidadesData.comunidadesData.map(cd => cd.nombre_comunidad);
            let individuosDataComunidades = comunidadesData.comunidadesData.map(cd => cd.numEspeciesTotales);

            setNIndividuosComunidad(prevState => ({
                ...prevState,
                labels: labelsComunidades,
                datasets: prevState.datasets.map(dataset => ({
                    ...dataset,
                    data: individuosDataComunidades,
                    backgroundColor: chooseColor(individuosDataComunidades.length),
                })),
            }));

            let especieDataComunidades = comunidadesData.comunidadesData.map(cd => cd.riquezaEspecifica);
            setEspeciesPorComunidad(prevState => ({
                ...prevState,
                labels: labelsComunidades,
                datasets: prevState.datasets.map(dataset => ({
                    ...dataset,
                    data: especieDataComunidades,
                    backgroundColor: chooseColor(especieDataComunidades.length),
                })),
            }));

            let indicesDeSimpson = {
                label: 'Índice de biodiversidad de Simpson',
                data: comunidadesData.comunidadesData.map(cd => cd.indiceSimpson),
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderColor: 'rgba(128, 198, 6, 1)',
                borderWidth: 1,
            };
            let indicesDePielou = {
                label: "Índice de equidad de Pielou (J')",
                data: comunidadesData.comunidadesData.map(cd => cd.indicePielou),
                backgroundColor: 'rgba(0, 0, 0, 0)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            };
            setEspeciesBiodiversidad(prevState => ({
                ...prevState,
                labels: partirStringLargo(labelsComunidades),
                datasets: [
                    indicesDeSimpson,
                    indicesDePielou
                ]
            }));

        }else{
            setRiquezaCompleta(prevData => ({...prevData, especiesTotales: especiesTotal, riquezaEspecifica: especiesTotal.length, isBasic: isBasic}));
        
        }
        
    }

    const totalEspecies = () => {
        const nombresUnicos = new Set();
        bioVertebradosByYear[year].forEach(bv => {
            bv.muestreos.forEach(muestreo => {

                muestreo.muestras.forEach(muestra => {
    
                    nombresUnicos.add(muestra.nombre);
                });
            });
        });

        return Array.from(nombresUnicos);
    }

    const getComunidadesData = (riquezaEspecificaFinca) => {
        let resultFinca = {};
        let listadoComunidadesInfo = [];
        let listadoBV = [];
        let resultListFinca = [];
        let total = 0;
        let superficieTotalComunidades = 0;
        let fincaIndiceSimpson = 0;
        let fincaIndiceDiversidadSimpson = 0;
        let fincaIndicePielou = 0;
        let fincaIndiceShannonWiener = 0;
        bioVertebradosByYear[year].forEach(bv=> {
            let sumasPorNombre = {};
            let sumaTotal = 0;
            let result = {};
            let resultList = [];
            let indiceSimpson = 0;
            let indiceDiversidadSimpson = 0;
            let indicePielou = 0;
            let indiceShannonWiener = 0;
            superficieTotalComunidades += bv.comunidad_superficie;
            const comunidad_superficie = bv.comunidad_superficie;
            const comunidad_id = bv.comunidad_id;
            bv.muestreos.forEach(muestreo => {
                muestreo.muestras.forEach(muestra => {
                    const nombre = muestra.nombre;
                    const ika = muestra.ika;
                    const num_individuos = muestra.num_individuos;

                    if (sumasPorNombre[nombre]) {
                        sumasPorNombre[nombre].suma += ika;
                        sumasPorNombre[nombre].sumaInd += num_individuos;
                        //sumasPorNombre[nombre].cantidad++;
                    } else {
                        sumasPorNombre[nombre] = {
                        suma: ika,
                        sumaInd: num_individuos,
                        cantidad: muestreo.numMuestreos,
                        comunidad_superficie: comunidad_superficie,
                        comunidad_id: comunidad_id
                        };
                    }
                });
            });

            for (const nombre in sumasPorNombre) {
                const { suma, cantidad, comunidad_superficie, comunidad_id } = sumasPorNombre[nombre];

                let sumaCalc = 0;
                if (cantidad > 1) {
                    sumaCalc = suma / cantidad;
                    sumaTotal += sumaCalc;
                } else {
                    sumaCalc = suma;
                    sumaTotal += suma;
                }
                listadoBV.push({nombre: nombre, suma: sumaCalc, comunidad_superficie: comunidad_superficie, comunidad_id: comunidad_id})
            }

            sumaTotal = Math.round(sumaTotal);

            for (const nombre in sumasPorNombre) {
                const { suma, cantidad, sumaInd } = sumasPorNombre[nombre];
    
                let pi;
                let pisquare;
                let h;
                let media = suma;
                if (cantidad > 1) {
                    media = media / cantidad;
                    pi = (media/sumaTotal);
                    pisquare = pi ** 2;
                    h = getH(pi);
                } else {
                    pi = (suma/sumaTotal);
                    pisquare = pi ** 2;
                    h = getH(pi);
                }
                indiceSimpson += pisquare;
                indiceShannonWiener += h;
                resultList.push({nombre: nombre, ika: media, nIndividuos: sumaInd, pi: pi, pisquare: pisquare, h: h});
            }
    
            indiceDiversidadSimpson = 1 - indiceSimpson;
            indicePielou = indiceShannonWiener / (Math.log(Object.keys(sumasPorNombre).length));
    
            result.nombre_comunidad = bv.nombre_comunidad;
            result.muestrasFiltradas = resultList;
            result.riquezaEspecifica = Object.keys(sumasPorNombre).length;
            result.numEspeciesTotales = Math.round(sumaTotal);
            result.indiceSimpson = parseFloat(indiceSimpson.toFixed(2));
            result.indiceDiversidadSimpson = parseFloat(indiceDiversidadSimpson.toFixed(2));
            result.indicePielou = parseFloat(indicePielou.toFixed(2));
            result.indiceShannonWiener = parseFloat(indiceShannonWiener.toFixed(2));

            listadoComunidadesInfo.push(result);
        });

        /*let sinRepetir = listadoBV.reduce((acumulador, actual) => {
            const encontrado = acumulador.find(item => item.nombre === actual.nombre);
            if (encontrado) {
                encontrado.nIndividuos += actual.suma;
            } else {
                acumulador.push({ nombre: actual.nombre, nIndividuos: actual.suma, comunidad_superficie: actual.comunidad_superficie });
            }
            return acumulador;
        }, []);*/

        // Primer paso: sumar por nombre y comunidad_id, luego multiplicar por comunidad_superficie
        let paso1 = listadoBV.reduce((acumulador, actual) => {
            const key = `${actual.nombre}-${actual.comunidad_id}`;
            if (!acumulador[key]) {
                acumulador[key] = { nombre: actual.nombre, sumaTotal: 0, comunidad_superficie: actual.comunidad_superficie };
            }
            acumulador[key].sumaTotal += actual.suma;
            return acumulador;
        }, {});

        let paso1Array = Object.values(paso1).map(item => ({
            nombre: item.nombre,
            suma: item.sumaTotal * (item.comunidad_superficie / superficieTotalComunidades)
        }));

        // Segundo paso: sumar por nombre
        let sinRepetir = paso1Array.reduce((acumulador, actual) => {
            const encontrado = acumulador.find(item => item.nombre === actual.nombre);
            if (encontrado) {
                encontrado.suma += actual.suma;
            } else {
                acumulador.push({ nombre: actual.nombre, suma: actual.suma });
            }
            return acumulador;
        }, []);
        
        console.log(sinRepetir);

        total = sinRepetir.reduce((acumulador, actual) => acumulador + actual.suma, 0);

        sinRepetir.forEach(item => {
            let pi;
            let pisquare;
            let h;
            let ika;

            ika = item.suma;
            pi = ika / total;
            pisquare = pi ** 2;
            h = getH(pi);

            fincaIndiceSimpson += pisquare;
            fincaIndiceShannonWiener += h;
            resultListFinca.push({nombre: item.nombre, nIndividuos: ika, pi: pi, pisquare: pisquare, h: h});
        });
        
        fincaIndiceDiversidadSimpson = 1 - fincaIndiceSimpson;
        fincaIndicePielou = fincaIndiceShannonWiener / (Math.log(riquezaEspecificaFinca));

        resultFinca.muestrasFiltradas = resultListFinca;
        resultFinca.numEspeciesTotales = Math.round(total);
        resultFinca.riquezaEspecifica = riquezaEspecificaFinca;
        resultFinca.indiceSimpson = parseFloat(fincaIndiceSimpson.toFixed(2));
        resultFinca.indiceDiversidadSimpson = parseFloat(fincaIndiceDiversidadSimpson.toFixed(2));
        resultFinca.indicePielou = parseFloat(fincaIndicePielou.toFixed(2));
        resultFinca.indiceShannonWiener = parseFloat(fincaIndiceShannonWiener.toFixed(2));

        return {comunidadesData: listadoComunidadesInfo, fincaData: resultFinca, totalIndividuosFinca: Math.round(total)};
    }

    const getH = (pi) => {
        let res = 0;
        if(pi > 0){
            res = -(pi * Math.log(pi));
        }
        return res;
    }

    const renderIndices = (indice) => {
        return riquezaCompleta.comunidades.map(comunidad => (
            <td key={comunidad.nombre_comunidad} style={{ backgroundColor: calculateColor(0, 1, comunidad[indice], false) }}>
                {comunidad[indice]}
            </td>
        ));
    };

    const renderIndiceWinner = (indice) => {
        return riquezaCompleta.comunidades.map(comunidad => (
            <td key={comunidad.nombre_comunidad} style={{ backgroundColor: calculateColor(0, parseFloat(Math.log(comunidad.riquezaEspecifica).toFixed(2)), comunidad[indice], false) }}>
                {comunidad[indice]}
            </td>
        ));
    };

    const renderIndiceSimpson = (indice) => {
        return riquezaCompleta.comunidades.map(comunidad => (
            <td key={comunidad.nombre_comunidad} style={{ backgroundColor: calculateColor(0, 1, comunidad[indice], true) }}>
                {comunidad[indice]}
            </td>
        ));
    };

    /*const renderIndices = (indice) => {
        return riquezaCompleta.comunidades.map(comunidad => (
            <td key={comunidad.nombre_comunidad} style={{ backgroundColor: getBackgroundColorCell(comunidad[indice]) }}>
                {comunidad[indice]}
            </td>
        ));
    };

    const renderIndiceWinner = (indice) => {
        return riquezaCompleta.comunidades.map(comunidad => (
            <td key={comunidad.nombre_comunidad} style={{ backgroundColor: getBackgroundColorCellWinner(comunidad[indice]) }}>
                {comunidad[indice]}
            </td>
        ));
    };

    const renderIndiceSimpson = (indice) => {
        return riquezaCompleta.comunidades.map(comunidad => (
            <td key={comunidad.nombre_comunidad} style={{ backgroundColor: getBackgroundColorCellInverted(comunidad[indice]) }}>
                {comunidad[indice]}
            </td>
        ));
    };*/

    const calculateColor = (min, max, valor, inverted) => {
        // Colores del gradiente
        let colorMin = [255, 255, 255];
        let colorMax = [85, 132, 54];
        if(inverted){
            colorMin = [85, 132, 54];
            colorMax = [255, 255, 255];
        }
        
        const proporcion = (valor - min) / (max - min);
        
        const colorInterpolado = colorMin.map((cMin, i) => {
            return Math.round(cMin + proporcion * (colorMax[i] - cMin));
        });
        
        const colorRgb = `rgb(${colorInterpolado.join(', ')})`;
        
        return colorRgb;
    }

    /*const getBackgroundColorCellWinner = (indice) => {
        if (indice >= 0 && indice < 0.25) {
            return "#ffef9c";
        } else if (indice >= 0.25 && indice < 0.76) {
            return "#d8e394";
        } else if (indice >= 0.76 && indice < 1.5) {
            return "#8fcb84";
        } else if (indice >= 1.5 && indice <= 2.3) {
            return "#63be7b";
        }
        return "transparent";
    };

    const getBackgroundColorCell = (indice) => {
        if (indice >= 0 && indice < 0.25) {
            return "#ffef9c";
        } else if (indice >= 0.25 && indice < 0.5) {
            return "#d8e394";
        } else if (indice >= 0.5 && indice < 0.75) {
            return "#8fcb84";
        } else if (indice >= 0.75 && indice <= 1) {
            return "#63be7b";
        }
        return "transparent";
    };

    const getBackgroundColorCellInverted = (indice) => {
        if (indice >= 0 && indice < 0.25) {
            return "#63be7b";
        } else if (indice >= 0.25 && indice < 0.5) {
            return "#8fcb84";
        } else if (indice >= 0.5 && indice < 0.75) {
            return "#d8e394";
        } else if (indice >= 0.75 && indice <= 1) {
            return "#ffef9c";
        }
        return "transparent";
    };
*/

    return(
        <>
            {(Object.keys(bioVertebradosByYear).length > 0) ?
                <>
                    <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                        <InputLabel htmlFor="fecha-select">Año*</InputLabel>
                        <Select
                            className="t4b-finca-sector-inputs"
                            id="fecha-select"
                            label="Fecha"
                            required
                            MenuProps={{ disableScrollLock: true }}
                            value={year}
                            onChange={(event) => { handleYearSelect(event) }
                            }>
                        
                            {Object.keys(bioVertebradosByYear).map((y, index) => (
                                <MenuItem key={index} value={y}>
                                    {y}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {year &&
                        <>
                            <div className="chart-container">
                                <h3 style={{textAlign: "center", textDecoration: "underline", color: "#80C606", fontSize: "26px", marginBottom: "40px"}}>Biodiversidad</h3>
                                
                                <div className="b-container">
                                    <div className={riquezaCompleta.isBasic === true ? "b-container-green" : "b-container-white"}>
                                        Básico
                                    </div>
                                    <div className={riquezaCompleta.isBasic === false ? "b-container-green" : "b-container-white"}>
                                        Avanzado
                                    </div>
                                </div>

                                <p style={{margin: "30px 0", textAlign: "center", fontSize: "18px", fontWeight: 600}}>Riqueza específica (S): {riquezaCompleta.riquezaEspecifica} <span className="info-icon" onClick={() => { handleOpenModal("riquezaespecifica") }}>i</span></p>

                                {riquezaCompleta.isBasic === false ?
                                    <>
                                        <p style={{margin: "30px 0", textAlign: "center", fontSize: "18px", fontWeight: 600}}>Total de individuos: {riquezaCompleta.numEspeciesTotales}</p>

                                        <div className="chart-box">
                                            <IndiceBiodiversidadVegetal value={riquezaCompleta.finca.indiceSimpson} minValue={0} maxValue={1} texto={"ÍNDICE DE DOMINANCIA DE SIMPSON"} />
                                            <div className="chart-indice-info"><span className="info-icon" onClick={() => { handleOpenModal("dominanciasimpson") }}>i</span></div>
                                        </div>
                                        <div className="chart-box">
                                            <IndiceBiodiversidadVegetal value={riquezaCompleta.finca.indiceDiversidadSimpson} minValue={0} maxValue={1} texto={"ÍNDICE DE DIVERSIDAD DE SIMPSON"} />
                                            <div className="chart-indice-info"><span className="info-icon" onClick={() => { handleOpenModal("diversidadsimpson") }}>i</span></div>
                                        </div>
                                        <div className="chart-box">
                                            <IndiceBiodiversidadVegetal value={riquezaCompleta.finca.indicePielou} minValue={0} maxValue={1} texto={"ÍNDICE EQUIDAD PIELOU"} />
                                            <div className="chart-indice-info"><span className="info-icon" onClick={() => { handleOpenModal("pielou") }}>i</span></div>
                                        </div>
                                        <div className="chart-box">
                                            <IndiceBiodiversidadVegetal value={riquezaCompleta.finca.indiceShannonWiener} minValue={0} maxValue={2.30} texto={"ÍNDICE DE SHANNON-WIENER"} riqEsp={riquezaCompleta.finca.riquezaEspecifica}/>
                                            <div className="chart-indice-info"><span className="info-icon" onClick={() => { handleOpenModal("shannonwiener") }}>i</span></div>
                                        </div>
                                    </>
                                    :
                                    <DoughnutRiquezaBasico data={data}/>
                                }

                            </div>

                            {riquezaCompleta.isBasic === true &&
                                <div className="chart-container">
                                    <h3 style={{textAlign: "center", textDecoration: "underline", color: "#80C606", fontSize: "26px", marginBottom: "40px"}}>Datos</h3>
                                    
                                    <div className="tabla-bio-box">
                                        <table className="tabla-bio" style={{marginbottom: "30px"}}>
                                            <thead>
                                                <tr>
                                                    <th>Especies en totales de la finca</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {riquezaCompleta.especiesTotales.map((item, index) => (
                                                    <tr key={"especie-" + index}>
                                                        <td>{item}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    
                                </div>
                            }

                            {riquezaCompleta.isBasic === false &&
                                <>
                                    <div className="chart-container">
                                        <div className="relative">
                                            <h3 style={{textAlign: "center", textDecoration: "underline", color: "#80C606", fontSize: "26px", marginBottom: "40px"}}>Gráfico</h3>
                                            <span className="info-icon" style={{position: "absolute", top: 0, right: 0}} onClick={() => { handleOpenModal("grafico") }}>i</span>
                                        </div>
                                        <RadarChart infoData={infoData}/>

                                        <div className="tabla-bio-box" style={{marginBottom: "60px"}}>
                                            <table className="tabla-bio">
                                                <thead>
                                                    <tr>
                                                        <th className="fc"></th>
                                                        {riquezaCompleta.comunidades.map((comunidad, index) => (
                                                            <th className="fc" key={comunidad.nombre_comunidad + "-" + index}>{comunidad.nombre_comunidad}</th>
                                                        ))}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Total individuos (N)</td>
                                                        {riquezaCompleta.comunidades.map(comunidad => (
                                                            <td key={comunidad.nombre_comunidad}>
                                                                {riquezaCompleta.comunidades.find(obj => obj.nombre_comunidad === comunidad.nombre_comunidad)["numEspeciesTotales"]}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                    <tr>
                                                        <td>Riqueza específica (S)</td>
                                                        {riquezaCompleta.comunidades.map(comunidad => (
                                                            <td key={comunidad.nombre_comunidad}>
                                                                {riquezaCompleta.comunidades.find(obj => obj.nombre_comunidad === comunidad.nombre_comunidad)["riquezaEspecifica"]}
                                                            </td>
                                                        ))}
                                                    </tr>
                                                    <tr>
                                                        <td>Índice de Simpson (D)</td>
                                                        {renderIndiceSimpson("indiceSimpson")}
                                                    </tr>
                                                    <tr>
                                                        <td>Índice de diversidad de Simpson (1 - D)</td>
                                                        {renderIndices("indiceDiversidadSimpson")}
                                                    </tr>
                                                    <tr>
                                                        <td>Índice de equidad de Pielou (J')</td>
                                                        {renderIndices("indicePielou")}
                                                    </tr>
                                                    <tr>
                                                        <td>Índice de Shannon-Wiener (H')</td>
                                                        {renderIndiceWinner("indiceShannonWiener")}
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <p style={{textAlign: "center", color: "#595959", fontWeight: 600, fontSize: "18px"}}>Nº individuos aportados por cada comunidad</p>
                                        <DoughnutRiquezaBasico data={nIndividuosComunidad}/>

                                        <p style={{textAlign: "center", color: "#595959", fontWeight: 600, fontSize: "18px"}}>Especies en cada comunidad</p>
                                        <DoughnutEspeciesComunidad data={especiesPorComunidad}/>

                                        <div>
                                            <p style={{textAlign: "center", color: "#595959", fontWeight: 600, fontSize: "18px", marginTop: "40px"}}>Contribución de las especies a la biodiversidad, equilibrio y equidad de la finca</p>
                                            
                                            <div className="flex-align-box" style={{flexDirection: "column"}}>
                                                <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
                                                    <span style={{display: "block", backgroundColor: "rgba(128, 198, 6, 1)", width: "20px", height: "2px"}}></span>
                                                    <p style={{margin: 0}}>Índice de biodiversidad de Simpson</p>
                                                </div>
                                                <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
                                                    <span style={{display: "block", backgroundColor: "rgba(255, 99, 132, 1)", width: "20px", height: "2px"}}></span>
                                                    <p>Índice de equidad de Pielou (J')</p>
                                                </div>
                                            </div>

                                            <RadarChart infoData={especiesBiodiversidad}/>
                                        </div>
                                    </div>
                                </>
                            }

                        </>
                    }
                </>
                :
                <>
                    <p>Aún no se han añadido ninguna biodiversidad de vertebrados. Necesitas tener al menos una creada para poder visualizar el detalle.</p>
                </>
            }
            <ModalTip open={openTip} setOpen={setOpenTip} contentText={msgTip.content}/>
        </>
    )
}

export default DetallesFincaCompletaComponent;