import { useEffect, useState, Fragment } from "react";
import { instance } from '../utils/axios-config.js';
import { useParams } from 'react-router-dom';
import ModalInfo from '../components/ModalInfo.js';
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useAuth } from "../context/Auth.js";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { checkHoursNo24Pattern, checkMinutesNo60Pattern } from "../utils/commons.js";
import ActionMessage from "../components/ActionMessage.js";
import Mensajes from "../utils/mensajes.js";



const AnadirRiegoManualPage = () => {

    var cdate = new Date();
    cdate.setHours(0, 0, 0, 0);

    const {sessionToken} = useAuth();
    const { id } = useParams();
    const [ loading, setLoading ] = useState(true);
    const [ msg, setMsg ] = useState();
    const [ loadingRiego, setLoadingRiego ] = useState(false);
    const [ loadingSectores, setLoadingSectores ] = useState(false);
    const [ loadingSubmit, setLoadingSubmit ] = useState(false);
    const [ horasRiego, setHorasRiego ] = useState();
    const [ minutosRiego, setMinutosRiego ] = useState();
    const [ open, setOpen ] = useState(false);
    const [sectores, setSectores] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [sectorId, setSectorId] = useState('');
    const [fincaId, setFincaId] = useState('');
    const [errors, setErrors] = useState({});
    const [errorMsg, setErrorMsg] = useState({title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado al intentar recuperar la información referente al riego. Por favor, vuelve a intentarlo más tarde."});
    const [dataForm, setDataForm] = useState({
        dia:cdate,
        hRiego:""
    });

    useEffect(() => {
        if(id){
            loadPageData(); 
        }else{
            loadFincas();
        }
    }, []);

    const loadPageData = async () => {
        try {
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`};
            
            const [fincaResp, sectoresResp] = await Promise.all([
                instance.get(`/fincas/finca/${id}`),
                instance.get(`/sectores/listall/${id}`)
            ]);
            let sectores = sectoresResp.data.sectores.filter(elemento => elemento.tipoUsuario_id === 1 && elemento.tipoPlantacion !== "Secano")
            setUnidades(fincaResp.data);
            setFincaId(fincaResp.data[0].id);
            setSectores(sectores);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setOpen(true);
            setErrorMsg({title: "¡Vaya! Parece que algo ha ido mal.", content: "Algo ha fallado al intentar recuperar la información de las unidades productivas. Por favor, vuelve a intentarlo más tarde."});
        }
    }

    const loadFincas = async () => {
        let buildUrl = `/fincas/fincasByDataType/finca`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
        
            await instance.get(buildUrl).then(function(resp) {
                setUnidades(resp.data);
            }).catch(function(resp) {
                setOpen(true);
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setOpen(true);
            setErrorMsg({title: "¡Vaya! Parece que algo ha ido mal.", content: "Algo ha fallado al intentar recuperar la información de las unidades productivas. Por favor, vuelve a intentarlo más tarde."});
        }
    }

    const onChangeDia = async (date) => {
        let currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        if(date <= currentDate){
            setLoadingRiego(true);
            setDataForm((prevDataForm) => ({
                ...prevDataForm,
                dia: date,
            }));

            const fechaFormateada = formatDate(date);
            
            await loadRiegoOnChange(fechaFormateada, sectorId);
        }
    }

    const calculoTiempoRiego = (horas, minutos) => {
        setHorasRiego(+horas);
        setMinutosRiego(+minutos);
        let totalMinutos = (horas ? +horas : 0) * 60 + (minutos ? +minutos : 0);
        let value = +(totalMinutos / 60).toFixed(2);
        setDataForm((prevDataForm) => ({
            ...prevDataForm,
            hRiego: value,
        }));
    }

    const loadRiegoOnChange = async (date, sectId) => {
        let buildUrl = `/dotacion/entradaRiego/${sectId}/${date}`;

        try{
            setLoadingRiego(true);
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
            await instance.get(buildUrl).then(function(resp) {
                const horas = Math.floor(resp.data.horasRiego);
                const minutosDecimal = (resp.data.horasRiego - horas) * 60;
                const minutos = Math.round(minutosDecimal);
                setHorasRiego(horas);
                setMinutosRiego(minutos);
            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg({title: "¡Vaya! Parece que algo ha ido mal.", content: "Algo ha fallado al intentar recuperar la información de riego. Por favor, vuelve a intentarlo más tarde."});
            }).finally(()=>{
                setLoadingRiego(false);
            });
            setLoadingRiego(false);
        }catch(error){
            setOpen(true);
            setLoadingRiego(false);
            setErrorMsg({title: "¡Vaya! Parece que algo ha ido mal.", content: "Algo ha fallado al intentar recuperar la información de riego. Por favor, vuelve a intentarlo más tarde."});
        }
    }

    const loadSectorOnChange = async (idFinca) => {
        let buildUrl = `/sectores/listall/${idFinca}`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
            await instance.get(buildUrl).then(function(resp) {
                let sectores = resp.data.sectores.filter(elemento => elemento.tipoUsuario_id === 1 && elemento.tipoPlantacion !== "Secano");
                setSectores(sectores);
            }).catch(function(resp) {
                setOpen(true);
            }).finally(()=>{
                setLoadingSectores(false);
            });
            setLoadingSectores(false);
        }catch(error){
            setOpen(true);
            setErrorMsg({title: "¡Vaya! Parece que algo ha ido mal.", content: "Algo ha fallado al intentar recuperar los sectores de la unidad productiva. Por favor, vuelve a intentarlo más tarde."});
        }
    }

    const handleLoadSectores = async (event) => {
        setFincaId(event.target.value);
        setSectorId("");
        setLoadingSectores(true);
        loadSectorOnChange(event.target.value);
    }


    const handleLoadRiego = (event) => {
        let currentDate = new Date();
        currentDate = formatDate(currentDate);
        setSectorId(event.target.value);
        loadRiegoOnChange(currentDate,event.target.value);
    }

    const formatDate = (date) => {
        const año = date.getFullYear();
        const mes = String(date.getMonth() + 1).padStart(2, '0'); 
        const día = String(date.getDate()).padStart(2, '0');
      
        return `${año}-${mes}-${día}`;
    }

    const tileDisabled = ({ date, view }) => {
        let currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        // Deshabilita las fechas superiores a la fecha actual solo en la vista del mes
        return view === 'month' && date > currentDate;
    };

    const checkForm = (sectorId) =>{
        let valid = true;
        setErrors({});
        if((sectorId === undefined || sectorId === '')){
            setErrors(errors => ({...errors, errorSector: true, errorSectorMsg: "Seleccionar un Sector es obligatorio"}));
            valid = false;
        }
        if((dataForm.dia === undefined || dataForm.dia === '')){
            setErrors(errors => ({...errors, errorFecha: true, errorFechaMsg: "Seleccionar un dia es obligatorio"}));
            valid = false;
        }
        if((dataForm.hRiego === undefined || dataForm.hRiego === '')){
            setErrors(errors => ({...errors, errorHora: true, errorHoraMsg: "Seleccionar las horas es obligatorio"}));
            valid = false;
        }
        return valid;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(checkForm(sectorId)){
            const fechaLocal = new Date(dataForm.dia);
            const year = fechaLocal.getFullYear();
            const month = String(fechaLocal.getMonth() + 1).padStart(2, '0');
            const day = String(fechaLocal.getDate()).padStart(2, '0');
            const formattedDataForm = {
                ...dataForm,
                date: `${year}-${month}-${day}`,
                hRiego: dataForm.hRiego
              };
            try {
                setLoadingSubmit(true);
                console.log(formattedDataForm);
                instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
                await instance.put(`/dotacion/entradaRiego/${sectorId}`, formattedDataForm).then(() => {
                    setMsg({type: "action-new", text: "Se ha creado la entrada de riego correctamente."});
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                      });
                }).catch(function(err){
                    setOpen(true);
                    setErrorMsg({title: "¡Vaya! Parece que algo ha ido mal.", content: "Algo ha fallado al intentar añadir el riego. Por favor, vuelve a intentarlo más tarde."});
                }).finally(() =>{
                    setLoadingSubmit(false);
                });
            } catch (error) {
                setOpen(true);
                setLoadingSubmit(false);
                setErrorMsg({title: "¡Vaya! Parece que algo ha ido mal.", content: "Algo ha fallado al intentar añadir el riego. Por favor, vuelve a intentarlo más tarde."});
            }
        }
    }

    return(
        <>
         <div className="tic4bio-home">
                {msg &&
                    <>
                    <ActionMessage msg={msg} setMsg={setMsg}/>
                    </>
                }
                {loading ? 
                    (
                        <Box p={3} align="center">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            <div>
                                <h4>AÑADIR RIEGO MANUALMENTE</h4>
                                {unidades.length > 0 &&
                                    <p>Finca y Sector donde quiera introducir las horas de riego*</p>
                                }
                                
                            </div>
                            {unidades.length > 0 ? (
                                <Box component="form" className="tic4bio-riego-create" onSubmit={handleSubmit} style={{marginLeft: "0"}} noValidate>
                                    
                                        <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                            <InputLabel htmlFor="unidad-select">Unidades*</InputLabel>
                                            <Select
                                                className="t4b-finca-sector-inputs"
                                                id="unidad-select"
                                                label="unidad"
                                                required
                                                MenuProps={{ disableScrollLock: true }}
                                                value={fincaId}
                                                disabled={loadingSectores || loadingSubmit || loadingRiego}
                                                onChange={(event) => { handleLoadSectores(event); }    
                                                }
                                                >
                                                {unidades.map((unidad, index) => (
                                                <MenuItem key={index} value={unidad.id}>
                                                    {unidad.unidadProductiva} - {unidad.nombreFinca} ({unidad.dataType.charAt(0).toUpperCase() + unidad.dataType.slice(1)})
                                                </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.errorSector && <div className="errorInput">{errors.errorSectorMsg}</div>}
                                        </FormControl>
                                    
                                    {loadingSectores ? (
                                        <Box p={3} align="center">
                                            <CircularProgress />
                                        </Box>
                                    ) : (
                                        <>
                                        {fincaId && 
                                            <>
                                                {sectores.length > 0 ? 
                                                    <>
                                                        <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                            <InputLabel htmlFor="sector-select">Sectores*</InputLabel>
                                                            <Select
                                                                className="t4b-finca-sector-inputs"
                                                                id="sector-select"
                                                                label="Sector"
                                                                required
                                                                MenuProps={{ disableScrollLock: true }}
                                                                value={sectorId}
                                                                disabled={loadingSubmit || loadingRiego}
                                                                onChange={(event) => {handleLoadRiego(event)}
                                                                }>
                                                            
                                                                {sectores.map((sector, index) => (
                                                                <MenuItem key={index} value={sector.id}>
                                                                    {sector.nombre}
                                                                </MenuItem>
                                                                ))}
                                                            </Select>
                                                            {errors.errorSector && <div className="errorInput">{errors.errorSectorMsg}</div>}
                                                        </FormControl>
                                                        {fincaId && sectorId &&
                                                            <>
                                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                    <div className="relative">
                                                                        {(loadingRiego || loadingSubmit) && (
                                                                            <div className="tic4bio-riego-blackout"
                                                                            ></div>
                                                                        )}
                                                                        <Calendar
                                                                            tileDisabled={tileDisabled}
                                                                            onChange={(value, event) => {
                                                                                if(sectorId){
                                                                                    onChangeDia(new Date(value));
                                                                                }
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    {errors.errorFecha && <div className="errorInput" style={{bottom:"50px", right:"35px"}}>{errors.errorFechaMsg}</div>}
                                                                    
                                                                    <div className="tic4bio-riego-time">
                                                                        {loadingRiego ? (
                                                                            <Box p={3} align="center">
                                                                                <CircularProgress />
                                                                            </Box>
                                                                        ) : (
                                                                            
                                                                            <>
                                                                                <TextField
                                                                                    label="Horas de Riego"
                                                                                    type="number"
                                                                                    InputLabelProps={{ shrink: true }}
                                                                                    disabled={loadingSubmit}
                                                                                    placeholder="00"
                                                                                    InputProps={{
                                                                                        inputProps: { 
                                                                                            max: 23, min: 0 
                                                                                        }
                                                                                    }}
                                                                                    value={horasRiego}
                                                                                    onChange={(e) =>{
                                                                                        if(checkHoursNo24Pattern(e.target.value)){
                                                                                            calculoTiempoRiego(e.target.value, minutosRiego);
                                                                                        }
                                                                                    }
                                                                                    }
                                                                                />
                                                                                <TextField
                                                                                    label="Minutos de Riego"
                                                                                    type="number"
                                                                                    InputLabelProps={{ shrink: true }}
                                                                                    disabled={loadingSubmit}
                                                                                    placeholder="00"
                                                                                    InputProps={{
                                                                                        inputProps: { 
                                                                                            max: 59, min: 0 
                                                                                        }
                                                                                    }}
                                                                                    value={minutosRiego}
                                                                                    onChange={(e) =>{
                                                                                        if(checkMinutesNo60Pattern(e.target.value)){
                                                                                            calculoTiempoRiego(horasRiego, e.target.value);
                                                                                        }
                                                                                    }
                                                                                    }
                                                                                />
                                                                                {errors.errorHora && <div className="errorInput" style={{bottom:"50px", right:"35px"}}>{errors.errorHoraMsg}</div>}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                    
                                                                </LocalizationProvider>
                                                                {(loadingSubmit) ?
                                                                    <Box align="center" sx={{my: 2}}>
                                                                        <CircularProgress />
                                                                    </Box>
                                                                    :
                                                                    <button type="submit" className="btn green" disabled={loadingRiego}>
                                                                        <span>Añadir Riego</span>
                                                                    </button>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                    :
                                                    <p>Parece que la unidad productiva seleccionada no tiene sectores de tipo usuario estándar asociados. Necesitas un al menos un sector de este tipo para poder añadirle riego manual.</p>
                                                }
                                            </>
                                        }
                                        </>  
                                    )}
                                    
                                </Box>
                            )
                            :
                            <p><Mensajes type="unidades" /></p>
                            }
                        </>
                    )
                }
            </div>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.title} contentText={errorMsg.content}/>
    </>
    );
} 
export default AnadirRiegoManualPage;