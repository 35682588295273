import { FormControl, InputLabel, MenuItem, Select, CircularProgress, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/Auth";
import { instance } from "../../utils/axios-config";
import BarChartHAEvolucion from "../charts/BarChartHAEvolucion";
import BarChartHAProduccionEvolucion from "../charts/BarChartHAProduccionEvolucion";
import BarChartHAPorcentajeEvolucion from "../charts/BarChartHAPorcentajeEvolucion";
import BarChartHAPorcentajeCultivoAlmazaraEvolucion from "../charts/BarChartHAPorcentajeCultivoAlmazaraEvolucion";
import BarChartHASectoresEvolucion from "../charts/BarChartHASectoresEvolucion";
import BarChartHASectoresTotalEvolucion from "../charts/BarChartHASectoresTotalEvolucion";
import BarChartHAAlmazaraEvolucion from "../charts/BarChartHAAlmazaraEvolucion";
import BarChartHAAlmazaraDatosEvolucion from "../charts/BarChartHAAlmazaraDatosEvolucion";

const EvoHuellaComponent = ({finca, setError, setOpen, loadingEvoHuella, setLoadingEvoHuella}) => {

    const {sessionToken} = useAuth();

    const [ option, setOption ] = useState("");
    const [ campanas, setCampanas ] = useState([]);
    const [ startCampana, setStartCampana ] = useState("");
    const [ endCampana, setEndCampana ] = useState("");
    const [ errorCampanas, setErrorCampanas ] = useState(false);
    const [ data, setData ] = useState({
        unidad: {
            check: false,
            groupByCampanas: {},
            infoUnidad: {}
        }
    });

    useEffect(() => {
        setOption("");
        setCampanas([]);
        setStartCampana("");
        setEndCampana("");
        setErrorCampanas(false);
        setData({
            unidad: {
                check: false,
                groupByCampanas: {},
                infoUnidad: {}
            }
        });
    }, [finca]); 

    const handleOptionSelection = (opt) => {
        if(opt !== option && loadingEvoHuella === false){
            setOption(opt);

            if(data.unidad.check === false){
                loadHuellaUnidad();
            }
        }
    }

    const loadHuellaUnidad = async () => {
        setLoadingEvoHuella(true);
        let buildUrl = `/huella/unidad/${finca.nombre}`;
        let buildFullInfoUnidadUrl = `/huella/unidades-list/${finca.nombre}`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}

            await Promise.all([instance.get(buildUrl), instance.get(buildFullInfoUnidadUrl)]).then(function(resp){
                
                let cs = getCampanas(resp[0].data);
                setCampanas(cs);
                let newData = groupDataByCampana(sortByCampana(resp[0].data));

                let infoUnidad = convertListToObject(sortByCampana(resp[1].data));
                
                setData(prevData => ({
                    ...prevData,
                    unidad: {
                        check: true,
                        groupByCampanas: newData,
                        infoUnidad: infoUnidad
                    }
                }));
            }).catch(function(err) {
                setOpen(true);
                setError(true);
            }).finally(()=>{
                setLoadingEvoHuella(false);
            });
        }catch(error){
            setLoadingEvoHuella(false);
            setOpen(true);
            setError(true);
        }
    }

    
    const handleStartCampanaSelect = (sc, ec) => {
        setStartCampana(sc);
        //Meter comprobacion de si ambos estan seleccionados
        CampanaValidation(sc, ec);
    }

    const handleEndCampanaSelect = (sc, ec) => {
        setEndCampana(ec);
        //Meter comprobacion de si ambos estan seleccionados
        CampanaValidation(sc, ec);
    }

    const getCampanas = (objects) => {
        const campanasArr = objects.map(obj => obj.campana);

        const campanasSet = Array.from(new Set(campanasArr));

        const compararCampanas = (a, b) => {
            //const [aInicio, aFin] = a.split(' ')[1].split('/').map(Number);
            //const [bInicio, bFin] = b.split(' ')[1].split('/').map(Number);
            const aYears = getYears(a);
            const bYears = getYears(b);
            
            // Comparar por el año de inicio primero, luego por el año de fin
            if (aYears.startYear !== bYears.startYear) {
            return aYears.startYear - bYears.startYear;
            } else {
            return aYears.endYear - bYears.endYear;
            }
        };

        campanasSet.sort(compararCampanas);

        return campanasSet;

    }

    const CampanaValidation = (startCampana, endCampana) => {
        const regex = /(\d{4})\/(\d{4})/;
        const startMatch = startCampana.match(regex);
        const endMatch = endCampana.match(regex);
        let startValue = 0;
        let endValue = 0;
        if(startMatch !== null){
            startValue = (+startMatch[1]) + (+startMatch[2]);
        }
        if(endMatch !== null){
            endValue = (+endMatch[1]) + (+endMatch[2]);
        }
        
        if(endCampana && startCampana && (endValue < startValue)){
            setErrorCampanas(true);
        }else{
            setErrorCampanas(false);
        }
    }

    const groupDataByCampana = (objects) => {
        let grouped = {};
        objects.forEach(obj => {
          const campana = obj.campana;
          if (!grouped[campana]) {
            grouped[campana] = {
                huellaAguaAzul: 0,
                huellaAguaVerde: 0,
                campana: campana
            };
          }
          grouped[campana].huellaAguaAzul = parseFloat(obj.huellaAguaAzul.toFixed(2));
          grouped[campana].huellaAguaVerde = parseFloat(obj.huellaAguaVerde.toFixed(2));
        });
        return grouped;
    };

    const getYears = (campana) => {
        const match = campana.match(/(\d{4})\/(\d{4})/);
        if (match) {
            const [_, startYear, endYear] = match;
            return { startYear: parseInt(startYear), endYear: parseInt(endYear) };
        }
        return { startYear: 0, endYear: 0 };
    };

    const sortByCampana = (data) => {
        let dataSorted = data.sort((a, b) => {
            const yearsA = getYears(a.campana);
            const yearsB = getYears(b.campana);
            
            if (yearsA.startYear !== yearsB.startYear) {
                return yearsA.startYear - yearsB.startYear;
            }
            return yearsA.endYear - yearsB.endYear;
        });
        return dataSorted;
    }


    const convertListToObject = (list) => {
        const result = {};
        list.forEach(item => {
            const campana = item.campana;
            result[campana] = item;
        });
        return result;
    };


    return(
        <>
            <div className="evolucion-options-container">
                <div className={"evolucion-options unidad" + (option === "unidad" ? " selected" : "")} onClick={() => handleOptionSelection("unidad")}>Unidad</div>
                <div className={"evolucion-options cultivo" + (option === "cultivo" ? " selected" : "")} onClick={() => handleOptionSelection("cultivo")}>Cultivo</div>
                <div className={"evolucion-options almazara" + (option === "almazara" ? " selected" : "")} onClick={() => handleOptionSelection("almazara")}>Almazara</div>
            </div>

            {loadingEvoHuella ? 
                (
                    <Box p={3} align="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {option &&
                            <>
                                {campanas.length > 0 ?
                                    <>
                                        <div className="evolucion-dates">
                                            <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                <InputLabel htmlFor="campana-inicio-select">Campaña de inicio*</InputLabel>
                                                <Select
                                                    className="t4b-finca-sector-inputs"
                                                    id="campana-inicio-select"
                                                    label="Campana de inicio*"
                                                    required
                                                    MenuProps={{ disableScrollLock: true }}
                                                    value={startCampana}
                                                    onChange={(event) => { handleStartCampanaSelect(event.target.value, endCampana) }
                                                    }>
                                                
                                                    {campanas.map((y, index) => (
                                                        <MenuItem key={"startCampana-" + index} value={y}>
                                                            {y}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                <InputLabel htmlFor="campana-fin-select">Campaña de fin*</InputLabel>
                                                <Select
                                                    className="t4b-finca-sector-inputs"
                                                    id="campana-fin-select"
                                                    label="Campaña de fin*"
                                                    required
                                                    MenuProps={{ disableScrollLock: true }}
                                                    value={endCampana}
                                                    onChange={(event) => { handleEndCampanaSelect(startCampana, event.target.value) }
                                                    }>
                                                
                                                    {campanas.map((y, index) => (
                                                        <MenuItem key={"endCampana-" + index} value={y}>
                                                            {y}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        {errorCampanas && <p className="errorMsg">La campaña de fin no puede ser menor que la campaña de inicio.</p>}

                                        {startCampana && endCampana && errorCampanas === false && option === "unidad" &&
                                            <>
                                            <BarChartHAEvolucion startCampana={startCampana} endCampana={endCampana} campanas={campanas} option={option} data={data} finca={finca}/>
                                            <BarChartHAProduccionEvolucion startCampana={startCampana} endCampana={endCampana} campanas={campanas} data={data} finca={finca}/>
                                            <BarChartHAPorcentajeEvolucion startCampana={startCampana} endCampana={endCampana} campanas={campanas} data={data} finca={finca}/>
                                            <BarChartHAPorcentajeCultivoAlmazaraEvolucion startCampana={startCampana} endCampana={endCampana} campanas={campanas} data={data} finca={finca}/>
                                            </>
                                        }
                                        {startCampana && endCampana && errorCampanas === false && option === "cultivo" &&
                                            <>
                                            
                                            <BarChartHASectoresTotalEvolucion startCampana={startCampana} endCampana={endCampana} campanas={campanas} data={data} finca={finca}/>
                                            <BarChartHASectoresEvolucion startCampana={startCampana} endCampana={endCampana} campanas={campanas} data={data} finca={finca}/>
                                            
                                            </>
                                        }
                                        {startCampana && endCampana && errorCampanas === false && option === "almazara" &&
                                            <>
                                            <BarChartHAAlmazaraEvolucion startCampana={startCampana} endCampana={endCampana} campanas={campanas} data={data} finca={finca}/>
                                            <BarChartHAAlmazaraDatosEvolucion startCampana={startCampana} endCampana={endCampana} campanas={campanas} data={data} finca={finca}/>
                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                        <p>No existen huellas asociadas para el tipo seleccionado.</p>
                                    </>
                                }
                            </>
                        }
                    </>
                )
            }
            
        </>
    );
}

export default EvoHuellaComponent;