import { Fragment, useEffect, useRef, useState } from "react";
import ModalInfo from "../components/ModalInfo";
import { instance } from "../utils/axios-config";
import { useParams, Link } from "react-router-dom";
import { Box, CircularProgress, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select } from "@mui/material";
import { useAuth } from "../context/Auth";
import { convertDate } from "../utils/commons";
import Mensajes from "../utils/mensajes";

const PrediccionClimaticaPage = () => {

    const {id} = useParams();
    const {sessionToken} = useAuth();

    const [ loading, setLoading ] = useState(true);
    const [open, setOpen] = useState(false);
    const [unidades, setUnidades] = useState([]);
    const [unidad, setUnidad] = useState();
    const [ loadingSectores, setLoadingSectores ] = useState(false);
    const [sectores, setSectores] = useState([]);
    const [sector, setSector] = useState();
    const [historicos, setHistoricos] = useState([]);
    const [ loadingHistorico, setLoadingHistorico ] = useState(false);
    const [tab, setTab] = useState("prediccion");
    const [anchoPantalla, setAnchoPantalla] = useState(window.innerWidth);
    const containerRef = useRef(null);
    const [errorMsg, setErrorMsg] = useState({title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado. Por favor, vuelve a intentarlo más tarde."});

    useEffect(() => {
        document.title = "Predicción climática";
        loadUnidades();
        actualizarAnchoPantalla();
      
        window.addEventListener('resize', actualizarAnchoPantalla);
    
        return () => {
            window.removeEventListener('resize', actualizarAnchoPantalla);
        };

    }, []);

    const actualizarAnchoPantalla = () => {
        setAnchoPantalla(Math.floor(containerRef.current.getBoundingClientRect().width));
    };

    function removeAccents(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    const loadUnidades = async () => {
        let buildUrl = `/fincas/fincasByDataType/finca`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await instance.get(buildUrl).then(function(resp) {
                setUnidades(resp.data);
            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar la información de las unidades productivas. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar la programación de riego. Por favor, vuelve a intentarlo más tarde."
            }));
            setLoading(false);
        }
    }

    const handleChangeUnidad = async (event) => {
        try{
            let idFinca = event.target.value;
            let unidad = unidades.find(object => object.id === idFinca);
            let formattedMunicipio = removeAccents(unidad.municipio).toLowerCase().replace(/\s+/g, '-');
            unidad.nombreMunicipioFormatted = formattedMunicipio;
            setUnidad(unidad);
            let buildUrl = `/sectores/listall/${unidad.id}`;
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
            setLoadingSectores(true);
            await instance.get(buildUrl).then(function(resp) {
                let sectores = resp.data.sectores.filter(elemento => elemento.tipoPlantacion !== "Secano");
                setSectores(sectores);
            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar los sectores. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(() => {
                setLoadingSectores(false);
            });
        }catch(err){
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar los sectores. Por favor, vuelve a intentarlo más tarde."
            }));
            setLoadingSectores(false);
        }
    }

    const handleChangeSector = async (event) => {
        try{
            let idSector = event.target.value;
            let sector = sectores.find(object => object.id === idSector);
            setSector(sector);
            let buildUrl = `/clima/historico/predicciones/${sector.id}`;
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
            setLoadingHistorico(true);
            await instance.get(buildUrl).then(function(resp) {
                const historicos = resp.data.map(objeto => {
                    return { ...objeto, fecha: convertDate(new Date(objeto.fecha)), eto: objeto.eto?.toFixed(2), p: objeto.p?.toFixed(2) };
                  });
                setHistoricos(historicos);
            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar la información del historico de predicciones. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(() => {
                setLoadingHistorico(false);
            });
        }catch(err){
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar la información del historico de predicciones. Por favor, vuelve a intentarlo más tarde."
            }));
            setLoadingHistorico(false);
        }
    }

    const handleChangeTab = (event) => {
        setTab(event.target.value);
    }



    return (
        <>
          <div className="tic4bio-home" ref={containerRef}>
            {loading ? (
              <Box p={3} align="center">
                <CircularProgress />
              </Box>
            ) : (
              <>
                {!id && (
                  <>
                    {unidades.length > 0 ? (
                        <>
                            <h1 className="tic4bio-home-title">Selecciona una unidad productiva para consultar su predicción climática:</h1>
                            <Box component="form" className="tic4bio-riego-create" style={{ marginLeft: "0" }} noValidate>
                                <FormControl sx={{ width: "100%", marginBottom: "40px" }} variant="standard">
                                    <InputLabel htmlFor="unidad-select">Unidades*</InputLabel>
                                    <Select
                                        className="t4b-finca-sector-inputs"
                                        id="unidad-select"
                                        label="Unidades"
                                        required
                                        MenuProps={{ disableScrollLock: true }}
                                        disabled={loadingSectores || loadingHistorico}
                                        value={unidad?.id}
                                        onChange={(e) => { handleChangeUnidad(e); }}
                                    >
                                        {unidades.map((unidad, index) => (
                                        <MenuItem key={"unidad-"+index} value={unidad.id}>
                                            {unidad.unidadProductiva} - {unidad.nombreFinca} ({unidad.dataType.charAt(0).toUpperCase() + unidad.dataType.slice(1)})
                                        </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {loadingSectores ? (
                                        <Box p={3} align="center">
                                            <CircularProgress />
                                        </Box>
                                    ) : (
                                        <>
                                            {unidad?.id && 
                                                <>
                                                    {sectores.length > 0 ? 
                                                        <>
                                                            <FormControl sx={{ width: "100%", marginBottom: "40px" }} variant="standard">
                                                                <InputLabel htmlFor="sector-select">Sectores*</InputLabel>
                                                                <Select
                                                                    className="t4b-finca-sector-inputs"
                                                                    id="sector-select"
                                                                    label="Sector"
                                                                    required
                                                                    MenuProps={{ disableScrollLock: true }}
                                                                    disabled={loadingHistorico}
                                                                    value={sector?.id}
                                                                    onChange={(e) => { handleChangeSector(e) }}
                                                                >
                                                                    {sectores.map((sector, index) => (
                                                                        <MenuItem key={"sector-"+index} value={sector.id}>
                                                                            {sector.nombre}
                                                                        </MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                            {sector?.id &&
                                                                <>
                                                                    <FormControl sx={{width: "100%", marginBottom: "20px"}} className="radio-green" variant="standard">
                                                                        <RadioGroup
                                                                            name="tabs"
                                                                            id="pred-tabs"
                                                                            value={tab}
                                                                            onChange={(e) => handleChangeTab(e)}
                                                                        >
                                                                            <FormControlLabel value="prediccion" control={<Radio />} label="Predicción 7 días" />
                                                                            <FormControlLabel value="historico" control={<Radio />} label="Histórico" />
                                                                        </RadioGroup>
                                                                    </FormControl>
                                        
                                                                    {unidad.nombreMunicipioFormatted ? (
                                                                        <>
                                                                            {tab === "prediccion" && 
                                                                                <>
                                                                                    <h1 className="tic4bio-home-title">Predicción Climática:</h1>
                                                                                    <iframe
                                                                                    name="iframe_aemet_id33044"
                                                                                    title={`${unidad.nombreMunicipio} - Pronóstico del tiempo`}
                                                                                    width="100%"
                                                                                    height="480px"
                                                                                    frameBorder="0"
                                                                                    scrolling="no"
                                                                                    src={`https://www.aemet.es/es/eltiempo/prediccion/municipios/mostrarwidget/${unidad.nombreMunicipioFormatted}-id${unidad.municipio_id}?w=g4p01110001ohmffffffw${anchoPantalla}z408x4f86d9t95b6e9r1s8n2`}
                                                                                    ></iframe>
                                                                                </>
                                                                            }
                                                                            {tab === "historico" && 
                                                                                <>
                                                                                {loadingHistorico ? (
                                                                                        <Box p={3} align="center">
                                                                                            <CircularProgress />
                                                                                        </Box>
                                                                                        ) : (
                                                                                            <>
                                                                                                {historicos.length > 0 ? (
                                                                                                    <>
                                                                                                        <div className="tic4bio-prediccion">
                                                                                                            <table className="tic4bio-prediccion-table">
                                                                                                                <thead>
                                                                                                                    <tr>
                                                                                                                        <th>Día</th>
                                                                                                                        <th>Lluvia (mm)</th>
                                                                                                                        <th>ETo (mm)</th>
                                                                                                                        <th>T. Max (ºC)</th>
                                                                                                                        <th>T. Min (ºC)</th>
                                                                                                                        <th>Vel. Viento (m/s)</th>
                                                                                                                    </tr>
                                                                                                                </thead>
                                                                                                                <tbody>
                                                                                                                    {historicos.map((historico, index) => {
                                                                                                                        return (
                                                                                                                            <Fragment key={"historico-" + index}>
                                                                                                                                <tr>
                                                                                                                                    <td>
                                                                                                                                        {historico.fecha}
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        {historico.p === null ? 0 : historico.p}
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        {historico.eto}
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        {historico.tMaxima}
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        {historico.tMinima}
                                                                                                                                    </td>
                                                                                                                                    <td>
                                                                                                                                        {historico.velocidadViento}
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </Fragment>
                                                                                                                        )
                                                                                                                    })}
                                                                                                                </tbody>
                                                                                                            </table>
                                                                                                        </div>
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <p>No hay historico de predicciones disponibles.</p>
                                                                                                )}
                                                                                                
                                                                                            </>
                                                                                        )
                                                                                }
                                                                                </>
                                                                            }
                                                                        </>
                                                                        ) : (
                                                                            <p>No se ha encontrado una ubicacion registrada para esta Unidad Productiva.</p>
                                                                        )
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            <p>Parece que la unidad productiva seleccionada no tiene sectores de regadío asociados. Necesitas un al menos un sector de este tipo para poder ver la predicción.</p>
                                                        </>
                                                    }
                                                </>
                                            }
                                        </>
                                    )
                                }

                            </Box>
                        </>
                    ) : (
                        <p><Mensajes type="unidades" /></p>
                    )}
                    {unidad &&
                        <>
                            
                        </>
                    }
                    </>
                )}
              </>
            )}
          </div>
          <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.title} contentText={errorMsg.content}/>
        </>
      );
}

export default PrediccionClimaticaPage;
