import axios from "axios";

export const instance = axios.create({
  baseURL: "https://5v9hwwhhsf.execute-api.eu-central-1.amazonaws.com/pre",
  //baseURL: "http://localhost:3000/dev",
});

export const instanceJA = axios.create({
  baseURL: process.env.REACT_APP_JUNTA_ANDALUCIA_API,
});

export const instanceEST = axios.create({
  baseURL: process.env.REACT_APP_ESTACIONES_API,
});


instance.interceptors.response.use(
    (response) => successHandler(response),
    (error) => errorHandler(error)
);

instanceJA.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response && error.response.data) {
      error.response.data.message = 'error estaciones';
    } else if (error.response) {
      error.response.data = { message: 'error estaciones' };
    } else {
      error.message = 'error estaciones';
    }
    return Promise.reject(error);
  }
);
  
  const successHandler = (response) => {
    return response;
  };
  
  const errorHandler = async (error) => {
    const { status } = error.response;
    if(status === 403){
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('userData');
        window.location = '/';
    }
    console.log("INTERCEPTADO! " + status);
  
    return Promise.reject(error);
  };