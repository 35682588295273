import { Box, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/Auth";
import { instance } from "../../utils/axios-config";
import ordentaxonomico from "../../assets/Iconos_biodiversidad/ordentaxonomico.png";
import ModalInfo from "../ModalInfo";
import ModalTip from "../ModalTip";

const FormBioStepTwoComponent = ({position, setPosition, stateForm, setStateForm}) => {

    const {sessionToken} = useAuth();

    const [comunidades, setComunidades] = useState([]);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(false);
    const [ openTip, setOpenTip ] = useState(false);
    const [ msgTip, setMsgTip ] = useState({open: false, content: ''});
    const [errors, setErrors] = useState({});
    const [errorMsg, setErrorMsg] = useState({
        contentTitle: "¡Vaya! Parece que algo ha ido mal.",
        contentText: ""
    });


    useEffect(() => {
        loadComunidades();
    }, []);

    const loadComunidades = async () => {
        let buildUrl = `/biodiversidad/comunidad-list/${stateForm.finca.id}`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await instance.get(buildUrl).then(function(resp) {
                setComunidades(resp.data);
            }).catch(function(error) {
                setOpen(true);
                setError(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    contentText: "Algo ha fallado al intentar recuperar la información de las comunidades. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setLoading(false);
            setOpen(true);
            setError(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                contentText: "Algo ha fallado al intentar recuperar la información de las comunidades. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const handleSelectComunidad = (event) => {
        let comunidad = comunidades.find((a) => a.id === event.target.value);
        setStateForm(prevData => ({
            ...prevData,
            comunidad: comunidad}));
    }


    const validateForm = () =>{
        setErrors({});
        let inputIds = "";
        let valid = true;
        if(stateForm.comunidad.id === undefined || stateForm.comunidad.id === ''){
            setErrors(errors => ({...errors, errorComunidad: true, errorComunidadMsg: ""}));
            inputIds = inputIds ? inputIds +",comunidad" : "comunidad";
            valid = false;
        }

        if(!Object.values(stateForm.metodoMuestreo).some(value => value === true)){
            setErrors(errors => ({...errors, errorMetodoMuestreo: true, errorMetodoMuestreoMsg: "Por favor, seleccione al menos un método de muestreo de invertebrados."}));
            inputIds = inputIds ? inputIds +",metodoMuestreo" : "metodoMuestreo";
            valid = false;
        }

        if(inputIds !== ""){
            try{
                let elem = document.getElementById(inputIds.split(",")[0]);
                elem.scrollIntoView({behavior: "smooth"});
                elem.focus();
            }catch(err){

            }
        }
        return valid;
    }

    const handleChangeCheckbox = (event) => {
        if(event.target.checked === false){
            
            setStateForm((prevData) => {
                delete prevData.muestreos[event.target.name];
                return {
                    ...prevData,
                    metodoMuestreo: {
                      ...prevData.metodoMuestreo,
                      [event.target.name]: event.target.checked,
                    }
                  };
            });
        }else{
            setStateForm( prevData => ({
                ...prevData,
                metodoMuestreo: {
                  ...prevData.metodoMuestreo,
                  [event.target.name]: event.target.checked,
                }
            }));
        }
    };

    const handleOpenTip = () => {
        setOpenTip(true);
        setMsgTip((msg) => ({
            ...msg,
            content: '<div class="modaltip"><h4>El orden es una categoría taxonómica que se encuentra entre la clase y la familia. Los órdenes se agrupan por características morfológicas, fisiológicas o conductuales.</h4><div class="flex-align-box"><img src='+ ordentaxonomico +' alt=""/></div><h4>Esta herramienta presenta una lista interactiva de los órdenes más comunes en olivar. En base a sus observaciones simplemente deberá seleccionar los que encuentre en sus muestreos.</h4></div>'
        }));
    }

    const handlePrev = (e) => {
        e.preventDefault();
        setPosition(0);
    }

    const handleNext = (e) => {
        e.preventDefault();
        if(validateForm()){
            setPosition(2);
        }
    };

    return(
        <>
        <Box className={position !== 1 && 'hide'}>
            {loading ? 
                (
                    <Box p={3} align="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {!error ? 
                            <>
                                <h1 className="tic4bio-home-title">Nueva Biodiversidad Vegetal</h1>
                                <p style={{color: "rgba(0, 0, 0, 0.6)", margin: "42px 0px"}}>Finca seleccionada: <span style={{color: "#80C606", fontSize: "20px", fontWeight: "700"}}>{stateForm.finca.nombre}</span></p>
                                {(comunidades.length > 0) ?
                                    <>
                                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                            <InputLabel htmlFor="comunidad">Comunidad vegetal*</InputLabel>
                                            <Select
                                                className="t4b-finca-sector-inputs"
                                                id="comunidad"
                                                label="Comunidad"
                                                required
                                                MenuProps={{ disableScrollLock: true }}
                                                value={stateForm.comunidad.id}
                                                onChange={(event) => { handleSelectComunidad(event); }}
                                                error={errors.errorComunidad}
                                                >
                                                {comunidades.map((comunidad, index) => (
                                                <MenuItem key={index} value={comunidad.id}>
                                                    {comunidad.nombre}
                                                </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.errorComunidad && <div className="errorMsg">{errors.errorComunidadMsg}</div>}
                                        </FormControl>

                                        <FormControl sx={{width: "100%", marginBottom: "20px"}} className="radio-green" variant="standard">
                                            <FormLabel id="metodoMuestreo-label">Método de muestreo de los invertebrados:*</FormLabel>
                                                <FormGroup
                                                    name="metodoMuestreo"
                                                    id="metodoMuestreo"
                                                    aria-labelledby="metodoMuestreo-label"
                                                >
                                                    <FormControlLabel control={<Checkbox checked={stateForm.metodoMuestreo.muestreosuperficiesuelo} onChange={handleChangeCheckbox} name="muestreosuperficiesuelo" />} label="Muestreo superficie de suelo" />
                                                
                                                    <FormControlLabel control={<Checkbox checked={stateForm.metodoMuestreo.trampasparaadultos} onChange={handleChangeCheckbox} name="trampasparaadultos"/>} label="Trampas para adultos" />
                                                
                                                </FormGroup>
                                                {errors.errorMetodoMuestreo && <div className="errorMsg">{errors.errorMetodoMuestreoMsg}</div>}
                                        </FormControl>

                                        <p style={{lineHeight: "26px"}}>Dada la dificultad que supone identifica y clasificar distintas especies de invertebrados, en este grupo, la identificación taxonómica no será a nivel de especie, sino de orden*.<span className="modaltip-icon" onClick={() => { handleOpenTip() }}>?</span></p>
                                    </>
                                    :
                                    <>
                                        <p>No tiene ninguna comunidad creada para esta finca. Para crear un nuevo cálculo de biodiversidad, debe tener al menos una asociada.</p>
                                    </>
                                }

                                <Stack spacing={2} direction="row" justifyContent="space-between" className="tic4bio-sectores-btns">
                                    <button className="btn white" onClick={(e) => handlePrev(e)}>Anterior</button>
                                    {(comunidades.length > 0) &&
                                        <button className="btn green" onClick={(e) => handleNext(e)} style={{margin: "16px 0"}}>Siguiente</button>
                                    }
                                </Stack>
                            </>
                            : 
                            <p>
                                Se ha producido un error inesperado. Vuelva a intentarlo más tarde.
                            </p>
                        }
                    </>
                )
            }
        </Box>
        <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.contentTitle} contentText={errorMsg.contentText}/>
        <ModalTip open={openTip} setOpen={setOpenTip} contentText={msgTip.content}/>
        </>
    )
}

export default FormBioStepTwoComponent;