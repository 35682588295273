import { Box, CircularProgress, FormControl, Input, InputAdornment, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import ModalInfo from "../ModalInfo";
import { useAuth } from "../../context/Auth";
import { instance } from "../../utils/axios-config";
import { checkDoublePattern, checkPercentagePattern, generarListadoCamp } from "../../utils/commons";
import ModalTip from "../ModalTip";
import Mensajes from "../../utils/mensajes";


const FormCampanaComponent = ({position, setPosition, state, setState}) => {

    const {sessionToken} = useAuth();

    const year_Actual = new Date().getFullYear();
    const year_Final = year_Actual - 1;

    const [almazaras, setAlmazaras] = useState(false);
    const [campanas, setCampanas] = useState(generarListadoCamp(2022, year_Final));
    const [loading, setLoading] = useState(true);
    const [ openTip, setOpenTip ] = useState(false);
    const [ msgTip, setMsgTip ] = useState({open: false, content: ''});
    const [error, setError] = useState(false);
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState({
        contentTitle: "¡Vaya! Parece que algo ha ido mal.",
        contentText: ""
    });

    useEffect(() => {
        loadAlmazaras();
    }, []);

    useEffect(() => {
        if (state.exists) {
            setErrors(errors => ({...errors, errorCampana: true, errorCampanaMsg: "Ya existe una huella de agua para ese periodo."}));
            let elem = document.getElementById("periodo-campana");
            if(elem){
                elem.scrollIntoView({behavior: "smooth"});
                elem.focus();
            }
        }
    }, [state.exists]);

    const loadAlmazaras = async () => {
        let buildUrl = `/fincas/fincasByDataType/industria`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await instance.get(buildUrl).then(function(resp) {
                setAlmazaras(resp.data);
            }).catch(function(error) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    contentText: "Algo ha fallado al intentar recuperar la información de las almazaras. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setLoading(false);
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                contentText: "Algo ha fallado al intentar recuperar la información de las almazaras. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const handleSelectAlmazara = (event) => {
        let almazara = almazaras.find((a) => a.id === event.target.value);
        setState(prevData => ({
            ...prevData,
            almazara: almazara}));
    }

    const handleNext = (e) => {
        e.preventDefault();
        if(validateForm()){
            setPosition(1);
            setState(prevState =>({
                ...prevState,
                exists: false
            }))
        }
    }

    const validateForm = () =>{
        setErrors({});
        let inputIds = "";
        let valid = true;

        if(state.almazara.id === ''){
            setErrors(errors => ({...errors, errorAlmazara: true, errorAlmazaraMsg: ""}));
            inputIds = inputIds ? inputIds +",almazara" : "almazara";
            valid = false;
        }

        if(state.nombre === undefined || state.nombre === ''){
            setErrors(errors => ({...errors, errorNombre: true, errorNombreMsg: ""}));
            inputIds = inputIds ? inputIds +",nombre" : "nombre";
            valid = false;
        }

        if(state.campana === undefined || state.campana === ''){
            setErrors(errors => ({...errors, errorCampana: true, errorCampanaMsg: ""}));
            inputIds = inputIds ? inputIds +",periodo-campana" : "periodo-campana";
            valid = false;
        }

        if(state.nDiasMolturacion === undefined || state.nDiasMolturacion === ''){
            setErrors(errors => ({...errors, errorNDiasMolturacion: true, errorNDiasMolturacionMsg: ""}));
            inputIds = inputIds ? inputIds +",nDiasMolturacion" : "nDiasMolturacion";
            valid = false;
        }

        if(state.kgAceitunaMolturado === undefined || state.kgAceitunaMolturado === ''){
            setErrors(errors => ({...errors, errorKgAceitunaMolturado: true, errorKgAceitunaMolturadoMsg: ""}));
            inputIds = inputIds ? inputIds +",kgAceitunaMolturado" : "kgAceitunaMolturado";
            valid = false;
        }

        if(state.rendimientoMedio === undefined || state.rendimientoMedio === ''){
            setErrors(errors => ({...errors, errorRendimientoMedio: true, errorRendimientoMedioMsg: ""}));
            inputIds = inputIds ? inputIds +",rendimientoMedio" : "rendimientoMedio";
            valid = false;
        }

        if(state.precioAceite === undefined || state.precioAceite === ''){
            setErrors(errors => ({...errors, errorPrecioAceite: true, errorPrecioAceiteMsg: ""}));
            inputIds = inputIds ? inputIds +",precioAceite" : "precioAceite";
            valid = false;
        }
        
        if(inputIds !== ""){
            let elem = document.getElementById(inputIds.split(",")[0]);
            elem.scrollIntoView({behavior: "smooth"});
            elem.focus();
        }
        return valid;
    }

    const handleOpenTip = () => {
        setOpenTip(true);
        setMsgTip((msg) => ({
            ...msg,
            content: '<div class="modaltip"><h4>Precio de venta o liquidación del aceite (sin considerar el envase ni el envasado).</h4></div>'
        }));
    }

    const handleChangekgMolturado = (e) => {
        if(checkDoublePattern(e)){
            setState({...state, kgAceitunaMolturado: e});
        }
    }

    const handleChangeRendimiento = (e) => {
        if(checkPercentagePattern(e)){
            setState({...state, rendimientoMedio: e});
        }
    }

    const handleChangePrecioAceite = (e) => {
        if(checkDoublePattern(e)){
            setState({...state, precioAceite: e});
        }
    }

    return(
        <>
            <Box className={position !== 0 && 'hide'}>
            {loading ? 
                (
                    <Box p={3} align="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {!error ? 
                            <>
                                {(almazaras.length > 0) ?
                                    <>
                                        <h1 className="tic4bio-home-title">Nueva Huella de Agua</h1>
                                        <p>Seleccione la Industria/Almazara*</p>
                                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                            <InputLabel htmlFor="almazara">Almazara*</InputLabel>
                                            <Select
                                                className="t4b-finca-sector-inputs"
                                                id="almazara"
                                                label="almazara"
                                                required
                                                MenuProps={{ disableScrollLock: true }}
                                                value={state.almazara.id}
                                                onChange={(event) => { handleSelectAlmazara(event); }}
                                                error={errors.errorAlmazara}
                                                >
                                                {almazaras.map((almazara, index) => (
                                                <MenuItem key={index} value={almazara.id}>
                                                    {almazara.unidadProductiva} - {almazara.nombre}
                                                </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.errorAlmazaraMsg && <div className="errorInput">{errors.errorAlmazaraMsg}</div>}
                                        </FormControl>
                                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                            <InputLabel htmlFor="nombre">Nombre*</InputLabel>
                                            <Input
                                                id="nombre"
                                                className="t4b-finca-sector-inputs"
                                                label="Nombre del Sector"
                                                value={state.nombre}
                                                onChange={(e) => setState({...state, nombre: e.target.value})}
                                                required
                                                name="nombre"
                                                error={errors.errorNombre}
                                            />
                                            {errors.errorNombreMsg && <div className="errorInput">{errors.errorNombreMsg}</div>}
                                        </FormControl>

                                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                            <InputLabel htmlFor="periodo-campana">Periodo de campaña*</InputLabel>
                                            <Select
                                                className="t4b-finca-sector-inputs"
                                                id="periodo-campana"
                                                label="Periodo de campaña"
                                                required
                                                name="periodo-campana"
                                                MenuProps={{ disableScrollLock: true }}
                                                value={state.campana}
                                                onChange={(e) => { setState({...state, campana: e.target.value}) }}
                                                error={errors.errorCampana}
                                                >
                                                {campanas.map((campana, index) => (
                                                    <MenuItem key={index} value={campana.nombre}>
                                                        {campana.nombre}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            {errors.errorCampanaMsg && <p className="errorMsg">{errors.errorCampanaMsg}</p>}
                                        </FormControl>

                                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                            <TextField
                                                className="t4b-finca-sector-inputs no-red-label"
                                                label="Nº días de molturación"
                                                id="nDiasMolturacion"
                                                type="number"
                                                variant="standard"
                                                InputProps={{
                                                    inputProps: { 
                                                        max: 730, min: 0 
                                                    }
                                                }}
                                                name="nDiasMolturacion"
                                                value={state.nDiasMolturacion}
                                                onChange={(e) =>{
                                                    setState({...state, nDiasMolturacion: e.target.value})
                                                }}
                                                error={errors.errorNDiasMolturacion}
                                            />
                                            {errors.errorNDiasMolturacionMsg && <div className="errorInput">{errors.errorNDiasMolturacionMsg}</div>}
                                        </FormControl>

                                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                            <InputLabel htmlFor="kgAceitunaMolturado">Kg de aceituna molturados en la campaña*</InputLabel>
                                            <Input
                                                id="kgAceitunaMolturado"
                                                label="Kg de aceituna molturados en la campaña"
                                                required
                                                name="kgAceitunaMolturado"
                                                value={state.kgAceitunaMolturado}
                                                onChange={(e) => handleChangekgMolturado(e.target.value)}
                                                error={errors.errorKgAceitunaMolturado}
                                            />
                                            {errors.errorKgAceitunaMolturadoMsg && <div className="errorInput">{errors.errorKgAceitunaMolturadoMsg}</div>}
                                        </FormControl>

                                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                            <InputLabel htmlFor="rendimientoMedio">Rendimiento medio de la campaña (%)*</InputLabel>
                                            <Input
                                                id="rendimientoMedio"
                                                className="t4b-finca-sector-inputs"
                                                label="Rendimiento medio de la campaña (%)*"
                                                required
                                                name="rendimientoMedio"
                                                value={state.rendimientoMedio}
                                                onChange={(e) => handleChangeRendimiento(e.target.value)}
                                                error={errors.errorRendimientoMedio}
                                            />
                                            {errors.errorRendimientoMedioMsg && <div className="errorInput">{errors.errorRendimientoMedioMsg}</div>}
                                        </FormControl>

                                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                            <InputLabel htmlFor="precioAceite">Precio del aceite (€/Kg aceite)*</InputLabel>
                                            <Input
                                                id="precioAceite"
                                                label="Precio del aceite (€/Kg aceite)*"
                                                required
                                                name="precioAceite"
                                                value={state.precioAceite}
                                                onChange={(e) => handleChangePrecioAceite(e.target.value)}
                                                error={errors.errorPrecioAceite}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <span className="modaltip-icon" onClick={() => { handleOpenTip() }}>?</span>
                                                    </InputAdornment>
                                                }
                                            />
                                            {errors.errorPrecioAceiteMsg && <div className="errorInput">{errors.errorPrecioAceiteMsg}</div>}
                                        </FormControl>

                                        <Stack className="tic4bio-sectores-btns right">
                                            <button className="btn green" disabled={loading ? true : false} onClick={(e) => handleNext(e)}>Siguiente</button>
                                        </Stack>
                                    </>
                                    :
                                    <>
                                        <p><Mensajes type="industria"/></p>
                                    </>
                                }
                            </>
                        : 
                            <>Se ha producido un error inesperado. Vuelva a intentarlo más tarde.</>
                        }
                        
                    </>
                )
            }
                
            </Box>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.contentTitle} contentText={errorMsg.contentText}/>
            <ModalTip open={openTip} setOpen={setOpenTip} contentText={msgTip.content}/>
        </>
    );
}
export default FormCampanaComponent;