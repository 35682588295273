import { Box, CircularProgress, FormControl, InputLabel, Select, MenuItem, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useAuth } from "../context/Auth";
import { instance } from "../utils/axios-config";
import { useEffect, useState } from "react";
import ModalInfo from "../components/ModalInfo";
import ModalEditComunidad from "../components/modals/ModalEditComunidad";
import ModalDeleteConfirm from "../components/modals/ModalDeleteConfirm";
import UndoIcon from '@mui/icons-material/Undo';
import ActionMessage from "../components/ActionMessage";
import Mensajes from "../utils/mensajes";

const ComunidadesPage = () => {

    const {sessionToken} = useAuth();

    const [ loading, setLoading ] = useState(true);
    const [ loadingComunidades, setLoadingComunidades ] = useState(false);
    const [ loadingSend, setLoadingSend ] = useState(false);
    const [ fincas, setFincas ] = useState([]);
    const [ finca, setFinca ] = useState({id: ""});
    const [ comunidades, setComunidades ] = useState([]);
    const [ comunidad, setComunidad ] = useState({id: ""});
    const [ msg, setMsg ] = useState();

    const [ open, setOpen ] = useState(false);
    const [ openEdit, setOpenEdit ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState({title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado. Por favor, vuelve a intentarlo más tarde."});
    const [ openDeleteConfirm, setOpenDeleteConfirm ] = useState({
        state: false,
        finca: null
    });

    useEffect(() => {
        document.title = "Comunidades Vegetales";
        loadFincas();
    }, []);


    const loadFincas = async () => {
        let buildUrl = `/fincas/fincasByDataType/finca`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await instance.get(buildUrl).then(function(resp) {
                setFincas(resp.data);
            }).catch(function(error) {
                setOpen(true);
                setError(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    contentText: "Algo ha fallado al intentar recuperar la información de las fincas. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setLoading(false);
            setOpen(true);
            setError(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                contentText: "Algo ha fallado al intentar recuperar la información de las fincas. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const handleLoadComunidades = async (fincaId) => {
        let buildUrl = `/biodiversidad/comunidad-list/` + fincaId;
        try{
            let foundFinca = fincas.find((f) => f.id === fincaId);
            setFinca(foundFinca);
            setLoadingComunidades(true);
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await instance.get(buildUrl).then(function(resp) {
                const comunidadesActualizadas = resp.data.map(objeto => ({
                    ...objeto,
                    valido: true
                  }));
                setComunidades(comunidadesActualizadas);
            }).catch(function(error) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    contentText: "Algo ha fallado al intentar recuperar la información de las comunidades. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoadingComunidades(false);
            });
        }catch(error){
            setLoadingComunidades(false);
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                contentText: "Algo ha fallado al intentar recuperar la información de las comunidades. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const deleteComunidad = async (c) => {
        c.delete = true;
        let tComunidades = comunidades.filter(objeto => {
            return !(c?.tempId && objeto.tempId === c.tempId);
        });
        setComunidades(tComunidades);
    }

    const undoComunidad = (c) => {
        c.delete = false;
        const tComunidades = comunidades.map(objeto => {
            if (objeto.id === c.id) {
              return c;
            }
            return objeto;
          });
        setComunidades(tComunidades);
    }

    const handleAddComunidad = () => {
        let nuevaComunidad = {
            nombre: "Nueva Comunidad",
            superficie: "",
            tipo: "normal",
            metrosLindero: "",
            anchuraLindero: "",
            finca_id: finca.id,
            valido: false,
            tempId: "tempId-" + new Date().getTime()
        }
        setComunidades([...comunidades, nuevaComunidad])
    }

    const openModalDeleteConfirm = (cm) => {
        setOpenDeleteConfirm({state: true, element: cm});
    }

    const handleOpenEdit = (cm) => {
        let comunidadFound = comunidades.find(c => {
            if(cm?.id){
                return c?.id === cm?.id;
            }else{
                return c?.tempId === cm?.tempId;
            }
        });
        setComunidad(comunidadFound);
        setOpenEdit(true);
    }

    const colorClase = (cm) => {
        if (cm?.valido === false) {
            return "tabla-comunidad-invalida";
        } else if (cm?.valido === true && cm?.touch === true) {
            return "tabla-comunidad-modificada";
        } else {
            return "";
        }
    }

    const formatJSON = () => {
        let insertar = [];
        let eliminar = [];
        let actualizar = [];

        comunidades.map((c) => {
            if (typeof c.superficie === 'string') {
                c.superficie = parseFloat(c.superficie?.replace(',', '.'));
            }
            if(c?.tempId && c?.valido === true){
                insertar.push(c);
            }else if(c?.id && c?.delete === true){
                eliminar.push(c);
            }else if(c?.id && c?.touch === true && c?.valido === true){
                actualizar.push(c);
            }
        });

        return {
            insertar: insertar,
            eliminar: eliminar,
            actualizar: actualizar
        };
    }

    const saveAll = async () => {
        let buildUrl = "/biodiversidad/comunidad";
        try{
            setLoadingSend(true);
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}

            let objectJSON = formatJSON();
            
            await instance.post(buildUrl, objectJSON).then(function async (resp) {
                handleLoadComunidades(finca.id).then(function (res){
                    setMsg({type: "action-new", text: "Se han guardado los cambios correctamente."});
                });
                console.log(objectJSON);
            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    contentText: "Algo ha fallado al intentar guardar la información de las comunidades. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoadingSend(false);
            });
        }catch(error){
            setLoadingSend(false);
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                contentText: "Algo ha fallado al intentar guardar la información de las comunidades. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    return(
        <>
            <div className="tic4bio-home">
            {msg &&
                <>
                    <ActionMessage msg={msg} setMsg={setMsg}/>
                </>
            }
            {loading ? 
                (
                    <Box p={3} align="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        <div>
                            <h1 className="tic4bio-home-title">Mis comunidades vegetales</h1>
                                {!error ?
                                    <>
                                        {(fincas.length > 0) ?
                                            <>
                                                <Box component="form" style={{}} noValidate>
                                                    <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                                        <InputLabel htmlFor="almazara">Finca*</InputLabel>
                                                        <Select
                                                            className="t4b-finca-sector-inputs"
                                                            id="finca"
                                                            label="finca"
                                                            required
                                                            MenuProps={{ disableScrollLock: true }}
                                                            value={finca.id}
                                                            onChange={(event) => { handleLoadComunidades(event.target.value); }}
                                                            >
                                                            {fincas.map((f, index) => (
                                                                <MenuItem key={index} value={f.id}>
                                                                    {f.unidadProductiva} - {f.nombre}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                                {loadingComunidades ? ( 
                                                    <Box p={3} align="center">
                                                        <CircularProgress />
                                                    </Box>
                                                    ) : (
                                                        <>
                                                            {finca.id !== "" && 
                                                                <>
                                                                    <div style={{marginTop: "40px"}}>
                                                                        <p>COMUNIDADES VEGETALES</p>
                                                                        <div className="tabla-comunidad-box">
                                                                            <table className="tabla-comunidad">
                                                                                <tbody>
                                                                                    {comunidades.map((c, index) => {
                                                                                        return(
                                                                                            <tr key={"comunidades-" + index} className={colorClase(c)}>
                                                                                                <td>
                                                                                                    <div className="edicion-nombre">
                                                                                                        <span>{c.nombre}</span>
                                                                                                        <IconButton
                                                                                                            size="large"
                                                                                                            edge="start"
                                                                                                            className="delete"
                                                                                                            aria-label="Editar comunidad"
                                                                                                            onClick={() => handleOpenEdit(c)}
                                                                                                            >
                                                                                                                <EditIcon />
                                                                                                        </IconButton>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td>
                                                                                                    {c.superficie ? 
                                                                                                        <>
                                                                                                            {c.superficie} ha
                                                                                                        </>
                                                                                                        :
                                                                                                        <>
                                                                                                            -
                                                                                                        </>
                                                                                                    }
                                                                                                </td>
                                                                                                <td>
                                                                                                    {c?.delete === true ?
                                                                                                    <>
                                                                                                        <IconButton
                                                                                                        size="large"
                                                                                                        edge="start"
                                                                                                        className="delete"
                                                                                                        aria-label="Deshacer eliminación comunidad"
                                                                                                        style={{color: "#fa0000"}}
                                                                                                        onClick={() =>  undoComunidad(c)}
                                                                                                        >
                                                                                                            <UndoIcon />
                                                                                                        </IconButton>
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        <IconButton
                                                                                                        size="large"
                                                                                                        edge="start"
                                                                                                        className="delete"
                                                                                                        aria-label="Eliminar comunidad"
                                                                                                        style={{color: "#fa0000"}}
                                                                                                        onClick={() => openModalDeleteConfirm(c)}
                                                                                                        >
                                                                                                            <DeleteIcon />
                                                                                                        </IconButton>
                                                                                                    </>
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    })}
                                                                                    <tr>
                                                                                        <td>
                                                                                            Añadir nueva zona
                                                                                        </td>
                                                                                        <td colspan="2">
                                                                                            <IconButton
                                                                                                size="large"
                                                                                                edge="start"
                                                                                                className="delete"
                                                                                                aria-label="Añadir comunidad"
                                                                                                style={{color: "#80C606"}}
                                                                                                onClick={() => handleAddComunidad()}
                                                                                                >
                                                                                                    <AddCircleIcon />
                                                                                            </IconButton>
                                                                                        </td>
                                                                                    </tr>
                                                                                    
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                        {loadingSend ?
                                                                            <Box align="center" sx={{my: 2}}>
                                                                                <CircularProgress />
                                                                            </Box>
                                                                            :
                                                                            <button className="btn green" onClick={() => saveAll()} style={{margin: "16px 0"}}>Guardar Cambios</button>
                                                                        }
                                                                    </div>
                                                                </>
                                                            }
                                                        </>
                                                    )
                                                }
                                                
                                            </>
                                            :
                                            <>
                                                <p><Mensajes type="comunidades" /></p>
                                            </>
                                        }
                                    </>
                                    :
                                    <><p>Se ha producido un error inesperado. Vuelva a intentarlo más tarde.</p></>
                                }
                        </div>
                    </>
                )
            }
            </div>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.title} contentText={errorMsg.content}/>
            <ModalEditComunidad open={openEdit} setOpen={setOpenEdit} comunidad={comunidad} setComunidad={setComunidad} comunidades={comunidades} setComunidades={setComunidades}/>
            <ModalDeleteConfirm open={openDeleteConfirm} setOpen={setOpenDeleteConfirm} func={deleteComunidad} contentTitle={"Eliminar " + openDeleteConfirm.element?.nombre + "."} contentText={"¿Está seguro que desea eliminar esta comunidad?"}/>
        </>
    );
}

export default ComunidadesPage;