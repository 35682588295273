import { Autocomplete, Box, FormControl, IconButton, TextField } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Fragment, useState } from "react";
import { checkIntPattern } from "../../utils/commons";
import ModalCalculatorEditCultivosLenosos from "../modals/ModalCalculatorEditCultivosLenosos";

const TableAdvancedNoSamplingComponent = ({stateForm, setStateForm, tipoVegetacion, errors }) => {

    const [open, setOpen] = useState({
        open: false,
        id: ""
    });

    const handleAddEspecie = () => {
        let nuevaEspecie = {
            nombre: "Nueva Especie",
            num_individuos: 1,
            nuevo: true,
            tempId: "tempId-" + new Date().getTime()
        }
        setStateForm(prevData => {
            const newData = prevData.muestreos.map(item => {
                if (item.tipoVegetacion === tipoVegetacion) {
                    item.muestreos.forEach(muestreo => {
                        muestreo.muestras.push(nuevaEspecie);
                    });
                }
                return item;
            });
            return {...prevData, muestreos: newData};
        })
    }

    const handleChangeNombre = (e, id) => {
        setStateForm(prevData => {
            const newData = prevData.muestreos.map(item => {
              if (item.tipoVegetacion === tipoVegetacion) {
                let muestras = item.muestreos[0].muestras.map(m => {
                    if(m.tempId === id){
                        return {...m, nombre: e}
                    }else{
                        return m;
                    }
                });
                const newItem = {
                    ...item,
                    muestreos: [{ ...item.muestreos[0], muestras: muestras }]
                };
                return newItem;
              }else{
                return item;
              }
            });
            return { ...prevData, muestreos: newData };
          });
    }

    const handleChangeNI = (e, id) => {
        if(checkIntPattern(e)){
            setStateForm(prevData => {
                const newData = prevData.muestreos.map(item => {
                  if (item.tipoVegetacion === tipoVegetacion) {
                    let muestras = item.muestreos[0].muestras.map(m => {
                        if(m.tempId === id){
                            return {...m, num_individuos: e}
                        }else{
                            return m;
                        }
                    });
                    const newItem = {
                        ...item,
                        muestreos: [{ ...item.muestreos[0], muestras: muestras }]
                    };
                    return newItem;
                  }else{
                    return item;
                  }
                });
                return { ...prevData, muestreos: newData };
            });
        }
    }

    const deleteEspecie = (e) => {
        let newData = stateForm.muestreos.map(item => {
            if (item.tipoVegetacion === tipoVegetacion) {
                let muestras = item.muestreos[0].muestras.map(muestra => {
                    if(e?.tempId && muestra.tempId === e.tempId){
                        muestra.eliminar = true;
                    }
                    return muestra;
                });
                const newItem = {
                    ...item,
                    muestreos: [{ ...item.muestreos[0], muestras: muestras }]
                };
                return newItem;
            }else{
                return item;
            }
        });
        setStateForm(prevData => ({
            ...prevData,
            muestreos: newData
        }));
    }

    return(
        <>
        <div className="tabla-bio-box">
            <table className="tabla-bio">
                <thead>
                    <tr>
                        <th colSpan="3">Especies</th>
                    </tr>
                </thead>
                <tbody>
                    {stateForm.muestreos.map((esp, index) => (
                        <Fragment key={"muestreo_" + index}>
                            {esp.tipoVegetacion === tipoVegetacion &&
                                <>
                                    {esp.muestreos[0].muestras.map((m, jindex) => (
                                        <Fragment key={"muestra_" + index + "_" + jindex}>
                                            {m?.eliminar !== true && 
                                                <tr>
                                                    <td>
                                                        <FormControl sx={{width: "100%"}} variant="standard">
                                                            {/*<TextField
                                                                className="t4b-finca-sector-inputs no-red-label"
                                                                label="Nombre de especie*"
                                                                id={"especieNombre-" + tipoVegetacion + "-0-" + jindex}
                                                                value={m?.nombre}
                                                                variant="standard"
                                                                onChange={(e) => handleChangeNombre(e.target.value, m?.tempId)}
                                                                name="especieNombre"
                                                                error={errors["errorEspecie-" + tipoVegetacion + "-0-" + jindex + "Nombre"]}
                                                            />
                                                            {errors["errorEspecie-" + tipoVegetacion + "-0-" + jindex + "NombreMsg"] && <div className="errorInput">{errors["errorEspecie-"  + tipoVegetacion + "-" + index + "-" + jindex + "NombreMsg"]}</div>} */}
                                                            <Autocomplete
                                                                freeSolo
                                                                options={stateForm.diccionario}
                                                                renderOption={(props, option) => {
                                                                    return <Box element="li" sx={{ display: 'flex', alignItems: 'center' }} {...props}> 
                                                                        {option}
                                                                    </Box>
                                                                }}
                                                                className="t4b-finca-inputs"
                                                                getOptionLabel={(option) => (
                                                                    typeof option === "string" ? option : option.label
                                                                )}
                                                                id={"especieNombre-" + tipoVegetacion + "-0-" + jindex}
                                                                onInputChange={(e, newValue) => {
                                                                    handleChangeNombre(newValue, m?.tempId)
                                                                }}
                                                                value={m?.nombre || ""}
                                                                renderInput={(params) => (
                                                                    <>
                                                                    <TextField {...params} label="Nombre de especie*" variant="standard" />
                                                                    </>
                                                                )}
                                                                sx={{
                                                                    ...(errors["errorEspecie-" + tipoVegetacion + "-0-" + jindex + "Nombre"] && {
                                                                        '& .MuiInput-root:before': {
                                                                            borderBottom: "1px solid #d32f2f",
                                                                        }
                                                                    })
                                                                }}
                                                            />
                                                        </FormControl>
                                                    </td>
                                                    <td className={tipoVegetacion === "cultivoslenosos" && "cellFlex"}>
                                                        <FormControl sx={{width: "100%"}} variant="standard">
                                                            <TextField
                                                                className="t4b-finca-sector-inputs no-red-label"
                                                                label="Número de individuos*"
                                                                id={"especieNI-" + tipoVegetacion + "-0-" + jindex}
                                                                value={m?.num_individuos}
                                                                type="number"
                                                                variant="standard"
                                                                InputProps={{
                                                                    inputProps: { 
                                                                        max: 99999, min: 1,
                                                                    }
                                                                }}
                                                                onChange={(e) => handleChangeNI(e.target.value, m?.tempId)}
                                                                name="especieNI"
                                                                error={errors["errorEspecie-" + tipoVegetacion + "-0-" + jindex + "NI"]}
                                                            />
                                                            {errors["errorEspecie-" + tipoVegetacion + "-0-" + jindex + "NIMsg"] && <div className="errorInput">{errors["errorEspecie-"  + tipoVegetacion + "-0-" + jindex + "NIMsg"]}</div>}
                                                        </FormControl>
                                                        {tipoVegetacion === "cultivoslenosos" &&
                                                            <span className="modaltip-icon pink" onClick={() => { setOpen({open: true, id: m?.tempId}) }}>?</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        <IconButton
                                                        size="large"
                                                        edge="start"
                                                        className="delete"
                                                        aria-label="Eliminar especie"
                                                        style={{color: "#fa0000"}}
                                                        onClick={() => deleteEspecie(m)}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </td>
                                                </tr>
                                            }
                                        </Fragment>
                                    ))}
                                </>
                            }
                        </Fragment>
                    ))}
                    <tr>
                        <td colSpan="2" className="tabla-bio-add">
                            <IconButton
                                size="large"
                                edge="start"
                                className="delete"
                                aria-label="Añadir Especie"
                                style={{color: "#80C606"}}
                                onClick={() => {handleAddEspecie()}}
                                >
                                    <AddCircleIcon />
                            </IconButton>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ModalCalculatorEditCultivosLenosos open={open} setOpen={setOpen} stateForm={stateForm} setStateForm={setStateForm} tipoVegetacion={tipoVegetacion} />
        </>
    )
}

export default TableAdvancedNoSamplingComponent;