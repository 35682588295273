import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

function BarChartHAAlmazaraDatosEvolucion({ startCampana, endCampana, campanas, data, finca }) {

  const chartRef = useRef(null);
  const navigate = useNavigate();
  const [ chartInfo, setChartInfo ] = useState();
  const [state, setState] = useState({
    kgAceitunaMolturado: true,
    litrosAceite: true,
    rendimientoMedio: true,
    nDiasMolturacion: true,
  });

  const backgroundColors = [
    'rgba(255,0,0,0.8)',
    'rgba(112,173,71,0.8)',
    'rgba(166,166,166,0.8)',
    'rgba(255,192,0,0.8)',
  ];

  const borderColors = [
    'rgba(255,0,0,1)',
    'rgba(112,173,71,1)',
    'rgba(166,166,166,1)',
    'rgba(255,192,0,1)',
  ];

  useEffect(() => {
    chooseOption();
  }, [state, startCampana, endCampana]); 

  useEffect(() => {
    const chart = chartRef.current;

    const handleClick = (event) => {
      
        const canvasPosition = {
            x: event.offsetX,
            y: event.offsetY,
        };

        const dataXLabels = chart.scales.x.ticks;
        
        // Calcular la posición de las etiquetas en el eje X
        dataXLabels.forEach((label, index) => {
            const xLabelPosition = chart.scales.x.getPixelForTick(index);
            const labelWidth = 100; // Ancho aproximado de la etiqueta

            // Verificar si el clic está dentro del rango de la etiqueta
            if (canvasPosition.x >= xLabelPosition - labelWidth / 2 && canvasPosition.x <= xLabelPosition + labelWidth / 2) {

              let posElement = chart.data.labels.indexOf(label.label);
              let huellaId = chart.data.datasets[0].huellasIds[posElement];

              let fincaAux;
              if(finca.fincas.length > 0){
                fincaAux = finca.fincas.find(s => {
                  if(s.dataType === "industria"){
                    return s.id;
                  }
                });
              }
              console.log(fincaAux.id);
              console.log(huellaId);
              navigate(`/huella-almazaras/${fincaAux.id}`, {
                  state: { huellaId: huellaId }
              });
            }
        });
    };

    if (chart) {
        chart.canvas.addEventListener('click', handleClick);
    }

    return () => {
        if (chart?.canvas) {
            chart.canvas.removeEventListener('click', handleClick);
        }
    };
  }, [chartRef, chartInfo]);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const generateCampanas = () => {
    const indiceInicio = campanas.indexOf(startCampana);
    const indiceFin = campanas.indexOf(endCampana);

    return campanas.slice(indiceInicio, indiceFin + 1);
  }

  const generateDataSets = (subData) => {
    let labels = [];
    if(state.kgAceitunaMolturado){
      labels.push({key: 'kgAceitunaMolturado', valor: 'Kg aceituna cosechados', colorIndex: 0});
    }
    if(state.litrosAceite){
      labels.push({key: 'litrosAceite', valor: 'L. aceite obtenidos', colorIndex: 1});
    }
    if(state.rendimientoMedio){
      labels.push({key: 'rendimientoMedio', valor: 'Rendimiento medio (%)', colorIndex: 2});
    }
    if(state.nDiasMolturacion){
      labels.push({key: 'nDiasMolturacion', valor: 'Días campaña', colorIndex: 3});
    }
      
    const result = labels.map((label) => ({
      label: label.valor,
      data: Object.values(subData).map((campanaData) => campanaData.almazaras[0][label.key]),
      backgroundColor: backgroundColors[label.colorIndex],
      borderColor: borderColors[label.colorIndex],
      borderWidth: 1,
      huellasIds: Object.values(subData).map((campanaData) => campanaData.almazaras[0].id),
    }));
    return result;
  
  }

  const chooseOption = () => {

    let subData = data.unidad.infoUnidad;

    const labelsCampanas = generateCampanas();

    const filteredData = Object.keys(subData)
    .filter((key) => labelsCampanas.includes(key))
    .reduce((obj, key) => {
      obj[key] = subData[key];
      return obj;
    }, {});

    let someData = Object.values(filteredData).some(c => c.almazaras[0]);
    
    if(someData){

      const info = {
        labels: labelsCampanas,
        datasets: generateDataSets(filteredData)
      }

      setChartInfo(info);
    }else{
      setChartInfo();
    }

  }

  const options = {
    layout: {
      padding: {
          top: 0,
          bottom: 100,
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        type: 'logarithmic',
        beginAtZero: true,
        ticks: {
          maxTicksLimit: 5,
          callback: function(value, index, values) {
            return Number(value.toString());
          }
        }
      }
    },
  };

    return (
      <>
        {chartInfo &&
          <>
              <h3 style={{textAlign: "center", textDecoration: "underline", color: "#80C606", fontSize: "26px", marginBottom: "40px"}}>Datos de campaña</h3>
              <div style={{minHeight: "600px"}}>
                <Bar data={chartInfo} options={options} ref={chartRef}/>
              </div>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Opciones de filtrado</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.kgAceitunaMolturado}
                            onChange={handleChange}
                            name="kgAceitunaMolturado"
                          />
                        }
                        label="Kg aceituna cosechados"
                    />
                
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.litrosAceite}
                          onChange={handleChange}
                          name="litrosAceite"
                        />
                      }
                      label="L. aceite obtenidos"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.rendimientoMedio}
                          onChange={handleChange}
                          name="rendimientoMedio"
                        />
                      }
                      label="Rendimiento medio (%)"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.nDiasMolturacion}
                          onChange={handleChange}
                          name="nDiasMolturacion"
                        />
                      }
                      label="Días campaña"
                    />
                  </FormGroup>
                </FormControl>
          </>
        }
        {!chartInfo &&
          <p>No existe información disponible para el rango de campañas seleccionadas en el <b>gráfico de datos de campaña.</b></p>
        }
      </>
    );
}

export default BarChartHAAlmazaraDatosEvolucion;