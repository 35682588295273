import { Box, FormControl, Input, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import FormCaracteristicasCultivoComponent from "./FormCarateristicasCultivo";
import FormRedRiegoComponent from "./FormRedRiego";
import FormCaracteristicasSueloComponent from "./FormCaracteristicasSuelo";
import FormAguaRiegoCampañaComponent from "./FormAguaRiegoCampaña";
import { useState } from "react";
import StepperSectorComponent from "./StepperSector";
import FormInicioSectorComponent from "./FormInicioSector";
import FormEndComponent from "./FormEnd";

const FormNuevoSectorComponent = () => {

    const [ position, setPosition ] = useState(0);
    const [ stateForm, setStateForm ] = useState({
        nombre: "",
        hRiegoAplicadas: "",
        tipoUsuario: "",
        metodoCalculoPE: "",
        tipoCultivo: "",
        distanciaArboles: "",
        distanciaLineas: "",
        diametroCopa: "",
        seto: "",
        tipoRiego: "",
        tipoPlantacion: "",
        nRamales: "",
        distanciaGoteros: "",
        nGoteros: "",
        caudalGotero: "",
        superficieSector: "",
        tipoEstrategia: "",
        riegoACubrir: "",
        limiteLV: "",
        limiteSD: "",
        conductividad: "",
        textura: "",
        analisis: "",
        ptArena: "",
        ptLimo: "",
        ptArcilla: "",
        densidad: "",
        humedadSueloInicial: "",
        pmp: "",
        cc: "",
        usuario: "",
        clave: "",
        codigoEstacion: "",
        idEstacion: "",
        verificado: "",
        distanciaContador: ""
    });

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return(
        <div className="tic4bio-sectores-create">
            <StepperSectorComponent position={position} setPosition={setPosition} state={stateForm}/>  
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <FormInicioSectorComponent position={position} setPosition={setPosition} state={stateForm} setState={setStateForm} />
                <FormCaracteristicasCultivoComponent position={position} setPosition={setPosition} state={stateForm} setState={setStateForm} />
                <FormRedRiegoComponent position={position} setPosition={setPosition} state={stateForm} setState={setStateForm} />
                <FormAguaRiegoCampañaComponent position={position} setPosition={setPosition} state={stateForm} setState={setStateForm} /> 
                <FormCaracteristicasSueloComponent position={position} setPosition={setPosition} state={stateForm} setState={setStateForm} />
                <FormEndComponent position={position} setPosition={setPosition} state={stateForm} setState={setStateForm} /> 
            </Box>
        </div>
    );
}

export default FormNuevoSectorComponent;