import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

function BarChartHAEvolucion({ startCampana, endCampana, campanas, option, data, finca }) {

  const chartRef = useRef(null);
  const navigate = useNavigate();
  const [ chartInfo, setChartInfo ] = useState();

  const backgroundColors = [
    'rgba(140, 228, 46, 0.8)',
    'rgba(122,154,200, 0.8)',
  ];

  const borderColors = [
    'rgba(140, 228, 46, 1)',
    'rgba(122,154,200, 1)',
  ];

  useEffect(() => {
    chooseOption();
  }, [option, startCampana, endCampana]); 

  useEffect(() => {
    const chart = chartRef.current;

    const handleClick = (event) => {
        const canvasPosition = {
            x: event.offsetX,
            y: event.offsetY,
        };

        const dataXLabels = chart.scales.x.ticks;
        const scaleY = chart.scales.y;
        
        // Calcular la posición de las etiquetas en el eje X
        dataXLabels.forEach((label, index) => {
            const xLabelPosition = chart.scales.x.getPixelForTick(index);
            const labelWidth = 100; // Ancho aproximado de la etiqueta

            // Verificar si el clic está dentro del rango de la etiqueta
            if (canvasPosition.x >= xLabelPosition - labelWidth / 2 && canvasPosition.x <= xLabelPosition + labelWidth / 2) {

                let fincaId;
                if(finca.fincas.length > 0){
                  fincaId = finca.fincas[0].id;
                }else{
                  fincaId = finca.almazaras[0].id
                }
                navigate(`/huella-unidades/${fincaId}`, {
                    state: { campana: label.label }
                });
            }
        });
    };

    if (chart) {
        chart.canvas.addEventListener('click', handleClick);
    }

    return () => {
        if (chart?.canvas) {
            chart.canvas.removeEventListener('click', handleClick);
        }
    };
  }, [chartRef, chartInfo]);

  const generateCampanas = () => {
    const indiceInicio = campanas.indexOf(startCampana);
    const indiceFin = campanas.indexOf(endCampana);

    return campanas.slice(indiceInicio, indiceFin + 1);
  }

  const generateDataSets = (subData) => {
    let labels = [{key: "huellaAguaVerde", value: "HA Verde (l/kg aceituna)"}, {key: "huellaAguaAzul", value: "HA Azul (l/kg aceituna)"}];
    
    const result = labels.map((label, index) => ({
      label: label.value,
      data: Object.values(subData).map((campanaData) => campanaData[label.key]),
      backgroundColor: backgroundColors[index],
      borderColor: borderColors[index],
      borderWidth: 1,
    }));
    return result;
  }

  const chooseOption = () => {
    let subData;
    if(option === "unidad"){
      subData = data.unidad.groupByCampanas;
    }else{
      subData = data.unidad.infoUnidad;
    }

    const labelsCampanas = generateCampanas();

    const filteredData = Object.keys(subData)
    .filter((key) => labelsCampanas.includes(key))
    .reduce((obj, key) => {
      obj[key] = subData[key];
      return obj;
    }, {});

    let someData = Object.values(filteredData).some(c => {
      const regex = /(\d{4})\/(\d{4})/;
      const startMatch = startCampana.match(regex);
      const endMatch = endCampana.match(regex);

      const actualMatch = c.campana.match(regex);

      const startValue = (+startMatch[1]) + (+startMatch[2]);
      const endValue = (+endMatch[1]) + (+endMatch[2]);
      const actualValue = (+actualMatch[1]) + (+actualMatch[2]);
      if(actualValue <= endValue && actualValue >= startValue){
        return true;
      }
      return false;
    });

    if(someData){
      const info = {
        labels: labelsCampanas,
        datasets: generateDataSets(filteredData)
      }

      setChartInfo(info);
    }else{
      setChartInfo();
    }

  }

  const options = {
    layout: {
      padding: {
          top: 0,
          bottom: 100,
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x:{
        stacked: true
      },
      y: {
        stacked: true,
        type: 'logarithmic',
        beginAtZero: true,
        ticks: {
          maxTicksLimit: 5,
          callback: function(value, index, values) {
            return Number(value.toString());
          }
        }
      },
    },
  };

    return (
      <>
        {chartInfo &&
          <>
              <div>
                <h3 style={{textAlign: "center", textDecoration: "underline", color: "#80C606", fontSize: "26px", marginBottom: "40px"}}>Huella Agua (l agua/kg aceituna)</h3>
                <div style={{minHeight: "600px"}}>
                  <Bar data={chartInfo} options={options} ref={chartRef}/>
                </div>
              </div>
          </>
        }
        {!chartInfo &&
          <p>No existe información disponible para el rango de campañas seleccionadas en el <b>gráfico de la huella de agua (l agua /kg aceituna)</b></p>
        }
      </>
    );
}

export default BarChartHAEvolucion;