import { Box, CircularProgress, IconButton } from "@mui/material";
import { Fragment, useEffect, useState } from 'react';
import { instance } from '../../utils/axios-config';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';



const HomeBackofficePage = () => {

    const [ loading, setLoading ] = useState(true);
    const [ elementos, setElementos ] = useState([]);
    const [selectedListType, setSelectedListType] = useState('usuarios');
    const [data, setData] = useState([]);
    const [error, setError] = useState(false);
    const [ totalPages, setTotalPages ] = useState(1);
    const [ open, setOpen ] = useState(false);
    const [ openConfirm, setOpenConfirm ] = useState({
        state: false,
        data: null
    });

    useEffect( () => {
        if (selectedListType === 'usuarios') {
            loadUsuariosData();
        }
    }, [selectedListType]);

    const openModalConfirm = (data) => {
        setOpenConfirm({state: true, data: data});
    }

    const loadUsuariosData = async (startPage = 1) => {
        let buildUrl = `/users/list?page=${startPage}`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
        
            await instance.get(buildUrl).then(function(resp) {
                setData(resp.data.fincas);
                setTotalPages(resp.data.pages);
            }).catch(function(resp) {
                setOpen(true);
                setError(true);
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setOpen(true);
        }
    }


    <div className="backoffice-home">
        <div className="backoffice-home-container">
            <div className="backoffice-home-container-list">
                <ul className="tic4bio-fincas">
                    {loading ? 
                        (
                            <Box p={3} align="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                {data.length > 0 ? (
                                     <>
                                        {data.map((finca, index) => {
                                            return (
                                                <Fragment key={"fincas-" + index}>
                                                    <li className="tic4bio-finca">
                                                        <Link className="details" to={"/listaSectores/" + finca.id}>
                                                            <div>
                                                                <p className="details-provincia">{finca.municipio}, {finca.provincia}</p>
                                                                <p className="details-nombre">{finca.nombre}</p>
                                                                <p className="details-dotacion">Dotación: {finca.dotacionRiego} l/m<sup>3</sup></p>
                                                            </div>
                                                        </Link>
                                                        <IconButton
                                                            size="large"
                                                            edge="start"
                                                            className="delete"
                                                            aria-label="Eliminar finca"
                                                            style={{color: "#fa0000"}}
                                                            onClick={() => {openModalConfirm(finca)}}
                                                        >
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </li>
                                                </Fragment>
                                            )
                                        })}
                                    </>
                                )
                                :
                                (
                                    <>
                                    {!error ?
                                        <p className="tic4bio-fincas-empty">Aún no se ha añadido ninguna elemnto a la lista.</p>
                                        :
                                        <p className="tic4bio-fincas-empty">Se ha producido un error al recuperar el listado.</p>
                                    }
                                    </>
                                )
                                }
                            </>
                        )
                    }
                </ul>
            </div>
        </div>
    </div>
}
export default HomeBackofficePage;