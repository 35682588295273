import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useState } from "react";

const ModalDeleteConfirm = ({open, setOpen, func, contentTitle, contentText}) => {
    
    const [ loading, setLoading ] = useState(false);

    const handleClose = () => {
        setOpen({state: false, element: ""});
    };

    const handleFunction = async (e) =>{
        setLoading(true);
        await func(e).then((resp) =>{
            setOpen({state: false, element: ""});
        }).catch((err)=>{
            
        }).finally(() => {
            setLoading(false);
        });
    }

    return ( 
        <Dialog open={open.state} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                {contentTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {contentText}
                </DialogContentText>
            </DialogContent>
            
            <DialogActions className="spaceCenter">
                    {loading ?
                        <Box align="center" sx={{my: 2}}>
                            <CircularProgress />
                        </Box>
                        :
                        <>
                        <button className="btn white" onClick={handleClose}>Cerrar</button>
                        <button className="btn red" onClick={() => {handleFunction(open.element)}}>Eliminar</button>
                        </>
                    }
            </DialogActions>
        </Dialog>
     );
}
 
export default ModalDeleteConfirm;