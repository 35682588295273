import { FormControl, TextField } from "@mui/material";
import { Fragment, useEffect } from "react";
import { checkDoublePattern, checkIntNoZeroPattern, checkIntPattern } from "../../utils/commons";

const InputZonaMantenimientoComponent = ({state, setState, errors }) => {


    /*useEffect(() => {
        console.log("useEffect ZonaMantenimiento" + pos)
        setState(prevState => ({
            ...prevState,
            zonas: {
              ...prevState.zonas,
              [pos]: {
                ...prevState.zonas[pos],
                caudal: "",
                tiempo: "",
                nombre: "Zona " + pos
              },
            },
            zonasMantenimiento: {
                ...prevState.zonasMantenimiento,
                [pos]: {
                    ...prevState.zonasMantenimiento[pos],
                    caudal: "",
                    tiempo: "",
                    frecuencia: "",
                    nombre: "Zona " + pos
                }
            }
        }));
    }, []);*/

    useEffect(() => {
        const nZonas = parseInt(state.nZonas, 10);
        for (let pos = 0; pos < nZonas; pos++) {
          handleChangeNombre(`Zona ${pos}`, pos);
          handleChangeCaudal("0", pos);
          handleChangeTiempo("0", pos);
          handleChangeFrecuencia("1", pos);
        }
    }, [state.nZonas]);

    const handleChangeNombre = (v, pos) => {
        setState(prevState => ({
            ...prevState,
            zonas: {
                ...prevState.zonas,
                [pos]: {
                ...prevState.zonas[pos],
                nombre: v,
                },
            },
            zonasMantenimiento: {
                ...prevState.zonasMantenimiento,
                [pos]: {
                ...prevState.zonasMantenimiento[pos],
                nombre: v,
                }
            }
        }));
    }

    const handleChangeCaudal = (v, pos) => {
        if(checkDoublePattern(v)){
            setState(prevState => ({
                ...prevState,
                zonasMantenimiento: {
                    ...prevState.zonasMantenimiento,
                    [pos]: {
                    ...prevState.zonasMantenimiento[pos],
                    caudal: v,
                    },
                },
            }));
        }
    }

    const handleChangeTiempo = (v, pos) => {
        if(checkIntPattern(v)){
            setState(prevState => ({
                ...prevState,
                zonasMantenimiento: {
                    ...prevState.zonasMantenimiento,
                    [pos]: {
                    ...prevState.zonasMantenimiento[pos],
                    tiempo: v,
                    },
                },
            }));
        }
    }

    const handleChangeFrecuencia = (v, pos) => {
        if(checkIntNoZeroPattern(v)){
            setState(prevState => ({
                ...prevState,
                zonasMantenimiento: {
                    ...prevState.zonasMantenimiento,
                    [pos]: {
                    ...prevState.zonasMantenimiento[pos],
                    frecuencia: v,
                    },
                },
            }));
        }
    }


    return(
        <>
            {Array.from({ length: parseInt(state.nZonas) }, (v, pos) => (
                <Fragment key={pos}>
                    <tr>
                        <td>
                            <FormControl sx={{width: "100%"}} variant="standard">
                                <TextField
                                    className="t4b-finca-sector-inputs no-red-label"
                                    label="Nombre de zona*"
                                    id={"zonaMantenimientoNombre-" + pos}
                                    value={state.zonas[pos]?.nombre ? state.zonas[pos]?.nombre : "" }
                                    variant="standard"
                                    onChange={(e) => handleChangeNombre(e.target.value, pos)}
                                    name="zonaMantenimientoNombre"
                                    error={errors["errorZonaMantenimiento" + pos + "Nombre"]}
                                />
                                {errors["errorZonaMantenimiento" + pos + "NombreMsg"] && <div className="errorInput">{errors["errorZonaMantenimiento" + pos + "NombreMsg"]}</div>}
                            </FormControl>
                        </td>
                        <td>
                            <FormControl sx={{width: "100%"}} variant="standard">
                                <TextField
                                    className="t4b-finca-sector-inputs no-red-label"
                                    label="Caudal*"
                                    id={"zonaMantenimientoCaudal-" + pos}
                                    type="number"
                                    variant="standard"
                                    InputProps={{
                                        inputProps: { 
                                            max: 99999, min: 0,
                                            step: 0.5,
                                        }
                                    }}
                                    name={"zonaMantenimientoCaudal-" + pos}
                                    value={state.zonasMantenimiento[pos]?.caudal ? state.zonasMantenimiento[pos]?.caudal : ""}
                                    onChange={(e) => handleChangeCaudal(e.target.value, pos)}
                                    error={errors["errorZonaMantenimiento" + pos + "Caudal"]}
                                />
                                {errors["errorZonaMantenimiento" + pos + "CaudalMsg"] && <div className="errorInput">{errors["errorZonaMantenimiento" + pos + "CaudalMsg"]}</div>}
                            </FormControl>
                        </td>
                        <td>
                            <FormControl sx={{width: "100%"}} variant="standard">
                                <TextField
                                    className="t4b-finca-sector-inputs no-red-label"
                                    label="Tiempo*"
                                    id={"zonaMantenimientoTiempo-" + pos}
                                    type="number"
                                    variant="standard"
                                    InputProps={{
                                        inputProps: {
                                            max: 99999, min: 0,
                                        }
                                    }}
                                    name={"zonaMantenimientoTiempo-" + pos}
                                    value={state.zonasMantenimiento[pos]?.tiempo ? state.zonasMantenimiento[pos]?.tiempo : ""}
                                    onChange={(e) => handleChangeTiempo(e.target.value, pos)}
                                    error={errors["errorZonaMantenimiento" + pos + "Tiempo"]}
                                />
                                {errors["errorZonaMantenimiento" + pos + "TiempoMsg"] && <div className="errorInput">{errors["errorZonaMantenimiento" + pos + "TiempoMsg"]}</div>}
                            </FormControl>
                        </td>
                        <td>
                            <FormControl sx={{width: "100%"}} variant="standard">
                                <TextField
                                    className="t4b-finca-sector-inputs no-red-label"
                                    label="Frecuencia*"
                                    id={"zonaMantenimientoFrecuencia-" + pos}
                                    type="number"
                                    variant="standard"
                                    InputProps={{
                                        inputProps: { 
                                            max: 99999, min: 1,
                                        }
                                    }}
                                    name={"zonaMantenimientoFrecuencia-" + pos}
                                    value={state.zonasMantenimiento[pos]?.frecuencia ? state.zonasMantenimiento[pos]?.frecuencia : ""}
                                    onChange={(e) => handleChangeFrecuencia(e.target.value, pos)}
                                    error={errors["errorZonaMantenimiento" + pos + "Frecuencia"]}
                                />
                                {errors["errorZonaMantenimiento" + pos + "FrecuenciaMsg"] && <div className="errorInput">{errors["errorZonaMantenimiento" + pos + "FrecuenciaMsg"]}</div>}
                            </FormControl>
                        </td>
                    </tr>
                </Fragment>
            ))}
        </>
    );
}
export default InputZonaMantenimientoComponent;