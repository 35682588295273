import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import StepperBiodiversidadComponent from "../components/formsBioVertebrados/StepperBiodiversidad";
import FormBioStepOneComponent from "../components/formsBioVertebrados/FormBioStepOne";
import FormBioStepTwoComponent from "../components/formsBioVertebrados/FormBioStepTwo";
import FormBioStepThreeComponent from "../components/formsBioVertebrados/FormBioStepThree";
import FormBioStepFourComponent from "../components/formsBioVertebrados/FormBioStepFour";



const BioVertebradosPage = () => {

    const [ position, setPosition ] = useState(0);
    const [ stateForm, setStateForm ] = useState({
        finca: {id: ""},
        fecha: "",
        tipoAnalisis: "",
        comunidad: {id: ""},
        tipoMuestreo: {
            itinerariodecenso: true,
        },
        muestreos: {},
        diccionario: []
    });

    useEffect(() => {
        document.title = "Nueva biodiversidad vertebrados";
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return(
        <div className="tic4bio-home">
            <StepperBiodiversidadComponent position={position} setPosition={setPosition}/>  
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <FormBioStepOneComponent position={position} setPosition={setPosition} stateForm={stateForm} setStateForm={setStateForm}/>
                {(position === 1 || (stateForm.comunidad.id !== "")) &&
                    <>
                        <FormBioStepTwoComponent position={position} setPosition={setPosition} stateForm={stateForm} setStateForm={setStateForm}/>
                    </>
                }
                {(position === 2) &&
                    <>
                        <FormBioStepThreeComponent position={position} setPosition={setPosition} stateForm={stateForm} setStateForm={setStateForm}/>
                    </>
                }
                {(position === 3) &&
                    <>
                        <FormBioStepFourComponent position={position} setPosition={setPosition} stateForm={stateForm} setStateForm={setStateForm}/>
                    </>
                }
            </Box>
        </div>
    );
}
export default BioVertebradosPage;