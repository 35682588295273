import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { checkIntPattern } from "../../utils/commons";
import contadorbg from "../../assets/contador-bg.png";
import InputConsumoComponent from "./InputConsumo";
import InputCapacidadComponent from "./InputCapacidad";


const FormConsumoAguaComponent = ({position, setPosition, state, setState}) => {

    const [errors, setErrors] = useState({});
    

    const validateForm = () =>{
        setErrors({});
        let inputIds = "";
        let valid = true;

        if(state.consumoAguaTotal === undefined || state.consumoAguaTotal === ''){
            setErrors(errors => ({...errors, errorConsumoAguaTotal: true, errorConsumoAguaTotalMsg: ""}));
            inputIds = inputIds ? inputIds +",consumoAguaTotal" : "consumoAguaTotal";
            valid = false;
        }
        

        if(state.almazara.lavadora === 1){
            if(state.equipos.lavadora.consumo === ''){
                setErrors(errors => ({...errors, errorlavadoraconsumo: true, errorlavadoraconsumoMsg: ""}));
                inputIds = inputIds ? inputIds +",lavadora-consumoAgua" : "lavadora-consumoAgua";
                valid = false;
            }
        }

        if(state.almazara.ducha === 1){
            if(state.equipos.ducha.consumo === ''){
                setErrors(errors => ({...errors, errorduchaconsumo: true, errorduchaconsumoMsg: ""}));
                inputIds = inputIds ? inputIds +",ducha-consumoAgua" : "ducha-consumoAgua";
                valid = false;
            }
        }

        if(state.almazara.molino === 1){
            if(state.equipos.molino.consumo === ''){
                setErrors(errors => ({...errors, errormolinoconsumo: true, errormolinoconsumoMsg: ""}));
                inputIds = inputIds ? inputIds +",molino-consumoAgua" : "molino-consumoAgua";
                valid = false;
            }
        }

        if(state.almazara.batidora === 1){
            if(state.equipos.batidora.consumo === ''){
                setErrors(errors => ({...errors, errorbatidoraconsumo: true, errorbatidoraconsumoMsg: ""}));
                inputIds = inputIds ? inputIds +",batidora-consumoAgua" : "batidora-consumoAgua";
                valid = false;
            }
        }

        if(state.almazara.decanter === 1){
            if(state.equipos.decanter.consumo === ''){
                setErrors(errors => ({...errors, errordecanterconsumo: true, errordecanterconsumoMsg: ""}));
                inputIds = inputIds ? inputIds +",decanter-consumoAgua" : "decanter-consumoAgua";
                valid = false;
            }
        }

        if(state.almazara.centrifuga === 1){
            if(state.equipos.centrifuga.consumo === ''){
                setErrors(errors => ({...errors, errorcentrifugaconsumo: true, errorcentrifugaconsumoMsg: ""}));
                inputIds = inputIds ? inputIds +",centrifuga-consumoAgua" : "centrifuga-consumoAgua";
                valid = false;
            }
        }
        
        
        if(inputIds !== ""){
            let elem = document.getElementById(inputIds.split(",")[0]);
            elem.scrollIntoView({behavior: "smooth"});
            elem.focus();
        }
        return valid;
    }

    const handleChangeConsumo = (e) => {
        if(checkIntPattern(e)){
            setState({...state, consumoAguaTotal: e});
        }
    }

    const handlePrev = (e) => {
        e.preventDefault();
        setPosition(0);
    }

    const handleNext = (e) => {
        e.preventDefault();
        if(validateForm()){
            setPosition(2);
        }
    }

    return(
        <>
            <Box className={position !== 1 && 'hide'}>
            
                <p>La almazara seleccionada tiene:</p>
                <FormControl sx={{width: "100%", marginBottom: "20px"}} className="radio-green disabled-opacity" variant="standard">
                    <RadioGroup
                        name="contador"
                        id="contador"
                        value={state.almazara.contadorGeneral}
                    >
                        <FormControlLabel disabled value="1" control={<Radio />} label="Solamente contador general" />
                        <FormControlLabel disabled value="0" control={<Radio />} label="+ Contadores en línea molturación" />
                    </RadioGroup>
                </FormControl>

                
                <>
                    <p>Consumo total de agua (m<sup>3</sup>) en la campaña registrado por el <b><u>contador general</u></b> (ver factura de agua):</p>
                    <FormControl className="t4b-input contador" sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                        <TextField
                            className="t4b-finca-sector-inputs no-red-label"
                            label="Consumo total de agua"
                            id="consumoAguaTotal"
                            type="number"
                            variant="standard"
                            InputProps={{
                                inputProps: { 
                                    max: 99999, min: 0 
                                }
                            }}
                            name="consumoAguaTotal"
                            value={state.consumoAguaTotal}
                            onChange={(e) => handleChangeConsumo(e.target.value)}
                            error={errors.errorConsumoAguaTotal}
                        />
                        {errors.errorConsumoAguaTotalMsg && <div className="errorInput">{errors.errorConsumoAguaTotalMsg}</div>}
                        <img src={contadorbg} style={{position: "absolute"}}/>
                    </FormControl>
                </>
                
                {state.almazara.contadorGeneral === 0 && 
                
                    <>
                        <p>Inserte el consumo diario de los equipos de su industria. Seleccione la unidad en la que va a introducir los datos :</p>
                        {state.almazara.lavadora === 1 && 
                            <>
                                <InputConsumoComponent type={"lavadora"} state={state} setState={setState} errors={errors}/>
                            </>
                        }
                        {state.almazara.ducha === 1 && 
                            <>
                                <InputConsumoComponent type={"ducha"} state={state} setState={setState} errors={errors}/>
                            </>
                        }
                        {state.almazara.molino === 1 && 
                            <>
                                <InputConsumoComponent type={"molino"} state={state} setState={setState} errors={errors}/>
                            </>
                        }
                        {state.almazara.batidora === 1 && 
                            <>
                                <InputConsumoComponent type={"batidora"} state={state} setState={setState} errors={errors}/>
                            </>
                        }
                        {state.almazara.decanter === 1 && 
                            <>
                                <InputConsumoComponent type={"decanter"} state={state} setState={setState} errors={errors}/>
                            </>
                        }
                        {state.almazara.centrifuga === 1 && 
                            <>
                                <InputConsumoComponent type={"centrifuga"} state={state} setState={setState} errors={errors}/>
                            </>
                        }
                    </>

                }

                {state.almazara.contadorGeneral === 0 && 
                
                    <div className="t4b-capacidad-box">
                        <p>Inserte la capacidad de procesado de los equipos (opcional):</p>

                        <table border="1" className="t4b-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th><p>Capacidad producción (kg aceituna/h)</p></th>
                                </tr>
                            </thead>
                            <tbody>
                                {state.almazara.lavadora === 1 && 
                                    <>
                                        <InputCapacidadComponent type={"lavadora"} state={state} setState={setState} errors={errors}/>
                                    </>
                                }
                                {state.almazara.ducha === 1 && 
                                    <>
                                        <InputCapacidadComponent type={"ducha"} state={state} setState={setState} errors={errors}/>
                                    </>
                                }
                                {state.almazara.molino === 1 && 
                                    <>
                                        <InputCapacidadComponent type={"molino"} state={state} setState={setState} errors={errors}/>
                                    </>
                                }
                                {state.almazara.batidora === 1 && 
                                    <>
                                        <InputCapacidadComponent type={"batidora"} state={state} setState={setState} errors={errors}/>
                                    </>
                                }
                                {state.almazara.decanter === 1 && 
                                    <>
                                        <InputCapacidadComponent type={"decanter"} state={state} setState={setState} errors={errors}/>
                                    </>
                                }
                                {state.almazara.centrifuga === 1 && 
                                    <>
                                        <InputCapacidadComponent type={"centrifuga"} state={state} setState={setState} errors={errors}/>
                                    </>
                                }
                            </tbody>
                        </table>
                    </div>
                        
                }
                

                <Stack spacing={2} direction="row" justifyContent="space-between" className="tic4bio-sectores-btns">
                    <button className="btn white" onClick={(e) => handlePrev(e)}>Anterior</button>
                    <button className="btn green" onClick={(e) => handleNext(e)} style={{margin: "16px 0"}}>Siguiente</button>
                </Stack>
            </Box>

        </>
    );
}
export default FormConsumoAguaComponent;