import { Box, Drawer } from "@mui/material";
import { Link as LinkRouter } from "react-router-dom";
import { useAuth } from "../../context/Auth";
import { useNavigate } from "react-router-dom";

const LateralNav = () => {

    const { logout, currentUser } = useAuth();
    const navigate = useNavigate();

    const handleLogout = async (event) => {
        event.preventDefault();
        logout();
        navigate("/backofficeLogin");
    };

    return (
        <Box className="lateralnav-container dsk">
            <div className="lateralnav-principal">
                <h3 className="lateralnav-head-section">Backoffice</h3>
                <ul className="lateralnav-list">
                    <Box component="li">
                        <LinkRouter className="principal" to="/home">Usuarios</LinkRouter>
                    </Box>
                    <Box component="li">
                        <LinkRouter className="closeicon" to="#">Cerrar Sesión</LinkRouter>
                    </Box>
                </ul>
            </div>
        </Box>
     );
}
 
export default LateralNav;