import { Box, FormControl, FormControlLabel, FormLabel, Input, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack } from "@mui/material";
import { useState } from "react";
import { checkDoublePattern, checkIntPattern, checkPercentagePattern } from "../../utils/commons";

const FormRedRiegoComponent = ({position, setPosition, state, setState}) => {

    const [errors, setErrors] = useState({});

    const handleNext = (e) => {
        e.preventDefault();
        if(validateForm()){
            setPosition(3);
        }
    };
    const handlePrev = (e) => {
        e.preventDefault();
        setPosition(1);
    }

    const handleTipoRiego = (e) => {
        if(e === "Goteros equidistantes"){
            setState({...state, tipoRiego: e, nGoteros: ""});
        }else if(e === "Goteros por árbol"){
            setState({...state, tipoRiego: e, nRamales: "", distanciaGoteros: ""});
        }
    }

    const handleChangeNRamales = (e) => {
        if(checkIntPattern(e)){
            setState({...state, nRamales: e});
        }
    }

    const handleChangeDistanciaGoteros = (e) => {
        if(checkDoublePattern(e)){
            setState({...state, distanciaGoteros: e});
        }
    }

    const handleChangeNGoteros = (e) => {
        if(checkIntPattern(e)){
            setState({...state, nGoteros: e});
        }
    }

    const handleChangeCaudalGotero = (e) => {
        if(checkDoublePattern(e)){
            setState({...state, caudalGotero: e});
        }
    }

    const handleChangeSuperficieSector = (e) => {
        if(checkDoublePattern(e)){
            setState({...state, superficieSector: e});
        }
    }

    const handleChangeRiegoACubrir = (e) => {
        if(checkPercentagePattern(e)){
            setState({...state, riegoACubrir: e});
        }
    }

    const handleChangeTipoEstrategia = (e) => {
        if(e === "Riego deficitario sostenido (RDS)"){
            setState({...state, tipoEstrategia: e});
        }else{
            setState({...state, tipoEstrategia: e, riegoACubrir: ""});
        }
    }

    const validateForm = () =>{
        setErrors({});
        let inputIds = "";
        let valid = true;
        if(state.tipoRiego === undefined || state.tipoRiego === ''){
            setErrors(errors => ({...errors, errorTipoRiego: true, errorTipoRiegoMsg: "Debe elegir un tipo de riego por goteo."}));
            inputIds = inputIds ? inputIds +",tipoRiego" : "tipoRiego";
            valid = false;
        }else if(state.tipoRiego === "Goteros equidistantes"){
            if(state.nRamales === undefined || state.nRamales === ''){
                setErrors(errors => ({...errors, errorNRamales: true, errorNRamalesMsg: ""}));
                inputIds = inputIds ? inputIds +",nRamales" : "nRamales";
                valid = false;
            }else if(!(checkIntPattern(state.nRamales))){
                setErrors(errors => ({...errors, errorNRamales: true, errorNRamalesMsg: "Debe ser un valor numérico."}));
                inputIds = inputIds ? inputIds +",nRamales" : "nRamales";
                valid = false;
            }
            if(state.distanciaGoteros === undefined || state.distanciaGoteros === ''){
                setErrors(errors => ({...errors, errorDistanciaGoteros: true, errorDistanciaGoterosMsg: ""}));
                inputIds = inputIds ? inputIds +",distanciaGoteros" : "distanciaGoteros";
                valid = false;
            }else if(!(checkDoublePattern(state.distanciaGoteros))){
                setErrors(errors => ({...errors, errorDistanciaGoteros: true, errorDistanciaGoterosMsg: "Debe ser un valor numérico."}));
                inputIds = inputIds ? inputIds +",distanciaGoteros" : "distanciaGoteros";
                valid = false;
            }
        }else if(state.tipoRiego === "Goteros por árbol"){
            if(state.nGoteros === undefined || state.nGoteros === ''){
                setErrors(errors => ({...errors, errorNGoteros: true, errorNGoterosMsg: ""}));
                inputIds = inputIds ? inputIds +",nGoteros" : "nGoteros";
                valid = false;
            }else if(!(checkIntPattern(state.nGoteros))){
                setErrors(errors => ({...errors, errorNGoteros: true, errorNGoterosMsg: "Debe ser un valor numérico."}));
                inputIds = inputIds ? inputIds +",nGoteros" : "nGoteros";
                valid = false;
            }
        }if(state.caudalGotero === undefined || state.caudalGotero === ''){
            setErrors(errors => ({...errors, errorCaudalGotero: true, errorCaudalGoteroMsg: ""}));
            inputIds = inputIds ? inputIds +",caudalGotero" : "caudalGotero";
            valid = false;
        }else if(!(checkDoublePattern(state.caudalGotero))){
            setErrors(errors => ({...errors, errorCaudalGotero: true, errorCaudalGoteroMsg: "Debe ser un valor numérico."}));
            inputIds = inputIds ? inputIds +",caudalGotero" : "caudalGotero";
            valid = false;
        }
        if(state.superficieSector === undefined || state.superficieSector === ''){
            setErrors(errors => ({...errors, errorSuperficieSector: true, errorSuperficieSectorMsg: ""}));
            inputIds = inputIds ? inputIds +",superficieSector" : "superficieSector";
            valid = false;
        }else if(!(checkDoublePattern(state.superficieSector))){
            setErrors(errors => ({...errors, errorSuperficieSector: true, errorSuperficieSectorMsg: "Debe ser un valor numérico."}));
            inputIds = inputIds ? inputIds +",superficieSector" : "superficieSector";
            valid = false;
        }
        if(state.tipoEstrategia === undefined || state.tipoEstrategia === ''){
            setErrors(errors => ({...errors, errorTipoEstrategia: true, errorTipoEstrategiaMsg: "Debe elegir una estrategia de riego."}));
            inputIds = inputIds ? inputIds +",tipoEstrategia" : "tipoEstrategia";
            valid = false;
        }
        if(state.tipoEstrategia === "Riego deficitario sostenido (RDS)"){
            if(state.riegoACubrir === undefined || state.riegoACubrir === ''){
                setErrors(errors => ({...errors, errorRiegoACubrir: true, errorRiegoACubrirMsg: ""}));
                inputIds = inputIds ? inputIds +",riegoAcubrir" : "riegoACubrir";
                valid = false;
            }
        }
        if(inputIds !== ""){
            try{
                let elem = document.getElementById(inputIds.split(",")[0]);
                elem.scrollIntoView({behavior: "smooth"});
                elem.focus();
            }catch(err){

            }
        }
        return valid;
    }
    
    return(
        <>
        <Box className={position !== 2 && 'hide'}>
            <h2>Características de la Red de Riego</h2>
                
                <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                        <InputLabel htmlFor="caudalGotero">Caudal Nominal del Gotero (litros/hora)*</InputLabel>
                        <Input
                            id="caudalGotero"
                            className="t4b-finca-sector-inputs"
                            label="Caudal Nominal del gotero"
                            value={state.caudalGotero}
                            onChange={(e) => handleChangeCaudalGotero(e.target.value)}
                            required
                            name="caudalGotero"
                            error={errors.errorCaudalGotero}
                        />
                        {errors.errorCaudalGoteroMsg && <div className="errorInput">{errors.errorCaudalGoteroMsg}</div>}
                </FormControl>
                <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                        <InputLabel htmlFor="superficieSector">Superficie del Sector (hectáreas)*</InputLabel>
                        <Input
                            id="superficieSector"
                            className="t4b-finca-sector-inputs"
                            label="Superficie Sector"
                            value={state.superficieSector}
                            onChange={(e) => handleChangeSuperficieSector(e.target.value)}
                            required
                            name="superficieSector"
                            error={errors.errorSuperficieSector}
                        />
                        {errors.errorSuperficieSectorMsg && <div className="errorInput">{errors.errorSuperficieSectorMsg}</div>}
                </FormControl>

                <FormControl sx={{width: "100%", marginBottom: "20px"}} className="radio-green" variant="standard">
                    <FormLabel htmlFor="tipoRiego">Sistema de Riego por Goteo*</FormLabel>
                    <RadioGroup
                        name="tipoRiego"
                        id="tipoRiego"
                        value={state.tipoRiego}
                        onChange={(e) => handleTipoRiego(e.target.value)}
                        error={errors.errorTipoRiego ? "true" : ""}
                    >
                        <FormControlLabel value="Goteros equidistantes" control={<Radio />} label="Goteros equidistantes" />
                        <FormControlLabel value="Goteros por árbol" control={<Radio />} label="Goteros por árbol" />
                    </RadioGroup>
                    {errors.errorTipoRiegoMsg && <div className="errorMsg">{errors.errorTipoRiegoMsg}</div>}
                </FormControl>
                {state.tipoRiego === "Goteros equidistantes" && (
                <>
                    <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="nRamales">Número de ramales por línea de cultivo*</InputLabel>
                            <Input
                                id="nRamales"
                                className="t4b-finca-sector-inputs"
                                label="Número Ramales"
                                value={state.nRamales}
                                onChange={(e) => handleChangeNRamales(e.target.value)}
                                required
                                name="nRamales"
                                error={errors.errorNRamales}
                            />
                            {errors.errorNRamalesMsg && <div className="errorInput">{errors.errorNRamalesMsg}</div>}
                    </FormControl>
                    <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="distanciaGoteros">Distancia entre goteros (metros)*</InputLabel>
                            <Input
                                id="distanciaGoteros"
                                className="t4b-finca-sector-inputs"
                                label="Distancia entre gotero"
                                value={state.distanciaGoteros}
                                onChange={(e) => handleChangeDistanciaGoteros(e.target.value)}
                                required
                                name="distanciaGoteros"
                                error={errors.errorDistanciaGoteros}
                            />
                            {errors.errorDistanciaGoterosMsg && <div className="errorInput">{errors.errorDistanciaGoterosMsg}</div>}
                    </FormControl>
                </>
                )}
                {state.tipoRiego === "Goteros por árbol" && (
                    <>
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="nGoteros">Número de Goteros por Árbol*</InputLabel>
                            <Input
                                id="nGoteros"
                                className="t4b-finca-sector-inputs"
                                label="Número de Goteros"
                                value={state.nGoteros}
                                onChange={(e) => handleChangeNGoteros(e.target.value)}
                                required
                                name="nGoteros"
                                error={errors.errorNGoteros}
                            />
                            {errors.errorNGoterosMsg && <div className="errorInput">{errors.errorNGoterosMsg}</div>}
                        </FormControl>
                    </>
                )}
                
                <h2>Estrategia de Riego</h2>
                
                <FormControl sx={{width: "100%", marginBottom: "20px"}} className="radio-green" variant="standard">
                    <FormLabel htmlFor="tipoEstrategia">Estrategia de Riego*</FormLabel>
                    <RadioGroup
                        name="tipoEstrategia"
                        id="tipoEstrategia"
                        value={state.tipoEstrategia}
                        onChange={(e) => handleChangeTipoEstrategia(e.target.value)}
                        error={errors.errorTipoEstrategia ? "true" : ""}
                    >
                        <FormControlLabel value="Necesidades completas (NC)" control={<Radio />} label="Necesidades completas (NC)" />
                        <FormControlLabel value="Riego deficitario sostenido (RDS)" control={<Radio />} label="Riego deficitario sostenido (RDS)" />
                        <FormControlLabel value="Riego deficitario controlado Cítricos (RDCc)" control={<Radio />} label="Riego deficitario controlado Cítricos (RDCc)" />
                        <FormControlLabel value="Riego deficitario controlado Olivar (RDCo)" control={<Radio />} label="Riego deficitario controlado Olivar (RDCo)" />
                    </RadioGroup>
                    {errors.errorTipoEstrategiaMsg && <div className="errorMsg">{errors.errorTipoEstrategiaMsg}</div>}
                </FormControl>
                {state.tipoEstrategia === "Riego deficitario sostenido (RDS)" && (
                <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                        <InputLabel htmlFor="riegoACubrir">Porcentaje de Necesidades de Riego a Cubrir*</InputLabel>
                        <Input
                            id="riegoAcubrir"
                            className="t4b-finca-sector-inputs"
                            label="% Necesidades de riego a cubrir"
                            value={state.riegoACubrir}
                            onChange={(e) => handleChangeRiegoACubrir(e.target.value)}
                            required
                            name="riegoACubrir"
                            error={errors.errorRiegoACubrir}
                        />
                        {errors.errorRiegoACubrirMsg && <div className="errorInput">{errors.errorRiegoACubrirMsg}</div>}
                </FormControl>
                )}

                <Stack spacing={2} direction="row" justifyContent="space-between" className="tic4bio-sectores-btns">
                    <button className="btn white" onClick={(e) => handlePrev(e)} >Anterior</button>
                    <button className="btn green" onClick={(e) => handleNext(e)} style={{margin: "16px 0"}}>Siguiente</button>
                </Stack>
        </Box>
            
            
        </>
    );
}

export default FormRedRiegoComponent;