import { useEffect, useState, Fragment } from "react";
import { useAuth } from "../context/Auth";
import { instance } from '../utils/axios-config.js';
import { Box, Card, CircularProgress } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import ModalInfo from '../components/ModalInfo';
import CarouselSlideSector from "../components/CarouselSlideSector";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { DETALLES_INFO } from '../utils/commons'



const SectorPage = () => {

    const { currentUser } = useAuth();
    const { id } = useParams();

    const [ loading, setLoading ] = useState(true);
    const [ open, setOpen ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ sector, setSector] = useState({});
    const [index, setIndex] = useState(0);

    useEffect(() => {
        loadSectorData();
    }, []);

    const loadSectorData = async () => {
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
        
            await instance.get(`/sectores/detalle/${id}`).then(function(resp) {
                setSector(resp.data);
                setLoading(false);
            }).catch(function(error){
                setOpen(true);
                setLoading(false);
                setError(true);
            });
        }catch(error){
            setOpen(true);
            setLoading(false);
            setError(true);
        }
    }

    return(
        <>
         <div className="tic4bio-home">
                <div>
                    <h1 className="tic4bio-home-title sectores">Detalles del Sector: </h1>
                </div>
                    {loading ? 
                        (
                            <Box p={3} align="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                {!error ? (
                                     <>
                                        {sector && 
                                            <>
                                                <div className="detalles-sector">
                                                    <p className="detalles-tipoCultivo">Cultivo <span>{sector.tipoCultivo?.charAt(0).toUpperCase() + sector.tipoCultivo?.slice(1).toLowerCase()}</span></p>
                                                    <p className="detalles-nombre">Nombre:<span>{sector.nombre}</span></p>
                                                </div>
                                                <div className="detalles-sector-secciones">
                                                    {sector.tipoPlantacion !== "Secano" &&
                                                        <>
                                                            <Link to={"/programacion-de-riego/" + id}>
                                                                <Card className="detalles-sector-priego">
                                                                    <p> PROGRAMACIÓN DE RIEGO </p>
                                                                </Card>
                                                            </Link>
                                                        </>
                                                    }
                                                    <Link to={"/lista-huellas/" + sector.fincaId}>
                                                        <Card className="detalles-sector-hagua">
                                                            <p> HUELLA DE AGUA </p>
                                                        </Card>
                                                    </Link>
                                                    <Link to={"/biodiversidad/" + sector.fincaId}>
                                                        <Card className="detalles-sector-bio">
                                                            <p> BIODIVERSIDAD </p>
                                                        </Card>
                                                    </Link>
                                                    <Link to={"/evolucion/" + sector.fincaId}>
                                                        <Card className="detalles-sector-evo">
                                                            <p> EVOLUCIÓN </p>
                                                        </Card>
                                                    </Link>
                                                </div>
                                            {/*<div className="carousel-container">
                                                <CarouselSlideSector
                                                    content={content}
                                                    body={detalles}
                                                />
                                            </div>*/}
                                            <div className="detalles-sector-container">
                                                <div className="detalles-sector-par">
                                                    <div className="detalles-sector-par-container-left">
                                                        <h2>Tipo de sector</h2>
                                                        <Card className="carousel-card-desktop">
                                                            <div className="carousel-properties" >
                                                                <p>Secano o Riego: <span>{sector.tipoPlantacion}</span></p>
                                                                <p>Nivel de digitalización: <span>{sector.tipoUsuario}</span></p>
                                                            </div>
                                                        </Card>                                                    
                                                    </div>
                                                
                                                    <div className="detalles-sector-par-container-right">
                                                        <h2>Características del Cultivo</h2>
                                                        <Card className="carousel-card-desktop">
                                                            <div className="carousel-properties">
                                                                <p>Marco de plantación (entre árbol): <span>{sector.distanciaArboles % 1 === 0 ? sector.distanciaArboles : sector.distanciaArboles?.toFixed(2)}<span> (Metros)</span></span></p>
                                                                <p>Marco de plantación (entre líneas):  <span>{sector.distanciaLineas % 1 === 0 ? sector.distanciaLineas : sector.distanciaLineas?.toFixed(2)}<span> (Metros)</span></span></p>
                                                                <p>Diámetro de copa: <span>{sector.diametroCopa % 1 === 0 ? sector.diametroCopa : sector.diametroCopa?.toFixed(2)}<span> (Metros)</span></span></p>
                                                                <p>Seto: <span>{sector.seto === 1 ? "Sí" : "No"}</span></p>
                                                                {sector.tipoPlantacion === "Secano" &&
                                                                    <p>Superficie del sector: <span>{sector.superficieSector % 1 === 0 ? sector.superficieSector : sector.superficieSector?.toFixed(2)}<span> (Hectárea/s)</span></span></p>
                                                                }
                                                            </div>
                                                        </Card>
                                                    </div>
                                                </div>
                                            
                                                {sector.tipoPlantacion === "Regadío" &&
                                                    <>
                                                        <div className="detalles-sector-par">
                                                            <div className="detalles-sector-par-container-left">
                                                                <h2>Características del sistema de riego</h2>
                                                                <Card className="carousel-card-desktop">
                                                                    <div className="carousel-properties" >
                                                                        <p>Sistema de riego por goteo: <span>{sector.tipoGoteo}</span></p>
                                                                        {sector.tipoGoteo === "Goteros por árbol" && <p>Nº de goteros por árbol:  <span>{sector.nGoteros % 1 === 0 ? sector.nGoteros: sector.nGoteros?.toFixed(2)}</span></p>}
                                                                        {sector.tipoGoteo === "Goteros equidistantes" &&
                                                                            <>
                                                                                <p>Nº de ramales por líneas de cultivo:  <span>{sector.nRamales}</span></p>
                                                                                <p>Distancia entre goteros:  <span>{sector.distanciaGoteros % 1 === 0 ? sector.distanciaGoteros: sector.distanciaGoteros?.toFixed(2)}<span> (Metros)</span></span></p>
                                                                            </>
                                                                        }
                                                                        
                                                                        <p>Caudal nominal del gotero: <span>{sector.caudalGotero % 1 === 0 ? sector.caudalGotero : sector.caudalGotero?.toFixed(2)}<span> (Litros/Hora)</span></span></p>
                                                                        <p>Superficie del sector: <span>{sector.superficieSector % 1 === 0 ? sector.superficieSector : sector.superficieSector?.toFixed(2)}<span> (Hectárea/s)</span></span></p>
                                                                    </div>
                                                                </Card>                                                    
                                                            </div>
                                                            <div className="detalles-sector-par-container-right">
                                                                <h2>Estrategia de riego</h2>
                                                                <Card className="carousel-card-desktop">
                                                                    <div className="carousel-properties" >
                                                                        <p>Estrategia: <span>{sector.estrategia}</span></p>
                                                                    </div>
                                                                </Card>
                                                            </div>
                                                        </div>
                                                        <div className="detalles-sector-par">
                                                            <div className="detalles-sector-par-container-left">
                                                                <h2>Características del Suelo</h2>
                                                                <Card className="carousel-card-desktop">
                                                                    <div className="carousel-properties" >
                                                                        {sector.texturaSuelo && 
                                                                            <>
                                                                                <p>Estrategia de captura de datos del suelo: <span>Textura del suelo</span></p>
                                                                                <p>Textura del Suelo: <span>{sector.texturaSuelo}</span></p>
                                                                            </>
                                                                        }
                                                                        {sector.opmp && 
                                                                            <>
                                                                                <p>Estrategia de captura de datos del suelo: <span>Manual</span></p>
                                                                                <p>Punto de marchitez permanente (PMP): <span>{sector.opmp % 1 === 0 ? sector.opmp : sector.opmp?.toFixed(2)} (m<sup>3</sup>/m<sup>3</sup>)</span></p>
                                                                                <p>Capacidad de Campo (CC): <span>{sector.occ % 1 === 0 ? sector.pcc : sector.occ?.toFixed(2)} (m<sup>3</sup>/m<sup>3</sup>)</span></p>
                                                                            </>
                                                                        
                                                                        }
                                                                        {sector.ptArena && 
                                                                            <>
                                                                                <p>Estrategia de captura de datos del suelo: <span>Análisis del suelo</span></p>
                                                                                <p>Porcentaje de Arcilla: <span>{sector.ptArcilla} %</span></p>
                                                                                <p>Porcentaje de Limo: <span>{sector.ptLimo} %</span></p>
                                                                                <p>Porcentaje de Arena: <span>{sector.ptArena} %</span></p>
                                                                                {sector.densidad && <p>Densidad aparente: <span>{sector.densidad} g/cm<sup>3</sup></span></p>}
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </Card>
                                                            </div>
                                                            <div className="detalles-sector-par-container-right">
                                                                <h2>Tarifa eléctrica y agua</h2>
                                                                <Card className="carousel-card-desktop">
                                                                    <div className="carousel-properties" >
                                                                        <p>Límite horas de riego Lunes - Viernes: <span>{sector.limiteLV} (HORAS)</span></p>
                                                                        <p>Límite horas de riego Sábado - Domingo: <span>{sector.limiteSD} (HORAS)</span></p>
                                                                            
                                                                        {sector.conductividad && 
                                                                            <>
                                                                                <p>Conductividad eléctrica del agua: <span>{sector.conductividad} (dS/m)</span></p>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </Card>
                                                            </div>
                                                        </div>
                                                        {sector.tipoUsuario === "Usuario estándar" &&
                                                            <div className="detalles-sector-par">
                                                                <div className="detalles-sector-par-container-left">
                                                                    <h2>Horas de riego aplicado al inicio</h2>
                                                                    <Card className="carousel-card-desktop">
                                                                        <div className="carousel-properties" >
                                                                            <p>Riego aplicado: <span>{sector.hRiegoAPlicadas} (HORAS)</span></p>
                                                                        </div>
                                                                    </Card>
                                                                </div>
                                                            </div>
                                                        }
                                                    </>
                                                }
                                            </div>
                                            </>
                                        }
                                    </>
                                )
                                :
                                (
                                    <p className="tic4bio-fincas-empty">Error al cargar los datos del Sector.</p>
                                )
                                }
                            </>
                        )
                    }
            </div>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={"¡Vaya! Parace que algo ha ido mal."} contentText={"Algo ha fallado al intentar recuperar el listado de fincas. Por favor, vuelve a intentarlo más tarde."}/>
    </>
    );
} 
export default SectorPage;