import { useEffect, useState, Fragment } from "react";
import { useAuth } from "../context/Auth";
import { instance } from '../utils/axios-config.js';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { Link, useParams,useLocation } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ModalInfo from '../components/ModalInfo';
import DeleteIcon from '@mui/icons-material/Delete'; 
import ModalConfirm from '../components/ModalConfirm';
import ActionMessage from "../components/ActionMessage";
import PPagination from "../components/Pagination";


const SectoresListPage = () => {

    const { currentUser } = useAuth();
    const { id } = useParams();
    const {state} = useLocation();

    const [ msg, setMsg ] = useState( state ? state : undefined);
    const [ sectores, setSectores ] = useState([]);
    const [ totalPages, setTotalPages ] = useState(1);
    const [ loading, setLoading ] = useState(true);
    const [ open, setOpen ] = useState(false);
    const [ finca, setFinca] = useState({});
    const [ openConfirm, setOpenConfirm ] = useState({
        state: false,
        finca: null
    });
    const [ errorConfirm, setErrorConfirm ] = useState(false);
    const [ error, setError ] = useState(false);

    useEffect(() => {
        loadPageData();
    }, []);

    const openModalConfirm = (sector) => {
        setOpenConfirm({state: true, finca: sector});
    }


    const loadPageData = async (startPage = 1) => {
        try {
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`};
            
            const [fincaResp, sectoresResp] = await Promise.all([
                instance.get(`/fincas/finca/${id}`),
                instance.get(`/sectores/list/${id}?page=${startPage}`)

            ]);
            setFinca(fincaResp.data[0]);
            setSectores(sectoresResp.data.sectores);
            setTotalPages(sectoresResp.data.pages !== 0 ? sectoresResp.data.pages : 1);
            setLoading(false);
        } catch (error) {
            setOpen(true);
            setLoading(false);
            setError(true);
        }
    }

    const deleteSector = async (idSector) => {
        let buildUrl = `/sectores/delete/${idSector}`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
        
            await instance.delete(buildUrl).then(function(resp) {
                setMsg({type: "action-delete", text: "Se ha eliminado la finca correctamente."});
                setSectores(sectores.filter((s) => {return s.id !== idSector}));
            }).catch(function(resp) {
                setErrorConfirm(true);
            }).finally(()=>{

            });
        }catch(error){
            setErrorConfirm(true);
        }
    }

   
    return(
        <>
         <div className="tic4bio-home">
                {msg &&
                    <ActionMessage msg={msg} setMsg={setMsg}/>
                }
                <h1 className="tic4bio-home-title mv">¡Bienvenido {currentUser.nombre}!</h1>
                <div className="button green" style={{maxWidth: "400px"}}>
                    <Link to={"/nuevosector/" + id}><AddCircleOutlineIcon className="add"/>CREAR NUEVO SECTOR</Link>
                </div>
                {loading ? (
                    <>
                    </>
                ) : (
                    <>
                        {!error && 
                            <div>
                                <div>
                                    <h1 className="tic4bio-sectores-nombreFinca "> {finca.nombre}</h1>
                                    <h2 className="tic4bio-sectores-localizacion">{finca.provincia}, {finca.municipio}</h2>
                                </div>
                            </div>
                        }
                    </>
                )}
                <div>
                    <h1 className="tic4bio-home-title sectores">Mis Sectores:</h1>
                </div>
                <ul className="tic4bio-sectores">
                    {loading ? 
                        (
                            <Box p={3} align="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                {error ? 
                                    <p className="tic4bio-unidades-empty">Se ha producido un error al recuperar el listado de sectores.</p>
                                    :
                                    <>
                                        {sectores.length > 0 ? (
                                            <>
                                                {sectores.map((sector, index) => {
                                                    return (
                                                        <Fragment key={"sectores-" + index}>
                                                            <li className="tic4bio-sector">
                                                            <Link className="details" to={"/sector/" + sector.id}>
                                                                <div>
                                                                    <p className="details-tipoCultivo">{sector.cultivo.charAt(0).toUpperCase() + sector.cultivo.slice(1).toLowerCase()}</p>
                                                                    <p className="details-nombre">{sector.nombre}</p>
                                                                    {/*<p className="details-hRiegoAplicadas">Horas de Riego Aplicadas: <span>{sector.hRiegoAplicadas}</span></p>*/}
                                                                    <p className="details-limiteDotacion">{sector.tipoUsuario_id === 1 ? "Usuario Estándar" : "Usuario 4.0"}</p>
                                                                </div> 
                                                            </Link>
                                                                <IconButton
                                                                    size="large"
                                                                    edge="start"
                                                                    className="delete"
                                                                    aria-label="Eliminar sector"
                                                                    style={{color: "#fa0000"}}
                                                                    onClick={() => {openModalConfirm(sector)}}
                                                                >
                                                                    <DeleteIcon />
                                                                </IconButton>
                                                            </li>
                                                        </Fragment>
                                                    )
                                                })}
                                            </>
                                            )
                                            :
                                            (
                                                <p className="tic4bio-fincas-empty">Aún no se ha añadido ningún sector.</p>
                                            )
                                        }
                                    </>
                                }
                            </>
                        )
                    }
                </ul>
                <Box sx={{mb: 6, mt: 4}} align="center">
                    <PPagination setLoading={setLoading} loadData={loadPageData} totalPages={totalPages}/>
                </Box>
            </div>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={"¡Vaya! Parace que algo ha ido mal."} contentText={"Algo ha fallado al intentar recuperar el listado de fincas. Por favor, vuelve a intentarlo más tarde."}/>
            <ModalInfo open={errorConfirm} setOpen={setErrorConfirm} contentTitle={"¡Vaya! Parace que algo ha ido mal."} contentText={"Algo ha fallado al intentar eliminar el sector. Por favor, vuelve a intentarlo más tarde."}/>
            <ModalConfirm open={openConfirm} setOpen={setOpenConfirm} func={deleteSector} contentTitle={"Eliminar " + openConfirm.finca?.nombre + "."} contentText={"¿Está seguro que desea eliminar este sector?"}/>
    </>
    );
} 
export default SectoresListPage;