const Ris = ({valorRis}) => {
    
    const positionPointer = (valor) =>{
        if (valor <= 0) {
            return 0;
        } else if (valor >= 2) {
            return 100;
        } else {
            return (valor / 2) * 100;
        }
    }

    return ( 
        <div className="chart-ris">
            <div className="chart-ris-text">
                <p>Déficit</p>
                <p>Adecuado</p>
                <p>Exceso</p>
            </div>
            <div className="chart-ris-numeric min">
                <p>0</p>
            </div>
            <div className="chart-ris-numeric one">
                <p>1</p>
            </div>
            <div className="chart-ris-numeric two">
                <p>1,2</p>
            </div>
            <div className="chart-ris-numeric max">
                <p>2</p>
            </div>
            <div className="chart-ris-box">
                <div className="chart-pointer" style={{
                    left: `${positionPointer(valorRis)}%`,
                }}>

                </div>
            </div>
        </div>
     );
}
 
export default Ris;