import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import vegetacion from "../../assets/Iconos_biodiversidad/vegetacion.png";
import vertebrados from "../../assets/Iconos_biodiversidad/vertebrados.png";
import invertebrados from "../../assets/Iconos_biodiversidad/invertebrados.png";
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { useState } from 'react';
import { convertShortDate } from '../../utils/commons';
import { Fragment } from 'react';


const ComunidadesBioCarousel = ({comunidades, anos, func}) => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = anos.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const buttonStyles = {
    color: '#80C606',
    '&:disabled': {
      color: "rgba(0, 0, 0, 0.26)"
    }
  };

  return (
    <Box sx={{ marginBottom: "30px"}}>
      <Box className="table-wrapper" sx={{ p: 2 }}>
        <table  className="t4b-biodiversidad-table">
            <thead>
                <tr className="">
                    <th style={{border: 0}}></th>
                    <th style={{border: 0, width: "30%"}}>Comunidades</th>
                    <th><img style={{width: "50px"}} src={vegetacion} alt={"Imagen vegetación"}/></th>
                    <th><img style={{width: "50px"}} src={vertebrados} alt={"Imagen vertebrados"}/></th>
                    <th><img style={{width: "50px"}} src={invertebrados} alt={"Imagen invertebrados"}/></th>
                </tr>
            </thead>
            <tbody>

              {comunidades.map((c, index) => (
                <tr key={"row_" + index}>
                  {index === 0 &&
                    <th rowSpan={comunidades.length}>{anos[activeStep]}</th>
                  }
                  <td>{c.nombre_comunidad}</td>

                  {c.biovegetales.filter(b => b.ano === anos[activeStep]).length > 0 ?
                    <>
                      {c.biovegetales.map((b, jindex) => (
                        <Fragment key={"row_veg_" + index + "_" + jindex}>
                          {anos[activeStep] === b.ano &&
                            <td><div onClick={() => func({...b, nombre_comunidad: c.nombre_comunidad, comunidad_id: c.id, finca_id: c.finca_id, tipoBio: "biovegetal"})} className={b.tipoAnalisis ? "basic" : ""}>{convertShortDate(b.fecha)}</div></td>
                          }
                        </Fragment>
                      ))}
                    </>
                    :
                    <>
                      <td></td>
                    </>
                  }

                  {c.biovertebrados.filter(b => b.ano === anos[activeStep]).length > 0 ?
                    <>
                      {c.biovertebrados.map((b, zindex) => (
                        <Fragment key={"row_ver_" + index + "_" + zindex}>
                          {anos[activeStep] === b.ano &&
                            <td><div onClick={() => func({...b, nombre_comunidad:c.nombre_comunidad, comunidad_id: c.id, finca_id: c.finca_id, tipoBio: "biovertebrado"})} className={b.tipoAnalisis ? "basic" : ""}>{convertShortDate(b.fecha)}</div></td>
                          }
                        </Fragment>
                      ))}
                    </>
                    :
                    <>
                      <td></td>
                    </>
                  }

                  {c.bioinvertebrados.filter(b => b.ano === anos[activeStep]).length > 0 ?
                    <>
                      {c.bioinvertebrados.map((b, yindex) => (
                        <Fragment key={"row_inv_" + index + "_" + yindex}>
                          {anos[activeStep] === b.ano &&
                            <td><div onClick={() => func({...b, nombre_comunidad:c.nombre_comunidad, comunidad_id: c.id, finca_id: c.finca_id, tipoBio: "bioinvertebrado"})} className={b.tipoAnalisis ? "basic" : ""}>{convertShortDate(b.fecha)}</div></td>
                          }
                        </Fragment>
                      ))}
                    </>
                    :
                    <>
                      <td></td>
                    </>
                  }
                </tr>
              ))}
            </tbody>
        </table>
      </Box>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
            style={buttonStyles}
          >
            Siguiente
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button style={buttonStyles} size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Anterior
          </Button>
        }
      />
    </Box>
  );
}

export default ComunidadesBioCarousel;