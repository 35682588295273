import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

function BarChartHASectoresTotalEvolucion({ startCampana, endCampana, campanas, data, finca }) {

  const chartRef = useRef(null);
  const navigate = useNavigate();
  const [ chartInfo, setChartInfo ] = useState();

  useEffect(() => {
    chooseOption();
  }, [startCampana, endCampana]); 

  useEffect(() => {
    const chart = chartRef.current;

    const handleClick = (event) => {
      
        const canvasPosition = {
            x: event.offsetX,
            y: event.offsetY,
        };

        const dataXLabels = chart.scales.x.ticks;
        
        // Calcular la posición de las etiquetas en el eje X
        dataXLabels.forEach((label, index) => {
            const xLabelPosition = chart.scales.x.getPixelForTick(index);
            const labelWidth = 100; // Ancho aproximado de la etiqueta

            // Verificar si el clic está dentro del rango de la etiqueta
            if (canvasPosition.x >= xLabelPosition - labelWidth / 2 && canvasPosition.x <= xLabelPosition + labelWidth / 2) {

                let fincaId;
                if(finca.fincas.length > 0){
                  fincaId = finca.fincas[0].id;
                }else{
                  fincaId = finca.almazaras[0].id
                }
                navigate(`/huella-unidades/${fincaId}`, {
                    state: { campana: label.label }
                });
            }
        });
    };

    if (chart) {
        chart.canvas.addEventListener('click', handleClick);
    }

    return () => {
        if (chart?.canvas) {
            chart.canvas.removeEventListener('click', handleClick);
        }
    };
  }, [chartRef, chartInfo]);

  const backgroundColors = [
    'rgba(140, 228, 46, 0.8)',
    'rgba(122,154,200, 0.8)',
  ];

  const borderColors = [
    'rgba(140, 228, 46, 1)',
    'rgba(122,154,200, 1)',
  ];

  const generateCampanas = () => {
    const indiceInicio = campanas.indexOf(startCampana);
    const indiceFin = campanas.indexOf(endCampana);

    return campanas.slice(indiceInicio, indiceFin + 1);
  }

  const generateDataSets = (subData) => {

    const campanasData = Object.values(subData).map(campana => {
      const huellaAguaVerdeTotal = campana.fincas.reduce((total, sector) => total + sector.huellaAguaVerde, 0);
      const huellaAguaAzulTotal = campana.fincas.reduce((total, sector) => total + sector.huellaAguaAzul, 0);
      return { nombre: campana.campana, huellaAguaVerdeTotal, huellaAguaAzulTotal };
    });

    const result = [
      {
        label: 'Huella Agua Verde',
        data: campanasData.map(campana => campana.huellaAguaVerdeTotal),
        backgroundColor: backgroundColors[0],
        borderColor: borderColors[0],
        borderWidth: 1,
      },
      {
        label: 'Huella Agua Azul',
        data: campanasData.map(campana => campana.huellaAguaAzulTotal),
        backgroundColor: backgroundColors[1],
        borderColor: borderColors[1],
        borderWidth: 1,
      }
    ];

    return result;
  }

  const chooseOption = () => {
    let subData;

    subData = data.unidad.infoUnidad;

    const labelsCampanas = generateCampanas();

    const filteredData = Object.keys(subData)
    .filter((key) => labelsCampanas.includes(key))
    .reduce((obj, key) => {
      obj[key] = subData[key];
      return obj;
    }, {});

    let someData = Object.values(filteredData).some(c => {

      if(c.fincas.length !== 0){
        return true;
      }
      return false;
    });
    
    if(someData){

      const info = {
        labels: labelsCampanas,
        datasets: generateDataSets(filteredData)
      }

      setChartInfo(info);
    }else{
      setChartInfo();
    }

  }

  const options = {
    layout: {
      padding: {
          top: 0,
          bottom: 100,
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x:{
        stacked: true
      },
      y: {
        stacked: true,
        type: 'logarithmic',
        beginAtZero: true,
        ticks: {
          maxTicksLimit: 5,
          callback: function(value, index, values) {
            return Number(value.toString());
          }
        }
      },
    },
  };

    return (
      <>
        {chartInfo &&
          <>
              <div>
                <h3 style={{textAlign: "center", textDecoration: "underline", color: "#80C606", fontSize: "26px", marginBottom: "40px"}}>Huella Agua (l agua/kg aceituna)</h3>
                <div style={{minHeight: "600px"}}>
                  <Bar data={chartInfo} options={options} ref={chartRef}/>
                </div>
              </div>
          </>
        }
        {!chartInfo &&
          <p>No existen sectores con huellas de agua creadas para mostrar en el <b>gráfico de evolución de agua azul y verde por campaña.</b></p>
        }
      </>
    );
}

export default BarChartHASectoresTotalEvolucion;