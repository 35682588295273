import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { Link } from "react-router-dom";
import ModalDeleteConfirm from "./ModalDeleteConfirm";
import { useState } from "react";
import { convertShortDate } from "../../utils/commons";

const ModalOptionsBio = ({open, setOpen, item, func}) => {

    const [openDeleteConfirm, setOpenDeleteConfirm] = useState({
        state: false,
        element: null
    });
    
    const handleClose = () => {
        setOpen(false);
    };

    const dialogStyle = {
        width: '380px',
      };

    return ( 
        <>
        <Dialog PaperProps={{ style: dialogStyle }} open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                Biodiversidad de {item?.nombre_comunidad} del {convertShortDate(item?.fecha)}.
            </DialogTitle>
            <DialogContent>
                <div className="button green" style={{maxWidth: "400px"}} >
                    {item.tipoBio === "bioinvertebrado" &&
                        <Link to={"/biodiversidad/invertebrados/detalles/" + item.id} state={{comunidadId: item.comunidad_id, fincaId: item.finca_id}}>Resultado</Link>
                    }
                    {item.tipoBio === "biovertebrado" &&
                        <Link to={"/biodiversidad/vertebrados/detalles/" + item.id} state={{comunidadId: item.comunidad_id, fincaId: item.finca_id}}>Resultado</Link>
                    }
                    {item.tipoBio === "biovegetal" &&
                        <Link to={"/biodiversidad/vegetal/detalles/" + item.id} state={{comunidadId: item.comunidad_id, fincaId: item.finca_id}}>Resultado</Link>
                    }
                </div>
                <div className="button green" style={{maxWidth: "400px"}} >
                    {item.tipoBio === "bioinvertebrado" &&
                        <Link to={"/biodiversidad/invertebrados/editar/" + item.id}>Editar Datos</Link>
                    }
                    {item.tipoBio === "biovertebrado" &&
                        <Link to={"/biodiversidad/vertebrados/editar/" + item.id}>Editar Datos</Link>
                    }
                    {item.tipoBio === "biovegetal" &&
                        <Link to={"/biodiversidad/vegetal/editar/" + item.id}>Editar Datos</Link>
                    }
                </div>
                <button className="btn red" onClick={() => setOpenDeleteConfirm({
                    state: true,
                    element: item
                })} style={{maxWidth: "400px"}}>
                    Eliminar
                </button>
            </DialogContent>
            <DialogActions>
                <button className="btn green" onClick={handleClose}>Cerrar</button>
            </DialogActions>
        </Dialog>
        <ModalDeleteConfirm open={openDeleteConfirm} setOpen={setOpenDeleteConfirm} func={func} contentTitle={"Eliminar " + convertShortDate(openDeleteConfirm.element?.fecha) + "."} contentText={"¿Está seguro que desea eliminar este elemento de biodiversidad?"}/>
        </>
     );
}
 
export default ModalOptionsBio;