import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const ActionMessage = ({msg, setMsg}) => {

    const location = useLocation();
    const navigate = useNavigate();

    const handleClose = () => {
        setMsg();
        navigate(location.pathname, { replace: true });
    }

    return(
        <div className={msg.type + " " + (msg ? "" : "hide")}>
            <span>{msg.text}</span>
            <IconButton
                size="large"
                edge="start"
                aria-label="Cerrar mensaje"
                onClick={handleClose}
                style={{color: "#fff"}}>
                <CloseIcon style={{color: "#fff"}} />
            </IconButton>
        </div>
    );
}

export default ActionMessage;