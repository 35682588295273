import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {AppBar, Box, IconButton, Toolbar} from "@mui/material";
import retroceder from "../../assets/retroceder.svg";
import { CONFIG } from "../../utils/pages-config";
import { Fragment, useState } from "react";
import LateralMenu from "../../components/header/LateralMenu";
import logotic4bio from '../../assets/lateralmenu/logoheadbio.png';
import logoJunta from '../../assets/logo-juntaAndalucia.png';
import logoEU from '../../assets/logo-UE.png';
import { useAuth } from "../../context/Auth";
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';


const Header = (props) => {

    const bPages = CONFIG.pages.find((f) => f.slug === props?.page);
    const { logout } = useAuth();
    const [ isDrawerOpen, setIsDrawerOpen ] = useState(false);
    const locate = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();

    const renderBreabcrumb = (item, i, total) =>
    item.to ? (
        <Fragment key={item.title + "-" + i}>
            {locate.pathname !== "/home" &&
                <IconButton
                    size="large"
                    edge="start"
                    aria-label="Retroceder"
                    onClick={() => {
                        if(item?.id){
                            navigate(item.to + "/" + id);
                        }else{
                            navigate(item.to);
                        }}
                    }
                    style={{color: "#fff"}}>
                    <img src={retroceder} alt="Retroceder" className="t4b-logo"/>
                </IconButton>
            }
            {i === 0 ? 
                <span>
                    {item.title}
                </span>
            :
                <Link to={item.to}>
                    {item.title}
                </Link>
            }
            
            <span>{i === (total - 1) ? "" : " / "}</span>
        </Fragment>
    ) : (
        <Fragment key={item.title + "-" + i}>
            <span>
                {item.title}
            </span>
            <span>{i === (total - 1) ? "" : " / "}</span>
        </Fragment>
    );

    const handleLogout = async (event) => {
        event.preventDefault();
        logout();
        navigate("/login");
    };

    return ( 
        <AppBar position="static" className="header-bar">
            <LateralMenu open={isDrawerOpen} setOpen={setIsDrawerOpen}/>
            <Toolbar className="toolbar">
                <div className="toolbar-left">
                    <img src={logotic4bio} alt="Logo TIC4BIO"/>
                </div>
                </Toolbar>
            <Toolbar className="toolbarsnd">
                <Box className="toolbarsnd-items">
                    {bPages?.breadcrumbs?.length > 0 &&
                        <Box className="toolbar-breadcrumbs">
                            {bPages?.breadcrumbs?.length > 0 && bPages.breadcrumbs.map((b,i) => renderBreabcrumb(b,i, bPages.breadcrumbs.length))}
                        </Box>
                    }
                    <Box className="dsk">
                        <IconButton
                            size="large"
                            edge="start"
                            aria-label="Retroceder"
                            onClick={handleLogout}
                            style={{color: "#fff"}}>
                            <LogoutIcon style={{color: "#fff"}} />
                        </IconButton>
                    </Box>
                    <Box className="mv">
                        <IconButton
                            size="large"
                            edge="start"
                            aria-label="Abrir menú lateral"
                            style={{color: "#fff"}}
                            onClick={() => {setIsDrawerOpen(true)}}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Toolbar>
        </AppBar>
    );
}
 
export default Header;