import React from 'react';
import { Radar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';

function RadarChart({ infoData }) {

  ChartJS.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Filler,
    Tooltip,
    Legend
  );

  const options = {
    layout: {
      padding: {
          top: 0,
          bottom: 100,
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      r: {
        pointLabels: {
          font: {
            size: 14,
            weight: 600
          }
        }
      }
    },
    plugins: {
      legend: {
          display: false
      },
    }
  };

    return (
      <div style={{maxWidth: "800px", margin: "0 auto", minHeight: "600px"}}>
        <Radar data={infoData} options={options} />
      </div>
    );
}

export default RadarChart;