import lavadoraicon from "../../assets/almazara_icons/lavadora.png";
import duchaicon from "../../assets/almazara_icons/ducha.png";
import molinoicon from "../../assets/almazara_icons/molino.png";
import batidoraicon from "../../assets/almazara_icons/batidora.png";
import decantericon from "../../assets/almazara_icons/decanter.png";
import centrifugadoraicon from "../../assets/almazara_icons/centrifugadora.png";
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { checkDoublePattern } from "../../utils/commons";

const InputConsumoComponent = ({type, state, setState, errors }) => {

    const ImagenSelector = (option) => {
        let imagen;
        // Determina qué imagen mostrar según el valor de la variable "opcion"
        if (option.opcion === 'lavadora') {
            imagen = <img src={lavadoraicon} alt="Icono Lavadora" />;
        } else if (option.opcion === 'ducha') {
            imagen = <img src={duchaicon} alt="Icono Ducha" />;
        } else if(option.opcion === 'molino'){
            imagen = <img src={molinoicon} alt="Icono Molino" />;
        } else if(option.opcion === 'batidora'){
            imagen = <img src={batidoraicon} alt="Icono Batidora" />;
        }else if(option.opcion === 'decanter'){
            imagen = <img src={decantericon} alt="Icono Decanter" />;
        } else if(option.opcion === 'centrifuga'){
            imagen = <img src={centrifugadoraicon} alt="Icono Centrifugadora" />;
        }
        return imagen;
    }

    const handleChangeConsumo = (v) => {
        if(checkDoublePattern(v)){
            setState(prevState => ({
                ...prevState,
                equipos: {
                  ...prevState.equipos,
                  [type]: {
                    ...prevState.equipos[type],
                    consumo: v,
                  },
                },
              }));
        }
    }

    const handleChangeConsumoUnidad = (v) => {
        setState(prevState => ({
            ...prevState,
            equipos: {
                ...prevState.equipos,
                [type]: {
                ...prevState.equipos[type],
                unidad: v,
                },
            },
            }));
    }

    return(
        <table border="1" className="t4b-table">
            <tbody>
                <tr>
                    <td className="cell-image">
                        <p>{type}</p>
                        <ImagenSelector opcion={type} />
                    </td>
                    <td>
                        <FormControl sx={{width: "100%"}} variant="standard">
                            <TextField
                                className="t4b-finca-sector-inputs no-red-label"
                                label="Consumo de agua*"
                                id={type + "-consumoAgua"}
                                type="number"
                                variant="standard"
                                InputProps={{
                                    inputProps: { 
                                        max: 99999, min: 0,
                                        step: 0.5,
                                    }
                                }}
                                name={type + "-consumoAgua"}
                                value={state.equipos[type].consumo}
                                onChange={(e) => handleChangeConsumo(e.target.value)}
                                error={errors["error" + type + "consumo"]}
                            />
                            {errors["error" + type + "consumoMsg"] && <div className="errorInput">{errors["error" + type + "consumoMsg"]}</div>}
                        </FormControl>
                    </td>
                    <td>
                        <FormControl sx={{width: "100%"}} variant="standard">
                            <InputLabel htmlFor={type + "unidad"}>Unidad*</InputLabel>
                            <Select
                                className="t4b-finca-sector-inputs"
                                id={type + "-unidad"}
                                label="Unidad*"
                                required
                                MenuProps={{ disableScrollLock: true }}
                                value={state.equipos[type].unidad}
                                onChange={(e) => { handleChangeConsumoUnidad(e.target.value) }}
                                error={errors["error" + type + "unidad"]}
                                >
                                <MenuItem value="l/min">
                                    L/min
                                </MenuItem>
                                <MenuItem value="l/h">
                                    L/h
                                </MenuItem>
                                <MenuItem value="l/dia">
                                    L/día
                                </MenuItem>
                            </Select>
                            {errors["error" + type + "UnidadMsg"] && <div className="errorInput">{errors["error" + type + "unidadMsg"]}</div>}
                        </FormControl> 
                    </td>
                </tr>
            </tbody>
        </table>
    );
}
export default InputConsumoComponent;