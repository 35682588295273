import React from 'react';
import { Link } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Alert from '@mui/material/Alert';


const Mensajes = ({ type }) => {
  
  const renderErrorUnidades = () => (
     <> <Alert severity="error">No se han encontrado Unidades Productivas. Primero debe tener alguna creada. </Alert>
       <Link to="/nuevaUnidadProductiva"><button type="button" className="btn green" value="finca"> <AddCircleOutlineIcon className="add" /> Crear Unidad</button>
       </Link>
     </>
 );


 const renderErrorIndustria = () => (
    <> <Alert severity="error">No tiene ninguna industria/almazara creada. Para crear una huella de agua, debe tener al menos una asociada. </Alert>
      <Link to="/nuevaUnidadProductiva"><button type="button" className="btn green" value="finca"> <AddCircleOutlineIcon className="add" /> Crear Industria</button>
      </Link>
    </>
);

const renderErrorComunidades = () => (
    <> <Alert severity="error">No tiene ninguna finca. Para ver, crear o editar las comunidades vegetales, primero debe tener una finca creada </Alert>
      <Link to="/nuevaUnidadProductiva"><button type="button" className="btn green" value="finca"> <AddCircleOutlineIcon className="add" /> Crear Finca</button>
      </Link>
    </>
);

const renderErrorVegetal = () => (
    <> <Alert severity="error">No tiene ninguna finca creada. Para crear un nuevo cálculo de biodiversidad, debe tener al menos una asociada. </Alert>
      <Link to="/nuevaUnidadProductiva"><button type="button" className="btn green" value="finca"> <AddCircleOutlineIcon className="add" /> Crear Finca</button>
      </Link>
    </>
);

 
   
  return (
    <div>
      {type === 'unidades' && renderErrorUnidades()}        
      {type === 'industria' && renderErrorIndustria()}          
      {type === 'comunidades' && renderErrorComunidades()}
      {type === 'vegetal' && renderErrorVegetal()}  
    </div>
  );
};

export default Mensajes;
