import React from 'react'
import LateralNav from './LateralNav';

export default function MainComponent({children}) {

    return (
        <main>
            <LateralNav />
            {children}
        </main>
    )
}
