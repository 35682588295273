import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

function BarChartEvolucion({ bioStartYear, bioEndYear, option, data, finca }) {

  const chartRef = useRef(null);
  const navigate = useNavigate();
  const [ chartInfo, setChartInfo ] = useState();
  const [state, setState] = useState({
    nIndividuos: true,
    nEspecies: true,
    indiceDiversidadSimpson: true,
    indicePielou: true,
  });

  useEffect(() => {
    chooseOption();
  }, [state, option, bioStartYear, bioEndYear]); 

  useEffect(() => {
    const chart = chartRef.current;

    const handleClick = (event) => {
        const canvasPosition = {
            x: event.offsetX,
            y: event.offsetY,
        };

        const dataXLabels = chart.scales.x.ticks;
        const scaleY = chart.scales.y;
        
        // Calcular la posición de las etiquetas en el eje X
        dataXLabels.forEach((label, index) => {
            const xLabelPosition = chart.scales.x.getPixelForTick(index);
            const labelWidth = 100; // Ancho aproximado de la etiqueta

            // Verificar si el clic está dentro del rango de la etiqueta
            if (canvasPosition.x >= xLabelPosition - labelWidth / 2 && canvasPosition.x <= xLabelPosition + labelWidth / 2) {

              if(option === "vegetal"){
                navigate(`/biodiversidad/vegetal/detalles`, {
                  state: { comunidadId: "FINCA COMPLETA", fincaId: finca.id, year: label.label }
                });
              }else if(option === "vertebrados"){
                navigate(`/biodiversidad/vertebrados/detalles`, {
                  state: { comunidadId: "FINCA COMPLETA", fincaId: finca.id, year: label.label }
                });
              }else{
                navigate(`/biodiversidad/invertebrados/detalles`, {
                  state: { comunidadId: "FINCA COMPLETA", fincaId: finca.id, year: label.label }
                });
              }
            }
        });
    };

    if (chart) {
        chart.canvas.addEventListener('click', handleClick);
    }

    return () => {
        if (chart?.canvas) {
            chart.canvas.removeEventListener('click', handleClick);
        }
    };
  }, [chartRef, chartInfo]);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });


  };

  const generateYears = (sYear, eYear) => {
    const list = [];
    for (let i = sYear; i <= eYear; i++) {
      list.push(i);
    }
    return list;
  }

  const generateDataSets = (subData) => {
    let labels = [];
    if(data[option].tipoAnalisis === 0){
      if(state.nEspecies){
        labels.push({key: 'riquezaEspecifica', valor: 'S: Nº de especies', color: 'rgba(122,154,200, 0.8)', borderColor: 'rgba(122,154,200, 1)'});
      }
      if(state.nIndividuos){
        labels.push({key: 'numEspeciesTotales', valor: 'N: Nº total de individuos', color: 'rgba(128, 198, 6, 0.8)', borderColor: 'rgba(128, 198, 6, 1)'});
      }
      if(state.indiceDiversidadSimpson){
        labels.push({key: 'indiceDiversidadSimpson', valor: '1-D: Índice diversidad de Simpson', color: 'rgba(140, 228, 46, 0.8)', borderColor: 'rgba(140, 228, 46, 1)'});
      }
      if(state.indicePielou){
        labels.push({key: 'indicePielou', valor: 'J’: Índice de equidad de Pielou', color: 'rgba(255, 99, 132, 0.8)', borderColor: 'rgba(255, 99, 132, 1)'});
      }
      //labels = [{key: 'riquezaEspecifica', valor: 'S: Nº de especies'}, {key: 'numEspeciesTotales', valor: 'N: Nº total de individuos'}, {key: 'indiceDiversidadSimpson', valor: '1-D: Índice diversidad de Simpson'}, {key: 'indicePielou', valor: 'J’: Índice de equidad de Pielou'}];
    }else{
      labels.push({key: 'riquezaEspecifica', valor: 'S: Nº de especies'});
    }
    const result = labels.map((label, index) => ({
      label: label.valor,
      data: Object.values(subData).map((yearData) => yearData[label.key]),
      backgroundColor: label.color,
      borderColor: label.borderColor,
      borderWidth: 1,
    }));
    return result;
  
  }

  const chooseOption = () => {
    let subData;
    if(option === "vegetal"){
      subData = data.vegetal.groupByYears;
    }else if(option === "invertebrados"){
      subData = data.invertebrados.groupByYears;
    }else{
      subData = data.vertebrados.groupByYears;
    }

    const labelsYears = generateYears(bioStartYear, bioEndYear);

    const filteredData = Object.keys(subData)
    .filter((key) => labelsYears.includes(+key))
    .reduce((obj, key) => {
      obj[key] = subData[key];
      return obj;
    }, {});

    let someData = Object.values(filteredData).some(c => c.riquezaEspecifica);
    
    if(someData){

      const info = {
        labels: labelsYears,
        datasets: generateDataSets(subData)
      }

      setChartInfo(info);
  }else{
    setChartInfo();
  }

  }

  const options = {
    layout: {
      padding: {
          top: 0,
          bottom: 100,
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        type: 'logarithmic',
        beginAtZero: true,
        ticks: {
          maxTicksLimit: 5,
          callback: function(value, index, values) {
            return Number(value.toString());
          }
        }
      }
    },
    onClick: (event, elements) => {
      if (elements.length > 0) {
        /*const elementIndex = elements[0].index;
        const label = data[option][elementIndex];
        const urlMap = {
          'Red': 'https://www.example.com/red',
          'Blue': 'https://www.example.com/blue',
          'Yellow': 'https://www.example.com/yellow',
          'Green': 'https://www.example.com/green',
          'Purple': 'https://www.example.com/purple',
          'Orange': 'https://www.example.com/orange',
        };
        window.open(urlMap[label], '_blank');*/
      }
    },
  };

    return (
      <>
        {chartInfo &&
          <>
              <h3 style={{textAlign: "center", textDecoration: "underline", color: "#80C606", fontSize: "26px", marginBottom: "40px"}}>Diversidad {option}</h3>
              <div style={{minHeight: "600px"}}>
                <Bar data={chartInfo} options={options} ref={chartRef}/>
              </div>
              {data[option].tipoAnalisis === 0 && 
                <FormControl component="fieldset">
                  <FormLabel component="legend">Opciones de filtrado</FormLabel>
                  <FormGroup>
                    <FormControlLabel
                        control={
                          <Checkbox
                            checked={state.nEspecies}
                            onChange={handleChange}
                            name="nEspecies"
                          />
                        }
                        label="S: Nº de especies"
                    />
                
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.nIndividuos}
                          onChange={handleChange}
                          name="nIndividuos"
                        />
                      }
                      label="N: Nº total de individuos"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.indiceDiversidadSimpson}
                          onChange={handleChange}
                          name="indiceDiversidadSimpson"
                        />
                      }
                      label="1-D: Índice diversidad de Simpson"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.indicePielou}
                          onChange={handleChange}
                          name="indicePielou"
                        />
                      }
                      label="J’: Índice de equidad de Pielou"
                    />
                  </FormGroup>
                </FormControl>
              }
          </>
        }
        {!chartInfo &&
          <p>No existe información para mostrar para el <b>gráfico de evolución de diversidad {option}.</b></p>
        }
      </>
    );
}

export default BarChartEvolucion;