const IndiceBiodiversidadVegetal = ({value, maxValue, minValue, texto, riqEsp}) => {
    
    const positionPointer = (v) =>{
        if(riqEsp){
            maxValue = parseFloat(Math.log(riqEsp).toFixed(2));
        }

        if (v <= minValue) {
            return 0;
        } else if (v >= maxValue) {
            return 100;
        } else {
            return (v * 100) / maxValue;
        }
    }

    return ( 
        <div className="chart-indice">
            <div className="chart-indice-text">
                <p>{texto} = {value.toFixed(2)}</p>
            </div>
            <div className="chart-indice-numeric min">
                <p>{minValue}</p>
            </div>
            <div className="chart-indice-numeric max">
                {riqEsp ? 
                    <p>{Math.log(riqEsp).toFixed(2)}</p>
                    :
                    <p>{maxValue}</p>
                }
            </div>
            <div className={`chart-indice-box ${texto === "ÍNDICE DE DOMINANCIA DE SIMPSON" ? "inverted" : ""}`}>
                <div className="chart-pointer" style={{
                    left: `calc(${positionPointer(value)}% - 10px)`,
                }}>
                </div>
            </div>
        </div>
     );
}
 
export default IndiceBiodiversidadVegetal;