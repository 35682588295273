import  {Box, FormControl, IconButton, Input, InputAdornment, InputLabel} from '@mui/material';
import { useState } from 'react';
import HeaderOutNavLogin from '../components/headerOutNavLogin';
import LoadingBackdrop from '../components/LoadingBackdrop';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import OjoAbierto from "../assets/ojo-abierto.svg";
import OjoCerrado from "../assets/ojo-cerrado.svg";
import LockIcon from '@mui/icons-material/Lock';
import KeyIcon from '@mui/icons-material/Key';
import logotic4bio from '../assets/lateralmenu/logoheadbio.png';
import logoue from '../assets/logo-UE.png';
import logojunta from '../assets/logo-juntaAndalucia.png';
import Footer from '../components/footer/Footer';
import { Link, useSearchParams } from 'react-router-dom';
import { instance } from '../utils/axios-config';
import ModalInfo from '../components/ModalInfo';

const ResetPasswordPage = () => {

    const [ searchParams, setSearchParams ] = useSearchParams();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [errorSend, setErrorSend] = useState("");
    const [completed, setCompleted] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordRpe, setShowPasswordRpe] = useState(false);
    const [errors, setErrors] = useState({});
    const [ formData, setFormData ] = useState({
        username:  searchParams.get('email') ? searchParams.get('email') : "",
        password: "",
        passwordRpe: "",
        code: ""
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors({});
        if(validateForm()){
            sendForm();
        }
    };

    const handleClickShowPassword = (event) => {
        event.preventDefault();
        setShowPassword((show) => !show)
    };

    const handleClickShowPasswordRpe = (event) => {
        event.preventDefault();
        setShowPasswordRpe((show) => !show)
    };

    const handleHideErrors = () => {
        setErrors(errors => ({...errors, errorUserMsg: null, errorEmailMsg: null, errorPasswordMsg: null, errorPasswordRpeMsg: null, errorCodeMsg: null}));
    };

    const validateForm = () =>{
        let valid = true;
        if(formData.username === undefined || formData.username === ''){
            setErrors(errors => ({...errors, errorEmail: true, errorEmailMsg: "El email no debe estar vacío"}));
            valid = false;
        }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.username)){
            setErrors(errors => ({...errors, errorEmail: true, errorEmailMsg: "El email no es válido"}));
            valid = false;
        }
        if(formData.password === undefined || formData.password === ''){
            setErrors(errors => ({...errors, errorPassword: true, errorPasswordMsg: "La contraseña no puede estar vacía"}));
            valid = false;
        }else if(formData?.password.length < 3){
            setErrors(errors => ({...errors, errorPassword: true, errorPasswordMsg: "La contraseña debe ser de al menos 3 dígitos"}));
            valid = false;
        }
        if(formData.passwordRpe !== formData.password && (formData.password !== "" || formData.passwordRpe !== "")){
            setErrors(errors => ({...errors, errorPasswordRpe: true, errorPasswordRpeMsg: "Las contraseñas no coinciden"}));
            valid = false;
        }
        if(formData.code === undefined || formData.code === ""){
            setErrors(errors => ({...errors, errorCode: true, errorCodeMsg: "El código no puede estar vacío"}));
            valid = false;
        }else if(formData?.code.length !== 6){
            setErrors(errors => ({...errors, errorCode: true, errorCodeMsg: "El código debe contener 6 dígitos"}));
            valid = false;
        }
        return valid;
    }

    const sendForm = async () => {
        setLoading(true);
        try {
            await instance.post(`/users/restorePassword/`, formData).then(() => {
                setCompleted(true);
            }).catch(err => {
                if(err.response.status === 400){
                    setErrorSend("Error en la petición. El código introducido no es correcto, vuelve a intentarlo.");
                }else if(err.response.status === 404){
                    setErrorSend("Error en la petición. No se ha encontrado coincidencia con el usuario introducido. Revisa el correo introducido.");
                }else{
                    setErrorSend("Se ha producido un error inesperado, vuelve a intentarlo más tarde.");
                }
                setOpen(true);
            });
        } catch (error) {
            setErrorSend("Error en la petición. Vuelve a intentarlo más tarde.");
            setOpen(true);
        }
        setLoading(false);
    }

    return(
        <div className="welcome-main" onClick={handleHideErrors}>
            <div className="welcome-background" >
                <div className="welcome-logos">
                    <div className="welcome-logos-top">
                        <img className="welcome-logo-tic4bio" src={logotic4bio} alt={"Imagen Logo Tic4Bio"}/>
                    </div>
                    <div className="welcome-logos-bottom">
                        <Box className="financian-text">FINANCIAN:</Box>
                        <div className="welcome-logos-bottom-images">
                            <img className="welcome-logo-ue" src={logoue} alt={"Imagen Logo Tic4Bio"}/>
                            <img className="welcome-logo-junta" src={logojunta} alt={"Imagen Logo Tic4Bio"}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="welcome-container">
                {completed ? <></> : <HeaderOutNavLogin text={"¿Has olvidado tu contraseña?"} url={"/recuperarPassword"}/>}
                <div className="welcome-body">
                    {completed ? 
                        <Box>
                            <h1>Cambio de contraseña completado</h1>
                            <h2 style={{marginBottom: "40px"}}>¡Contraseña cambiada correctamente! Ya puedes acceder con tu nueva contraseña a la aplicación desde la página de Login.</h2>
                            <div className="button green">
                                <Link to="/login" >
                                    Volver al Login
                                </Link>
                            </div>
                        </Box>
                    :
                        <>
                            {searchParams.get('email') ? <h2 style={{marginBottom: "40px"}}>Se ha enviado un código a tu correo para el cambio de contraseña.</h2> : <></>}
                            <Box component="form" onSubmit={handleSubmit} noValidate>
                                <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                    <InputLabel htmlFor="email">Correo electrónico*</InputLabel>
                                    <Input
                                        id="email"
                                        className=""
                                        label="Correo electrónico"
                                        required
                                        name="email"
                                        error={errors.errorEmail}
                                        onChange={(e) => {
                                            setFormData({
                                                ...formData,
                                                username: e.target.value,
                                            });
                                        }}
                                        value={formData.username}
                                        startAdornment={
                                        <InputAdornment position="start">
                                            <MailOutlineIcon />
                                        </InputAdornment>
                                        }
                                    />
                                    {errors.errorEmailMsg && <div className="errorInput">{errors.errorEmailMsg}</div>}
                                </FormControl>
                                <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                    <InputLabel htmlFor="password">Nueva Contraseña*</InputLabel>
                                    <Input
                                        id="password"
                                        required
                                        className=""
                                        type={showPassword ? 'text' : 'password'}
                                        name="password"
                                        error={errors.errorPassword}
                                        onChange={(e) => {
                                            setFormData({
                                                ...formData,
                                                password: e.target.value,
                                            });
                                        }}
                                        value={formData.password}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <LockIcon />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="Conmuta la visibilidad de la contraseña"
                                            onClick={handleClickShowPassword}
                                            edge="end"><img
                                            title={showPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
                                            src={showPassword ? OjoCerrado : OjoAbierto}
                                            alt={"Icono visibilidad"}
                                            />
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                        label="Contraseña"
                                    />
                                    {errors.errorPasswordMsg && <div className="errorInput">{errors.errorPasswordMsg}</div>}
                                </FormControl>
                                <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                    <InputLabel htmlFor="passwordRpe">Confirmar Nueva Contraseña*</InputLabel>
                                    <Input
                                        id="passwordRpe"
                                        required
                                        className=""
                                        type={showPasswordRpe ? 'text' : 'password'}
                                        name="passwordRpe"
                                        error={errors.errorPasswordRpe}
                                        onChange={(e) => {
                                            setFormData({
                                                ...formData,
                                                passwordRpe: e.target.value,
                                            });
                                        }}
                                        value={formData.passwordRpe}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <LockIcon />
                                            </InputAdornment>
                                        }
                                        endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                            aria-label="Conmuta la visibilidad de la contraseña"
                                            onClick={handleClickShowPasswordRpe}
                                            edge="end"><img
                                            title={showPasswordRpe ? "Ocultar contraseña" : "Mostrar contraseña"}
                                            src={showPasswordRpe ? OjoCerrado : OjoAbierto}
                                            alt={"Icono visibilidad"}
                                            />
                                            </IconButton>
                                        </InputAdornment>
                                        }
                                        label="Contraseña"
                                    />
                                    {errors.errorPasswordRpeMsg && <div className="errorInput">{errors.errorPasswordRpeMsg}</div>}
                                </FormControl>
                                <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                    <InputLabel htmlFor="code">Código*</InputLabel>
                                    <Input
                                        className=""
                                        required
                                        name="code"
                                        label="CÓDIGO"
                                        id="code"
                                        onChange={(e) => {
                                            const re = /^[0-9\b]+$/;
                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                setFormData({
                                                    ...formData,
                                                    code: e.target.value,
                                                });
                                            }
                                        }}
                                        value={formData.code}
                                        error={errors.errorCode}
                                        inputProps={{ maxLength: 6 }}
                                        startAdornment={
                                            <InputAdornment position="start">
                                                <KeyIcon />
                                            </InputAdornment>
                                        }
                                        />
                                    {errors.errorCodeMsg && <div className="errorInput">{errors.errorCodeMsg}</div>}
                                </FormControl>
                                <div className='login-buttons'>
                                    <button type="submit" className="btn green">
                                        Restablecer Contraseña
                                    </button>
                                </div>
                            </Box>
                        </>
                    }
                    
                </div>
            </div>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={"Error"} contentText={errorSend}/>
            <Footer />
            <LoadingBackdrop loading={loading} />
        </div>
    );
}

export default ResetPasswordPage;