import { Dialog, DialogActions, DialogContent, DialogContentText, Box, FormControl, Input, InputLabel, FormControlLabel, Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { checkDoublePattern } from "../../utils/commons";

const ModalEditComunidad = ({open, setOpen, comunidad, setComunidad, comunidades, setComunidades}) => {

    const [errors, setErrors] = useState({});
    const [dialogWidth, setDialogWidth] = useState('50%');

    useEffect(() => {
        const handleResize = () => {
            let width;
            if(window.innerWidth < 768){
                width = window.innerWidth * 0.8;
            }else{
                width = window.innerWidth * 0.5;
            }
            setDialogWidth(`${width}px`);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleCheckLinde = (event) => {
        let nuevoTipo = event.target.checked ? "linde" : "normal";
        let nuevasHectareas = (nuevoTipo === "linde" && comunidad.metrosLindero && comunidad.anchuraLindero) ? ((comunidad.metrosLindero * comunidad.anchuraLindero)/10000) : "";
        setComunidad((comunidad) =>({...comunidad, tipo: nuevoTipo, metrosLindero: (nuevoTipo !== "linde" ? null : comunidad.metrosLindero), anchuraLindero: (nuevoTipo !== "linde" ? null : comunidad.anchuraLindero), superficie: (nuevoTipo === "normal" ? comunidad.superficie : nuevasHectareas)}));
    }

    const handleMetrosLindero = (event) => {
        let nuevosMetrosLindero = event.target.value;
        nuevosMetrosLindero = nuevosMetrosLindero.replace(",", ".");
        let nuevasHectareas = "";
        if(comunidad.anchuraLindero && nuevosMetrosLindero){
            nuevasHectareas = parseFloat(((nuevosMetrosLindero * comunidad.anchuraLindero) /10000)?.toFixed(2));
        }
        setComunidad((comunidad) =>({...comunidad, metrosLindero: nuevosMetrosLindero, superficie: nuevasHectareas}));
    }

    const handleAnchuraLindero = (event) => {
        let nuevaAnchura = event.target.value;
        nuevaAnchura = nuevaAnchura.replace(",", ".");
        let nuevasHectareas = "";
        if(comunidad.metrosLindero && nuevaAnchura){
            nuevasHectareas = parseFloat(((comunidad.metrosLindero * nuevaAnchura)/10000)?.toFixed(2));
        }
        setComunidad((comunidad) =>({...comunidad, anchuraLindero: nuevaAnchura, superficie: nuevasHectareas}));
    }

    const handleSave = () => {
        if(validateForm()){
            saveComunidad(true);
            setOpen(false);
        }else{
            saveComunidad(false);
        }
    }

    const validateForm = () =>{
        setErrors({});
        let valid = true;

        if(comunidad.nombre === ''){
            setErrors(errors => ({...errors, errorNombre: true, errorNombreMsg: ""}));
            valid = false;
        }

        if(comunidad.superficie === ''){
            setErrors(errors => ({...errors, errorHectareas: true, errorHectareasMsg: ""}));
            valid = false;
        }

        if(comunidad.tipo === 'linde'){
            if(comunidad.metrosLindero === ''){
                setErrors(errors => ({...errors, errorMetrosLindero: true, errorMetrosLinderoMsg: ""}));
                valid = false;
            }
            if(comunidad.anchuraLindero === ''){
                setErrors(errors => ({...errors, errorAnchuraLindero: true, errorAnchuraLinderoMsg: ""}));
                valid = false;
            }
        }

        return valid;
    }

    const saveComunidad = (valid) => {
        let tComunidad = comunidad;
        tComunidad.valido = valid;
        if(valid){
            tComunidad.touch = true;
        }else{
            tComunidad.touch = false;
        }
        let tComunidades = comunidades.map(objeto => {
            if((tComunidad?.id && objeto.id === tComunidad.id) || (tComunidad?.tempId && objeto.tempId === tComunidad.tempId)){
                return tComunidad;
            }else{
                return objeto;
            }
        });
        setComunidad(tComunidad);
        setComunidades(tComunidades)
    }

    const dialogStyle = {
        width: dialogWidth,
      };

    return ( 
        <Dialog PaperProps={{ style: dialogStyle }} open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogContent>
                <DialogContentText id="alert-dialog-description"> 
                    <div style={{color: "#80C606", padding: "10px", borderTop: "1px solid #80C606", borderBottom: "1px solid #80C606", textAlign: "center", margin: "0 auto 30px", fontSize: "18px"}}>Editar</div>
                    <Box component="form" noValidate>
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="nombre">Nombre*</InputLabel>
                            <Input
                                id="nombre"
                                className="t4b-finca-sector-inputs"
                                label="Nombre"
                                value={comunidad?.nombre}
                                onChange={(e) => {setComunidad((comunidad) => ({...comunidad, nombre: e.target.value}))}}
                                required
                                name="nombre"
                                error={errors.errorNombre}
                            />
                            {errors.errorNombreMsg && <div className="errorInput">{errors.errorNombreMsg}</div>}
                        </FormControl>
                        
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="hectareas">Hectáreas*</InputLabel>
                            <Input
                                id="hectareas"
                                className="t4b-finca-sector-inputs"
                                label="Hectáreas"
                                value={comunidad?.superficie}
                                onChange={(e) => {
                                    if(checkDoublePattern(e.target.value)){
                                        setComunidad((comunidad) =>({...comunidad, superficie: e.target.value}));
                                    }
                                }}
                                disabled={comunidad?.tipo === "linde"}
                                required
                                name="hectareas"
                                error={errors.errorHectareas}
                            />
                            {errors.errorHectareasMsg && <div className="errorInput">{errors.errorHectareasMsg}</div>}
                        </FormControl>
                        
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <FormControlLabel
                                control={
                                    <Checkbox checked={comunidad?.tipo === "linde"} onChange={handleCheckLinde} name="¿Es un lindero?" />
                                }
                                label="¿Es un lindero?"
                            />
                        </FormControl>
                        {comunidad?.tipo === "linde" && 
                            <>
                                <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                    <InputLabel htmlFor="metrosLindero">Metros Lineales de lindero (m)*</InputLabel>
                                    <Input
                                        id="metrosLindero"
                                        label="Metros Lineales de lindero (m)"
                                        required
                                        name="metrosLindero"
                                        value={comunidad?.metrosLindero}
                                        disabled={comunidad?.tipo === "normal"}
                                        onChange={(e) => {
                                            if(checkDoublePattern(e.target.value)){
                                                handleMetrosLindero(e)
                                            }
                                        }}
                                        error={errors.errorMetrosLindero}
                                    />
                                    {errors.errorMetrosLinderoMsg && <div className="errorInput">{errors.errorMetrosLinderoMsg}</div>}
                                </FormControl>
                                <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                    <InputLabel htmlFor="anchura">Anchura de lindero (m)*</InputLabel>
                                    <Input
                                        id="anchura"
                                        label="Anchura de lindero (m)"
                                        required
                                        name="anchura"
                                        value={comunidad?.anchuraLindero}
                                        disabled={comunidad?.tipo === "normal"}
                                        onChange={(e) => {
                                            if(checkDoublePattern(e.target.value)){
                                                handleAnchuraLindero(e)
                                            }
                                        }}
                                        error={errors.errorAnchuraLindero}
                                    />
                                    {errors.errorAnchuraLinderoMsg && <div className="errorInput">{errors.errorAnchuraLinderoMsg}</div>}
                                </FormControl>
                            </>
                        }
                    </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button className="btn green" onClick={handleClose}>Cerrar</button>
                <button className="btn green" onClick={handleSave}>Guardar cambios</button>
            </DialogActions>
        </Dialog>
     );
}
 
export default ModalEditComunidad;