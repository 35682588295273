import { Fragment, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useAuth } from "../context/Auth";
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { instance } from "../utils/axios-config";
import ModalInfo from "../components/ModalInfo";
import { diasSemana, meses } from "../utils/constants"; 
import termometrorojo from '../assets/termometrorojo.png';
import termometroazul from '../assets/termometroazul.png';
import sunicon from '../assets/Iconos_meteorologicos/sunicon.png';
import cloud25 from '../assets/Iconos_meteorologicos/cloud25.png';
import cloud50 from '../assets/Iconos_meteorologicos/cloud50.png';
import cloud75 from '../assets/Iconos_meteorologicos/cloud75.png';
import cloud100 from '../assets/Iconos_meteorologicos/cloud100.png';
import rain25 from '../assets/Iconos_meteorologicos/rain25.png';
import rain50 from '../assets/Iconos_meteorologicos/rain50.png';
import rain75 from '../assets/Iconos_meteorologicos/rain75.png';
import rain100 from '../assets/Iconos_meteorologicos/rain100.png';
import regadera from '../assets/regadera.png';
import Mensajes from "../utils/mensajes";
import { convertDateEn } from "../utils/commons";

const ProgramacionDeRiegoPage = () => {

    const {sessionToken} = useAuth();
    const { id } = useParams();
    const [sectores, setSectores] = useState([]);
    const [unidades, setUnidades] = useState([]);
    const [programacion, setProgramacion] = useState([]);
    const [sector, setSector] = useState({id: ""});
    const [fincaId, setFincaId] = useState('');
    const [ loading, setLoading ] = useState(true);
    const [ loadingSectores, setLoadingSectores ] = useState(false);
    const [ loadingProgramacion, setLoadingProgramacion ] = useState(false);
    const [ open, setOpen ] = useState(false);
    const [errorMsg, setErrorMsg] = useState({title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado. Por favor, vuelve a intentarlo más tarde."});

    useEffect(() => {
        document.title = "Programación de riego";
        if(id){
            loadPageData(); 
        }else{
            loadUnidades();
        }
    }, []);

    const loadPageData = async () => {
        loadProgramacionOnInit(id);
    }

    const loadUnidades = async () => {
        let buildUrl = `/fincas/fincasByDataType/finca`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await instance.get(buildUrl).then(function(resp) {
                setUnidades(resp.data);
            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar la información de las unidades productivas. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar la programación de riego. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const loadSectorOnChange = async (idFinca) => {
        let buildUrl = `/sectores/listall/${idFinca}`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
            await instance.get(buildUrl).then(function(resp) {
                let sectores = resp.data.sectores.filter(elemento => elemento.tipoPlantacion !== "Secano");
                setSectores(sectores);
            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar los sectores de la unidad productiva. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoadingSectores(false);
            });
            setLoadingSectores(false);
        }catch(error){
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar los sectores de la unidad productiva. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const loadProgramacionOnInit = async (sectId) => {
        let buildProgramacionUrl = `/sectores/programacionriego/${sectId}`;
        let buildProgramacionErrorUrl = `/sectores/programacionerror/${sectId}`;
        let buildSectorUrl = `/sectores/sector/${sectId}`;
        let buildResumenUrl = `/dotacion/dotacionUsada/${sectId}`;
        let buildUrl = `/fincas/fincasByDataType/finca`;
        try{
            setLoading(true);
            setLoadingProgramacion(true);
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
            await Promise.all([
                instance.get(buildProgramacionUrl),
                instance.get(buildProgramacionErrorUrl),
                instance.get(buildSectorUrl),
                instance.get(buildResumenUrl),
                instance.get(buildUrl)
            ]).then(async function([pResp, eResp, sResp, rResp, uResp]) {
                let buildUrlSectores = `/sectores/listall/${sResp.data[0].finca_id}`;
                const sectoresResp = await instance.get(buildUrlSectores);

                if(sResp.data[0].tipoPlantacion !== "Secano"){
                    setUnidades(uResp.data);
                    setFincaId(sResp.data[0].finca_id);
                    let sectores = sectoresResp.data.sectores.filter(elemento => elemento.tipoPlantacion !== "Secano");
                    setSectores(sectores);
                    setSector({...sResp.data[0], ...rResp.data});
                    let errorProgramacion = eResp.data.find(objeto => objeto.errormsg);
                    if(errorProgramacion){
                        const todayDate = convertDateEn(new Date());
                        const isSameDate = errorProgramacion.fecha === todayDate;
                        const isNotFullData = pResp.data.length < 6 && compareDates(errorProgramacion.fecha, todayDate);
                        const todosTienenTiempoRiego = pResp.data.every(objeto => objeto.hasOwnProperty('tiempoRiego'));

                        if (isSameDate || (!isSameDate && isNotFullData) || (!todosTienenTiempoRiego && isSameDate)) {
                            modalError(errorProgramacion);
                        }else if(!todosTienenTiempoRiego && !isSameDate){
                            modalError("error default"); 
                        }
                    }
                    const programaciones = pResp.data.map(objeto => {
                        const fecha = new Date(objeto.fecha);
                        let horas = 0;
                        let minutos = 0;
                        if(objeto.tiempoRiego && objeto.necesidadRiego !== "0"){
                            horas = Math.floor(objeto.tiempoRiego);
                            const minutosDecimal = (objeto.tiempoRiego - horas) * 60;
                            minutos = Math.round(minutosDecimal);
                        }
                        return { ...objeto, fecha: (diasSemana[fecha.getUTCDay()] + " " + fecha.getUTCDate() + " " + meses[fecha.getUTCMonth()] + " " + fecha.getUTCFullYear()), date: fecha, horas: horas, minutos: minutos};
                    }).filter(objeto => objeto?.necesidadRiego).sort((a, b) => a.date - b.date);
                    setProgramacion(programaciones);
                }else{
                    setOpen(true);
                    setErrorMsg((prevErrorMsg) => ({
                        ...prevErrorMsg,
                        content: "Los sectores de secano no tienen programación de riego."
                    }));
                }
                
            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar la programación de riego. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
                setLoadingProgramacion(false);
            });
        }catch(error){
            setOpen(true);
            setLoading(false);
            setLoadingProgramacion(false);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar la programación de riego. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const loadProgramacionOnChange = async (sectId) => {
        let buildProgramacionUrl = `/sectores/programacionriego/${sectId}`;
        let buildProgramacionErrorUrl = `/sectores/programacionerror/${sectId}`;
        let buildSectorUrl = `/sectores/sector/${sectId}`;
        let buildResumenUrl = `/dotacion/dotacionUsada/${sectId}`;
        try{
            setLoadingProgramacion(true);
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
            await Promise.all([
                instance.get(buildProgramacionUrl),
                instance.get(buildProgramacionErrorUrl),
                instance.get(buildSectorUrl),
                instance.get(buildResumenUrl),
            ]).then(async function([pResp, eResp, sResp, rResp]) {

                if(sResp.data[0].tipoPlantacion !== "Secano"){
                    setSector({...sResp.data[0], ...rResp.data});
                    let errorProgramacion = eResp.data.find(objeto => objeto.errormsg);
                    if(errorProgramacion){
                        const todayDate = convertDateEn(new Date());
                        const isSameDate = errorProgramacion.fecha === todayDate;
                        const isNotFullData = pResp.data.length < 6 && compareDates(errorProgramacion.fecha, todayDate);
                        const todosTienenTiempoRiego = pResp.data.every(objeto => objeto.hasOwnProperty('tiempoRiego'));

                        if (isSameDate || (!isSameDate && isNotFullData) || (!todosTienenTiempoRiego && isSameDate)) {
                            modalError(errorProgramacion);
                        }else if(!todosTienenTiempoRiego && !isSameDate){
                            modalError("error default"); 
                        }
                    }
                    const programaciones = pResp.data.map(objeto => {
                        const fecha = new Date(objeto.fecha);
                        let horas = 0;
                        let minutos = 0;
                        if(objeto.tiempoRiego && objeto.necesidadRiego !== "0"){
                            horas = Math.floor(objeto.tiempoRiego);
                            const minutosDecimal = (objeto.tiempoRiego - horas) * 60;
                            minutos = Math.round(minutosDecimal);
                        }
                        return { ...objeto, fecha: (diasSemana[fecha.getUTCDay()] + " " + fecha.getUTCDate() + " " + meses[fecha.getUTCMonth()] + " " + fecha.getUTCFullYear()), date: fecha, horas: horas, minutos: minutos};
                    }).filter(objeto => objeto?.necesidadRiego).sort((a, b) => a.date - b.date);
                    setProgramacion(programaciones);
                }else{
                    setOpen(true);
                    setErrorMsg((prevErrorMsg) => ({
                        ...prevErrorMsg,
                        content: "Los sectores de secano no tienen programación de riego."
                    }));
                }
                
            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar la programación de riego. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoadingProgramacion(false);
            });
        }catch(error){
            setOpen(true);
            setLoadingProgramacion(false);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar la programación de riego. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const modalError = (error) => {
        
        switch (error.errormsg) {
            case "error info sector":
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Se ha producido un error al intentar recuperar la información del sector, por favor, inténtelo de nuevo más tarde. Debido a este fallo, la programación puede no estar disponible o incompleta."
                }));
                break;
            case "error info riego":
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Se ha producido un error al intentar recuperar la información de riego, por favor, inténtelo de nuevo más tarde. Debido a este fallo, la programación puede no estar disponible o incompleta."
                }));
                break;
            case "error info cultivo":
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Se ha producido un error al intentar recuperar la información del cultivo, por favor, inténtelo de nuevo más tarde. Debido a este fallo, la programación puede no estar disponible o incompleta."
                }));
                break;
            case "error info suelo":
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Se ha producido un error al intentar recuperar la información del suelo, por favor, inténtelo de nuevo más tarde. Debido a este fallo, la programación puede no estar disponible o incompleta."
                }));
                break;
            case "error sensores":
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Se ha producido un error al intentar recuperar la información de los sensores de humedad, por favor, inténtelo de nuevo más tarde. Debido a este fallo, la programación puede no estar disponible o incompleta."
                }));
                break;
            case "error info programacion":
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Se ha producido un error al intentar recuperar la información del déficit de humedad de las programaciones previas, por favor, inténtelo de nuevo más tarde. Debido a este fallo, la programación puede no estar disponible o incompleta."
                }));
                break;
            case "error historico":
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Se ha producido un error al intentar recuperar la información del histórico de datos climáticos, por favor, inténtelo de nuevo más tarde. Debido a este fallo, la programación puede no estar disponible o incompleta."
                }));
                break;
            case "error calculo dotacion":
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Se ha producido un error al intentar recuperar la información del cálculo de dotación, por favor, inténtelo de nuevo más tarde. Debido a este fallo, la programación puede no estar disponible o incompleta."
                }));
                break;
            case "error prediccion":
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Se ha producido un error al intentar recuperar la información de las predicciones climáticas, por favor, inténtelo de nuevo más tarde. Debido a este fallo, la programación puede no estar disponible o incompleta."
                }));
                break;
            case "error reparto dotacion":
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Se ha producido un error al intentar recuperar la información del reparto de dotación, por favor, inténtelo de nuevo más tarde. Debido a este fallo, la programación puede no estar disponible o incompleta."
                }));
                break;
            default:
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado. Por favor, vuelve a intentarlo más tarde."
                }));
                break;
        }
    }

    const handleLoadSectores = async (event) => {
        setFincaId(event.target.value);
        setSector({id: ""});
        setProgramacion([]);
        setLoadingSectores(true);
        loadSectorOnChange(event.target.value);
    }

    const handleLoadProgramacion = (event) => {
        setSector({id: event.target.value});
        loadProgramacionOnChange(event.target.value);
    }

    const determinarIconoDeClima = (probPrecipitacion, nubosidad) => {
        if (probPrecipitacion <= 25) {
          if (nubosidad <= 25) return sunicon;
          else if (nubosidad <= 50) return cloud25;
          else if (nubosidad <= 75) return cloud50;
          else if (nubosidad < 100) return cloud75;
          else return cloud100; // nubosidad > 75
        } else if(probPrecipitacion > 25){
            if (probPrecipitacion === 100) return rain100;
            else if (probPrecipitacion >= 75) return rain75;
            else if (probPrecipitacion >= 50) return rain50;
            else return rain25;
        }
    };

    const compareDates = (date1, date2) => {
        const firstDate = new Date(date1);
        const secondDate = new Date(date2);
        
        const differenceInTime = Math.abs(secondDate - firstDate);
    
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    
        return (differenceInDays > 1);
    };
   
    return(
        <>
            <div className="tic4bio-home">
                {loading ? 
                    (
                        <Box p={3} align="center">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            <div>
                                <h1 className="tic4bio-home-title">Programación de riego</h1>
                                {(unidades.length > 0 && !id) &&
                                    <p>Finca y Sector donde quiera visualizar la programación de riego*</p>
                                }
                                
                            </div>
                            
                                {unidades.length > 0 ? (
                                    <Box component="form" className="tic4bio-riego-create" style={{marginLeft: "0"}} noValidate>
                                        <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                            <InputLabel htmlFor="unidad-select">Unidades*</InputLabel>
                                            <Select
                                                className="t4b-finca-sector-inputs"
                                                id="unidad-select"
                                                label="unidad"
                                                required
                                                MenuProps={{ disableScrollLock: true }}
                                                value={fincaId}
                                                disabled={loadingSectores || loadingProgramacion}
                                                onChange={(event) => { handleLoadSectores(event); }    
                                                }
                                                >
                                                {unidades.map((unidad, index) => (
                                                <MenuItem key={index} value={unidad.id}>
                                                    {unidad.unidadProductiva} - {unidad.nombreFinca} ({unidad.dataType.charAt(0).toUpperCase() + unidad.dataType.slice(1)})
                                                </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        
                                        {loadingSectores ? (
                                            <Box p={3} align="center">
                                                <CircularProgress />
                                            </Box>
                                        ) : (
                                            <>
                                                {(fincaId || id) && 
                                                    <>
                                                        {sectores.length > 0 ? 
                                                            <>
                                                                <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                                    <InputLabel htmlFor="sector-select">Sectores*</InputLabel>
                                                                    <Select
                                                                        className="t4b-finca-sector-inputs"
                                                                        id="sector-select"
                                                                        label="Sector"
                                                                        required
                                                                        MenuProps={{ disableScrollLock: true }}
                                                                        value={sector?.id}
                                                                        disabled={loadingProgramacion}
                                                                        onChange={(event) => { handleLoadProgramacion(event) }
                                                                        }>
                                                                    
                                                                        {sectores.map((sector, index) => (
                                                                        <MenuItem key={index} value={sector.id}>
                                                                            {sector.nombre}
                                                                        </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                            </>
                                                            :
                                                            <p>Parece que la unidad productiva seleccionada no tiene sectores de tipo usuario estándar asociados. Necesitas un al menos un sector de este tipo para poder visualizar la programación de riego.</p>
                                                        }
                                                    </>
                                                }
                                            </>
                                        )}
                                    </Box>
                                ) : (
                                    <p><Mensajes type="unidades" /></p>
                                )}
                            {loadingProgramacion ? 
                                (
                                    <Box p={3} align="center">
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    <>
                                        {sector?.id &&
                                            <>
                                                {(sector?.nombre && id) && <p className="tic4bio-programacion-nombre">Sector: <span>{sector.nombre}</span></p>}
                                                {programacion.length > 0 ? (
                                                    <>
                                                        <ul className="tic4bio-programacion-list">
                                                            <>
                                                                {programacion.map((prog, index) => {
                                                                    return (
                                                                        <Fragment key={"programacion-" + index}>
                                                                            <li>
                                                                                <div className="left">
                                                                                    <img className="bg-can" src={regadera}/>
                                                                                    <p>
                                                                                        {(prog?.horas > 0 || (prog?.horas === 0 && prog?.minutos === 0) ) &&
                                                                                            <>{prog.horas}H</>
                                                                                        }
                                                                                        {prog?.minutos > 0 &&
                                                                                            <>{prog.minutos}Min</>
                                                                                        }
                                                                                    </p>
                                                                                </div>
                                                                                <div className="right">
                                                                                    <p className="fecha">{prog.fecha}</p>
                                                                                    <div className="data">
                                                                                        <div className="cloud sss">
                                                                                            <img src={determinarIconoDeClima(prog.probPrecipitacion, prog.nubosidad)} />
                                                                                            {prog.p !== null ?
                                                                                                <>
                                                                                                    <p className="noMargin">{prog.p?.toFixed(2)} mm</p>
                                                                                                    <p className="noMargin">({prog.probPrecipitacion}% prob.)</p>
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    <p className="noMargin">{prog.probPrecipitacion}% prob.</p>
                                                                                                </>
                                                                                            }
                                                                                            <p className="noMargin hide">Nubosidad: {prog.nubosidad} %</p>
                                                                                        </div>
                                                                                        <div className="temperature">
                                                                                            <div className="temperature-images">
                                                                                                <img src={termometrorojo}/>
                                                                                                <img src={termometroazul}/>
                                                                                            </div>
                                                                                            <div>
                                                                                                <p className="noMargin">{prog.tMaxima}º / {prog.tMinima}º</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                            <p style={{display: "none"}}>Predicción eto: {prog.eto}</p>
                                                                            <p style={{display: "none"}}>Predicción etc: {prog.etc}</p>
                                                                            <p style={{display: "none"}}>Predicción precipitacion efectiva(mm): {prog.p}</p>
                                                                            <p style={{display: "none"}}>CIR Requerimiento Agua de cultivo: {prog.cir}</p>
                                                                            <p style={{display: "none"}}>IR Riego: {prog.ir}</p>
                                                                            <p style={{display: "none"}}>Tiempo de riego sin pasar a horas y minutos: {prog.tiempoRiego}</p>
                                                                        </Fragment>
                                                                    )
                                                                })}
                                                            </>
                                                        </ul>
                                                        <div>
                                                            <h1 className="tic4bio-home-title">Resumen riego campaña</h1>
                                                    
                                                            <div className="tic4bio-progressbar">
                                                                <div className="tic4bio-progressbar-bg"
                                                                    style={{
                                                                    width: `${sector.dotacionUsadaPer}%`,
                                                                    }}>
                                                                    <p className="percent">{sector.dotacionUsadaPer}%</p>
                                                                </div>
                                                                <div className="tic4bio-progressbar-total">
                                                                    <p className="total">{sector.dotacionRiego} m<sup>3</sup>/ha</p>
                                                                </div>
                                                            </div>

                                                            <div>
                                                                <div className="tic4bio-dotacion">
                                                                    <p>Dotación usada: <span>{sector.dotacionUsada} m<sup>3</sup>/ha</span></p>
                                                                    <p>Dotación restante: <span>{sector.dotacionRestante} m<sup>3</sup>/ha</span></p>
                                                        
                                                                    <p>Nº días de riego: <span>{sector.diasRegados} días</span></p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                    )
                                                    :
                                                    (
                                                        <p>No existen programaciones de riego disponibles. Por favor, intentelo más tarde.</p>
                                                    )
                                                }
                                            </>
                                        }
                                    </>
                                )
                            }
                        </>
                    )
                }
            </div>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.title} contentText={errorMsg.content}/>
        </>
    );
}

export default ProgramacionDeRiegoPage;