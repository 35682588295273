import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import { useNavigate } from 'react-router-dom';

function BarChartRiegoRisEvolucion({ startCampana, endCampana, campanas, data, finca }) {

  const chartRef = useRef(null);
  const navigate = useNavigate();
  const [ chartInfo, setChartInfo ] = useState();

  const backgroundColors = [
    'rgba(122,154,200, 1)',
  ];

  const borderColors = [
    'rgba(122,154,200, 1)',
  ];

  const chartBackgroundStripesPlugin = {
    id: 'backgroundStripes',
    beforeDraw: (chart, args, options) => {
      const {ctx, chartArea: {top, bottom, left, right}, scales: {x, y}} = chart;
  
      ctx.save();
      const stripes = options.stripes || [];
  
      stripes.forEach(stripe => {
        const {startValue, endValue, color} = stripe;
        const start = y.getPixelForValue(startValue);
        const end = y.getPixelForValue(endValue);
  
        ctx.fillStyle = color;
        ctx.fillRect(left, start, right - left, end - start);
      });
  
      ctx.restore();
    }
  };

  Chart.register(chartBackgroundStripesPlugin);

  useEffect(() => {
    chooseOption();
  }, [startCampana, endCampana]); 

  useEffect(() => {
    const chart = chartRef.current;

    const handleClick = (event) => {
        const canvasPosition = {
            x: event.offsetX,
            y: event.offsetY,
        };

        const dataXLabels = chart.scales.x.ticks;
        
        // Calcular la posición de las etiquetas en el eje X
        dataXLabels.forEach((label, index) => {
            const xLabelPosition = chart.scales.x.getPixelForTick(index);
            const labelWidth = 100; // Ancho aproximado de la etiqueta

            // Verificar si el clic está dentro del rango de la etiqueta
            if (canvasPosition.x >= xLabelPosition - labelWidth / 2 && canvasPosition.x <= xLabelPosition + labelWidth / 2) {

                let fincaId;
                if(finca.fincas.length > 0){
                  fincaId = finca.fincas[0].id;
                }else{
                  fincaId = finca.almazaras[0].id
                }
                navigate(`/huella-unidades/${fincaId}`, {
                  state: { campana: label.label }
              });
            }
        });
    };

    if (chart) {
        chart.canvas.addEventListener('click', handleClick);
    }

    return () => {
        if (chart?.canvas) {
            chart.canvas.removeEventListener('click', handleClick);
        }
    };
  }, [chartRef, chartInfo]);

  const generateCampanas = () => {
    const indiceInicio = campanas.indexOf(startCampana);
    const indiceFin = campanas.indexOf(endCampana);

    return campanas.slice(indiceInicio, indiceFin + 1);
  }

  const generateDataSets = (subData) => {
    
    const campanasData = Object.values(subData).map(c => {
      const { campana, fincas } = c;
      const totalRis = fincas.reduce((sum, sector) => sum + sector.ris, 0);
      let mediaRis = 0;
      if(fincas.length !== 0){
        mediaRis = totalRis / fincas.length;
      }
      return {
        campana: campana,
        mediaRis: mediaRis
      };
    });
    
    const result = [
      {
        label: 'Adecuación de riego (RIS)',
        data: campanasData.map(campana => campana.mediaRis),
        backgroundColor: backgroundColors[0],
        borderColor: borderColors[0],
        borderWidth: 1,
      }
    ];
    return result;
  }

  const chooseOption = () => {
    let subData = data.unidad.infoUnidad;

    const labelsCampanas = generateCampanas();

    const filteredData = Object.keys(subData)
    .filter((key) => labelsCampanas.includes(key))
    .reduce((obj, key) => {
      obj[key] = subData[key];
      return obj;
    }, {});
    
    let someData = Object.values(filteredData).some(c => {

      if(c.fincas.length !== 0){
        return true;
      }
      return false;
    });
    
    if(someData){
      const info = {
        labels: labelsCampanas,
        datasets: generateDataSets(filteredData)
      }

      setChartInfo(info);
    }else{
      setChartInfo();
    }

  }

  const options = {
    plugins: {
      backgroundStripes: {
        stripes: [
          {
            startValue: 0,
            endValue: 1,
            color: 'rgba(254, 192, 7, 0.5)',
          },
          {
            startValue: 1,
            endValue: 1.2,
            color: 'rgba(112, 173, 71, 0.5)',
          },
          {
            startValue: 1.2,
            endValue: 2,
            color: 'rgba(184, 87, 36, 0.5)',
          },
        ],
      },
    },
    layout: {
      padding: {
          top: 0,
          bottom: 100,
      }
    },
    maintainAspectRatio: false,
    responsive: true,
  };

    return (
      <>
        {chartInfo &&
          <>
              <h3 style={{textAlign: "center", textDecoration: "underline", color: "#80C606", fontSize: "26px", marginBottom: "40px"}}>Adecuación del riego (RIS)</h3>
              <div style={{minHeight: "600px"}}>
                <Bar data={chartInfo} options={options} ref={chartRef}/>
              </div>
          </>
        }
        {!chartInfo &&
          <p>No existe información disponible para el rango de campañas seleccionadas en el <b>gráfico de Adecuación del riego (RIS)</b></p>
        }
      </>
    );
}

export default BarChartRiegoRisEvolucion;