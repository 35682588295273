import { Navigate } from "react-router-dom";
import { useAuth } from "../context/Auth";
import jwt_decode from "jwt-decode";

export const BackofficeRoute = ({ children }) => {

    const { backofficeToken } = useAuth();

    const {exp} = backofficeToken ? jwt_decode(backofficeToken) : 0;

    if((exp * 1000) >= Date.now()){ 
        return <Navigate to="/backofficeHome" />;
    }
    return children;

}