import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const ModalTip = ({open, setOpen, contentText}) => {
    
    const handleClose = () => {
        setOpen(false);
    };

    return ( 
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogContent>
                <div style={{lineHeight: 1.5, letterSpacing: "0.00938em", color: "rgba(0, 0, 0, 0.6)"}}>
                    <div dangerouslySetInnerHTML={{ __html: contentText }} />
                </div>
            </DialogContent>
            <DialogActions>
                <button className="btn green" onClick={handleClose}>Ok</button>
            </DialogActions>
        </Dialog>
     );
}
 
export default ModalTip;