import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { instance } from "../utils/axios-config";

const AuthContext = createContext();

export default function AuthProvider({children}) {
    const [ currentUser, setCurrentUser ] = useState();
    const [ sessionToken, setSessionToken ] = useState();
    const [ loading, setLoading ] = useState(true);
    const [ backofficeToken, setBackofficeToken ] = useState();

    const signIn = async (email, pass) => {
        const body = {
            username: email,
            password: pass,
        };
        let response = undefined;
        try {
            await instance.post(`/users/login`, body).then((userCredentials) => {
                setCurrentUser(userCredentials.data.user);
                setSessionToken(userCredentials.data.sessionToken);
                window.localStorage.setItem("token", userCredentials.data.sessionToken);
                window.localStorage.setItem("userData", JSON.stringify(userCredentials.data.user));
                response = userCredentials.data;
                setLoading(false);
                //return response;
            });
        } catch (error) {
            console.warn(error);
            setLoading(false);
            response = error;
        } finally {
            return response;
        }
    };
    
    const singInBackoffice = async (email, pass) => {
        const body = {
            username: email,
            password: pass,
        };
        let response = undefined;
        try {
            await instance.post(`/users/loginBO`, body).then((backofficeResponse) => {
                setBackofficeToken(backofficeResponse.data.tokenoken);
                window.localStorage.setItem("tokenBackoffice", backofficeResponse.data.token);
                console.log(backofficeToken);
                response = backofficeResponse.data;
                //return response;
            });
        } catch (error) {
            console.warn(error);
            setLoading(false);
            response = error;
        } finally {
            return response;
        }
    }; 

    const logout = () => {
        setCurrentUser(null);
        setSessionToken(null);
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('userData');
    }

    const logoutBackoffice = () => {
        setBackofficeToken(null);
        window.localStorage.removeItem('tokenBackoffice');
      }

    useEffect(() => {
        const initialState = () => {
            if(window.localStorage.getItem("userData") && window.localStorage.getItem("token")){
                return true;
            }else{
                return false;
            }
        }
       
        if(initialState()){
            try{
                setCurrentUser(JSON.parse(window.localStorage.getItem("userData")));
                setSessionToken(window.localStorage.getItem("token"));
            } catch(e){
                window.localStorage.removeItem('token');
                window.localStorage.removeItem('userData');
            }
            
            setLoading(false);

        }else{
            setLoading(false);
        }

        //Hace que cuando haga f5 en el backoffice no se desloguee
        const initialStateBack = () => {
            if(window.localStorage.getItem("tokenBackoffice")){
                return true;
            }else{
                return false;
            }
        }
        if(initialStateBack()){
            try{
                setBackofficeToken(window.localStorage.getItem("tokenBackoffice"));
            } catch(e){
                window.localStorage.removeItem('tokenBackoffice');
            }
            setLoading(false);
        }else{
            setLoading(false);
        }
    }, []);

    const contextValue = useMemo(() => {
        return {
          currentUser,
          sessionToken,
          backofficeToken,
          setSessionToken,
          setCurrentUser,
          setBackofficeToken,
          signIn,
          singInBackoffice,
          logout,
          logoutBackoffice,
        };
      }, [currentUser, sessionToken]);

    return (
        <AuthContext.Provider value={contextValue}>
            {loading ? (
                <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress style={{'color': 'white'}}/>
                </Backdrop>
                </>
            )
            : children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => useContext(AuthContext);