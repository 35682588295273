import { Navigate } from "react-router-dom";
import { useAuth } from "../context/Auth";
import jwt_decode from "jwt-decode";

export const LoggedRoute = ({ children }) => {

    const { currentUser, sessionToken } = useAuth();

    const {exp} = sessionToken ? jwt_decode(sessionToken) : 0;

    if(currentUser && (exp * 1000) >= Date.now()){ 
        return <Navigate to="/home" />;
    }
    return children;

}