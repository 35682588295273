import { Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { Link } from "react-router-dom";

import vegetalicon from "../../assets/Iconos_biodiversidad/planta-icono.svg";
import invertebradoicon from "../../assets/Iconos_biodiversidad/saltamontes-icono.svg";
import vertebradoicon from "../../assets/Iconos_biodiversidad/pajaro-icono.svg";
import vegetalhovericon from "../../assets/Iconos_biodiversidad/planta-icono-hover.svg";
import invertebradohovericon from "../../assets/Iconos_biodiversidad/saltamontes-icono-hover.svg";
import vertebradohovericon from "../../assets/Iconos_biodiversidad/pajaro-icono-hover.svg";
import { useState } from "react";

const ModalSelectBio = ({open, setOpen}) => {

    const [isHovered, setIsHovered] = useState({vegetales: false, invertebrados: false, vertebrados: false});

    const handleHover = (element) => {
        switch (element) {
            case 1:
              setIsHovered(isHovered => ({...isHovered, vegetales: !isHovered.vegetales}));
              break;
            case 2:
              setIsHovered(isHovered => ({...isHovered, invertebrados: !isHovered.invertebrados}));
              break;
            default:
              setIsHovered(isHovered => ({...isHovered, vertebrados: !isHovered.vertebrados}));
          }
      };
    
    const handleClose = () => {
        setOpen(false);
    };

    const dialogStyle = {
        width: '380px', // Ancho deseado del diálogo
      };

    return ( 
        <Dialog PaperProps={{ style: dialogStyle }} open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogContent id="alert-dialog-description">
                <div className="button green" style={{maxWidth: "400px"}} onMouseEnter={() => handleHover(1)} onMouseLeave={() => handleHover(1)} onTouchStart={() => handleHover(1)} onTouchEnd={() => handleHover(1)}>
                    <Link to="/biodiversidad/nueva-vegetal" className="icon">Vegetal<img src={!isHovered.vegetales ? vegetalicon : vegetalhovericon}/></Link>
                </div>
                <div className="button green icon" style={{maxWidth: "400px"}} onMouseEnter={() => handleHover(2)} onMouseLeave={() => handleHover(2)} onTouchStart={() => handleHover(2)} onTouchEnd={() => handleHover(2)}>
                    <Link to="/biodiversidad/nueva-invertebrados" className="icon">Invertebrados<img src={!isHovered.invertebrados ? invertebradoicon : invertebradohovericon}/></Link>
                </div>
                <div className="button green icon" style={{maxWidth: "400px"}} onMouseEnter={() => handleHover(3)} onMouseLeave={() => handleHover(3)} onTouchStart={() => handleHover(3)} onTouchEnd={() => handleHover(3)}>
                    <Link to="/biodiversidad/nueva-vertebrados" className="icon">Vertebrados<img src={!isHovered.vertebrados ? vertebradoicon : vertebradohovericon}/></Link>
                </div>
            </DialogContent>
            <DialogActions>
                <button className="btn green" onClick={handleClose}>Cerrar</button>
            </DialogActions>
        </Dialog>
     );
}
 
export default ModalSelectBio;