import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { checkDoublePattern } from "../../utils/commons";

const InputCapacidadComponent = ({type, state, setState, errors}) => {

    const handleChangeCapacidad = (v) => {
        if(checkDoublePattern(v)){
            setState(prevState => ({
                ...prevState,
                equipos: {
                  ...prevState.equipos,
                  [type]: {
                    ...prevState.equipos[type],
                    capacidad: v,
                  },
                },
              }));
        }
    }

    return(
        <tr>
            <td className="cell-image">
                <p>{type}</p>
            </td>
            <td>
                <FormControl sx={{width: "100%"}} variant="standard">
                    <TextField
                        className="t4b-finca-sector-inputs no-red-label"
                        label="Capacidad de producción"
                        id={type + "-capacidad"}
                        type="number"
                        variant="standard"
                        InputProps={{
                            inputProps: { 
                                max: 99999, min: 0,
                                step: 0.5, 
                            }
                        }}
                        name={type + "-capacidad"}
                        value={state.equipos[type].capacidad}
                        onChange={(e) => handleChangeCapacidad(e.target.value)}
                        error={errors["error" + type + "capacidad"]}
                    />
                    {errors["error" + type + "capacidadMsg"] && <div className="errorInput">{errors["error" + type + "capacidadMsg"]}</div>}
                </FormControl>
            </td>
        </tr>
    );
}
export default InputCapacidadComponent;