import { Fragment, useEffect, useState } from "react";
import { instance } from '../utils/axios-config.js';
import { Box, CircularProgress, FormControl, Stack, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAuth } from "../context/Auth.js";
import redsquare from "../../src/assets/Iconos_biodiversidad/redsquare.png";
import bluesquare from "../../src/assets/Iconos_biodiversidad/bluesquare.png";
import terrain from "../../src/assets/Iconos_biodiversidad/terrain.jpg";
import tinyterrain from "../../src/assets/Iconos_biodiversidad/tinyterrain.png";
import googlelens from "../../src/assets/Iconos_biodiversidad/googlelens.png";
import { checkDoublePattern } from "../utils/commons.js";
import TableAdvancedEditNoSamplingComponent from "../components/formsBioVegetal/TableAdvancedEditNoSampling.js";
import TableAdvancedEditSamplingComponent from "../components/formsBioVegetal/TableAdvancedEditSampling.js";
import TableBasicEditNoSamplingComponent from "../components/formsBioVegetal/TableBasicEditNoSampling.js";
import TableBasicEditSamplingComponent from "../components/formsBioVegetal/TableBasicEditSampling.js";
import BioVegetalEditarResumenComponent from "../components/formsBioVegetal/BioVegetalEditarResumen.js";



const BioVegetalEditarPage = () => {

    const {sessionToken} = useAuth();
    const { id } = useParams();

    const [ position, setPosition ] = useState(0);
    const [ bioVegetal, setBioVegetal ] = useState({id: ""});
    const [ loading, setLoading ] = useState(true);
    const [ open, setOpen ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState({open: false, title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado. Por favor, vuelve a intentarlo más tarde."});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        document.title = "Edición biodiversidad vegetal";
        loadData();
    }, []);

    const loadData = async () => {
        let buildUrl = `/biodiversidad/vegetal/${id}`;
        let buildDiccionarioUrl = `/diccionario/vegetal`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await Promise.all([instance.get(buildUrl), instance.get(buildDiccionarioUrl)]).then(function(resp) {
            //await instance.get(buildUrl).then(function(resp) {
                //setDiccionario((prevData) => ({...prevData, diccionario: resp[1].data}));
                resp[0].data.diccionario = resp[1].data;
                let mll = null;
                let lastTimeMuestreo = 0;
                let lastTime = 0;
                const muestreos = resp[0].data.muestreos.reduce((grupos, muestreo, indice) => {
                    muestreo.tempId = "tempId-" + indice + "-" + lastTimeMuestreo;
                    lastTimeMuestreo++;
                    if(muestreo.tipoVegetacion === "lindero" && mll === null){
                        mll = muestreo.metroslinealesLindero;
                    }
                    muestreo.muestras.forEach((muestra, index) => {
                        muestra.tempId = "tempId-" + index + "-" + lastTime;
                        lastTime++;
                    });
                
                    const tipoVegetacion = muestreo.tipoVegetacion;
                    delete muestreo.metroslinealesLindero;
                    if (grupos[tipoVegetacion]) {
                        grupos[tipoVegetacion].push(muestreo);
                    } else {
                        grupos[tipoVegetacion] = [muestreo];
                    }
                
                    return grupos;
                }, {});

                const gruposListado = Object.entries(muestreos).map(([tipoVegetacion, muestreos]) => {
                    if(tipoVegetacion === "lindero"){
                        return {
                            tipoVegetacion: tipoVegetacion,
                            metroslinealesLindero: mll,
                            muestreos: muestreos
                        }
                    }else{
                        return {
                            tipoVegetacion: tipoVegetacion,
                            muestreos: muestreos
                        }
                    }
                });

                resp[0].data.muestreos = gruposListado;
                setBioVegetal(resp[0].data);
            }).catch(function(error) {
                setOpen(true);
                setError(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    contentText: "Algo ha fallado al intentar recuperar la información de la biodiversidad vegetal. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setLoading(false);
            setOpen(true);
            setError(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                contentText: "Algo ha fallado al intentar recuperar la información de las biodiversidad vegetal. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const printNameText = (text) => {
        let res = "";
        switch (text) {
            case "cubiertavegetal":
                res = "Cubierta vegetal";
                break;
            case "cultivoanualherbaceo":
                res = "Cultivo Anual/Herbáceo";
                break;
            case "bosquesierramatorral":
                res = "Bosque, sierra o matorral";
                break;
            case "lindero":
                res = "Lindero";
                break;
            case "bosqueisla":
                res = "Bosque isla";
                break;
            default:
                res = "Cultivos Leñosos";
                break;
          }
        return res;
    }

    const changeMetrosLinderos = (value) => {
        if(checkDoublePattern(value)){
            setBioVegetal((prevData) => {
                return {
                    ...prevData,
                    muestreos: prevData.muestreos.map(item => {
                        if (item.tipoVegetacion === "lindero") {
                            return {
                                ...item,
                                metroslinealesLindero: value
                            };
                        }
                        return item;
                    })
                };
            });
        }
    }

    const validateForm = () =>{
        setErrors({});
        let inputIds = "";
        let valid = true;
        console.log(bioVegetal);
        
        //Igual aqui hay que hacer el filtro

        bioVegetal.muestreos.map((value) =>{
            value.muestreos.map((muestreo, index) => { //Por tipoVegetal
                let muestreoContador = 0;
                muestreo.muestras.map((muestra, j) => {
                    if(muestra?.eliminar !== true){
                        if(bioVegetal.tipoAnalisis === 0){ //Si avanzado
                            if(muestra.num_individuos === ""){
                                setErrors(errors => ({...errors, ["errorEspecie-" + muestreo.tipoVegetacion + "-" + index + "-" + j + "NI"]: true, ["errorEspecie-" + muestreo.tipoVegetacion + "-" + index + "-" + j + "NIMsg"]: ""}));
                                inputIds = inputIds ? inputIds +(",especieNI-" + muestreo.tipoVegetacion + "-" + index + "-" + j) : ("especieNI-" + muestreo.tipoVegetacion + "-" + index + "-" + j);
                                valid = false;
                            }
                            if(muestra.num_individuos <= 0){
                                setErrors(errors => ({...errors, ["errorEspecie-" + muestreo.tipoVegetacion + "-" + index + "-" + j + "NI"]: true, ["errorEspecie-" + muestreo.tipoVegetacion + "-" + index + "-" + j + "NIMsg"]: "Min: 1"}));
                                inputIds = inputIds ? inputIds +(",especieNI-" + muestreo.tipoVegetacion + "-" + index + "-" + j) : ("especieNI-" + muestreo.tipoVegetacion + "-" + index + "-" + j);
                                valid = false;
                            }
                        }
                        if(muestra.nombre === ""){
                            setErrors(errors => ({...errors, ["errorEspecie-" + muestreo.tipoVegetacion + "-" + index + "-" + j + "Nombre"]: true, ["errorEspecie-" + muestreo.tipoVegetacion + "-" + index + "-" + j + "NombreMsg"]: ""}));
                            inputIds = inputIds ? inputIds +(",especieNombre-" + muestreo.tipoVegetacion + "-" + index + "-" + j) : ("especieNombre-" + muestreo.tipoVegetacion + "-" + index + "-" + j);
                            valid = false;
                        }
                        muestreoContador++;
                    }
                });
                if(muestreoContador === 0){
                    setErrors(errors => ({...errors, ["errorTipoVegetacion-" + muestreo.tipoVegetacion + "-Vacio"]: true, ["errorTipoVegetacion-" + muestreo.tipoVegetacion + "-VacioMsg"]: "Debe incluir al menos una especie o muestreo"}));
                    inputIds = inputIds ? inputIds +(",tipoVegetacionVacio-" + muestreo.tipoVegetacion ) : ("tipoVegetacionVacio-" + muestreo.tipoVegetacion);
                    valid = false;
                }
            });
            if(value.metroslinealesLindero === ""){
                setErrors(errors => ({...errors, ["errorMetrosLineales"]: true, ["errorMetrosLinealesMsg"]: "Debe indicar la distancia de metros lineales de lindero por muestreo."}));
                inputIds = inputIds ? inputIds +(",metrosLineales" ) : ("metrosLineales");
                valid = false;
            }
              
        });


        if(inputIds !== ""){
            try{
                let elem = document.getElementById(inputIds.split(",")[0]);
                elem.scrollIntoView({behavior: "smooth"});
                elem.focus();
            }catch(err){

            }
        }
        return valid;
    }

    const handleNext = (e) => {
        e.preventDefault();
        if(validateForm()){
            setPosition(1)
        }
    };
    
    return(
        <>
            <div className="tic4bio-home">
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    {loading ? 
                        (
                            <Box p={3} align="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <div>
                                    <h1 className="tic4bio-home-title">Editar Biodiversidad vegetal</h1>
                                    {!error ?
                                        <>
                                            {bioVegetal.id ?
                                                <>
                                                    <p style={{color: "rgba(0, 0, 0, 0.6)", margin: "42px 0px"}}>Finca seleccionada: <span style={{color: "#80C606", fontSize: "20px", fontWeight: "700"}}>{bioVegetal.nombre_finca}</span></p>
                                                    <p style={{color: "rgba(0, 0, 0, 0.6)", margin: "42px 0px"}}>Comunidad vegetal seleccionada: <span style={{color: "#80C606", fontSize: "20px", fontWeight: "700"}}>{bioVegetal.nombre_comunidad}</span></p>
                                                    {position === 0 ? 
                                                        <>
                                                            {bioVegetal.muestreos.map((muestreo, index) => {
                                                                return (
                                                                    <Fragment key={muestreo.tipoVegetacion + "-" + index} >
                                                                        <button id={"tipoVegetacionVacio-" + muestreo.tipoVegetacion} className={"dropdown-box green active"} onClick={(e) => e.preventDefault()}>{printNameText(muestreo.tipoVegetacion)}</button>
                                                                        {(muestreo.tipoVegetacion === "cultivoslenosos" || muestreo.tipoVegetacion === "bosquesierramatorral" || muestreo.tipoVegetacion === "bosqueisla") &&
                                                                            <>
                                                                                {bioVegetal.tipoAnalisis === 1 ? 
                                                                                    <>
                                                                                        <p>Debe establecer un área de muestreo de 20 m e indicar el nombre de las distintas especies vegetales que identifique. El área de muestreo debe establecerse en una zona representativa de la vegetación presente en toda la comunidad.</p>
                                                                                        <p>El icono <a href="https://lens.google/intl/es/" target="_blank" style={{verticalAlign: 'sub', display: 'inline-block', height: '20px'}}><img src={googlelens} alt="Icono Google Lens" /></a> le redirigirá a Google Lens, que le proporcionará ayuda para identificar especies a partir de una foto.</p>
                                                                                        <TableBasicEditNoSamplingComponent stateForm={bioVegetal} setStateForm={setBioVegetal} tipoVegetacion={muestreo.tipoVegetacion} errors={errors} />
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <p>Debe establecer un área de muestreo de 20 m e indicar el nombre de las distintas especies vegetales que identifique y <b>el nº de individuos</b> de cada uno. El área de muestreo debe establecerse en una zona representativa de la vegetación presente en toda la comunidad.</p>
                                                                                        <p>El icono <a href="https://lens.google/intl/es/" target="_blank" style={{verticalAlign: 'sub', display: 'inline-block', height: '20px'}}><img src={googlelens} alt="Icono Google Lens" /></a> le redirigirá a Google Lens, que le proporcionará ayuda para identificar especies a partir de una foto.</p>
                                                                                        <TableAdvancedEditNoSamplingComponent stateForm={bioVegetal} setStateForm={setBioVegetal} tipoVegetacion={muestreo.tipoVegetacion} errors={errors} />
                                                                                    </>
                                                                                }

                                                                            </>
                                                                        }
                                                                        {!(muestreo.tipoVegetacion === "cultivoslenosos" || muestreo.tipoVegetacion === "bosquesierramatorral" || muestreo.tipoVegetacion === "bosqueisla") &&
                                                                            <>
                                                                                {bioVegetal.tipoAnalisis === 1 ? 
                                                                                    <>
                                                                                        <p>Debe indicar el nombre de los cultivos presentes en la comunidad seleccionada.</p>
                                                                                        <p>El icono <a href="https://lens.google/intl/es/" target="_blank" style={{verticalAlign: 'sub', display: 'inline-block', height: '20px'}}><img src={googlelens} alt="Icono Google Lens" /></a> le redirigirá a Google Lens, que le proporcionará ayuda para identificar especies a partir de una foto.</p>
                                                                                        <ol>
                                                                                            <li>Sobre la cubierta o zona de cultivo (<img src={tinyterrain} style={{width: "40px", display: "inline-block", verticalAlign: "middle"}} alt=""/>) lance o establezca un cuadrante de 1 x 1 m (<img src={redsquare} style={{width: "20px", display: "inline-block", verticalAlign: "middle"}} alt=""/>)</li>
                                                                                            <div style={{position: "relative", display: "inline-block"}}>
                                                                                                <img src={terrain} style={{maxWidth: "300px", width: "100%"}} alt=""/>
                                                                                                <img src={redsquare} style={{width: "34px", position: "absolute", top: "40%", left: "36%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                                            </div>
                                                                                            <li>Observe y anote el nombre de las especies que distinga. Puede ayudarse de la herramienta Google Lens.</li>
                                                                                            <li>Repita el proceso estableciendo 2 o 3 puntos de muestreos adicionales. Distribuya los puntos de muestreo aleatoriamente sobre el terreno y en zonas representativas. (Evitar zonas con crecimiento anormalmente alto de vegetación, o viceversa, parches del terreno con problemas de nascencia o crecimiento)</li>
                                                                                            <div style={{position: "relative", display: "inline-block"}}>
                                                                                                <img src={terrain} style={{maxWidth: "300px", width: "100%"}} alt=""/>
                                                                                                <img src={redsquare} style={{width: "22px", position: "absolute", top: "40%", left: "32%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                                                <img src={bluesquare} style={{width: "22px", position: "absolute", top: "32%", left: "52%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                                                <img src={bluesquare} style={{width: "22px", position: "absolute", top: "50%", left: "48%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                                                <img src={bluesquare} style={{width: "22px", position: "absolute", top: "40%", left: "64%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                                            </div>
                                                                                            <li>Anotar el nombre de las NUEVAS especies que identifique en cada cuadrante.</li>
                                                                                        </ol>
                                                                                        <TableBasicEditSamplingComponent stateForm={bioVegetal} setStateForm={setBioVegetal} tipoVegetacion={muestreo.tipoVegetacion} errors={errors} />
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <p>Debe indicar el nombre de los cultivos presentes en la comunidad seleccionada y <b>nº de individuos</b> de cada especie identificada.</p> 
                                                                                        <p>El icono <a href="https://lens.google/intl/es/" target="_blank" style={{verticalAlign: 'sub', display: 'inline-block', height: '20px'}}><img src={googlelens} alt="Icono Google Lens" /></a> le redirigirá a Google Lens, que le proporcionará ayuda para identificar especies a partir de una foto.</p>
                                                                                        <ol>
                                                                                            <li>Sobre la cubierta o zona de cultivo (<img src={tinyterrain} style={{width: "40px", display: "inline-block", verticalAlign: "middle"}} alt=""/>) lance o establezca un cuadrante de 1 x 1 m (<img src={redsquare} style={{width: "20px", display: "inline-block", verticalAlign: "middle"}} alt=""/>)</li>
                                                                                            <div style={{position: "relative", display: "inline-block"}}>
                                                                                                <img src={terrain} style={{maxWidth: "300px", width: "100%"}} alt=""/>
                                                                                                <img src={redsquare} style={{width: "34px", position: "absolute", top: "40%", left: "36%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                                            </div>
                                                                                            <li>Observe y anote el nombre de las especies que distinga dentro del cuadrante, así como el nº de individuos/especie . Ayúdese de la herramienta Google Lens.</li>
                                                                                            <li>Repita el proceso estableciendo 2 o 3 puntos de muestreos adicionales. Distribuya los puntos de muestreo aleatoriamente sobre el terreno y en zonas representativas. (Evitar zonas con crecimiento anormalmente alto de vegetación, o viceversa, parches del terreno con problemas de nascencia o crecimiento)</li>
                                                                                            <div style={{position: "relative", display: "inline-block"}}>
                                                                                                <img src={terrain} style={{maxWidth: "300px", width: "100%"}} alt=""/>
                                                                                                <img src={redsquare} style={{width: "22px", position: "absolute", top: "40%", left: "32%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                                                <img src={bluesquare} style={{width: "22px", position: "absolute", top: "32%", left: "52%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                                                <img src={bluesquare} style={{width: "22px", position: "absolute", top: "50%", left: "48%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                                                <img src={bluesquare} style={{width: "22px", position: "absolute", top: "40%", left: "64%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                                            </div>
                                                                                            <li>Anotar el nombre de las especies que identifique en cada cuadrante, así como el nº individuos/especie (Nº ind.).</li>
                                                                                        </ol>
                                                                                        {muestreo.tipoVegetacion === "lindero" &&
                                                                                            <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                                                                                <TextField
                                                                                                    className="t4b-finca-sector-inputs no-red-label"
                                                                                                    label="Metros lineales del lindero/muestreo*"
                                                                                                    id={"metrosLineales"}
                                                                                                    value={muestreo.metroslinealesLindero}
                                                                                                    type="number"
                                                                                                    variant="standard"
                                                                                                    InputProps={{
                                                                                                        inputProps: { 
                                                                                                            max: 99999, min: 1,
                                                                                                        }
                                                                                                    }}
                                                                                                    onChange={(e) => {changeMetrosLinderos(e.target.value)}}
                                                                                                    name="metrosLineales"
                                                                                                    error={errors["errorMetrosLineales"]}
                                                                                                />
                                                                                                {errors["errorMetrosLineales"] && <p className="errorMsg">{errors["errorMetrosLinealesMsg"]}</p>}
                                                                                            </FormControl>
                                                                                        }
                                                                                        <TableAdvancedEditSamplingComponent stateForm={bioVegetal} setStateForm={setBioVegetal} tipoVegetacion={muestreo.tipoVegetacion} errors={errors} />
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        }
                                                                        {errors["errorTipoVegetacion-" + muestreo.tipoVegetacion + "-Vacio"] && <p className="errorMsg">{errors["errorTipoVegetacion-" + muestreo.tipoVegetacion + "-VacioMsg"]}</p>}
                                                                    </Fragment>
                                                                );
                                                            })}
                                                            <Stack spacing={2} direction="row" justifyContent="space-between" className="tic4bio-sectores-btns">
                                                                <button className="btn green" onClick={(e) => handleNext(e)} style={{margin: "16px 0"}}>Siguiente</button>
                                                            </Stack>
                                                        </>
                                                    :
                                                        <>
                                                            <BioVegetalEditarResumenComponent stateForm={bioVegetal} setPosition={setPosition} />
                                                        </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    <p>No se ha encontrado ninguna biodiversidad.</p>
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            <p>Se ha producido un error inesperado. Vuelva a intentarlo más tarde.</p>
                                        </>
                                    }
                                </div>
                            </>
                        )
                    }
                </Box>
            </div>
        </>
    );
} 
export default BioVegetalEditarPage;