import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

function BarChartHAAlmazaraEvolucion({ startCampana, endCampana, campanas, data, finca }) {

  const chartRef = useRef(null);
  const navigate = useNavigate();
  const [ chartInfo, setChartInfo ] = useState();

  const backgroundColors = [
    'rgba(122,154,200, 0.8)',
  ];

  const borderColors = [
    'rgba(122,154,200, 1)',
  ];

  useEffect(() => {
    chooseOption();
  }, [startCampana, endCampana]);

  useEffect(() => {
    const chart = chartRef.current;

    const handleClick = (event) => {
      
        const canvasPosition = {
            x: event.offsetX,
            y: event.offsetY,
        };

        const dataXLabels = chart.scales.x.ticks;
        
        // Calcular la posición de las etiquetas en el eje X
        dataXLabels.forEach((label, index) => {
            const xLabelPosition = chart.scales.x.getPixelForTick(index);
            const labelWidth = 100; // Ancho aproximado de la etiqueta

            // Verificar si el clic está dentro del rango de la etiqueta
            if (canvasPosition.x >= xLabelPosition - labelWidth / 2 && canvasPosition.x <= xLabelPosition + labelWidth / 2) {

              let posElement = chart.data.labels.indexOf(label.label);
              let huellaId = chart.data.datasets[0].huellasIds[posElement];

              let fincaAux;
              if(finca.fincas.length > 0){
                fincaAux = finca.fincas.find(s => {
                  if(s.dataType === "industria"){
                    return s.id;
                  }
                });
              }
              console.log(fincaAux.id);
              console.log(huellaId);
              navigate(`/huella-almazaras/${fincaAux.id}`, {
                  state: { huellaId: huellaId }
              });
            }
        });
    };

    if (chart) {
        chart.canvas.addEventListener('click', handleClick);
    }

    return () => {
        if (chart?.canvas) {
            chart.canvas.removeEventListener('click', handleClick);
        }
    };
  }, [chartRef, chartInfo]);

  const generateCampanas = () => {
    const indiceInicio = campanas.indexOf(startCampana);
    const indiceFin = campanas.indexOf(endCampana);

    return campanas.slice(indiceInicio, indiceFin + 1);
  }

  const generateDataSets = (subData) => {
    let labels = [{key: "huellaAguaKg", value: "HA Azul (l/kg aceituna molturada)"}];
    
    const result = labels.map((label, index) => ({
      label: label.value,
      data: Object.values(subData).map((campanaData) => campanaData.almazaras[0][label.key]),
      backgroundColor: backgroundColors[index],
      borderColor: borderColors[index],
      borderWidth: 1,
      huellasIds: Object.values(subData).map((campanaData) => campanaData.almazaras[0].id),
    }));
    return result;
  }

  const chooseOption = () => {
    let subData;

    subData = data.unidad.infoUnidad;

    const labelsCampanas = generateCampanas();

    const filteredData = Object.keys(subData)
    .filter((key) => labelsCampanas.includes(key))
    .reduce((obj, key) => {
      obj[key] = subData[key];
      return obj;
    }, {});

    let someData = Object.values(filteredData).some(c => c.almazaras[0]);
    
    if(someData){

      const info = {
        labels: labelsCampanas,
        datasets: generateDataSets(filteredData)
      }

      setChartInfo(info);
    }else{
      setChartInfo();
    }

  }

  const options = {
    layout: {
      padding: {
          top: 0,
          bottom: 100,
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      x:{
        stacked: true
      },
      y: {
        stacked: true,
        type: 'logarithmic',
        beginAtZero: true,
        ticks: {
          maxTicksLimit: 5,
          callback: function(value, index, values) {
            return Number(value.toString());
          }
        }
      },
    },
  };

    return (
      <>
        {chartInfo &&
          <>
              <div>
                <h3 style={{textAlign: "center", textDecoration: "underline", color: "#80C606", fontSize: "26px", marginBottom: "40px"}}>Huella Agua (l/kg)</h3>
                <div style={{minHeight: "600px"}}>
                  <Bar data={chartInfo} options={options} ref={chartRef}/>
                </div>
              </div>
          </>
        }
        {!chartInfo &&
          <p>No existe información disponible para el rango de campañas seleccionadas en el <b>gráfico de huella de agua almazara</b></p>
        }
      </>
    );
}

export default BarChartHAAlmazaraEvolucion;