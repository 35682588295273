import { useEffect, useState } from "react";
import { instance } from '../utils/axios-config.js';
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';
import ModalInfo from '../components/ModalInfo.js';
import { useAuth } from "../context/Auth.js";
import ActionMessage from "../components/ActionMessage.js";

import DetallesComunidadComponent from "../components/DetallesBioVegetal/DetallesComunidad.js";
import DetallesFincaCompletaComponent from "../components/DetallesBioVegetal/DetallesFincaCompleta.js";
import Mensajes from "../utils/mensajes.js";



const BioVegetalDetallesPage = () => {

    const {sessionToken} = useAuth();
    const { id } = useParams();
    const {state} = useLocation();

    const [ fincas, setFincas ] = useState([]);
    const [ finca, setFinca ] = useState({id: ""});
    const [ comunidades, setComunidades ] = useState([]);
    const [ comunidad, setComunidad ] = useState({id: ""});
    const [ bioVegetales, setBioVegetales ] = useState([]);
    const [ bioVegetalesFilter, setBioVegetalesFilter ] = useState([]);
    const [ bioVegetal, setBioVegetal ] = useState({id: ""});
    const [ bioVegetalesByYear, setBioVegetalesByYear ] = useState([]);
    const [ year, setYear ] = useState("");
    const [ loading, setLoading ] = useState(true);
    const [ loadingComunidades, setLoadingComunidades ] = useState(false);
    const [ loadingBios, setLoadingBios ] = useState(false);
    const [ msg, setMsg ] = useState( state ? state : undefined);
    const [ open, setOpen ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState({open: false, title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado. Por favor, vuelve a intentarlo más tarde."});

    const [ data, setData ] = useState({
        labels: ['Bosquejo 1', 'Bosquejo 2', 'Bosquejo 3'],
        datasets: [
            {
              label: 'RIQUEZA ESPECIFICA',
              data: [25, 25, 50],
              backgroundColor: [
                'rgba(122,154,200, 0.8)',
                'rgba(128, 198, 6, 0.8)',
                'rgba(140, 228, 46, 0.8)'
              ],
              borderColor: [
                  'rgba(122,154,200, 0.8)',
                  'rgba(128, 198, 6, 0.8)',
                  'rgba(140, 228, 46, 0.8)'
              ],
              borderWidth: 2
            },
        ],
    });

    const [ infoData, setInfoData ] = useState({
        labels: ["Índice de Simpson (D)", ["Índice de Diversidad", "de Simpson (1 - D)"], ["Índice de equidad", "de Pielou (J')"]],
        datasets: [
            {
            data: [65, 59, 70],
            backgroundColor: 'rgba(0, 0, 0, 0)',
            borderColor: 'rgba(128, 198, 6, 1)',
            borderWidth: 2,
            },
        ],
    });

    const backgroundColors = [
        'rgba(122,154,200, 0.8)',
        'rgba(128, 198, 6, 0.8)',
        'rgba(140, 228, 46, 0.8)',
        'rgba(255, 99, 132, 0.8)',
        'rgba(54, 162, 235, 0.8)',
        'rgba(255, 206, 86, 0.8)',
        'rgba(75, 192, 192, 0.8)',
        'rgba(153, 102, 255, 0.8)',
    ];

    useEffect(() => {
        if(state){
            loadAll();
        }else{
            loadPageData();
        }
    }, []);

    const loadAll = async () => {
        let buildFincasUrl = `/fincas/fincasByDataType/finca`;
        let buildComunidadesUrl = `/biodiversidad/comunidad-list/${state.fincaId}`;
        let buildBioVegetalesComunidadesUrl = `/biodiversidad/comunidades-biovegetal-list/${state.fincaId}`;
        //let buildBioVegetalesUrl = `/biodiversidad/comunidad-biovegetal-list/${state.comunidadId}`;
        instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        try{
            await Promise.all([instance.get(buildFincasUrl), instance.get(buildComunidadesUrl),instance.get(buildBioVegetalesComunidadesUrl)]).then(function(responses){
                setFincas(responses[0].data);

                let fnc = responses[0].data.find((f) => f.id === +state.fincaId);
                if (fnc) {
                    setFinca(fnc);
                }

                setComunidades(responses[1].data);
                let cmn;
                if(state.comunidadId === "FINCA COMPLETA"){
                    setComunidad({id: "FINCA COMPLETA"});
                    cmn = {id: "FINCA COMPLETA"};
                }else{
                    cmn = responses[1].data.find((c) => c.id === +state?.comunidadId);
                    if(cmn){
                        setComunidad(cmn);
                    }
                }

                let bVegetales = responses[2].data.map((item) => {
                    let re = calculoRiquezaEspecifica(item);
                    item.riquezaEspecifica = re;
                    return item;
                });
                setBioVegetales(bVegetales);
                let bVegetalesFiltered = bVegetales.filter(bv => bv.comunidad_id === cmn.id);
                setBioVegetalesFilter(bVegetalesFiltered);

                let yearBVegetales = groupDataByYear(bVegetales);
                setBioVegetalesByYear(yearBVegetales);
                let bio = bVegetales.find(bv => bv.id === +id);
                if(bio){
                    if(bio.tipoAnalisis === 0){
                        let resbv = calculosEnMuestras(bio);
                        setInfoData(prevState => ({
                            ...prevState,
                            datasets: prevState.datasets.map(dataset => ({
                                ...dataset,
                                data: [resbv.indiceSimpson, resbv.indiceDiversidadSimpson, resbv.indicePielou]
                            })),
                        }));
                        bio = resbv;
                    }else{
                        bio.especiesTotales = totalEspecies(bio);
                        bio.numEspeciesTotales = 0;
                    }
                    setBioVegetal(bio);
                }
                if(state.year){

                    let bvs = yearBVegetales[state.year];
                    if(bvs){
                        let mpc = muestrasPorComunidad(bvs);
                        setData(prevState => ({
                            ...prevState,
                            labels: mpc.labels,
                            datasets: prevState.datasets.map(dataset => ({
                                ...dataset,
                                data: mpc.data,
                                backgroundColor: chooseColor(mpc.data.length),
                            })),
                        }));
                        
                        setYear(state.year);
                    }
                    
                }

            }).catch(function(error){
                setLoading(false);
                setOpen(true);
                setError(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar los datos. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });

        }catch(error){
            setLoading(false);
            setOpen(true);
            setError(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar los datos. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }
    
    const loadPageData = async () => {
        let buildUrl = `/fincas/fincasByDataType/finca`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}

            await instance.get(buildUrl).then(function(resp) {
                setFincas(resp.data);

            }).catch(function(resp) {
                setOpen(true);
                setError(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar la información de las fincas. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setLoading(false);
            setOpen(true);
            setError(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar la información de las fincas. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const loadComunidadOnChange = async (fincaId) => {
        try{
            let buildUrl = `/biodiversidad/comunidad-list/${fincaId}`;
            let buildBioVegetalesComunidadesUrl = `/biodiversidad/comunidades-biovegetal-list/${fincaId}`;

            setLoadingComunidades(true);
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}

            //await instance.get(buildUrl).then(function(resp) {
            await Promise.all([instance.get(buildUrl), instance.get(buildBioVegetalesComunidadesUrl)]).then(function(resp) {
                //setComunidades(resp.data);
                setComunidades(resp[0].data);

                let bVegetales = resp[1].data.map((item) => {
                    let re = calculoRiquezaEspecifica(item);
                    item.riquezaEspecifica = re;
                    return item;
                });
                setBioVegetales(bVegetales);

                let yearBVegetales = groupDataByYear(bVegetales);
                setBioVegetalesByYear(yearBVegetales);

                let mpc = muestrasPorComunidad(resp[1].data);
                setData(prevState => ({
                    ...prevState,
                    labels: mpc.labels,
                    datasets: prevState.datasets.map(dataset => ({
                        ...dataset,
                        data: mpc.data,
                        backgroundColor: chooseColor(mpc.data.length),
                    })),
                }));
            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar las comunidades de la finca. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoadingComunidades(false);
            });
            setLoadingComunidades(false);
        }catch(error){
            setLoadingComunidades(false);
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar las comunidades de la finca. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const loadBiosOnChange = async (comunidadId) => {
        try{
            setLoadingBios(true);
            let bVegetales = bioVegetales.filter(bv => bv.comunidad_id === comunidadId);
            setBioVegetalesFilter(bVegetales);
            setLoadingBios(false);
        }catch(err){
            setLoadingBios(false);
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar las biodiversidades vegetales de la comunidad. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const loadBioVegetalOnChange = async (event) => {
        let comunidad = null;

        if(event.target.value === "FINCA COMPLETA"){
            setComunidad({id: "FINCA COMPLETA"});
        }else{
            let found = comunidades.find((obj) => obj.id === event.target.value);
            if (found) {
                comunidad = found;
            }
    
            setComunidad(comunidad);
    
            loadBiosOnChange(event.target.value);
        }

        setBioVegetal({id: ""});
        setYear("");
    }

    const handleLoadComunidades = async (event) => {
        let finca = null;
        
        let found = fincas.find((obj) => obj.id === event.target.value);
        if (found) {
            finca = found;
        }

        setFinca(finca);
        setComunidad({id: ""});

        loadComunidadOnChange(event.target.value);
    }

    const handleFechaSelect = (event) => {
        let bv = bioVegetalesFilter.find((item) => item.id === event.target.value);
        if(bv){
            if(bv.tipoAnalisis === 0){
                let resbv = calculosEnMuestras(bv);
                setInfoData(prevState => ({
                    ...prevState,
                    datasets: prevState.datasets.map(dataset => ({
                        ...dataset,
                        data: [resbv.indiceSimpson, resbv.indiceDiversidadSimpson, resbv.indicePielou]
                    })),
                }));
                bv = resbv;
            }else{
                bv.especiesTotales = totalEspecies(bv);
                bv.numEspeciesTotales = 0;
            }
            setBioVegetal(bv);
        }
    }

    const handleYearSelect = (event) => {
        let bvs = bioVegetalesByYear[event.target.value];
        if(bvs){
            let mpc = muestrasPorComunidad(bvs);
            setData(prevState => ({
                ...prevState,
                labels: mpc.labels,
                datasets: prevState.datasets.map(dataset => ({
                    ...dataset,
                    data: mpc.data,
                    backgroundColor: chooseColor(mpc.data.length),
                })),
            }));
            
            setYear(event.target.value);
        }
    }

    const chooseColor = (len) => {
        let bgColors = [];
        for(let i=0; i<len; i++){
            bgColors.push(backgroundColors[i % backgroundColors.length]);
        }
        return bgColors;
    }

    const calculoRiquezaEspecifica = (bv) => {
        const muestrasUnicas = new Set();
        bv.muestreos.forEach(muestreo => {
            muestreo.muestras.forEach(muestra => {
                muestrasUnicas.add(muestra.nombre);
            });
        });
        return muestrasUnicas.size;
    }


    const muestrasPorComunidad = (dataList) => {
        const comunidades = {};

        dataList.forEach(objeto => {
            const nombreComunidad = objeto.nombre_comunidad;

            if (!comunidades[nombreComunidad]) {
                comunidades[nombreComunidad] = new Set();
            }

            objeto.muestreos.forEach(muestreo => {
                muestreo.muestras.forEach(muestra => {
                    comunidades[nombreComunidad].add(muestra.nombre);
                });
            });
        });

        const labels = [];
        const data = [];

        Object.keys(comunidades).forEach(comunidad => {
            labels.push(comunidad);
            data.push(comunidades[comunidad].size);
        });

        return { labels, data };
    }

    const totalEspecies = (bv) => {
        const nombresUnicos = new Set();

        bv.muestreos.forEach(muestreo => {

            muestreo.muestras.forEach(muestra => {

                nombresUnicos.add(muestra.nombre);
            });
        });

        return Array.from(nombresUnicos);
    }


    const calculosEnMuestras = (bv) => {
        let sumasPorNombre = {};
        let sumaTotal = 0;
        let resultList = [];
        let indiceSimpson = 0;
        let indiceDiversidadSimpson = 0;
        let indicePielou = 0;
        let indiceShannonWiener = 0;
        bv.muestreos.forEach((muestreo, index) => {
            let cantidad = muestreo.numMuestreos;
            muestreo.muestras.forEach(muestra => {
                const nombre = muestra.nombre;
                const numIndividuos = muestra.num_individuos_com;

                if (sumasPorNombre[nombre]) {
                    if(sumasPorNombre[nombre].tipoMuestreo === index && cantidad === 1){
                        sumasPorNombre[nombre].suma += numIndividuos;
                    }else{
                        sumasPorNombre[nombre].suma.push(numIndividuos);
                        sumasPorNombre[nombre].cantidad.push(cantidad);
                    }
                } else {
                    sumasPorNombre[nombre] = {
                        suma: [numIndividuos],
                        cantidad: [cantidad],
                        tipoMuestreo: index
                    };
                }
            });
        });

        for (const nombre in sumasPorNombre) {
            const { suma, cantidad } = sumasPorNombre[nombre];

            let media = 0;
            if(cantidad.length === 1){
                media = suma[0] / cantidad[0];
                sumaTotal += media;
                sumasPorNombre[nombre].sumaInd = media;
            }else{
                cantidad.map((c, index) => {
                    media += suma[index] / c;
                });
                sumaTotal += media;
                sumasPorNombre[nombre].sumaInd = media;
            }
        }

        sumaTotal = Math.round(sumaTotal);

        for (const nombre in sumasPorNombre) {
            const { sumaInd } = sumasPorNombre[nombre];

            let pi;
            let pisquare;
            let h;
            pi = (sumaInd/sumaTotal);
            pisquare = pi ** 2;
            h = getH(pi);

            indiceSimpson += pisquare;
            indiceShannonWiener += h;
            resultList.push({nombre: nombre, nIndividuos: sumaInd, pi: pi, pisquare: pisquare, h: h});
        }

        indiceDiversidadSimpson = 1 - indiceSimpson;
        indicePielou = indiceShannonWiener / (Math.log(Object.keys(sumasPorNombre).length));

        bv.muestrasFiltradas = resultList;
        bv.numEspeciesTotales = Math.round(sumaTotal);
        bv.indiceSimpson = parseFloat(indiceSimpson.toFixed(2));
        bv.indiceDiversidadSimpson = parseFloat(indiceDiversidadSimpson.toFixed(2));
        bv.indicePielou = parseFloat(indicePielou.toFixed(2));
        bv.indiceShannonWiener = parseFloat(indiceShannonWiener.toFixed(2));

        return bv;
    }

    const getH = (pi) => {
        let res = 0;
        if(pi > 0){
            res = -(pi * Math.log(pi));
        }
        return res;
    }

    const groupDataByYear = (bVegetales) => {
        const grouped = {};
        bVegetales.forEach(obj => {
          const year = obj.fecha.split("/")[2];
          if (!grouped[year]) {
            grouped[year] = [];
          }
          grouped[year].push(obj);
        });
        return grouped;
    };
   
    return(
        <div className="width100">
            <div className="tic4bio-huella-section">VEGETACIÓN</div>

            <div className="tic4bio-home">
                {msg?.type &&
                    <ActionMessage msg={msg} setMsg={setMsg}/>
                }
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    {loading ? 
                        (
                            <Box p={3} align="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <div>
                                    <h1 className="tic4bio-home-title">Biodiversidad vegetal</h1>
                                    {!error ?
                                        <>
                                            {(fincas.length > 0) ?
                                                <>
                                                    <p>Seleccione la finca de la biodiversidad vegetal que desea visualizar*</p>
                                                    <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                        <InputLabel htmlFor="finca-select">Fincas*</InputLabel>
                                                        <Select
                                                            className="t4b-finca-sector-inputs"
                                                            id="finca-select"
                                                            label="Finca"
                                                            required
                                                            MenuProps={{ disableScrollLock: true }}
                                                            disabled={loadingComunidades}
                                                            value={finca.id}
                                                            onChange={(event) => { handleLoadComunidades(event) }    
                                                            }
                                                            >
                                                            
                                                            {fincas.map((finca, index) => (
                                                            <MenuItem key={index} value={finca.id}>
                                                                {finca.unidadProductiva} - {finca.nombre}
                                                            </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    {loadingComunidades ? (
                                                        <Box p={3} align="center">
                                                            <CircularProgress />
                                                        </Box>
                                                        ) : (
                                                            <>
                                                                {finca.id &&
                                                                    <>
                                                                        {(comunidades.length > 0) ?
                                                                            <>
                                                                                <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                                                    <InputLabel htmlFor="comunidad-select">Comunidades*</InputLabel>
                                                                                    <Select
                                                                                        className="t4b-finca-sector-inputs"
                                                                                        id="comunidad-select"
                                                                                        label="Comunidad"
                                                                                        required
                                                                                        MenuProps={{ disableScrollLock: true }}
                                                                                        disabled={false}
                                                                                        value={comunidad.id}
                                                                                        onChange={(event) => { loadBioVegetalOnChange(event) }
                                                                                        }>
                                                                                    
                                                                                        <MenuItem value={"FINCA COMPLETA"}>FINCA COMPLETA</MenuItem>
                                                                                        {comunidades.map((comunidad, index) => (
                                                                                            <MenuItem key={index} value={comunidad.id}>
                                                                                                {comunidad.nombre}
                                                                                            </MenuItem>
                                                                                        ))}
                                                                                    </Select>
                                                                                </FormControl>
                                                                                
                                                                                {loadingBios ? (
                                                                                    <Box p={3} align="center">
                                                                                        <CircularProgress />
                                                                                    </Box>
                                                                                    ) : (
                                                                                        <>
                                                                                            {(comunidad.id && comunidad.id !== "FINCA COMPLETA") &&
                                                                                                <>
                                                                                                    <DetallesComunidadComponent bioVegetal={bioVegetal} bioVegetales={bioVegetalesFilter} handleFechaSelect={handleFechaSelect} infoData={infoData}/>
                                                                                                </>
                                                                                            }
                                                                                            {comunidad.id === "FINCA COMPLETA" &&
                                                                                                <>
                                                                                                    <DetallesFincaCompletaComponent year={year} bioVegetalesByYear={bioVegetalesByYear} handleYearSelect={handleYearSelect} data={data} />
                                                                                                </>
                                                                                            }
                                                                                        </>
                                                                                    )
                                                                                }
                                                                                                
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <p>Parece que la finca seleccionada no tiene comunidades asociadas. Necesitas al menos una comunidad y añadir alguna biodiversidad vegetal para poder visualizar su detalle.</p>
                                                                            </>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </>
                                                :
                                                <p><Mensajes type="vegetal"/></p>
                                            }
                                        </>
                                        :
                                        <>
                                            <p>Se ha producido un error inesperado. Vuelva a intentarlo más tarde.</p>
                                        </>
                                    }
                                    
                                </div>
                            </>
                        )
                    }
                </Box>
            </div>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.title} contentText={errorMsg.content}/>
        </div>
    );
} 
export default BioVegetalDetallesPage;