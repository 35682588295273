import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import leftarrow from "../../assets/left-arrow-svgrepo-com.svg";
import StepperHuellaAlmazaraComponent from "./StepperHuellaAlmazara";
import FormCampanaComponent from "./FormCampanaComponent";
import FormConsumoAguaComponent from "./FormConsumoAguaComponent";
import FormLimpiezaComponent from "./FormLimpiezaComponent";
import FormLimpieza2Component from "./FormLimpieza2Component";


const FormNuevaHuellaAlmazaraComponent = ({ setChoice }) => {

    const [ position, setPosition ] = useState(0);
    const [ stateForm, setStateForm ] = useState({
        nombre: "",
        campana: "",
        nDiasMolturacion: "",
        kgAceitunaMolturado: "",
        rendimientoMedio: "",
        precioAceite: "",
        consumoAguaTotal: "",
        almazara: {id: "", contadorGeneral: "", lavadora: "", ducha: "", molino: "", batidora: "", decanter: "", centrifuga: ""},
        equipos: {
            lavadora: {
                consumo: "",
                unidad: "l/min",
                capacidad: ""
            },
            ducha: {
                consumo: "",
                unidad: "l/min",
                capacidad: ""
            },
            molino: {
                consumo: "",
                unidad: "l/min",
                capacidad: ""
            },
            batidora: {
                consumo: "",
                unidad: "l/min",
                capacidad: ""
            },
            decanter: {
                consumo: "",
                unidad: "l/min",
                capacidad: ""
            },
            centrifuga: {
                consumo: "",
                unidad: "l/min",
                capacidad: ""
            }
        },
        aguaDeLimpieza: "",
        conoceCaudal: "",
        nZonas: "",
        zonas: {},
        zonasMantenimiento: {},
        exists: false
    });

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    useEffect(() => {
        document.title = "Nueva huella de agua almazara";
      
    }, []);

    return(
        <div className="tic4bio-home">
            <div>
                <button className="tic4bio-huella-back" onClick={() => setChoice("")}><img src={leftarrow}></img><span>Cambiar tipo</span></button>
            </div>
            <StepperHuellaAlmazaraComponent position={position} setPosition={setPosition} state={stateForm}/>  
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <FormCampanaComponent position={position} setPosition={setPosition} state={stateForm} setState={setStateForm} />
                <FormConsumoAguaComponent position={position} setPosition={setPosition} state={stateForm} setState={setStateForm} />
                <FormLimpiezaComponent position={position} setPosition={setPosition} state={stateForm} setState={setStateForm} />
                {(position === 3) &&
                    <>
                    <FormLimpieza2Component position={position} setPosition={setPosition} state={stateForm} setState={setStateForm} />
                    </>
                }
            </Box>
        </div>
    );
}

export default FormNuevaHuellaAlmazaraComponent;