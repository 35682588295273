import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, TextField } from "@mui/material";
import { useState } from "react";
import { checkDoublePattern } from "../../utils/commons";

const ModalCalculatorCultivosLenosos = ({open, setOpen, stateForm, setStateForm, tipoVegetacion}) => {

    const [calculos, setCalculos] = useState({
        da: "",
        dc: "",
        result: ""
    });
    
    const handleClose = (id) => {
        if(id){
            setStateForm(prevData => {
                const newData = prevData.muestreos.map(item => {
                  if (item.tipoVegetacion === tipoVegetacion) {
                    let muestras = item.muestreos[0].muestras.map(m => {
                        if(m.tempId === id){
                            return {...m, num_individuos: parseInt(calculos.result)}
                        }else{
                            return m;
                        }
                    });
                    const newItem = {
                        ...item,
                        muestreos: [{ ...item.muestreos[0], muestras: muestras }]
                    };
                    return newItem;
                  }else{
                    return item;
                  }
                });
                return { ...prevData, muestreos: newData };
            });
            setOpen({open: false, id: ""});
        }else{
            setOpen({open: false, id: ""});
        }
    };

    const calcular = (value, field) => {
        if(checkDoublePattern(value)){
            let res = "";
            if(field === "da" && value !== ""){
                res = ((10000 / (value * calculos.dc)) * stateForm.superficie_comunidad);
            }else if(field === "dc" && value !== ""){
                res = ((10000 / (calculos.da * value)) * stateForm.superficie_comunidad);
            }
            let parseValue = (res !== "" ? res.toFixed(2) : "");
            parseValue = (parseValue !== "" ? parseInt(parseValue) : "")
            setCalculos((prevData) => ({...prevData, [field]: value, result: parseValue}));
        }
    }

    const dialogStyle = {
        width: '380px',
      };

    return ( 
        <>
        <Dialog PaperProps={{ style: dialogStyle }} open={open.open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogContent>
                <h4>Nº de individuos/ especies</h4>
                <div style={{display: "flex",alignItems: "center"}}>
                    <span>nº árboles= </span>
                    <div>
                        <span>10.000</span>
                        <div style={{borderTop: "1px solid rgba(0, 0, 0, 0.6)", margin: "5px"}}></div>
                        <span>(𝐷𝐴 𝑥 𝐷𝐶 )</span>
                    </div>
                    <span> x S</span>
                </div>
                <ul>
                    <li>Distancia entre árboles (DA)</li>
                    <li>Distancia entre calles (DC)</li>
                    <li>Superficie de cultivo (S)</li>
                </ul>
                <div style={{display: "flex",alignItems: "center"}}>
                    <span>nº árboles= </span>
                    <div>
                        <span>10.000</span>
                        <div style={{borderTop: "1px solid rgba(0, 0, 0, 0.6)", margin: "5px"}}></div>
                        <span>(6 𝑥 7 )</span>
                    </div>
                    <span> x 5 </span>
                    <span>= 1190</span>
                </div>

                <h4 style={{marginBottom: 0}}>Calculadora</h4>
                <div>
                    <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                        <TextField
                            className="t4b-finca-sector-inputs no-red-label"
                            label="Distancia entre árboles (DA)*"
                            id={"distanciaArboles"}
                            value={calculos.da}
                            type="number"
                            variant="standard"
                            InputProps={{
                                inputProps: { 
                                    max: 9999, min: 1,
                                }
                            }}
                            onChange={(e) => {calcular(e.target.value, "da")}}
                            name="distanciaArboles"
                        />
                    </FormControl>
                    <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                        <TextField
                            className="t4b-finca-sector-inputs no-red-label"
                            label="Distancia entre calles (DC)*"
                            id={"distanciaCalles"}
                            value={calculos.dc}
                            type="number"
                            variant="standard"
                            InputProps={{
                                inputProps: { 
                                    max: 9999, min: 1,
                                }
                            }}
                            onChange={(e) => {calcular(e.target.value, "dc")}}
                            name="distanciaCalles"
                        />
                    </FormControl>
                    <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                        <TextField
                            className="t4b-finca-sector-inputs no-red-label"
                            label="Superficied de cultivo (S)*"
                            id={"superficieCultivo"}
                            value={stateForm.superficie_comunidad}
                            disabled={true}
                            type="number"
                            variant="standard"
                            name="superficieCultivo"
                        />
                    </FormControl>
                    <FormControl sx={{width: "100%"}} variant="standard">
                        <label htmlFor="resultado">Resultado:</label>
                        <TextField
                            className="t4b-finca-sector-inputs no-red-label"
                            id={"resultado"}
                            value={calculos.result}
                            disabled={true}
                            type="number"
                            variant="standard"
                            name="resultado"
                        />
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                {calculos.result ?
                    <button className="btn green" onClick={() => handleClose(open.id)}>Insertar Nº de indv.</button>
                    :
                    <button className="btn green" onClick={() => handleClose(null)}>Cerrar</button>
                }
            </DialogActions>
        </Dialog>
        </>
     );
}
 
export default ModalCalculatorCultivosLenosos;