import { FormControl, InputLabel, MenuItem, Select, CircularProgress, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/Auth";
import { instance } from "../../utils/axios-config";
import BarChartRiegoRisEvolucion from "../charts/BarChartRiegoRisEvolucion";
import BarChartRiegoRequerimientoEvolucion from "../charts/BarChartRiegoRequerimientoEvolucion";
import BarChartRiegoPrecipitacionesEvolucion from "../charts/BarChartRiegoPrecipitacionesEvolucion";
import BarChartRiegoProduccionEvolucion from "../charts/BarChartRiegoProduccionEvolucion";

const EvoRiegoComponent = ({finca, setError, setOpen, loadingEvoHuella, setLoadingEvoHuella}) => {

    const {sessionToken} = useAuth();

    const [ campanas, setCampanas ] = useState([]);
    const [ startCampana, setStartCampana ] = useState("");
    const [ endCampana, setEndCampana ] = useState("");
    const [ errorCampanas, setErrorCampanas ] = useState(false);
    const [ data, setData ] = useState({
        unidad: {
            check: false,
            infoUnidad: {},
            precipitacion: [],
        }
    });


    useEffect(() => {
        setCampanas([]);
        setStartCampana("");
        setEndCampana("");
        setErrorCampanas(false);
        setData({
            unidad: {
                check: false,
                infoUnidad: {},
                precipitacion: [],
            }
        });
        loadHuellaUnidad();
    }, [finca]); 

    const loadHuellaUnidad = async () => {
        setLoadingEvoHuella(true);
        let fincaSearch = finca.fincas.find(f => f.dataType === "finca");
        let buildPrecipitacion = `/huella/precipitacion-unidad-campanas/${fincaSearch?.id}`;
        let buildFullInfoUnidadUrl = `/huella/unidades-list/${finca.nombre}`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
            let requests = [instance.get(buildFullInfoUnidadUrl)];
            if(fincaSearch){
                requests.push(instance.get(buildPrecipitacion));
            }
            await Promise.all(requests).then(function(resp){
            //await instance.get(buildFullInfoUnidadUrl).then(function(resp){
                
                let cs = getCampanas(resp[0].data);
                setCampanas(cs);

                let infoUnidad = convertListToObject(sortByCampana(resp[0].data));

                let prec = [];

                if(fincaSearch){
                    let existingCampanas = new Set(resp[1].data.map(item => item.campana));

                    resp[0].data.forEach(item => {
                        if (!existingCampanas.has(item.campana)) {
                            resp[1].data.push({campana: item.campana, totalPrec: 0});
                            existingCampanas.add(item.campana); // Actualizar el conjunto para evitar duplicados
                        }
                    });
                    prec = convertListToObject(sortByCampana(resp[1].data));
                }
                
                setData(prevData => ({
                    ...prevData,
                    unidad: {
                        check: true,
                        infoUnidad: infoUnidad,
                        precipitacion: prec
                    }
                }));
            }).catch(function(err) {
                setOpen(true);
                setError(true);
            }).finally(()=>{
                setLoadingEvoHuella(false);
            });
        }catch(error){
            setLoadingEvoHuella(false);
            setOpen(true);
            setError(true);
        }
    }

    
    const handleStartCampanaSelect = (sc, ec) => {
        setStartCampana(sc);
        //Meter comprobacion de si ambos estan seleccionados
        CampanaValidation(sc, ec);
    }

    const handleEndCampanaSelect = (sc, ec) => {
        setEndCampana(ec);
        //Meter comprobacion de si ambos estan seleccionados
        CampanaValidation(sc, ec);
    }

    const getCampanas = (objects) => {
        const campanasArr = objects.map(obj => obj.campana);

        const campanasSet = Array.from(new Set(campanasArr));

        const compararCampanas = (a, b) => {
            //const [aInicio, aFin] = a.split(' ')[1].split('/').map(Number);
            //const [bInicio, bFin] = b.split(' ')[1].split('/').map(Number);
            const aYears = getYears(a);
            const bYears = getYears(b);
            
            // Comparar por el año de inicio primero, luego por el año de fin
            if (aYears.startYear !== bYears.startYear) {
            return aYears.startYear - bYears.startYear;
            } else {
            return aYears.endYear - bYears.endYear;
            }
        };

        campanasSet.sort(compararCampanas);

        return campanasSet;

    }

    const CampanaValidation = (startCampana, endCampana) => {
        const regex = /(\d{4})\/(\d{4})/;
        const startMatch = startCampana.match(regex);
        const endMatch = endCampana.match(regex);
        let startValue = 0;
        let endValue = 0;
        if(startMatch !== null){
            startValue = (+startMatch[1]) + (+startMatch[2]);
        }
        if(endMatch !== null){
            endValue = (+endMatch[1]) + (+endMatch[2]);
        }
        
        if(endCampana && startCampana && (endValue < startValue)){
            setErrorCampanas(true);
        }else{
            setErrorCampanas(false);
        }
    }

    const getYears = (campana) => {
        const match = campana.match(/(\d{4})\/(\d{4})/);
        if (match) {
            const [_, startYear, endYear] = match;
            return { startYear: parseInt(startYear), endYear: parseInt(endYear) };
        }
        return { startYear: 0, endYear: 0 };
    };

    const sortByCampana = (data) => {
        let dataSorted = data.sort((a, b) => {
            const yearsA = getYears(a.campana);
            const yearsB = getYears(b.campana);
            
            if (yearsA.startYear !== yearsB.startYear) {
                return yearsA.startYear - yearsB.startYear;
            }
            return yearsA.endYear - yearsB.endYear;
        });
        return dataSorted;
    }


    const convertListToObject = (list) => {
        const result = {};
        list.forEach(item => {
            const campana = item.campana;
            result[campana] = item;
        });
        return result;
    };


    return(
        <>

            {loadingEvoHuella ? 
                (
                    <Box p={3} align="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {campanas.length > 0 ?
                            <>
                                <div className="evolucion-dates">
                                    <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                        <InputLabel htmlFor="campana-inicio-select">Campaña de inicio*</InputLabel>
                                        <Select
                                            className="t4b-finca-sector-inputs"
                                            id="campana-inicio-select"
                                            label="Campana de inicio*"
                                            required
                                            MenuProps={{ disableScrollLock: true }}
                                            value={startCampana}
                                            onChange={(event) => { handleStartCampanaSelect(event.target.value, endCampana) }
                                            }>
                                        
                                            {campanas.map((y, index) => (
                                                <MenuItem key={"startCampana-" + index} value={y}>
                                                    {y}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                        <InputLabel htmlFor="campana-fin-select">Campaña de fin*</InputLabel>
                                        <Select
                                            className="t4b-finca-sector-inputs"
                                            id="campana-fin-select"
                                            label="Campaña de fin*"
                                            required
                                            MenuProps={{ disableScrollLock: true }}
                                            value={endCampana}
                                            onChange={(event) => { handleEndCampanaSelect(startCampana, event.target.value) }
                                            }>
                                        
                                            {campanas.map((y, index) => (
                                                <MenuItem key={"endCampana-" + index} value={y}>
                                                    {y}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                                {errorCampanas && <p className="errorMsg">La campaña de fin no puede ser menor que la campaña de inicio.</p>}

                                {startCampana && endCampana && errorCampanas === false &&
                                    <>
                                    <BarChartRiegoRisEvolucion startCampana={startCampana} endCampana={endCampana} campanas={campanas} data={data} finca={finca}/>
                                    <BarChartRiegoRequerimientoEvolucion startCampana={startCampana} endCampana={endCampana} campanas={campanas} data={data} finca={finca}/>
                                    <BarChartRiegoPrecipitacionesEvolucion startCampana={startCampana} endCampana={endCampana} campanas={campanas} data={data} finca={finca}/>
                                    <BarChartRiegoProduccionEvolucion startCampana={startCampana} endCampana={endCampana} campanas={campanas} data={data} finca={finca}/>
                                    </>
                                }
                                
                            </>
                            :
                            <>
                                <p>No existen riegos asociadas para el tipo seleccionado.</p>
                            </>
                        }
                    </>
                )
            }
            
        </>
    );
}

export default EvoRiegoComponent;