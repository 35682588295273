import { Box, CircularProgress, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import googlelens from "../../assets/Iconos_biodiversidad/googlelens.png";
import terrain from "../../assets/Iconos_biodiversidad/terrain.jpg";
import TableBasicSamplingComponent from "./TableBasicSampling";
import TableAdvancedSamplingComponent from "./TableAdvancedSampling";
import { instance } from "../../utils/axios-config";
import { useAuth } from "../../context/Auth";


const FormBioStepThreeComponent = ({position, setPosition, stateForm, setStateForm}) => {

    const {sessionToken} = useAuth();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(stateForm.diccionario.length === 0){
            loadDictionary();
        }
    }, []);

    const loadDictionary = async () => {
        let buildUrl = `/diccionario/vertebrados`;
        try{
            setLoading(true);
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await instance.get(buildUrl).then(function(resp) {
                setStateForm((prevData) => ({...prevData, diccionario: resp.data}));
            }).catch(function(error) {
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setLoading(false);
        }
    }

    const validateForm = () =>{
        setErrors({});
        let inputIds = "";
        let valid = true;

        Object.keys(stateForm.muestreos).forEach((key) =>{
            stateForm.muestreos[key].map((muestreo, index) => {
                if(muestreo.distancia === ""){
                    setErrors(errors => ({...errors, ["errorDistancia-" + key + "-" + index + "-" +"Distancia"]: true, ["errorDistancia-" + key + "-" + index + "-" + "DistanciaMsg"]: ""}));
                    inputIds = inputIds ? inputIds +(",distancia-" + key + "-" + index) : ("distancia-" + key + "-" + index);
                    valid = false;
                }
                muestreo.especies.map(((object, j) => {
                    if(object.nombre === ""){
                        setErrors(errors => ({...errors, ["errorEspecie-" + key + "-" + index + "-" + j + "Nombre"]: true, ["errorEspecie-" + key + "-" + index + "-" + j + "NombreMsg"]: ""}));
                        inputIds = inputIds ? inputIds +(",especieNombre-" + key + "-" + index + "-" + j) : ("especieNombre-" + key + "-" + index + "-" + j);
                        valid = false;
                    }
                    if(object.ni === "" && stateForm.tipoAnalisis !== "1"){
                        setErrors(errors => ({...errors, ["errorEspecie-" + key + "-" + index + "-" + j + "NI"]: true, ["errorEspecie-" + key + "-" + index + "-" + j + "NIMsg"]: ""}));
                        inputIds = inputIds ? inputIds +(",especieNI-" + key + "-" + index + "-" + j) : ("especieNI-" + key + "-" + index + "-" + j);
                        valid = false;
                    }
                }))
            });
        });

        Object.entries(stateForm.tipoMuestreo).forEach(([clave, valor]) => {
            stateForm.muestreos[clave].map(muestreo => {
                if(muestreo.especies.length === 0 && valor === true){
                    setErrors(errors => ({...errors, ["errorTipoMuestreo-" + clave  + "-Vacio"]: true, ["errorTipoMuestreo-" + clave + "-VacioMsg"]: "Los itinerarios deben tener al menos una especie o muestreo"}));
                    inputIds = inputIds ? inputIds +(",tipoMuestreoVacio-" + clave ) : ("tipoMuestreoVacio-" + clave);
                    valid = false;
                }
            });
        });

        if(inputIds !== ""){
            try{
                let elem = document.getElementById(inputIds.split(",")[0]);
                elem.scrollIntoView({behavior: "smooth"});
                elem.focus();
            }catch(err){

            }
        }
        return valid;
    }

    const handlePrev = (e) => {
        e.preventDefault();
        setPosition(1);
    }

    const handleNext = (e) => {
        e.preventDefault();
        if(validateForm()){
            setPosition(3);
            console.log(stateForm);
        }
    };

    return(
        <>
        <Box className={position !== 2 && 'hide'}>
            {loading ?
                <Box p={3} align="center">
                    <CircularProgress />
                </Box>
                :
                <>
                    <h1 className="tic4bio-home-title">Nueva Biodiversidad Vertebrados</h1>
                    <p style={{color: "rgba(0, 0, 0, 0.6)", margin: "42px 0px"}}>Finca seleccionada: <span style={{color: "#80C606", fontSize: "20px", fontWeight: "700"}}>{stateForm.finca.nombre}</span></p>
                    <p style={{color: "rgba(0, 0, 0, 0.6)", margin: "42px 0px"}}>Comunidad vegetal seleccionada: <span style={{color: "#80C606", fontSize: "20px", fontWeight: "700"}}>{stateForm.comunidad.nombre}</span></p>
                    
                    <p style={{color: "rgba(0, 0, 0, 0.6)", margin: "42px 0px"}}>Método de muestreo de vertebrados presente en la comunidad:</p>

                    <div>
                        
                        <button id={"tipoMuestreoVacio-itinerariodecenso"} className="dropdown-box green active">Itinerario de censo</button>
                        
                            <>
                                <p>Debe indicar las especies de vertebrados que visualice en el muestreo.</p>
                                <p>El icono <a href="https://lens.google/intl/es/" target="_blank" style={{verticalAlign: 'sub', display: 'inline-block', height: '20px'}}><img src={googlelens} alt="Icono Google Lens" /></a> le redirigirá a Google Lens, que le proporcionará ayuda para identificar especies a partir de una foto.</p>
                                <ol>
                                    <li>Establezca uno o más itinerarios o rutas de distancia conocida dentro de la finca. Estos trayectos deben diseñarse de forma que representen adecuadamente la variabilidad que hábitats y especies. Se recomienda “al menos” 1km de recorrido por cada 100 ha de superficie.</li>
                                    <div style={{position: "relative", display: "inline-block"}}>
                                        <img src={terrain} style={{maxWidth: "300px", width: "100%"}} alt=""/>
                                        <div>
                                            <div style={{position: "absolute", top: "118px", left: "90px", width: "58px", height: "2px", backgroundColor: "red"}}></div>
                                            <div style={{position: "absolute", top: "100px", left: "146px", width: "2px", height: "20px", backgroundColor: "red"}}></div>
                                            <div style={{position: "absolute", top: "100px", left: "147px", width: "58px", height: "2px", backgroundColor: "red"}}></div>
                                        </div>
                                        <div>
                                            <div style={{position: "absolute", top: "136px", left: "133px", width: "25px", height: "2px", backgroundColor: "blue"}}></div>
                                            <div style={{position: "absolute", top: "100px", left: "133px", width: "2px", height: "38px", backgroundColor: "blue"}}></div>
                                            <div style={{position: "absolute", top: "100px", left: "110px", width: "25px", height: "2px", backgroundColor: "blue"}}></div>
                                            <div style={{position: "absolute", top: "72px", left: "110px", width: "2px", height: "30px", backgroundColor: "blue"}}></div>
                                        </div>
                                    </div>
                                    <li>Para la identificación de especies puede usar la observación directa, trampas fotográficas, observación de huellas, restos fecales…</li>
                                    <li>Se recomienda realizar observación directa con paradas cada 50m para intentar identificar huellas o excrementos.</li>
                                    <li>Es importante que el muestreo se realice en una época en la que se puedan visualizar el mayor número de especies (primavera). El amanecer y el atardecer son los mejores momentos del día para realizar los itinerarios. Repetir el muestreo anualmente en la misma fecha y preferiblemente que lo realice la misma persona.</li>
                                    {stateForm.tipoAnalisis === "1" ? 
                                        <li>Observe y anote las especies de vertebrados salvajes o domésticos (aves, mamíferos, reptiles, anfibios …) que aviste.</li>
                                        :
                                        <li>Observe y anote las especies de vertebrados salvajes o domésticos (aves, mamíferos, reptiles, anfibios …) que aviste, así como <b>el número de individuos</b> de cada uno de ellos.</li>
                                    }
                                </ol>
                                {stateForm.tipoAnalisis === "1" ? 
                                    <TableBasicSamplingComponent stateForm={stateForm} setStateForm={setStateForm} tipoMuestreo={"itinerariodecenso"} errors={errors} />
                                    :
                                    <TableAdvancedSamplingComponent stateForm={stateForm} setStateForm={setStateForm} tipoMuestreo={"itinerariodecenso"} errors={errors} />
                                }
                                {errors["errorTipoMuestreo-itinerariodecenso-Vacio"] && <p className="errorMsg">{errors["errorTipoMuestreo-itinerariodecenso-VacioMsg"]}</p>}
                            </>
                    </div>

                    <Stack spacing={2} direction="row" justifyContent="space-between" className="tic4bio-sectores-btns">
                        <button className="btn green" onClick={(e) => handlePrev(e)}>Anterior</button>
                        <button className="btn green" onClick={(e) => handleNext(e)} style={{margin: "16px 0"}}>Siguiente</button>
                    </Stack>
                </>
            }

        </Box>
        </>
    )
}

export default FormBioStepThreeComponent;