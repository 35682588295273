import { Fragment, useEffect, useState } from "react";
import { instance } from '../utils/axios-config.js';
import { Box, CircularProgress, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAuth } from "../context/Auth.js";
import redsquare from "../../src/assets/Iconos_biodiversidad/redsquare.png";
import bluesquare from "../../src/assets/Iconos_biodiversidad/bluesquare.png";
import terrain from "../../src/assets/Iconos_biodiversidad/terrain.jpg";
import tinyterrain from "../../src/assets/Iconos_biodiversidad/tinyterrain.png";
import trampa1 from "../../src/assets/Iconos_biodiversidad/trampas1.jpg";
import trampa2 from "../../src/assets/Iconos_biodiversidad/trampas2.jpg";
import googlelens from "../../src/assets/Iconos_biodiversidad/googlelens.png";
import TableAdvancedEditSamplingComponent from "../components/formsBioInvertebrados/TableAdvancedEditSampling.js";
import TableBasicEditSamplingComponent from "../components/formsBioInvertebrados/TableBasicEditSampling.js";
import BioInvertebradosEditarResumenComponent from "../components/formsBioInvertebrados/BioInvertebradosEditarResumen.js";
import aracnidos from "../assets/Iconos_biodiversidad/iconos_invertebrados/aracnidos.png";
import acariformes from "../assets/Iconos_biodiversidad/iconos_invertebrados/acariformes.png";
import anelidos from "../assets/Iconos_biodiversidad/iconos_invertebrados/anelidos.png";
import blatodeos from "../assets/Iconos_biodiversidad/iconos_invertebrados/blatodeos.png";
import coleopteros from "../assets/Iconos_biodiversidad/iconos_invertebrados/coleopteros.png";
import dipteros from "../assets/Iconos_biodiversidad/iconos_invertebrados/dipteros.png";
import embiopteros from "../assets/Iconos_biodiversidad/iconos_invertebrados/embiopteros.png";
import hemipteros from "../assets/Iconos_biodiversidad/iconos_invertebrados/hemipteros.png";
import himenopteros from "../assets/Iconos_biodiversidad/iconos_invertebrados/himenopteros.png";
import isopodos from "../assets/Iconos_biodiversidad/iconos_invertebrados/isopodos.png";
import isopteros from "../assets/Iconos_biodiversidad/iconos_invertebrados/isopteros.png";
import lepidopteros from "../assets/Iconos_biodiversidad/iconos_invertebrados/lepidopteros.png";
import mantodeos from "../assets/Iconos_biodiversidad/iconos_invertebrados/mantodeos.png";
import miriapodos from "../assets/Iconos_biodiversidad/iconos_invertebrados/miriapodos.png";
import moluscos from "../assets/Iconos_biodiversidad/iconos_invertebrados/moluscos.png";
import neumopteros from "../assets/Iconos_biodiversidad/iconos_invertebrados/neumopteros.png";
import ortopteros from "../assets/Iconos_biodiversidad/iconos_invertebrados/ortopteros.png";
import tisanopteros from "../assets/Iconos_biodiversidad/iconos_invertebrados/tisanopteros.png";


const BioInvertebradosEditarPage = () => {

    const {sessionToken} = useAuth();
    const { id } = useParams();

    const [ position, setPosition ] = useState(0);
    const [ bioInvertebrado, setBioInvertebrado ] = useState({id: ""});
    const [ loading, setLoading ] = useState(true);
    const [ open, setOpen ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState({open: false, title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado. Por favor, vuelve a intentarlo más tarde."});
    const [errors, setErrors] = useState({});

    const ordenInvertebrados = [
        {label: "Acariformes", image: acariformes},
        {label: "Anélidos", image: anelidos},
        {label: "Arácnidos", image: aracnidos},
        {label: "Blatodeos", image: blatodeos},
        {label: "Coleópteros", image: coleopteros},
        {label: "Dípteros", image: dipteros},
        {label: "Embiópteros", image: embiopteros},
        {label: "Hemípteros", image: hemipteros},
        {label: "Himenópteros", image: himenopteros},
        {label: "Isópodos (cochinillas)", image: isopodos},
        {label: "Isópteros", image: isopteros},
        {label: "Lepidópteros", image: lepidopteros},
        {label: "Mantodeos", image: mantodeos},
        {label: "Miriápodos", image: miriapodos},
        {label: "Moluscos", image: moluscos},
        {label: "Neumópteros", image: neumopteros},
        {label: "Ortópteros", image: ortopteros},
        {label: "Tisanópteros", image: tisanopteros}
    ];

    useEffect(() => {
        document.title = "Edición biodiversidad Invertebrados";
        loadData();
    }, []);

    const loadData = async () => {
        let buildUrl = `/biodiversidad/invertebrados/${id}`;
        let buildDiccionarioUrl = `/diccionario/invertebrados`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}

            await Promise.all([instance.get(buildUrl), instance.get(buildDiccionarioUrl)]).then(function(resp) {
            //await instance.get(buildUrl).then(function(resp) {
                const elementosAAgregar = resp[1].data.map(elemento => ({ label: elemento }));
                resp[0].data.diccionario = [...ordenInvertebrados, ...elementosAAgregar];
                let lastTimeMuestreo = 0;
                let lastTime = 0;
                const muestreos = resp[0].data.muestreos.reduce((grupos, muestreo, indice) => {
                    muestreo.tempId = "tempId-" + indice + "-" + lastTimeMuestreo;
                    lastTimeMuestreo++;
                    muestreo.muestras.forEach((muestra, index) => {
                        muestra.tempId = "tempId-" + index + "-" + lastTime;
                        lastTime++;
                    });
                
                    const tipoMuestreo = muestreo.tipoMuestreo;
                    if (grupos[tipoMuestreo]) {
                        grupos[tipoMuestreo].push(muestreo);
                    } else {
                        grupos[tipoMuestreo] = [muestreo];
                    }
                
                    return grupos;
                }, {});

                const gruposListado = Object.entries(muestreos).map(([tipoMuestreo, muestreos]) => {
                    
                    return {
                        tipoMuestreo: tipoMuestreo,
                        muestreos: muestreos
                    }
                    
                });

                resp[0].data.muestreos = gruposListado;
                setBioInvertebrado(resp[0].data);
            }).catch(function(error) {
                setOpen(true);
                setError(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    contentText: "Algo ha fallado al intentar recuperar la información de la biodiversidad de invertebrados. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setLoading(false);
            setOpen(true);
            setError(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                contentText: "Algo ha fallado al intentar recuperar la información de las biodiversidad de invertebrados. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const printNameText = (text) => {
        let res = "";
        switch (text) {
            case "muestreosuperficiesuelo":
                res = "Muestreo en suelo";
                break;
            case "trampasparaadultos":
                res = "Trampas para adultos";
                break;
            default:
                res = "";
                break;
          }
        return res;
    }


    const validateForm = () =>{
        setErrors({});
        let inputIds = "";
        let valid = true;
        console.log(bioInvertebrado);
        
        //Igual aqui hay que hacer el filtro

        bioInvertebrado.muestreos.map((value) =>{
            value.muestreos.map((muestreo, index) => {
                let muestreoContador = 0;
                muestreo.muestras.map((muestra, j) => {
                    if(muestra?.eliminar !== true){
                        if(bioInvertebrado.tipoAnalisis === 0){
                            if(muestra.num_individuos === ""){
                                setErrors(errors => ({...errors, ["errorEspecie-" + muestreo.tipoMuestreo + "-" + index + "-" + j + "NI"]: true, ["errorEspecie-" + muestreo.tipoMuestreo + "-" + index + "-" + j + "NIMsg"]: ""}));
                                inputIds = inputIds ? inputIds +(",especieNI-" + muestreo.tipoMuestreo + "-" + index + "-" + j) : ("especieNI-" + muestreo.tipoMuestreo + "-" + index + "-" + j);
                                valid = false;
                            }
                            if(muestra.num_individuos <= 0){
                                setErrors(errors => ({...errors, ["errorEspecie-" + muestreo.tipoMuestreo + "-" + index + "-" + j + "NI"]: true, ["errorEspecie-" + muestreo.tipoMuestreo + "-" + index + "-" + j + "NIMsg"]: "Min: 1."}));
                                inputIds = inputIds ? inputIds +(",especieNI-" + muestreo.tipoMuestreo + "-" + index + "-" + j) : ("especieNI-" + muestreo.tipoMuestreo + "-" + index + "-" + j);
                                valid = false;
                            }
                        }
                        if(muestra.nombre === ""){
                            setErrors(errors => ({...errors, ["errorEspecie-" + muestreo.tipoMuestreo + "-" + index + "-" + j + "Nombre"]: true, ["errorEspecie-" + muestreo.tipoMuestreo + "-" + index + "-" + j + "NombreMsg"]: ""}));
                            inputIds = inputIds ? inputIds +(",especieNombre-" + muestreo.tipoMuestreo + "-" + index + "-" + j) : ("especieNombre-" + muestreo.tipoMuestreo + "-" + index + "-" + j);
                            valid = false;
                        }
                        muestreoContador++;
                    }
                });
                if(muestreoContador === 0){
                    setErrors(errors => ({...errors, ["errorTipoMuestreo-" + muestreo.tipoMuestreo + "-Vacio"]: true, ["errorTipoMuestreo-" + muestreo.tipoMuestreo + "-VacioMsg"]: "Debe incluir al menos una especie o muestreo"}));
                    inputIds = inputIds ? inputIds +(",tipoMuestreoVacio-" + muestreo.tipoMuestreo ) : ("tipoMuestreoVacio-" + muestreo.tipoMuestreo);
                    valid = false;
                }
            });
              
        });

        if(inputIds !== ""){
            try{
                let elem = document.getElementById(inputIds.split(",")[0]);
                elem.scrollIntoView({behavior: "smooth"});
                elem.focus();
            }catch(err){

            }
        }
        return valid;
    }

    const handleNext = (e) => {
        e.preventDefault();
        if(validateForm()){
            setPosition(1)
        }
    };
    
    return(
        <>
            <div className="tic4bio-home">
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    {loading ? 
                        (
                            <Box p={3} align="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <div>
                                    <h1 className="tic4bio-home-title">Editar Biodiversidad invertebrados</h1>
                                    {!error ?
                                        <>
                                            {bioInvertebrado.id ?
                                                <>
                                                    <p style={{color: "rgba(0, 0, 0, 0.6)", margin: "42px 0px"}}>Finca seleccionada: <span style={{color: "#80C606", fontSize: "20px", fontWeight: "700"}}>{bioInvertebrado.nombre_finca}</span></p>
                                                    <p style={{color: "rgba(0, 0, 0, 0.6)", margin: "42px 0px"}}>Comunidad vegetal seleccionada: <span style={{color: "#80C606", fontSize: "20px", fontWeight: "700"}}>{bioInvertebrado.nombre_comunidad}</span></p>
                                                    {position === 0 ? 
                                                        <>
                                                            {bioInvertebrado.muestreos.map((muestreo, index) => {
                                                                return (
                                                                    <Fragment key={muestreo.tipoMuestreo + "-" + index} >
                                                                        <button id={"tipoMuestreoVacio-" + muestreo.tipoMuestreo} className={"dropdown-box green active"} onClick={(e) => e.preventDefault()}>{printNameText(muestreo.tipoMuestreo)}</button>
                                                                        {muestreo.tipoMuestreo === "muestreosuperficiesuelo" &&
                                                                            <>
                                                                                <p>Debe indicar el orden de invertebrados que visualice en el muestreo.</p>
                                                                                <p>El icono <a href="https://lens.google/intl/es/" target="_blank" style={{verticalAlign: 'sub', display: 'inline-block', height: '20px'}}><img src={googlelens} alt="Icono Google Lens" /></a> le redirigirá a Google Lens, que le proporcionará ayuda para identificar especies a partir de una foto.</p>
                                                                                <ol>
                                                                                    <li>Sobre la cubierta o zona de cultivo (<img src={tinyterrain} style={{width: "40px", display: "inline-block", verticalAlign: "middle"}} alt=""/>) lance o establezca un cuadrante de 1 x 1 m (<img src={redsquare} style={{width: "20px", display: "inline-block", verticalAlign: "middle"}} alt=""/>)</li>
                                                                                    <div style={{position: "relative", display: "inline-block"}}>
                                                                                        <img src={terrain} style={{maxWidth: "300px", width: "100%"}} alt=""/>
                                                                                        <img src={redsquare} style={{width: "34px", position: "absolute", top: "40%", left: "36%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                                    </div>
                                                                                    {bioInvertebrado.tipoAnalisis === 1 ? 
                                                                                        <>
                                                                                            <li>Observe y anote el orden de los invertebrados que aviste. Puede ayudarse de la herramienta Google Lens.</li>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <li>Observe y anote el <b>orden</b> de los invertebrados que aviste, así como <b>el número individuos de cada tipo</b>. Ayúdese de la herramienta Google Lens.</li>
                                                                                        </>
                                                                                    }
                                                                                    <li>Repita el proceso estableciendo 2 o 3 puntos de muestreos adicionales. Distribuya los puntos de muestreo aleatoriamente sobre el terreno y en zonas representativas de la biodiversidad de la finca.</li>
                                                                                    <div style={{position: "relative", display: "inline-block"}}>
                                                                                        <img src={terrain} style={{maxWidth: "300px", width: "100%"}} alt=""/>
                                                                                        <img src={redsquare} style={{width: "22px", position: "absolute", top: "40%", left: "32%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                                        <img src={bluesquare} style={{width: "22px", position: "absolute", top: "32%", left: "52%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                                        <img src={bluesquare} style={{width: "22px", position: "absolute", top: "50%", left: "48%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                                        <img src={bluesquare} style={{width: "22px", position: "absolute", top: "40%", left: "64%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                                                    </div>
                                                                                    {bioInvertebrado.tipoAnalisis === 1 ? 
                                                                                        <>
                                                                                            <li>Anotar el nombre de las NUEVAS órdenes de invertebrados que visualice en cada nuevo cuadrante.</li>
                                                                                        </>
                                                                                        :
                                                                                        <>
                                                                                            <li>Anotar el nombre de los <b>órdenes de invertebrados y número de individuos de cada tipo</b> que visualice en cada nuevo cuadrante.</li>
                                                                                        </>
                                                                                    }
                                                                                </ol>
                                                                                {bioInvertebrado.tipoAnalisis === 1 ?
                                                                                    <>
                                                                                        <TableBasicEditSamplingComponent stateForm={bioInvertebrado} setStateForm={setBioInvertebrado} tipoMuestreo={muestreo.tipoMuestreo} errors={errors} />
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <TableAdvancedEditSamplingComponent stateForm={bioInvertebrado} setStateForm={setBioInvertebrado} tipoMuestreo={muestreo.tipoMuestreo} errors={errors} />
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        }
                                                                        {muestreo.tipoMuestreo === "trampasparaadultos" &&
                                                                            <>
                                                                                
                                                                                <p>Debe indicar el orden de invertebrados que visualice en el muestreo.</p>
                                                                                <p>El icono <a href="https://lens.google/intl/es/" target="_blank" style={{verticalAlign: 'sub', display: 'inline-block', height: '20px'}}><img src={googlelens} alt="Icono Google Lens" /></a> le redirigirá a Google Lens, que le proporcionará ayuda para identificar especies a partir de una foto.</p>
                                                                                {bioInvertebrado.tipoAnalisis === 1 ? 
                                                                                    <>
                                                                                        <ol>
                                                                                            <li>Si instala o cuenta con trampas McPhail o cromáticas (instalación conforme a criterios de la Guía de Gestión Integrada de Plagas de su cultivo) puede utilizar estos elementos  para identificar insectos capturados.</li>
                                                                                            <div className="flex-align-box" style={{justifyContent: "space-around", margin: "20px 0"}}>
                                                                                                <img src={trampa1} alt=""/>
                                                                                                <img src={trampa2} alt=""/>
                                                                                            </div>
                                                                                            <li>Anotar el nombre de los NUEVOS órdenes de invertebrados que visualice en cada trampeo.</li>
                                                                                        </ol>
                                                                                        
                                                                                        <TableBasicEditSamplingComponent stateForm={bioInvertebrado} setStateForm={setBioInvertebrado} tipoMuestreo={muestreo.tipoMuestreo} errors={errors} />
                                                                                    
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <ol>
                                                                                            <li>Si instala o cuenta con trampas McPhail o cromáticas (instalación conforme a criterios de la Guía de Gestión Integrada de Plagas de su cultivo) puede utilizar estos elementos  para <b>identificar insectos capturados</b> y estimar la proporción y <b>nº de individuos de cada uno.</b></li>
                                                                                            <div className="flex-align-box" style={{justifyContent: "space-around", margin: "20px 0"}}>
                                                                                                <img src={trampa1} alt=""/>
                                                                                                <img src={trampa2} alt=""/>
                                                                                            </div>
                                                                                            <li>Anotar el nombre de los órdenes de invertebrados que visualice en cada trampeo y el <b>nº de individuos de cada uno.</b></li>
                                                                                        </ol>

                                                                                        <TableAdvancedEditSamplingComponent stateForm={bioInvertebrado} setStateForm={setBioInvertebrado} tipoMuestreo={muestreo.tipoMuestreo} errors={errors} />
                                                                                    
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        }
                                                                        {errors["errorTipoMuestreo-" + muestreo.tipoMuestreo + "-Vacio"] && <p className="errorMsg">{errors["errorTipoMuestreo-" + muestreo.tipoMuestreo + "-VacioMsg"]}</p>}
                                                                    </Fragment>
                                                                );
                                                            })}
                                                            <Stack spacing={2} direction="row" justifyContent="space-between" className="tic4bio-sectores-btns">
                                                                <button className="btn green" onClick={(e) => handleNext(e)} style={{margin: "16px 0"}}>Siguiente</button>
                                                            </Stack>
                                                        </>
                                                    :
                                                        <>
                                                            <BioInvertebradosEditarResumenComponent stateForm={bioInvertebrado} setPosition={setPosition} />
                                                        </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    <p>No se ha encontrado ninguna biodiversidad.</p>
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            <p>Se ha producido un error inesperado. Vuelva a intentarlo más tarde.</p>
                                        </>
                                    }
                                </div>
                            </>
                        )
                    }
                </Box>
            </div>
        </>
    );
} 
export default BioInvertebradosEditarPage;