import { useEffect, useState } from "react";
import { instance } from '../utils/axios-config.js';
import { Box, CircularProgress, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAuth } from "../context/Auth.js";
import terrain from "../../src/assets/Iconos_biodiversidad/terrain.jpg";
import googlelens from "../../src/assets/Iconos_biodiversidad/googlelens.png";
import TableBasicEditSamplingComponent from "../components/formsBioVertebrados/TableBasicEditSampling.js";
import TableAdvancedEditSamplingComponent from "../components/formsBioVertebrados/TableAdvancedEditSampling.js";
import BioVertebradosEditarResumenComponent from "../components/formsBioVertebrados/BioVertebradosEditarResumen.js";
/*
import BioVertebradosEditarResumenComponent from "../components/formsBioVertebrados/BioVertebradosEditarResumen.js";*/



const BioVertebradosEditarPage = () => {

    const {sessionToken} = useAuth();
    const { id } = useParams();

    const [ position, setPosition ] = useState(0);
    const [ bioVertebrado, setBioVertebrado ] = useState({id: ""});
    const [ loading, setLoading ] = useState(true);
    const [ open, setOpen ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState({open: false, title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado. Por favor, vuelve a intentarlo más tarde."});
    const [errors, setErrors] = useState({});

    useEffect(() => {
        document.title = "Edición biodiversidad vertebrados";
        loadData();
    }, []);

    const loadData = async () => {
        let buildUrl = `/biodiversidad/vertebrados/${id}`;
        let buildDiccionarioUrl = `/diccionario/vertebrados`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await Promise.all([instance.get(buildUrl), instance.get(buildDiccionarioUrl)]).then(function(resp) {
            //await instance.get(buildUrl).then(function(resp) {
                resp[0].data.diccionario = resp[1].data;
                let lastTimeMuestreo = 0;
                let lastTime = 0;
                const muestreos = resp[0].data.muestreos.reduce((grupos, muestreo, indice) => {
                    muestreo.tempId = "tempId-" + indice + "-" + lastTimeMuestreo;
                    lastTimeMuestreo++;
                    muestreo.muestras.forEach((muestra, index) => {
                        muestra.tempId = "tempId-" + index + "-" + lastTime;
                        lastTime++;
                    });
                
                    const tipoMuestreo = "itinerariodecenso";
                    if (grupos[tipoMuestreo]) {
                        grupos[tipoMuestreo].push(muestreo);
                    } else {
                        grupos[tipoMuestreo] = [muestreo];
                    }
                
                    return grupos;
                }, {});

                const gruposListado = Object.entries(muestreos).map(([tipoMuestreo, muestreos]) => {
                    
                    return {
                        tipoMuestreo: tipoMuestreo,
                        muestreos: muestreos
                    }
                    
                });

                resp[0].data.muestreos = gruposListado;
                setBioVertebrado(resp[0].data);
            }).catch(function(error) {
                setOpen(true);
                setError(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    contentText: "Algo ha fallado al intentar recuperar la información de la biodiversidad de vertebrados. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setLoading(false);
            setOpen(true);
            setError(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                contentText: "Algo ha fallado al intentar recuperar la información de las biodiversidad de vertebrados. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const validateForm = () =>{
        setErrors({});
        let inputIds = "";
        let valid = true;
        console.log(bioVertebrado);
        
        //Igual aqui hay que hacer el filtro

        bioVertebrado.muestreos.map((value) =>{
            value.muestreos.map((muestreo, index) => {
                let muestreoContador = 0;
                muestreo.muestras.map((muestra, j) => {
                    if(muestra?.eliminar !== true){
                        if(bioVertebrado.tipoAnalisis === 0){
                            if(muestra.num_individuos === ""){
                                setErrors(errors => ({...errors, ["errorEspecie-" + muestreo.tipoMuestreo + "-" + index + "-" + j + "NI"]: true, ["errorEspecie-" + muestreo.tipoMuestreo + "-" + index + "-" + j + "NIMsg"]: ""}));
                                inputIds = inputIds ? inputIds +(",especieNI-" + muestreo.tipoMuestreo + "-" + index + "-" + j) : ("especieNI-" + muestreo.tipoMuestreo + "-" + index + "-" + j);
                                valid = false;
                            }
                            if(muestra.num_individuos <= 0){
                                setErrors(errors => ({...errors, ["errorEspecie-" + muestreo.tipoMuestreo + "-" + index + "-" + j + "NI"]: true, ["errorEspecie-" + muestreo.tipoMuestreo + "-" + index + "-" + j + "NIMsg"]: "Min: 1"}));
                                inputIds = inputIds ? inputIds +(",especieNI-" + muestreo.tipoMuestreo + "-" + index + "-" + j) : ("especieNI-" + muestreo.tipoMuestreo + "-" + index + "-" + j);
                                valid = false;
                            }
                        }
                        if(muestra.nombre === ""){
                            setErrors(errors => ({...errors, ["errorEspecie-" + muestreo.tipoMuestreo + "-" + index + "-" + j + "Nombre"]: true, ["errorEspecie-" + muestreo.tipoMuestreo + "-" + index + "-" + j + "NombreMsg"]: ""}));
                            inputIds = inputIds ? inputIds +(",especieNombre-" + muestreo.tipoMuestreo + "-" + index + "-" + j) : ("especieNombre-" + muestreo.tipoMuestreo + "-" + index + "-" + j);
                            valid = false;
                        }
                        muestreoContador++;
                    }
                });
                if(muestreo.longitudRecorrido === ""  || muestreo.longitudRecorrido <= 0){
                    setErrors(errors => ({...errors, ["errorDistancia-" + muestreo.tipoMuestreo + "-" + index + "-" +"Distancia"]: true, ["errorDistancia-" + muestreo.tipoMuestreo + "-" + index + "-" + "DistanciaMsg"]: ""}));
                    inputIds = inputIds ? inputIds +(",distancia-" + muestreo.tipoMuestreo + "-" + index) : ("distancia-" + muestreo.tipoMuestreo + "-" + index);
                    valid = false;
                }
                if(muestreoContador === 0){
                    setErrors(errors => ({...errors, ["errorTipoMuestreo-" + muestreo.tipoMuestreo + "-Vacio"]: true, ["errorTipoMuestreo-" + muestreo.tipoMuestreo + "-VacioMsg"]: "Debe incluir al menos una especie o muestreo"}));
                    inputIds = inputIds ? inputIds +(",tipoMuestreoVacio-" + muestreo.tipoMuestreo ) : ("tipoMuestreoVacio-" + muestreo.tipoMuestreo);
                    valid = false;
                }
            });
              
        });

        if(inputIds !== ""){
            try{
                let elem = document.getElementById(inputIds.split(",")[0]);
                elem.scrollIntoView({behavior: "smooth"});
                elem.focus();
            }catch(err){

            }
        }
        return valid;
    }

    const handleNext = (e) => {
        e.preventDefault();
        if(validateForm()){
            setPosition(1)
        }
    };
    
    return(
        <>
            <div className="tic4bio-home">
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    {loading ? 
                        (
                            <Box p={3} align="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <div>
                                    <h1 className="tic4bio-home-title">Editar Biodiversidad Vertebrados</h1>
                                    {!error ?
                                        <>
                                            {bioVertebrado.id ?
                                                <>
                                                    <p style={{color: "rgba(0, 0, 0, 0.6)", margin: "42px 0px"}}>Finca seleccionada: <span style={{color: "#80C606", fontSize: "20px", fontWeight: "700"}}>{bioVertebrado.nombre_finca}</span></p>
                                                    <p style={{color: "rgba(0, 0, 0, 0.6)", margin: "42px 0px"}}>Comunidad vegetal seleccionada: <span style={{color: "#80C606", fontSize: "20px", fontWeight: "700"}}>{bioVertebrado.nombre_comunidad}</span></p>
                                                    {position === 0 ? 
                                                        <>
                                                            
                                                            <button id="tipoMuestreoVacio-itinerariodecenso" className="dropdown-box green active" onClick={(e) => e.preventDefault()}>Itinerario de censo</button>
                                                           
                                                                <>
                                                                    <p>Debe indicar las especies de vertebrados que visualice en el muestreo.</p>
                                                                    <p>El icono <a href="https://lens.google/intl/es/" target="_blank" style={{verticalAlign: 'sub', display: 'inline-block', height: '20px'}}><img src={googlelens} alt="Icono Google Lens" /></a> le redirigirá a Google Lens, que le proporcionará ayuda para identificar especies a partir de una foto.</p>
                                                                    <ol>
                                                                        <li>Establezca uno o más itinerarios o rutas de distancia conocida dentro de la finca. Estos trayectos deben diseñarse de forma que representen adecuadamente la variabilidad que hábitats y especies. Se recomienda “al menos” 1km de recorrido por cada 100 ha de superficie.</li>
                                                                        <div style={{position: "relative", display: "inline-block"}}>
                                                                            <img src={terrain} style={{maxWidth: "300px", width: "100%"}} alt=""/>
                                                                            <div>
                                                                                <div style={{position: "absolute", top: "118px", left: "90px", width: "58px", height: "2px", backgroundColor: "red"}}></div>
                                                                                <div style={{position: "absolute", top: "100px", left: "146px", width: "2px", height: "20px", backgroundColor: "red"}}></div>
                                                                                <div style={{position: "absolute", top: "100px", left: "147px", width: "58px", height: "2px", backgroundColor: "red"}}></div>
                                                                            </div>
                                                                            <div>
                                                                                <div style={{position: "absolute", top: "136px", left: "133px", width: "25px", height: "2px", backgroundColor: "blue"}}></div>
                                                                                <div style={{position: "absolute", top: "100px", left: "133px", width: "2px", height: "38px", backgroundColor: "blue"}}></div>
                                                                                <div style={{position: "absolute", top: "100px", left: "110px", width: "25px", height: "2px", backgroundColor: "blue"}}></div>
                                                                                <div style={{position: "absolute", top: "72px", left: "110px", width: "2px", height: "30px", backgroundColor: "blue"}}></div>
                                                                            </div>
                                                                        </div>
                                                                        <li>Para la identificación de especies puede usar la observación directa, trampas fotográficas, observación de huellas, restos fecales…</li>
                                                                        <li>Se recomienda realizar observación directa con paradas cada 50m para intentar identificar huellas o excrementos.</li>
                                                                        <li>Es importante que el muestreo se realice en una época en la que se puedan visualizar el mayor número de especies (primavera). El amanecer y el atardecer son los mejores momentos del día para realizar los itinerarios. Repetir el muestreo anualmente en la misma fecha y preferiblemente que lo realice la misma persona.</li>
                                                                        {bioVertebrado.tipoAnalisis === 1 ? 
                                                                            <li>Observe y anote las especies de vertebrados salvajes o domésticos (aves, mamíferos, reptiles, anfibios …) que aviste.</li>
                                                                            :
                                                                            <li>Observe y anote las especies de vertebrados salvajes o domésticos (aves, mamíferos, reptiles, anfibios …) que aviste, así como <b>el número de individuos</b> de cada uno de ellos.</li>
                                                                        }
                                                                    </ol>
                                                                    {bioVertebrado.tipoAnalisis === 1 ?
                                                                        <>
                                                                        <TableBasicEditSamplingComponent stateForm={bioVertebrado} setStateForm={setBioVertebrado} tipoMuestreo={"itinerariodecenso"} errors={errors} />
                                                                        </>
                                                                        :
                                                                        <>
                                                                        <TableAdvancedEditSamplingComponent stateForm={bioVertebrado} setStateForm={setBioVertebrado} tipoMuestreo={"itinerariodecenso"} errors={errors} />
                                                                        </>
                                                                    }
                                                                    {errors["errorTipoMuestreo-itinerariodecenso-Vacio"] && <p className="errorMsg">{errors["errorTipoMuestreo-itinerariodecenso-VacioMsg"]}</p>}
                                                                </>
                                                        
                                                            <Stack spacing={2} direction="row" justifyContent="space-between" className="tic4bio-sectores-btns">
                                                                <button className="btn green" onClick={(e) => handleNext(e)} style={{margin: "16px 0"}}>Siguiente</button>
                                                            </Stack>
                                                        </>
                                                    :
                                                        <>
                                                            <BioVertebradosEditarResumenComponent stateForm={bioVertebrado} setPosition={setPosition} />
                                                        </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    <p>No se ha encontrado ninguna biodiversidad.</p>
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            <p>Se ha producido un error inesperado. Vuelva a intentarlo más tarde.</p>
                                        </>
                                    }
                                </div>
                            </>
                        )
                    }
                </Box>
            </div>
        </>
    );
} 
export default BioVertebradosEditarPage;