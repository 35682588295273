import { useEffect, useState, Fragment } from "react";
import { instance } from '../utils/axios-config.js';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ModalInfo from '../components/ModalInfo';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ModalConfirm from "../components/ModalConfirm.js";
import ActionMessage from "../components/ActionMessage.js";




const UnidadProductivaPage = () => {

    const { name } = useParams();
    const navigate = useNavigate();

    const [ msg, setMsg ] = useState();
    const [ loading, setLoading ] = useState(true);
    const [ open, setOpen ] = useState(false);
    const [ finca, setFinca] = useState();
    const [ industria, setIndustria] = useState();
    const [ type, setType ] = useState();
    const [ errorConfirm, setErrorConfirm ] = useState(false);
    const [ openConfirm, setOpenConfirm ] = useState({
        state: false,
        finca: null
    });

    useEffect(() => {
        loadUnidadData();
    }, []);

    const loadUnidadData = async () => {
        let buildUrl = `/fincas/fincasUnidad/${name}`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
            await instance.get(buildUrl).then(function(resp) {
                if(resp.data.length === 0){
                    navigate("/home");
                }
                resp.data.forEach(element => {
                    if(element.dataType === 'finca')
                        setFinca(element);
                    else
                        setIndustria(element);
                });
            }).catch(function(resp) {
                setOpen(true);
            }).finally(()=>{
                setLoading(false);
            });
            
            setLoading(false);
        }catch(error){
            setOpen(true);
        }
    }

    const deleteFinca = async (id) => {
        let buildUrl = `/fincas/delete/${id}`;
        setType(openConfirm.finca);
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
        
            await instance.delete(buildUrl).then(function(resp) {
                if(openConfirm.finca?.dataType === "finca"){
                    setFinca();
                }else{
                    setIndustria();
                }
                if((!finca && openConfirm.finca?.dataType === "industria") || (!industria && openConfirm.finca?.dataType === "finca")){
                    navigate("/home", {state: {type: "action-delete", text: "Se ha eliminado la " + openConfirm.finca?.dataType + " y la unidad productiva correctamente."}});
                }else{
                    setMsg({type: "action-delete", text: "Se ha eliminado la "+ openConfirm.finca?.dataType +" correctamente."});
                }
            }).catch(function(resp) {
                setErrorConfirm(true);
            })
        }catch(error){
            setErrorConfirm(true);
        }
    }

    const openModalConfirm = (finca) => {
        setOpenConfirm({state: true, finca: finca});
    }

    return(
        <>
         <div className="tic4bio-home">
                {msg &&
                    <ActionMessage msg={msg} setMsg={setMsg}/>
                }
                <div>
                    <h3 className="tic4bio-home-title sectores">Unidad Productiva:</h3>
                    <h1 style={{color: "#80C606"}}>{name}</h1>
                </div>
                    {loading ? 
                        (
                            <Box p={3} align="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                {(finca || industria) && <h3> Finca : </h3>}
                                {finca ? (
                                     <>
                                    <Fragment>
                                        <div className="tic4bio-finca">
                                            <Link className="details" to={"/detallesFinca/" + finca.id} state={name}>
                                                <div>
                                                    <p className="details-provincia">{finca.municipio}, {finca.provincia}</p>
                                                    <p className="details-nombre">{finca.nombre}</p>
                                                    <p className="details-dotacion">Dotación: {finca.dotacionRiego} m<sup>3</sup>/ha</p>
                                                </div>
                                            </Link>
                                        <IconButton
                                            size="large"
                                            edge="start"
                                            className="delete"
                                            aria-label="Eliminar finca"
                                            style={{color: "#fa0000"}}
                                            onClick={() => {openModalConfirm(finca)}}
                                        >
                                            <DeleteIcon />
                                        </IconButton> 
                                        </div> 
                                    </Fragment>
                                    </>
                                )
                                :
                                (
                                    <div className="button green" style={{maxWidth: "400px"}}>
                                        <Link to="/nuevaFinca/finca"  state={name}><AddCircleOutlineIcon className="add"/>Crear Finca</Link>
                                    </div>
                                )
                                }
                                {(industria || finca) && <h3> Industria : </h3>}
                                {industria ? (
                                    <>
                                    <Fragment>
                                        <div className="tic4bio-finca">
                                            <Link className="details" to={"/detallesFinca/" + industria.id} state={name}>
                                            <div>
                                                <p className="details-provincia">{industria.municipio}, {industria.provincia}</p>
                                                <p className="details-nombre">{industria.nombre}</p>
                                                <p className="details-dotacion">Capacidad de Molturación: {industria.capacidadMolturacion} m<sup>3</sup>/ha</p>
                                            </div>
                                            </Link>
                                            <IconButton
                                                size="large"
                                                edge="start"
                                                className="delete"
                                                aria-label="Eliminar industria"
                                                style={{color: "#fa0000"}}
                                                onClick={() => {openModalConfirm(industria)}}
                                            >
                                                <DeleteIcon />
                                            </IconButton> 
                                        </div> 
                                    </Fragment>
                                    </>
                                ) : ( 
                                    <div className="button green" style={{maxWidth: "400px"}}>
                                        <Link to="/nuevaFinca/industria" state={name}><AddCircleOutlineIcon className="add"/>Crear Industria</Link>
                                    </div>
                                )}
                                {(finca || industria) &&
                                    <div style={{marginTop: "50px", display: "none"}}>
                                        <h3> Detalles de la Unidad : </h3>
                                        <div>
                                            <div className="button blue" style={{maxWidth: "400px"}}>
                                                <Link to="/nuevaUnidadProductiva"><EqualizerIcon className="consultas"/>Consultas</Link>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {(!finca && !industria) &&
                                    <p className="tic4bio-fincas-empty">Aún no se ha añadido ninguna finca o industria.</p>
                                }
                            </>
                        )
                    }
            </div>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={"¡Vaya! Parace que algo ha ido mal."} contentText={"Algo ha fallado al intentar recuperar la finca/industria de la Unidad. Por favor, vuelve a intentarlo más tarde."}/>
            <ModalInfo open={errorConfirm} setOpen={setErrorConfirm} contentTitle={"¡Vaya! Parece que algo ha ido mal."} contentText={"Algo ha fallado al intentar eliminar la " + type?.dataType + ". Por favor, vuelve a intentarlo más tarde."}/>
            <ModalConfirm open={openConfirm} setOpen={setOpenConfirm} func={deleteFinca} contentTitle={"Eliminar " + openConfirm.finca?.nombre + "."} contentText={"¿Está seguro que desea eliminar esta " + openConfirm.finca?.dataType + "?"}/>
    </>
    );
} 
export default UnidadProductivaPage;