import { Box, CircularProgress, FormControl, Input, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { instance } from '../../utils/axios-config';
import { useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const FormNuevaUnidadProductivaComponent = () => {

    const navigate = useNavigate();

    const [ error, setError ] = useState(false);
    const [ errors, setErrors ] = useState({});
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingBtn, setLoadingBtn] = useState(true);
    const [unidades, setUnidades] = useState([]);
    const [nuevaUnidad, setNuevaUnidad] = useState('');
    const [nueva, setNueva] = useState(true);
    const [ finca, setFinca] = useState();
    const [ industria, setIndustria] = useState();
    
    useEffect(() => {
        document.title = "Nueva Unidad Productiva";
        loadUnidadesData();
    }, []);

    const loadUnidadesData = async () => {
        let buildUrl = `/fincas/unidades`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
        
            await instance.get(buildUrl).then(function(resp) {
                var uniqueUP = [];
                var uniqueValues = [];
                resp.data.forEach(obj => {
                    let value = obj["unidadProductiva"];
                    if (!uniqueValues.includes(value)) {
                        uniqueValues.push(value);
                        uniqueUP.push(obj);
                    }
                });
                setUnidades(uniqueUP);
            }).catch(function(resp) {
                setOpen(true);
                setError(true);
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setOpen(true);
        }
    } 

    const loadFincasIndustriasData = async (nombre) => {
        let buildUrl = `/fincas/fincasUnidad/${nombre}`;
        try{
            setLoadingBtn(true);
            var finca = null;
            var industria = null;
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
            await instance.get(buildUrl).then(function(resp) {
                resp.data.forEach(element => {
                    if(element.dataType === 'finca')
                        finca = element;
                    else
                        industria = element;
                });
                setFinca(finca);
                setIndustria(industria);
            }).catch(function(resp) {
                setOpen(true);
                setError(true);
            }).finally(()=>{
                setLoadingBtn(false);
            });
            
            setLoading(false);
        }catch(error){
            setOpen(true);
        }
    }

    const handleUnidadChange = (event) => {
        if(event.target.value === '' || event.target.value === undefined){
            setNueva(true);
        }else{
            setNueva(false);
            loadFincasIndustriasData(event.target.value);
        }
        setNuevaUnidad(event.target.value);
    };

    const checkForm = () =>{
        let valid = true;
        setErrors({});
        if(nueva){
            if(nuevaUnidad === undefined || nuevaUnidad === ''){
                setErrors(errors => ({...errors, errorNombre: true, errorNombreMsg: ""}));
                valid = false;   
            }else if(unidades.some(u => u.unidadProductiva === nuevaUnidad)){
                setErrors(errors => ({...errors, errorUnidad: true, errorUnidadMsg: "Ya tienes una unidad productiva con ese nombre. Elige otro."}));
                valid = false;
            }
        }
        return valid;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(checkForm()){
            try {
                setLoadingBtn(true);
                if(event.currentTarget.value === "finca"){
                    navigate("/nuevaFinca/finca", {state: nuevaUnidad});
                }else{
                    navigate("/nuevaFinca/industria", {state: nuevaUnidad});
                }
            } catch (error) {
                setLoadingBtn(false);
            }
        }
    }
    
    return(
        <>
        {loading ? 
            (
                <Box className="loading">
                    <CircularProgress />
                </Box>
            ) : (
                <Box component="form" className="tic4bio-unidades-create-form" onSubmit={handleSubmit} noValidate>
                    <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                        <InputLabel shrink htmlFor="unidadesproductivas">Unidades Productivas*</InputLabel>
                        <Select
                            className="t4b-finca-sector-inputs"
                            id="unidadesproductivas"
                            displayEmpty
                            defaultValue=""
                            label="Unidades productivas"
                            onChange={(e) => handleUnidadChange(e)}
                            required
                            disabled={loadingBtn}
                            MenuProps={{ disableScrollLock: true }}
                            error={errors.errorUnidad}
                        >
                            {unidades.map((unidad, index) => (
                            <MenuItem key={index} value={unidad.unidadProductiva}>
                                {unidad.unidadProductiva}
                            </MenuItem>
                            ))}
                            <MenuItem value="" selected={nueva}>Agregar Nueva Unidad</MenuItem>
                        </Select>      
                        {errors.errorUnidadMsg && <div className="errorInput">{errors.errorUnidadMsg}</div>}                  
                    </FormControl>
                    {nueva && 
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="name">Nombre de la nueva Unidad*</InputLabel>
                            <Input
                                id="nuevaUnidad"
                                className="t4b-finca-inputs"
                                label="Nueva Unidad"
                                required
                                name="nuevaUnidad"
                                placeholder="Nombre nueva unidad"
                                onChange={(e) => setNuevaUnidad(e.target.value)}
                                value={nuevaUnidad}
                                error={errors.errorNombre}
                            />
                            {errors.errorNombreMsg && <div className="errorInput">{errors.errorNombreMsg}</div>}
                        </FormControl>
                    }
                    {!nueva ? (
                        <div className="t4b-spacebetween">
                            {loadingBtn ? (
                            <Box align="center" sx={{ my: 2 }}>
                                <CircularProgress />
                            </Box>
                            ) : (
                            <>
                                {!finca && (
                                <button type="button" className="btn green" value="finca" style={{ maxWidth: "400px" }} onClick={handleSubmit}>
                                    <AddCircleOutlineIcon className="add" />Crear Finca
                                </button>
                                )}
                                {!industria && (
                                <button type="button" className="btn green" value="industria" style={{ maxWidth: "400px" }} onClick={handleSubmit}>
                                    <AddCircleOutlineIcon className="add" />Crear Industria
                                </button>
                                )}
                                {finca && industria && (
                                    <p>Esta unidad productiva ya tiene una finca y una industria asociada</p>
                                )}
                            </>
                            )}
                        </div>
                    )
                    :
                        <div className="t4b-spacebetween">
                            <button type="button" className="btn green" value="finca" style={{ maxWidth: "400px" }} onClick={handleSubmit}>
                                <AddCircleOutlineIcon className="add" />Crear Finca
                            </button>
                            <button type="button" className="btn green" value="industria" style={{ maxWidth: "400px" }} onClick={handleSubmit}>
                                <AddCircleOutlineIcon className="add" />Crear Industria
                            </button>
                        </div>
                    }
                    
                </Box>
            )
        }
        </>
    );
}

export default FormNuevaUnidadProductivaComponent;