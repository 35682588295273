import { Autocomplete, Box, FormControl, IconButton, TextField } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Fragment, useEffect } from "react";
import { checkDoublePattern } from "../../utils/commons";

const TableBasicSamplingComponent = ({stateForm, setStateForm, tipoMuestreo, errors }) => {

    useEffect(() => {
        if(Object.keys(stateForm.muestreos).length === 0 || !stateForm.muestreos[tipoMuestreo]){
            let tempId = "tempId-" + new Date().getTime();
            setStateForm(prevData => ({
                ...prevData,
                muestreos: {
                    ...prevData.muestreos,
                    [tipoMuestreo]: [{
                        especies: [],
                        distancia: "",
                        tempId
                    }],     
                } 
            }));
        }
    }, []);

    const handleAddColumn = () => {
        let tempId = "tempId-" + new Date().getTime();
        setStateForm(prevData => ({
            ...prevData,
            muestreos: {
                ...prevData.muestreos,
                [tipoMuestreo]: [
                    ...prevData.muestreos[tipoMuestreo],
                    {
                        especies: [],
                        distancia: "",
                        tempId
                    }
                ],     
            } 
        }));
    }

    const handleAddEspecie = (pos) => {
        let nuevaEspecie = {
            nombre: "Nueva Especie",
            tempId: "tempId-" + new Date().getTime()
        }
        setStateForm(prevState => {
            if(prevState.muestreos[tipoMuestreo]){
                return {
                    ...prevState,
                    muestreos: {
                        ...prevState.muestreos,
                        [tipoMuestreo]: prevState.muestreos[tipoMuestreo].map((item, index) => {
                            if (index === pos) {
                                return {
                                    ...item,
                                    especies: [
                                        ...item.especies,
                                        nuevaEspecie
                                    ]
                                };
                            }
                            return item;
                        })
                    }
                }
            }else{
                return {
                    ...prevState,
                    especies: {
                        ...prevState.especies,
                        [tipoMuestreo]: [
                            {
                                especies: [nuevaEspecie],
                                distancia: "",
                                tempId: "tempId-" + new Date().getTime(),
                            }
                        ],
                    }
                }
            }
        })
    }


    const handleChangeNombre = (e, id) => {
        setStateForm(prevData => {
            const newData = stateForm.muestreos[tipoMuestreo].map(subArray => {
                return {
                    ...subArray,
                    especies: subArray.especies.map(especie => {
                        if(especie.tempId === id){
                            return { ...especie,
                                nombre: e
                            };
                        }
                        return especie;
                    })
                };
            });
            return {...prevData, muestreos: {
                ...prevData.muestreos, 
                [tipoMuestreo]: newData
            }};
        });
    }

    const handleChangeDistancia = (e, id) => {
        if(checkDoublePattern(e)){
            setStateForm(prevData => {
                const newData = stateForm.muestreos[tipoMuestreo].map(subArray => {
                    if(subArray.tempId === id){
                        return{
                            ...subArray,
                            distancia: e
                        }
                    }
                    return subArray;
                });
                return {...prevData, muestreos: {
                    ...prevData.muestreos, 
                    [tipoMuestreo]: newData
                }};
            });
        }
    }

    const deleteMuestreo = (e) => {
        const newData = stateForm.muestreos[tipoMuestreo].map(subArray => {
            return {
                ...subArray,
                especies: subArray.especies.filter(item => item.tempId !== e.tempId)
            };
        });

        setStateForm(prevData => ({
            ...prevData,
            muestreos: {
                ...prevData.muestreos,
                [tipoMuestreo]: newData   
            } 
        }));
    }

    const handleDeleteColumn = (pos) => {
        let columnas = stateForm.muestreos[tipoMuestreo].filter((elemento, index) => index !== pos);
        setStateForm(prevData => ({
            ...prevData,
            muestreos: {
                ...prevData.muestreos,
                [tipoMuestreo]: columnas,     
            } 
        }));
    }

    return(
        <div className="tabla-bio-container">
             
            {stateForm.muestreos[tipoMuestreo]?.map((muestreo, index) => (
                <table className="tabla-bio" key={"tabla-muestreo-" + tipoMuestreo + "-" + index}>
                    <thead>
                        <tr style={{ display: index === 0 ? "table-row" : ""}}>
                            <th colSpan={index === 0 ? 2 : 1}>
                                Itineraririo {index + 1}
                            </th>
                            {index !== 0 &&
                                <th>
                                    <IconButton
                                    size="large"
                                    edge="start"
                                    className="delete"
                                    aria-label="Eliminar Muestreo"
                                    style={{color: "#fa0000"}}
                                    onClick={() => {handleDeleteColumn(index)}}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </th>
                            }
                        </tr>
                        <tr>
                            <th colSpan="2" style={{borderTop: "1px solid #80C606"}}>
                                <FormControl sx={{width: "100%"}} variant="standard">
                                    <TextField
                                        className="t4b-finca-sector-inputs no-red-label"
                                        label="Distancia (km)*"
                                        id={"distancia-" + tipoMuestreo + "-" + index}
                                        value={muestreo?.distancia}
                                        type="number"
                                        variant="standard"
                                        InputProps={{
                                            inputProps: { 
                                                max: 99999, min: 1,
                                            }
                                        }}
                                        onChange={(e) => {handleChangeDistancia(e.target.value, muestreo?.tempId)}}
                                        name="distancia"
                                        error={errors["errorDistancia-" + tipoMuestreo + "-" + index + "-Distancia"]}
                                    />
                                    {errors["errorDistancia-" + tipoMuestreo + "-" + index + "-DistanciaMsg"] && <div className="errorInput">{errors["errorDistancia"  + tipoMuestreo + "-" + index + "-DistanciaMsg"]}</div>}
                                </FormControl>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {stateForm.muestreos[tipoMuestreo][index].especies?.map((especimen, j) => (
                            <Fragment key={"muestreo-" + tipoMuestreo + "-" + index + "-" + j}>
                                <tr>
                                    <td>
                                        <FormControl sx={{width: "100%"}} variant="standard">
                                            {/*<TextField
                                                className="t4b-finca-sector-inputs no-red-label"
                                                label="Nombre de especie*"
                                                id={"especieNombre-" + tipoMuestreo + "-" + index + "-" + j}
                                                value={especimen?.nombre}
                                                variant="standard"
                                                onChange={(e) => {handleChangeNombre(e.target.value, especimen?.tempId)}}
                                                name="especieNombre"
                                                error={errors["errorEspecie-" + tipoMuestreo + "-" + index + "-" + j +  "Nombre"]}
                                            />
                                            {errors["errorEspecie-" + tipoMuestreo + "-" + index + "-" + j + "NombreMsg"] && <div className="errorInput">{errors["errorEspecie-"  + tipoMuestreo + "-" + index + "-" + j + "NombreMsg"]}</div>}*/}
                                            <Autocomplete
                                                freeSolo
                                                options={stateForm.diccionario}
                                                renderOption={(props, option) => {
                                                    return <Box element="li" sx={{ display: 'flex', alignItems: 'center' }} {...props}> 
                                                        {option}
                                                    </Box>
                                                }}
                                                className="t4b-finca-inputs"
                                                getOptionLabel={(option) => (
                                                    typeof option === "string" ? option : option.label
                                                )}
                                                id={"especieNombre-" + tipoMuestreo + "-" + index + "-" + j}
                                                onInputChange={(e, newValue) => {
                                                    handleChangeNombre(newValue, especimen?.tempId)
                                                }}
                                                value={especimen?.nombre || ""}
                                                renderInput={(params) => (
                                                    <>
                                                    <TextField {...params} label="Nombre de especie*" variant="standard" />
                                                    </>
                                                )}
                                                sx={{
                                                    ...(errors["errorEspecie-" + tipoMuestreo + "-" + index + "-" + j + "Nombre"] && {
                                                        '& .MuiInput-root:before': {
                                                            borderBottom: "1px solid #d32f2f",
                                                        }
                                                    })
                                                }}
                                            />
                                        </FormControl>
                                    </td>
                                    <td>
                                        <IconButton
                                        size="large"
                                        edge="start"
                                        className="delete"
                                        aria-label="Eliminar especie"
                                        style={{color: "#fa0000"}}
                                        onClick={() => {deleteMuestreo(especimen)}}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </td>
                                </tr>
                            </Fragment>
                        ))}
                        <tr>
                            <td colSpan="2" className="tabla-bio-add">
                                <IconButton
                                    size="large"
                                    edge="start"
                                    className="delete"
                                    aria-label="Añadir Especie"
                                    style={{color: "#80C606"}}
                                    onClick={() => {handleAddEspecie(index)}}
                                    >
                                        <AddCircleIcon />
                                </IconButton>
                            </td>
                        </tr>
                    </tbody>
                </table>
            ))}
            <table className="tabla-bio">
                <thead>
                    <tr style={{display: "table-row"}}>
                        <th colSpan="2">
                            <IconButton
                                size="large"
                                edge="start"
                                className="delete"
                                aria-label="Añadir Muestreo"
                                style={{color: "#80C606"}}
                                onClick={() => {handleAddColumn()}}
                                >
                                    <AddCircleIcon />
                            </IconButton>
                        </th>
                    </tr>
                </thead>
            </table>
        </div>
    )
}

export default TableBasicSamplingComponent;