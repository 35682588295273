import {Box, FormControl, IconButton, Input, InputAdornment, InputLabel} from '@mui/material';
import { useState } from 'react';
import OjoAbierto from "../assets/ojo-abierto.svg";
import OjoCerrado from "../assets/ojo-cerrado.svg";
import HeaderOutNavLogin from '../components/headerOutNavLogin';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LoadingBackdrop from '../components/LoadingBackdrop';
import logotic4bio from '../assets/lateralmenu/logoheadbio.png';
import logoue from '../assets/logo-UE.png';
import logojunta from '../assets/logo-juntaAndalucia.png';
import ModalInfo from '../components/ModalInfo';
import { useAuth } from '../context/Auth';
import Footer from '../components/footer/Footer';

const LoginPage = () => {

    const { signIn } = useAuth();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorLogin, setErrorLogin] = useState("");
    const location = useLocation();
    let prevRoute = location.state;

    const handleClickShowPassword = (event) => {
        event.preventDefault();
        setShowPassword((show) => !show)
    };

    const handleHideErrors = () => {
        setErrors(errors => ({...errors, errorUserMsg: null, errorPasswordMsg: null}));
    };

    const checkForm = (data) => {
        let valid = true;
        if(data.get('username') === "" || data.get('username') === null || data.get('username') === undefined){
            setErrors(errors => ({...errors, errorUser: true, errorUserMsg: "Falta el correo electrónico"}));
            valid = false;
        }
        if(data.get('password') === "" || data.get('password') === null || data.get('password') === undefined){
            setErrors(errors => ({...errors, errorPassword: true, errorPasswordMsg: "Falta la contraseña"}));
            valid = false;
        }
        return valid;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if(checkForm(data)){

            setLoading(true);
            const response = await signIn(data.get('username'), data.get('password'));
            if(response?.login === true){
                navigate("/home");
            }else if(response?.login === false){
                setErrorLogin("Las credenciales introducidas no corresponde con ningún usuario. Por favor, inténtelo de nuevo.");
                setOpen(true);
                setLoading(false);
            }else{
                setErrorLogin("Se ha producido un error inesperado, vuelve a intentarlo más tarde.");
                setOpen(true);
                setLoading(false);
            }
        }
    }

    return (
        <div className="welcome-main" onClick={handleHideErrors}>
            <div className="welcome-background">
                <div className="welcome-logos">
                    <div className="welcome-logos-top">
                        <img className="welcome-logo-tic4bio" src={logotic4bio} alt={"Imagen Logo Tic4Bio"}/>
                    </div>
                    <div className="welcome-logos-bottom">
                        <Box className="financian-text">FINANCIAN:</Box>
                        <div className="welcome-logos-bottom-images">
                            <img className="welcome-logo-ue" src={logoue} alt={"Imagen Logo Tic4Bio"}/>
                            <img className="welcome-logo-junta" src={logojunta} alt={"Imagen Logo Tic4Bio"}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="welcome-container">
                <HeaderOutNavLogin text={"Iniciar Sesión"} url={prevRoute}/>
                <div className="welcome-body">
                    <Box component="form" onSubmit={handleSubmit} noValidate>
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="username">Correo electrónico*</InputLabel>
                            <Input
                                id="username"
                                className=""
                                label="Correo electrónico"
                                required
                                name="username"
                                error={errors.errorUser}
                                startAdornment={
                                <InputAdornment position="start">
                                    <PersonIcon />
                                </InputAdornment>
                                }
                            />
                            {errors.errorUserMsg && <div className="errorInput">{errors.errorUserMsg}</div>}
                        </FormControl>
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="password">Contraseña*</InputLabel>
                            <Input
                                id="password"
                                required
                                className=""
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                error={errors.errorPassword}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <LockIcon />
                                    </InputAdornment>
                                }
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="Conmuta la visibilidad de la contraseña"
                                    onClick={handleClickShowPassword}
                                    edge="end"><img
                                    title={showPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
                                    src={showPassword ? OjoCerrado : OjoAbierto}
                                    alt={"Icono visibilidad"}
                                    />
                                    </IconButton>
                                </InputAdornment>
                                }
                                label="Contraseña"
                            />
                            {errors.errorPasswordMsg && <div className="errorInput">{errors.errorPasswordMsg}</div>}
                        </FormControl>
                        <div className="login-link-pass">
                            <Link to="/recuperarPassword" state={location.pathname}>
                                ¿Olvidaste la contraseña?
                            </Link>
                        </div>
                        <div className='login-buttons'>
                            <button type="submit" className="btn white">Iniciar sesión</button>
                            <div className="login-buttons-separator">
                                <div className="login-buttons-line"></div>
                                <div className="login-buttons-or">o</div>
                                <div className="login-buttons-line"></div>
                            </div>
                            <div className="button green">
                                <Link to="/registro" state={location.pathname}>Registrarse</Link>
                            </div>
                        </div>
                    </Box>
                    <div style={{textAlign: "right", color: "gray", fontSize: "14px", fontWeight: 500}}>V. {process.env.REACT_APP_VERSION_FRONT_T4B}</div>
                </div>
            </div>
            <Footer/>
            <LoadingBackdrop loading={loading} />
            <ModalInfo open={open} setOpen={setOpen} contentTitle={"Error"} contentText={errorLogin}/>
        </div>
    );
}
 
export default LoginPage;