import { Box, CircularProgress, Stack } from "@mui/material";
import { useState } from "react";
import ModalInfo from "../ModalInfo";
import InputZonaComponent from "./InputZona";
import InputZonaMantenimientoComponent from "./InputZonaMantenimiento";
import { instance } from "../../utils/axios-config";
import { useAuth } from "../../context/Auth";
import { useNavigate } from "react-router-dom";


const FormLimpieza2Component = ({position, setPosition, state, setState}) => {

    const {sessionToken} = useAuth();
    const navigate = useNavigate();

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState({
        title: "¡Vaya! Parace que algo ha ido mal.", 
        content: "Algo ha fallado. Por favor, vuelve a intentarlo más tarde."
    });
    

    const validateForm = () =>{
        setErrors({});
        let inputIds = "";
        let valid = true;

        if(state.aguaDeLimpieza === "3"){
            Array.from({ length: state.nZonas }, (v, i) => {

                if(state.zonasMantenimiento[i]?.nombre === "" || state.zonasMantenimiento[i]?.nombre === undefined){
                    setErrors(errors => ({...errors, ["errorZonaMantenimiento" + i + "Nombre"]: true, ["errorZonaMantenimiento" + i + "NombreMsg"]: ""}));
                    inputIds = inputIds ? inputIds +",zonaMantenimientoNombre-"+i : "zonaMantenimientoNombre-"+i;
                    valid = false;
                }

                if(state.zonasMantenimiento[i]?.caudal === "" || state.zonasMantenimiento[i]?.caudal === undefined){
                    setErrors(errors => ({...errors, ["errorZonaMantenimiento" + i + "Caudal"]: true, ["errorZonaMantenimiento" + i + "CaudalMsg"]: ""}));
                    inputIds = inputIds ? inputIds +",zonaMantenimientoCaudal-"+i : "zonaMantenimientoCaudal-"+i;
                    valid = false;
                }
    
                if(state.zonasMantenimiento[i]?.tiempo === "" || state.zonasMantenimiento[i]?.tiempo === undefined){
                    setErrors(errors => ({...errors, ["errorZonaMantenimiento" + i + "Tiempo"]: true, ["errorZonaMantenimiento" + i + "TiempoMsg"]: ""}));
                    inputIds = inputIds ? inputIds +",zonaMantenimientoTiempo-"+i : "zonaMantenimientoTiempo-"+i;
                    valid = false;
                }

                if(state.zonasMantenimiento[i]?.frecuencia === "" || state.zonasMantenimiento[i]?.frecuencia === undefined){
                    setErrors(errors => ({...errors, ["errorZonaMantenimiento" + i + "Frecuencia"]: true, ["errorZonaMantenimiento" + i + "FrecuenciaMsg"]: ""}));
                    inputIds = inputIds ? inputIds +",zonaMantenimientoFrecuencia-"+i : "zonaMantenimientoFrecuencia-"+i;
                    valid = false;
                }
            
            });
        }
        

        //Hago este segundo bucle aparte para que si hay errores se posicione en orden de cada tabla
        Array.from({ length: state.nZonas }, (v, i) => {

            if(state.aguaDeLimpieza !== "3"){
                if(state.zonas[i]?.nombre === "" || state.zonas[i]?.nombre === undefined){
                    setErrors(errors => ({...errors, ["errorZona" + i + "Nombre"]: true, ["errorZona" + i + "NombreMsg"]: ""}));
                    inputIds = inputIds ? inputIds +",zonaNombre-"+i : "zonaNombre-"+i;
                    valid = false;
                }
            }

            if(state.zonas[i]?.caudal === "" || state.zonas[i]?.caudal === undefined){
                setErrors(errors => ({...errors, ["errorZona" + i + "Caudal"]: true, ["errorZona" + i + "CaudalMsg"]: ""}));
                inputIds = inputIds ? inputIds +",zonaCaudal-"+i : "zonaCaudal-"+i;
                valid = false;
            }

            if(state.zonas[i]?.tiempo === "" || state.zonas[i]?.tiempo === undefined){
                setErrors(errors => ({...errors, ["errorZona" + i + "Tiempo"]: true, ["errorZona" + i + "TiempoMsg"]: ""}));
                inputIds = inputIds ? inputIds +",zonaTiempo-"+i : "zonaTiempo-"+i;
                valid = false;
            }

            if(state.aguaDeLimpieza !== "3"){
                if(state.zonas[i]?.nombre === "" || state.zonas[i]?.nombre === undefined){
                    setErrors(errors => ({...errors, ["errorZona" + i + "Nombre"]: true, ["errorZona" + i + "NombreMsg"]: ""}));
                    inputIds = inputIds ? inputIds +",zonaNombre-"+i : "zonaNombre-"+i;
                    valid = false;
                }
            }
        });
        
        if(inputIds !== ""){
            let elem = document.getElementById(inputIds.split(",")[0]);
            elem.scrollIntoView({behavior: "smooth"});
            elem.focus();
        }
        return valid;
    }

    const handlePrev = (e) => {
        e.preventDefault();
        setPosition(2);
    }

    const checkEquipos = () => {
        if(state.almazara.contadorGeneral !== "1"){
            return Object.entries(state.equipos).reduce((acc, [key, value]) => {
                if (value.consumo !== "") {
                  acc[key] = value;
                }
                return acc;
              }, {});
        }else{
            return [];
        }
    }

    const formatStateData = () => {
        let data = {
            nombre: state.nombre,
            campana: state.campana,
            nDiasMolturacion: parseInt(state.nDiasMolturacion),
            kgAceitunaMolturado: parseFloat(state.kgAceitunaMolturado.replace(",", ".")),
            rendimientoMedio: parseFloat(state.rendimientoMedio.replace(",", ".")),
            precioAceite: parseFloat(state.precioAceite.replace(",", ".")),
            consumoAguaTotal: state.consumoAguaTotal !== "" ? parseFloat(state.consumoAguaTotal.replace(",", ".")) : "",
            almazara: state.almazara,
            equipos: checkEquipos(),
            aguaDeLimpieza: state.aguaDeLimpieza,
            conoceCaudal: state.conoceCaudal,
            nZonas: parseInt(state.nZonas),
            zonas: state.zonas,
            zonasMantenimiento: state.zonasMantenimiento
        };

        return data;
    }
    

    const handleSend = async (e) => {
        e.preventDefault();
        if(validateForm()){
            try {
                setLoading(true);
                const data = formatStateData();
                instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
                await instance.post(`/huellaalmazara/almazara`, data).then(() => {
                    setLoading(false);
                    navigate("/lista-huellas-almazara/" + state.almazara.id, {state: {type: "action-new", text: "Se ha creado la huella de la almazara correctamente."}});
                });
                console.log(data);
                setLoading(false)
            } catch (error) {
                if(error.response?.status === 406){
                    setPosition(0);
                    setState(prevState => ({
                        ...prevState,
                        exists: true
                    }));
                }else{
                    setOpen(true);
                }
                setLoading(false);
            }
        }
    }


    return(
        <>
            <Box className={position !== 3 && 'hide'}>

                <div className="t4b-limpieza-box">
                    <div className="t4b-limpieza-btn">
                        {state.aguaDeLimpieza === "1" && "Limpieza diaria"}
                        {state.aguaDeLimpieza === "2" && "Limpieza a final de campaña"}
                        {state.aguaDeLimpieza === "3" && "Limpieza de mantenimiento y fin de campaña"}
                    </div>
                </div>

                <div className="t4b-zonas-box">
                    <p>Caracterice  el caudal de la toma de agua de limpieza  y el tiempo de limpieza empleado*:</p>


                    {(state.aguaDeLimpieza === "1" || state.aguaDeLimpieza === "2") && 
                        <table border="1" className="t4b-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th><p>Caudal (L/min)</p></th>
                                    <th><p>{state.aguaDeLimpieza === "1" ? "Tiempo (min/día)" : "Tiempo (min)"}</p></th>
                                </tr>
                            </thead>
                            <tbody className="t4b-table-body">
                                
                                <InputZonaComponent state={state} setState={setState} errors={errors}/>
                                    
                            </tbody>
                        </table>
                    }
                    {state.aguaDeLimpieza === "3" && 
                        <>
                            <p>LIMPIEZA DE MANTENIMIENTO:</p>
                            <table border="1" className="t4b-table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th><p>Caudal (L/min)</p></th>
                                        <th><p>Tiempo (min)</p></th>
                                        <th><p>Frecuencia (cada x días)</p></th>
                                    </tr>
                                </thead>
                                <tbody className="t4b-table-mantenimiento">
                                    
                                        <InputZonaMantenimientoComponent state={state} setState={setState} errors={errors}/>
                                        
                                </tbody>
                            </table>

                            <p>LIMPIEZA DE FINAL DE CAMPAÑA:</p>
                            <table border="1" className="t4b-table">
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th><p>Caudal (L/min)</p></th>
                                        <th><p>Tiempo (min)</p></th>
                                    </tr>
                                </thead>
                                <tbody className="t4b-table-body">
                                    
                                    <InputZonaComponent state={state} setState={setState} errors={errors}/>
                                        
                                </tbody>
                            </table>
                        </>
                    }
                </div>
                
                {loading ?
                    <Box align="center" sx={{my: 2}}>
                        <CircularProgress />
                    </Box>
                    :
                    <Stack spacing={2} direction="row" justifyContent="space-between" className="tic4bio-sectores-btns">
                        <button className="btn white" onClick={(e) => handlePrev(e)}>Anterior</button>
                        <button className="btn green" onClick={(e) => handleSend(e)} style={{margin: "16px 0"}}>Calcular</button>
                    </Stack>
                }
            </Box>

            <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.title} contentText={errorMsg.content}/>
        </>
    );
}
export default FormLimpieza2Component;