import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const ModalInfo = ({open, setOpen, contentTitle, contentText}) => {
    
    const handleClose = () => {
        setOpen(false);
    };

    return ( 
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                {contentTitle}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {contentText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <button className="btn green" onClick={handleClose}>Cerrar</button>
            </DialogActions>
        </Dialog>
     );
}
 
export default ModalInfo;