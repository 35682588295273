import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import StepperBiodiversidadComponent from "../components/formsBioInvertebrados/StepperBiodiversidad";
import FormBioStepOneComponent from "../components/formsBioInvertebrados/FormBioStepOne";
import FormBioStepTwoComponent from "../components/formsBioInvertebrados/FormBioStepTwo";
import FormBioStepThreeComponent from "../components/formsBioInvertebrados/FormBioStepThree";
import FormBioStepFourComponent from "../components/formsBioInvertebrados/FormBioStepFour";
import aracnidos from "../assets/Iconos_biodiversidad/iconos_invertebrados/aracnidos.png";
import acariformes from "../assets/Iconos_biodiversidad/iconos_invertebrados/acariformes.png";
import anelidos from "../assets/Iconos_biodiversidad/iconos_invertebrados/anelidos.png";
import blatodeos from "../assets/Iconos_biodiversidad/iconos_invertebrados/blatodeos.png";
import coleopteros from "../assets/Iconos_biodiversidad/iconos_invertebrados/coleopteros.png";
import dipteros from "../assets/Iconos_biodiversidad/iconos_invertebrados/dipteros.png";
import embiopteros from "../assets/Iconos_biodiversidad/iconos_invertebrados/embiopteros.png";
import hemipteros from "../assets/Iconos_biodiversidad/iconos_invertebrados/hemipteros.png";
import himenopteros from "../assets/Iconos_biodiversidad/iconos_invertebrados/himenopteros.png";
import isopodos from "../assets/Iconos_biodiversidad/iconos_invertebrados/isopodos.png";
import isopteros from "../assets/Iconos_biodiversidad/iconos_invertebrados/isopteros.png";
import lepidopteros from "../assets/Iconos_biodiversidad/iconos_invertebrados/lepidopteros.png";
import mantodeos from "../assets/Iconos_biodiversidad/iconos_invertebrados/mantodeos.png";
import miriapodos from "../assets/Iconos_biodiversidad/iconos_invertebrados/miriapodos.png";
import moluscos from "../assets/Iconos_biodiversidad/iconos_invertebrados/moluscos.png";
import neumopteros from "../assets/Iconos_biodiversidad/iconos_invertebrados/neumopteros.png";
import ortopteros from "../assets/Iconos_biodiversidad/iconos_invertebrados/ortopteros.png";
import tisanopteros from "../assets/Iconos_biodiversidad/iconos_invertebrados/tisanopteros.png";


const BioInvertebradosPage = () => {

    const [ position, setPosition ] = useState(0);
    const [ stateForm, setStateForm ] = useState({
        finca: {id: ""},
        fecha: "",
        tipoAnalisis: "",
        comunidad: {id: ""},
        metodoMuestreo: {
            muestreosuperficiesuelo: false,
            trampasparaadultos: false
        },
        muestreos: {},
        diccionario: [
            {label: "Acariformes", image: acariformes},
            {label: "Anélidos", image: anelidos},
            {label: "Arácnidos", image: aracnidos},
            {label: "Blatodeos", image: blatodeos},
            {label: "Coleópteros", image: coleopteros},
            {label: "Dípteros", image: dipteros},
            {label: "Embiópteros", image: embiopteros},
            {label: "Hemípteros", image: hemipteros},
            {label: "Himenópteros", image: himenopteros},
            {label: "Isópodos (cochinillas)", image: isopodos},
            {label: "Isópteros", image: isopteros},
            {label: "Lepidópteros", image: lepidopteros},
            {label: "Mantodeos", image: mantodeos},
            {label: "Miriápodos", image: miriapodos},
            {label: "Moluscos", image: moluscos},
            {label: "Neumópteros", image: neumopteros},
            {label: "Ortópteros", image: ortopteros},
            {label: "Tisanópteros", image: tisanopteros}
        ]
    });

    useEffect(() => {
        document.title = "Nueva biodiversidad invertebrados";
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return(
        <div className="tic4bio-home">
            <StepperBiodiversidadComponent position={position} setPosition={setPosition}/>  
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <FormBioStepOneComponent position={position} setPosition={setPosition} stateForm={stateForm} setStateForm={setStateForm}/>
                {(position === 1 || (stateForm.comunidad.id !== "")) &&
                    <>
                        <FormBioStepTwoComponent position={position} setPosition={setPosition} stateForm={stateForm} setStateForm={setStateForm}/>
                    </>
                }
                {(position === 2) &&
                    <>
                        <FormBioStepThreeComponent position={position} setPosition={setPosition} stateForm={stateForm} setStateForm={setStateForm}/>
                    </>
                }
                {(position === 3) &&
                    <>
                        <FormBioStepFourComponent position={position} setPosition={setPosition} stateForm={stateForm} setStateForm={setStateForm}/>
                    </>
                }
            </Box>
        </div>
    );
}
export default BioInvertebradosPage;