import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useAuth } from "../../context/Auth";
import {instance } from '../../utils/axios-config';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import icono_lavadora from "../../assets/Iconos_almazaras/lavadora.png";
import icono_molino from "../../assets/Iconos_almazaras/molino.png";
import icono_batidora from "../../assets/Iconos_almazaras/batidora.png";
import icono_ducha from "../../assets/Iconos_almazaras/ducha.png";
import icono_decanter from "../../assets/Iconos_almazaras/decanter.png";
import icono_centrifuga from "../../assets/Iconos_almazaras/centrifuga.png";
import ModalInfo from '../ModalInfo';

const FormMaquinariaIndustriaComponent = ({position, setPosition, dataForm, setDataForm}) => {

    const {sessionToken} = useAuth();
    const navigate = useNavigate();

    const [loadingBtn, setLoadingBtn] = useState(false);
    const [open, setOpen] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorMsg, setErrorMsg] = useState({title: "", content: ""});
    const [selectedMachines, setSelectedMachines] = useState({
        lavadora: false,
        ducha: false,
        molino: false,
        batidora: false,
        decanter: false,
        centrifuga: false,
    });

    const checkForm = (data) =>{
        let valid = true;
        setErrors({});
        if((!data.lavadora && !data.ducha && !data.molino && !data.batidora && !data.decanter && !data.centrifuga )){
            setErrors(errors => ({...errors, errorContadores: true, errorContadoresMsg: "Debes seleecionar al menos una maquina con contador individual"}));
            valid = false;
        }
        return valid;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(checkForm(dataForm)){
            try {
                setLoadingBtn(true);
                instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
                await instance.post(`/almazara/create`, dataForm).then(() => {
                    setLoadingBtn(false);
                    navigate("/home", {state: {type: "action-new", text: "Se ha creado la  almazara correctamente."}});
                });
            } catch (error) {
                setOpen(true);
                setErrorMsg({title: "¡Vaya! Parece que algo ha ido mal.", content: "Algo ha fallado al intentar crear la almazara. Por favor, vuelve a intentarlo más tarde."});
                setLoadingBtn(false);
            }
        }
    }

    const handlePrev = (e) => {
        e.preventDefault();
        setPosition(0);
    }

    const toggleMachineSelection = (machine) => {
        setSelectedMachines(prevState => ({
            ...prevState,
            [machine]: !prevState[machine],
        }));
    };

    useEffect(() => {
        Object.keys(selectedMachines).forEach(machine => {
            dataForm[machine] = selectedMachines[machine];
        });
    }, [selectedMachines]);

    return (
        <>
            <div className={"tic4bio-home " + (position !== 1 ? 'hide' : "")}>
                <h1 className="tic4bio-title">Crear linea de producción de la almazara</h1>
                <Typography sx={{ fontSize: '12px', fontWeight: 300, marginTop: '5px' }}>
                    (Seleccione únicamente los equipos de su almazara que disponen de contador de agua)*
                </Typography>
                <Box component="form" onSubmit={handleSubmit}>
                    <div className="maquinaria">
                        <div className="maquinaria-imagenes">
                            {/* Lavadora */}
                            <div className="maquinaria-imagen-container" onClick={() => toggleMachineSelection('lavadora')}>
                                <p className="maquinaria-title">Lavadora</p>
                                <img className={`maquinaria-imagen ${selectedMachines.lavadora ? 'selected' : ''}`} src={icono_lavadora} alt="icono_lavadora" />
                            </div>
                            {/* Ducha */}
                            <div className="maquinaria-imagen-container" onClick={() => toggleMachineSelection('ducha')}>
                                <p className="maquinaria-title">Ducha</p>
                                <img className={`maquinaria-imagen ${selectedMachines.ducha ? 'selected' : ''}`} src={icono_ducha} alt="icono_ducha" />
                            </div>
                            {/* Molino */}
                            <div className="maquinaria-imagen-container" onClick={() => toggleMachineSelection('molino')}>
                                <p className="maquinaria-title">Molino</p>
                                <img className={`maquinaria-imagen ${selectedMachines.molino ? 'selected' : ''}`} src={icono_molino} alt="icono_molino" />
                            </div>
                        </div>
                        <div className="maquinaria-imagenes">
                            {/* Batidora */}
                            <div className="maquinaria-imagen-container" onClick={() => toggleMachineSelection('batidora')}>
                                <p className="maquinaria-title">Batidora</p>
                                <img className={`maquinaria-imagen ${selectedMachines.batidora ? 'selected' : ''}`} src={icono_batidora} alt="icono_batidora" />
                            </div>
                            {/* Decanter */}
                            <div className="maquinaria-imagen-container" onClick={() => toggleMachineSelection('decanter')}>
                                <p className="maquinaria-title">Decanter</p>
                                <img className={`maquinaria-imagen ${selectedMachines.decanter ? 'selected' : ''}`} src={icono_decanter} alt="icono_decanter" />
                            </div>
                            {/* Centrifuga */}
                            <div className="maquinaria-imagen-container" onClick={() => toggleMachineSelection('centrifuga')}>
                                <p className="maquinaria-title">Centrifugadora</p>
                                <img className={`maquinaria-imagen ${selectedMachines.centrifuga ? 'selected' : ''}`} src={icono_centrifuga} alt="icono_centrifuga" />
                            </div>
                        </div>
                        {errors.errorContadoresMsg && <p className="errorMsg">{errors.errorContadoresMsg}</p>}
                    </div>
                    <div className='login-buttons'>
                        {loadingBtn ?
                            <Box align="center" sx={{my: 2}}>
                                <CircularProgress />
                            </Box>
                            :
                            <Stack spacing={2} direction="row" justifyContent="space-between" className="tic4bio-sectores-btns">
                                <button className="btn white" onClick={(e) => handlePrev(e)}>Anterior</button>
                                <button className="btn green" type="submit" style={{margin: "16px 0", marginLeft: 0}}>Crear</button>
                            </Stack>
                        }
                    </div>
                </Box>
            </div>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.title} contentText={errorMsg.content}/>
        </>
    );
    

}
export default FormMaquinariaIndustriaComponent;