import { useEffect, useState } from "react";
import { instance } from '../utils/axios-config.js';
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import ModalInfo from '../components/ModalInfo.js';
import { useAuth } from "../context/Auth.js";

import EvoBiodiversidadComponent from "../components/evolucionIndicadores/EvoBiodiversidad.js";
import EvoHuellaComponent from "../components/evolucionIndicadores/EvoHuella.js";
import { groupByUnidad } from "../utils/commons.js";
import EvoRiegoComponent from "../components/evolucionIndicadores/EvoRiego.js";
import Mensajes from "../utils/mensajes.js";

import { Chart, CategoryScale, LogarithmicScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

Chart.register(CategoryScale, LogarithmicScale, LinearScale, BarElement, Title, Tooltip, Legend);

const EvolucionPage = () => {

    const {sessionToken} = useAuth();

    const [ fincas, setFincas ] = useState([]);
    const [ finca, setFinca ] = useState({id: ""});
    const [ indicador, setIndicador ] = useState("");

    const [ loading, setLoading ] = useState(false);
    const [ loadingEvoBio, setLoadingEvoBio ] = useState(false);
    const [ loadingEvoHuella, setLoadingEvoHuella ] = useState(false);

    const [ open, setOpen ] = useState(false);
    const [ error, setError ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState({open: false, title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado. Por favor, vuelve a intentarlo más tarde."});
    
    useEffect(() => {
        document.title = "Evolución";
    }, []);

    const loadPageData = async (dataType) => {
        let buildUrl;
        if(dataType === "finca"){
            buildUrl = `/fincas/fincasByDataType/${dataType}`;
        }else{
            buildUrl = `/fincas/${dataType}`;
        }
        try{
            setLoading(true);
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}

            await instance.get(buildUrl).then(function(resp) {

                if(dataType === "unidades"){
                    let groupUnidades = groupByUnidad(resp.data);
                    groupUnidades = Object.entries(groupUnidades).map(([key, value], index) => {
                        return {
                            id: `temp-${index}`,
                            nombre: key,
                            fincas: value
                        };
                    });
                    setFincas(groupUnidades);
                }else{
                    setFincas(resp.data);
                }

            }).catch(function(resp) {
                setOpen(true);
                setError(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar la información de las fincas. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setLoading(false);
            setOpen(true);
            setError(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar la información de las fincas. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const handleSelectFinca = (fincaId) => {
        let fincaFound = fincas.find((item) => item.id === fincaId);
        if(fincaFound){
            setFinca(fincaFound);
        }
    }

    const handleSelectIndicador = (value) => {
        setIndicador(value);
        setFinca({id: ""});

        if(("bio" !== value && "bio" === indicador) || ("bio" !== value && indicador === "")){
            loadPageData("unidades");
        }else if(("bio" === value && "bio" !== indicador) || (!["HA", "riego"].includes(value) && indicador === "")){
            loadPageData("finca");
        }
    }
   
    return(
        <div className="width100">
            <div className="tic4bio-huella-section">EVOLUCIÓN</div>

            <div className="tic4bio-home">
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    <div>
                        {!error ?
                            <>
                                <p>Seleccione el indicador del módulo que desea visualizar*</p>
                                <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                    <InputLabel htmlFor="indicador-select">Indicador*</InputLabel>
                                    <Select
                                        className="t4b-finca-sector-inputs"
                                        id="indicador-select"
                                        label="Indicador*"
                                        required
                                        disabled={loading || loadingEvoBio || loadingEvoHuella}
                                        MenuProps={{ disableScrollLock: true }}
                                        value={indicador}
                                        onChange={(e) => { handleSelectIndicador(e.target.value) }}>
                                        
                                        <MenuItem value="HA">
                                            Huella de agua
                                        </MenuItem>
                                        <MenuItem value="riego">
                                            Riego
                                        </MenuItem>
                                        <MenuItem value="bio">
                                            Biodiversidad
                                        </MenuItem>
                                        
                                    </Select>
                                </FormControl>
                                {indicador &&
                                    <>
                                        {loading ? 
                                            (
                                                <Box p={3} align="center">
                                                    <CircularProgress />
                                                </Box>
                                            ) : (
                                                <>
                                                    {(fincas.length > 0) ?
                                                        <>
                                                            <p>Seleccione la finca para la evolución que desea visualizar*</p>
                                                            {(indicador !== "HA" && indicador !== "riego") ? 
                                                                <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                                    <InputLabel htmlFor="finca-select">Fincas*</InputLabel>
                                                                    <Select
                                                                        className="t4b-finca-sector-inputs"
                                                                        id="finca-select"
                                                                        label="Finca"
                                                                        required
                                                                        MenuProps={{ disableScrollLock: true }}
                                                                        disabled={loadingEvoBio}
                                                                        value={finca.id}
                                                                        onChange={(e) => { handleSelectFinca(e.target.value) }    
                                                                        }
                                                                        >
                                                                        
                                                                        
                                                                        {fincas.map((finca, index) => (
                                                                            <MenuItem key={index} value={finca.id}>
                                                                                {finca.unidadProductiva} - {finca.nombre}
                                                                            </MenuItem>
                                                                        ))}
                                                                    
                                                                        
                                                                    </Select>
                                                                </FormControl>
                                                            :
                                                                <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                                    <InputLabel htmlFor="finca-select">Fincas*</InputLabel>
                                                                    <Select
                                                                        className="t4b-finca-sector-inputs"
                                                                        id="finca-select"
                                                                        label="Finca"
                                                                        required
                                                                        disabled={loadingEvoHuella}
                                                                        MenuProps={{ disableScrollLock: true }}
                                                                        value={finca.id}
                                                                        onChange={(e) => { handleSelectFinca(e.target.value) }    
                                                                        }
                                                                        >
                                                                        
                                                                        
                                                                        {fincas.map((finca, index) => (
                                                                            <MenuItem key={index} value={finca.id}>
                                                                                {finca.nombre}
                                                                            </MenuItem>
                                                                        ))}
                                                                           
                                                                    </Select>
                                                                </FormControl>
                                                            }
                                                            
                                                            {finca?.id &&
                                                                <>
                                                                    
                                                                    {indicador === "bio" &&
                                                                        <EvoBiodiversidadComponent finca={finca} setError={setError} setOpen={setOpen} loadingEvoBio={loadingEvoBio} setLoadingEvoBio={setLoadingEvoBio}/>
                                                                    }
                                                                    {indicador === "HA" &&
                                                                        <EvoHuellaComponent finca={finca} setError={setError} setOpen={setOpen} loadingEvoHuella={loadingEvoHuella} setLoadingEvoHuella={setLoadingEvoHuella}/>
                                                                    }
                                                                    {indicador === "riego" &&
                                                                        <>
                                                                        <EvoRiegoComponent finca={finca} setError={setError} setOpen={setOpen} loadingEvoHuella={loadingEvoHuella} setLoadingEvoHuella={setLoadingEvoHuella} />
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                        :
                                                        <p><Mensajes type="vegetal" /></p>
                                                    }
                                                </>
                                            )
                                        }
                                    </>
                                }
                            </>
                            :
                            <>
                                <p>Se ha producido un error inesperado. Vuelva a intentarlo más tarde.</p>
                            </>
                        }
                        
                    </div>
                </Box>
            </div>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.title} contentText={errorMsg.content}/>
        </div>
    );
} 
export default EvolucionPage;