import { Dialog, DialogActions, DialogContent } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import ErrorIcon from '@mui/icons-material/Error';

const ModalMejoraBiodiversidad = ({open, setOpen}) => {
    
    const handleClose = () => {
        setOpen(false);
    };

    return ( 
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogContent>
                <div style={{lineHeight: 1.5, letterSpacing: "0.00938em",color: "rgba(0, 0, 0, 0.6)"}}>
                    <h4>Biodiversidad <Tooltip title="Conjunto de especies, variedades y variabilidad genética de las plantas cultivadas."><u>CULTIVADA:</u></Tooltip></h4>
                    <ul>
                        <li><b>Obligatorio</b>: Renunciar al uso de herbicidas, fitosanitarios y fertilizantes de síntesis química.<ErrorIcon color="primary" style={{verticalAlign: "middle", marginLeft: "4px"}}/></li>
                        <li><b>Obligatorio</b>: No utilizar cultivos transgénicos.<ErrorIcon color="primary" style={{verticalAlign: "middle", marginLeft: "4px"}}/></li>
                        <li><b>Obligatorio</b>: Elegir especies, variedades o material heterogéneo adecuados que resistan a plagas y enfermedades.<ErrorIcon color="primary" style={{verticalAlign: "middle", marginLeft: "4px"}}/></li>
                        <li><b>Obligatorio</b>: Usar semillas y animales con un alto grado de diversidad genética, resistencia a las enfermedades y longevidad.<ErrorIcon color="primary" style={{verticalAlign: "middle", marginLeft: "4px"}}/></li>
                        <li>Incrementar la diversidad de especies cultivadas.</li>
                        <li>Incrementar la diversidad de variedades cultivadas para cada especie.</li>
                        <li>Incrementar el uso de material heterogéneo de variedades locales y razas autóctonas adaptadas a las condiciones de la zona.</li>
                    </ul>
                    <h4>Biodiversidad <Tooltip title="Todas las especies de plantas, animales y microorganismos existentes que interactúan dentro de un ecosistema, en nuestro caso, agroecosistema."><u>ASOCIADA:</u></Tooltip></h4>
                    <ul>
                        <li>
                            <p>SUELO.</p>
                            <ul>
                                <li><b>Obligatorio</b>: Mantener y mejorar la vida y la fertilidad natural del suelo, la estabilidad, la retención de agua y la biodiversidad del suelo.<ErrorIcon color="primary" style={{verticalAlign: "middle", marginLeft: "4px"}}/></li>
                                <li><b>Obligatorio</b>: Prevenir la pérdida de materia orgánica, la compactación y la erosión del suelo y luchar contra estos fenómenos.<ErrorIcon color="primary" style={{verticalAlign: "middle", marginLeft: "4px"}}/></li>
                                <li><b>Obligatorio</b>: Asegurar la nutrición de los vegetales con nutrientes que procedan principalmente del ecosistema edáfico.<ErrorIcon color="primary" style={{verticalAlign: "middle", marginLeft: "4px"}}/></li>
                                <li><b>Obligatorio</b>: Respecto a la cantidad de estiércol utilizado, no exceder de 170 kg de nitrógeno al año por hectárea agrícola.<ErrorIcon color="primary" style={{verticalAlign: "middle", marginLeft: "4px"}}/></li>
                                <li>Labrar según curvas de nivel.<ErrorIcon color="primary" style={{verticalAlign: "middle", marginLeft: "4px"}}/></li>
                                <li>Labrar superficialmente.</li>
                                <li>Prescindir del laboreo.</li>
                                <li>Incorporar materia orgánica.<ErrorIcon color="primary" style={{verticalAlign: "middle", marginLeft: "4px"}}/></li>
                                <li>Mantener el suelo cubierto la mayor parte del tiempo que sea posible.</li>
                            </ul>
                        </li>
                        <li>
                            <p>PAISAJE y FLORA BENEFICIOSA.</p>
                            <ul>
                                <li><b>Obligatorio</b>: Conservar elementos del paisaje natural.<ErrorIcon color="primary" style={{verticalAlign: "middle", marginLeft: "4px"}}/></li>
                                <li><b>Obligatorio</b>: Conservar los recursos naturales (agua, suelo, aire…).<ErrorIcon color="primary" style={{verticalAlign: "middle", marginLeft: "4px"}}/></li>  
                                <li>Favorecer un paisaje más heterogéneo, en mosaico.</li>
                                <li>Restaurar elementos del paisaje natural.</li>
                                <li>Incrementar el porcentaje de hábitats seminaturales y naturales, aprovechando zonas improductivas.</li>
                                <li>Conservar la flora y fauna protegida y amenazada.</li>
                                <li>Restaurar la flora y fauna protegida y amenazada.</li>
                                <li>Luchar contra las especies exóticas invasoras.</li>
                                <li>Conservar la vegetación de ribera.</li>
                                <li>Restaurar la vegetación de ribera.</li>
                                <li>Conservar la vegetación de setos, sotos y herrizas.</li>
                                <li>Restaurar la vegetación de setos, sotos y herrizas.</li>
                            </ul>
                        </li>
                        <li>
                            <p>FAUNA BENEFICIOSA</p>
                            <ul>
                                <li><b>Obligatorio</b>: Proteger a los enemigos naturales de las plagas.<ErrorIcon color="primary" style={{verticalAlign: "middle", marginLeft: "4px"}}/></li>
                                <li>Fomentar los insectos auxiliares naturales a través de la colocación de estructuras que ofrezcan alimento y/o refugio como flora beneficiosa, muros de piedra, hoteles de insectos.</li>
                                <li>Fomentar las aves insectívoras con la colocación de estructuras que ofrezcan refugio como cajas nido.</li>
                            </ul>
                        </li>
                        <li>
                            <p>PRÁCTICAS AGRARIAS</p>
                            <ul>
                                <li><b>Obligatorio</b>: Establecer rotaciones adecuadas de cultivos.<ErrorIcon color="primary" style={{verticalAlign: "middle", marginLeft: "4px"}}/></li>
                                <li><b>Obligatorio</b>: Elegir variedades vegetales atendiendo a las particularidades de los sistemas específicos de producción ecológica y restando especial atención a los resultados agronómicos, la resistencia a las enfermedades, la adaptación a condiciones climáticas y edafológicas locales diversas y el respeto de las limitaciones naturales a los cruces.<ErrorIcon color="primary" style={{verticalAlign: "middle", marginLeft: "4px"}}/></li> 
                                <li><b>Obligatorio</b>: Usar materiales ecológicos de reproducción vegetal, tales como los compuestos de material heterogéneo ecológico y de variedades ecológicas adecuadas para la producción ecológica.<ErrorIcon color="primary" style={{verticalAlign: "middle", marginLeft: "4px"}}/></li>
                                <li><b>Obligatorio</b>: Elegir las razas animales atendiendo a un alto grado de diversidad genética, la capacidad de los animales de adaptarse a las condiciones locales, su valor de cría, su longevidad, su vitalidad y su resistencia frente a enfermedades o problemas sanitarios.<ErrorIcon color="primary" style={{verticalAlign: "middle", marginLeft: "4px"}}/></li>
                                <li><b>Obligatorio</b>: Establecer rotaciones apropiadas de cultivos.<ErrorIcon color="primary" style={{verticalAlign: "middle", marginLeft: "4px"}}/></li> 
                                <li>Implantar cubiertas vegetales.</li>
                                <li>Implantar cubiertas vegetales basadas en el cultivo de leguminosas.</li>
                                <li>Establecer rotaciones plurianuales de cultivos que incluyan el cultivo de leguminosas y los abonos verdes.</li>
                                <li>Implantar cultivos asociados y/o intercalados.</li>
                                <li>Introducir ganadería a través de animales adaptados al lugar y vinculados al suelo.</li>
                            </ul>
                        </li>
                    </ul>
                    <p style={{display: "flex", gap: "6px"}}><ErrorIcon color="primary"/>Obligatorio cumplimento para los operadores ecológicos.</p>
                </div>
            </DialogContent>
            <DialogActions>
                <button className="btn green" onClick={handleClose}>Cerrar</button>
            </DialogActions>
        </Dialog>
     );
}
 
export default ModalMejoraBiodiversidad;