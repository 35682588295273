import { Autocomplete, Box, CircularProgress, FormControl, FormControlLabel, FormLabel, Input, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { instanceJA, instance } from '../../utils/axios-config';
import { useAuth } from "../../context/Auth";
import { useNavigate, useLocation } from "react-router-dom";
import ModalInfo from "../ModalInfo";

const FormNuevaIndustriaComponent = ({position, setPosition, dataForm, setDataForm}) => {

    const {sessionToken} = useAuth();
    const navigate = useNavigate();

    const [error, setError] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorMsg, setErrorMsg] = useState({title: "¡Vaya! Parece que algo ha ido mal.", content: "Algo ha fallado al intentar recuperar la información del servicio de la AEMET. Por favor, vuelve a intentarlo más tarde."});
    const [open, setOpen] = useState(false);
    const [provincias, setProvincias] = useState([]);
    const [municipios, setMunicipios] = useState([]);
    const [municipioValue, setMunicipioValue] = useState(null);
    const [estaciones, setEstaciones] = useState([]);
    const [estacionValue, setEstacionValue] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingBtn, setLoadingBtn] = useState(false);

    const handleChangeProv = (event) => {
        let prov = provincias.find((p) => {return p.id === event.target.value});
        let stcFilter = [];
        let municipiosFilter = [];
        let remove_estacion = false;
        let remove_municipio = false;
        if(prov){
            stcFilter = estaciones.map(e => {
                if(parseInt(e.id.split("-")[0]) === prov?.id){
                    e.match = true;
                }else{
                    e.match = false;
                    if(dataForm.estacion_id === parseInt(e.id.split("-")[1])){
                        remove_estacion = true;
                        setEstacionValue(null);
                    }
                }
                return e;
            });
            
            municipiosFilter = municipios.map(e => {
                if(prov?.id === parseInt(e.id.slice(0,2))){
                    e.match = true;
                }else{
                    e.match = false;
                    if(parseInt(dataForm.municipio_id) === parseInt(e.id)){
                        remove_municipio = true;
                        setMunicipioValue(null);
                    }
                }
                return e;
            });
        }else{
            stcFilter = estaciones.map(e => {
                e.match = true;
                return e;
            });
            municipiosFilter = municipios.map(e => {
                e.match = true;
                return e;
            });
            remove_municipio = true;
            remove_estacion = true;
            setEstacionValue(null);
            setMunicipioValue(null);
        }

        setEstaciones(stcFilter);
        setMunicipios(municipiosFilter);
        setDataForm(dataForm => ({...dataForm, 
            provincia: prov?.nombre ? prov.nombre : "", 
            provincia_id: prov?.id ? prov.id : "",
            estacion_id: (remove_estacion ? "" : dataForm.estacion_id),
            municipio: (remove_estacion ? "" : dataForm.municipio),
            municipio_id: (remove_municipio ? "" : dataForm.municipio_id),
        }));
    };

    const handleChangeMunicipio = (event, newValue) => {
        setMunicipioValue(newValue);
        setDataForm(dataForm => ({...dataForm, municipio: newValue?.nombre, municipio_id: newValue?.id ? newValue.id: null}));
    };

    const handleNext = () => {
        if(checkForm(dataForm)){
            setPosition(1);
        }
    };

    const handleChangeTipoContador = (e) => {
        console.log(e);
        if(e === "true"){
            setDataForm({...dataForm, 
            contadorGeneral:true, 
            centrifuga: false,
            decanter: false,
            batidora: false,
            molino: false,
            ducha: false,
            lavadora: false});
        }else{
            setDataForm({...dataForm, 
            contadorGeneral: false,
            centrifuga: false,
            decanter: false,
            batidora: false,
            molino: false,
            ducha: false,
            lavadora: false});
        }
    }

    useEffect(() => {
        loadFormData();
    }, []);

    const loadFormData = async () => {
        let urlEstaciones = "/estaciones";
        let urlAemetMunicipios = "aemet/municipios";

        let allPromises = [];
        allPromises.push(instanceJA.get(urlEstaciones), instance.get(urlAemetMunicipios));
        try{
            await Promise.all(allPromises).then(function(resp) {

                let provs = resp[0].data.map((i)=>{return {id: i.provincia.id, nombre: i.provincia.nombre, match: true}});
                provs = provs.filter( (ele, ind) => ind === provs.findIndex( elem => elem.jobid === ele.jobid && elem.id === ele.id));

                setProvincias(Array.from(new Set(provs)));
                let stations = resp[0].data.map(e => {
                    e.id = e.provincia.id + "-" + e.codigoEstacion;
                    e.match = true;
                    return e;
                });
                let municipios = resp[1].data.map(e => {
                    e.match = true;
                    return e;
                });
                setMunicipios(municipios);
                setEstaciones(stations);
            }).catch(function(err) {
                if(err.response?.data.message === "error municipios"){
                    setErrorMsg((prevErrorMsg) => ({
                        ...prevErrorMsg,
                        content: "Se ha producido un error al intentar recuperar la información de los municipios y provincias del servicio de la AEMET necesaria para la creación, puede deberse un fallo temporal del propio servicio, por favor, inténtelo de nuevo más tarde."
                    }));
                }else if(err.response?.data.message === "error estaciones" || err.message === "error estaciones"){
                    setErrorMsg((prevErrorMsg) => ({
                        ...prevErrorMsg,
                        content: "Se ha producido un error al intentar recuperar las estaciones del servicio de la AEMET necesaria para la creación, puede deberse un fallo temporal del propio servicio, por favor, inténtelo de nuevo más tarde."
                    }));
                }else{
                    setErrorMsg({title: "¡Vaya! Parece que algo ha ido mal.", content: "Algo ha fallado al intentar recuperar la información del servicio de la AEMET. Por favor, vuelve a intentarlo más tarde."});
                }
                setOpen(true);
                setError(true);
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setOpen(true);
            setError(true);
            setErrorMsg({title: "¡Vaya! Parece que algo ha ido mal.", content: "Algo ha fallado al intentar recuperar las estaciones. Por favor, vuelve a intentarlo más tarde."});
        }
    }

    const checkForm = (data) =>{
        let valid = true;
        setErrors({});
        if((data.nombre === undefined || data.nombre === '')){
            setErrors(errors => ({...errors, errorNombre: true, errorNombreMsg: ""}));
            valid = false;
        }
        if(data.provincia === undefined || data.provincia === ''){
            setErrors(errors => ({...errors, errorProvincia: true, errorProvinciaMsg: ""}));
            valid = false;
        }
        if(data.municipio === undefined || data.municipio === '' || municipioValue === null || municipioValue === undefined || municipioValue === ''){
            setErrors(errors => ({...errors, errorMunicipio: true, errorMunicipioMsg: ""}));
            valid = false;
        }
        if(data.capacidadMolturacion === undefined || data.capacidadMolturacion === ''){
            setErrors(errors => ({...errors, errorMolturacion: true,errorMolturacionMsg: ""}));
            valid = false;
        }else if(!(/^[0-9\b]+$/.test(data.capacidadMolturacion))){
            setErrors(errors => ({...errors, errorMolturacion: true,errorMolturacionMsg: "La capacidad de molturacion debe ser un valor numérico."}));
            valid = false;
        }
        return valid;
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if(checkForm(dataForm)){
            try {
                setLoadingBtn(true);
                instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
                await instance.post(`/almazara/create`, dataForm).then(() => {
                    setLoadingBtn(false);
                    navigate("/home", {state: {type: "action-new", text: "Se ha creado la  almazara correctamente."}});
                });
            } catch (error) {
                if(error.response?.data.message === "error estaciones"){
                    setErrorMsg((prevErrorMsg) => ({
                        ...prevErrorMsg,
                        content: "Se ha producido un error al intentar recuperar las estaciones del servicio de la AEMET necesaria para la creación, puede deberse un fallo temporal del propio servicio, por favor, inténtelo de nuevo más tarde."
                    }));
                }else{
                    setErrorMsg({title: "¡Vaya! Parece que algo ha ido mal.", content: "Algo ha fallado al intentar crear la industria. Por favor, vuelve a intentarlo más tarde."});
                }
                setOpen(true);
                setLoadingBtn(false);
            }
        }
    }

    return(
        <>
            <div className={"tic4bio-home " + (position !== 0 ? 'hide' : "")}>
                {loading ? 
                    (
                        <Box className="loading">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Box component="form" onSubmit={handleSubmit} noValidate>
                            <h1 className="tic4bio-title">DATOS INDUSTRIA</h1>
                            {!error ? 
                                <>
                                    <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                        <InputLabel htmlFor="name">Nombre*</InputLabel>
                                        <Input
                                            id="name"
                                            className="t4b-finca-inputs"
                                            label="Nombre de la Finca"
                                            required
                                            name="name"
                                            onChange={(e) => {setDataForm(dataForm => ({...dataForm, nombre: e.target.value}))}}
                                            value={dataForm.nombre}
                                            error={errors.errorNombre}
                                        />
                                        {errors.errorNombreMsg && <div className="errorInput">{errors.errorNombreMsg}</div>}
                                    </FormControl>
                                    <h4 className="tic4bio-subtitle">Localización de la Almazara:</h4>
                                    <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                        <InputLabel htmlFor="provincia">Provincia*</InputLabel>
                                        <Select
                                            className="t4b-finca-inputs"
                                            id="provincia"
                                            name="provincia"
                                            value={dataForm.provincia_id}
                                            label="Provincia"
                                            onChange={(e) => handleChangeProv(e)}
                                            required
                                            error={errors.errorProvincia}>
                                                <MenuItem value={""}>-</MenuItem>
                                                {provincias.map((item, index) =>{
                                                        return <MenuItem key={"provincia-" + index} value={item.id}>{item.nombre}</MenuItem>
                                                    }
                                                )}
                                        </Select>
                                        {errors.errorProvinciaMsg && <div className="errorInput">{errors.errorProvinciaMsg}</div>}
                                    </FormControl>
                                    {dataForm.provincia_id &&
                                        <>
                                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                            <Autocomplete
                                                options={municipios}
                                                renderOption={(props, option) => {
                                                    if(option.match){
                                                        return <li {...props}>{option.nombre}</li>
                                                    }
                                                }}
                                                className="t4b-finca-inputs"
                                                getOptionLabel={(option) => option.nombre}
                                                id="municipio"
                                                onChange={(e, newValue) => handleChangeMunicipio(e, newValue)}
                                                value={municipioValue}
                                                renderInput={(params) => (
                                                    <>
                                                    <TextField {...params} label="Municipio*" variant="standard" />
                                                    </>
                                                )}
                                                sx={{
                                                    ...(errors.errorMunicipio && {
                                                        '& .MuiInput-root:before': {
                                                            borderBottom: "1px solid #d32f2f",
                                                        }
                                                    })
                                                    }}
                                            />
                                            {errors.errorMunicipioMsg && <div className="errorInput">{errors.errorMunicipioMsg}</div>}
                                        </FormControl>
                                        </>
                                    }
                                        <h4 className="tic4bio-subtitle">Características de la Almazara:</h4>
                                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                        <InputLabel htmlFor="cMolturacion">Capacidad de Molturacion*</InputLabel>
                                        <Input
                                            id="capacidadMolturacion"
                                            className="t4b-finca-inputs"
                                            label="Capacidad de Molturación"
                                            required
                                            name="capacidadMolturacion"
                                            onChange={(e) => {setDataForm(dataForm => ({...dataForm, capacidadMolturacion: e.target.value}))}}
                                            value={dataForm.capacidadMolturacion}
                                            error={errors.errorMolturacion}
                                            endAdornment={<InputAdornment position="end">kg/hora</InputAdornment>}
                                        />
                                        {errors.errorMolturacionMsg && <div className="errorInput">{errors.errorMolturacionMsg}</div>}
                                        </FormControl>
                                        <Typography sx={{ fontSize: '12px', fontWeight: 300, marginTop: '5px' }}>
                                            ¿Dispone de contadores de consumo de agua en los equipos de la línea de producción?*
                                        </Typography>

                                        <FormControl sx={{width: "100%", marginBottom: "20px", marginTop: "20px"}} className="radio-green" variant="standard">
                                            <FormLabel htmlFor="contadorGeneral" style={{color: "#000"}}>¿Dispone de Contadores Individuales?*</FormLabel>
                                            <RadioGroup
                                                name="contadorGeneral"
                                                id="contadorGeneral"
                                                value={dataForm.contadorGeneral}
                                                onChange={(e) => handleChangeTipoContador(e.target.value)}
                                            >
                                                <FormControlLabel value="true" control={<Radio />} label="Solo contador general" />
                                                <FormControlLabel value="false" control={<Radio />} label="Sí" />
                                            </RadioGroup>
                                            {errors.errorTipoPlantacionMsg && <div className="errorMsg">{errors.errorTipoPlantacionMsg}</div>}
                                        </FormControl>

                                        <div className='login-buttons'>
                                            {loadingBtn ?
                                                <Box align="center" sx={{ my: 2 }}>
                                                    <CircularProgress />
                                                </Box>
                                                :
                                                <>
                                                    {dataForm.contadorGeneral ? (
                                                        <button type="submit" className="btn green">
                                                            <span>Crear</span>
                                                        </button>
                                                    ) : (
                                                        <button type="button" className="btn green" onClick={() => handleNext()}>
                                                            <span>Siguiente</span>
                                                        </button>
                                                    )}
                                                </>
                                            }
                                        </div>
                                    </>
                                : 
                                    <p>Se ha producido un error inesperado. Vuelva a intentarlo más tarde.</p>
                            }

                        </Box>
                    )
                }
            </div>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.title} contentText={errorMsg.content}/>
        </>
    );
}

export default FormNuevaIndustriaComponent;