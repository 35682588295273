import { Box, CircularProgress, FormControl, FormControlLabel, FormLabel, Input, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack } from "@mui/material";
import { useState } from "react";
import { checkDoublePattern } from "../../utils/commons";
import { instance } from "../../utils/axios-config";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context/Auth";
import ModalInfo from "../ModalInfo";

const FormCaracteristicasCultivoComponent = ({position, setPosition, state, setState}) => {

    const {sessionToken} = useAuth();
    const {id} = useParams();
    const navigate = useNavigate();

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState({title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado al intentar crear el sector. Por favor, vuelve a intentarlo más tarde."});
    const [open, setOpen] = useState(false);

    const handleNext = (e) => {
        e.preventDefault();
        if(validateForm()){
            setPosition(2);
        }
    }

    const handlePrev = (e) => {
        e.preventDefault();
        setPosition(0);
    }

    const handleChangeCultivo = (e) => {
        if(e === "olivar"){
            setState({...state, tipoCultivo: e});
        }else{
            setState({...state, tipoCultivo: e, seto: ""});
        }
    }

    const handleChangeSuperficieSector = (e) => {
        if(checkDoublePattern(e)){
            setState({...state, superficieSector: e});
        }
    }

    const validateForm = () =>{
        setErrors({});
        let inputIds = "";
        let valid = true;
        if(state.tipoCultivo === undefined || state.tipoCultivo === ''){
            setErrors(errors => ({...errors, errorTipoCultivo: true, errorTipoCultivoMsg: ""}));
            inputIds = inputIds ? inputIds +",tipoCultivo" : "tipoCultivo";
            valid = false;
        }else if(state.tipoCultivo === "olivar"){
            if(state.seto === undefined || state.seto === ''){
                setErrors(errors => ({...errors, errorSeto: true, errorSetoMsg: "Debes elegir si el olivar es en seto o no."}));
                inputIds = inputIds ? inputIds +",seto" : "seto";
                valid = false;
            }
        }
        if(state.distanciaArboles === undefined || state.distanciaArboles === ''){
            setErrors(errors => ({...errors, errorDistanciaArboles: true, errorDistanciaArbolesMsg: ""}));
            inputIds = inputIds ? inputIds +",distanciaArboles" : "tipoUDistanciaArboles";
            valid = false;
        }else if(!(checkDoublePattern(state.distanciaArboles))){
            setErrors(errors => ({...errors, errorDistanciaArboles: true, errorDistanciaArbolesMsg: "Debe ser un valor numérico."}));
            inputIds = inputIds ? inputIds +",distanciaArboles" : "tipoUDistanciaArboles";
            valid = false;
        }
        if(state.distanciaLineas === undefined || state.distanciaLineas === ''){
            setErrors(errors => ({...errors, errorDistanciaLineas: true, errorDistanciaLineasMsg: ""}));
            inputIds = inputIds ? inputIds +",distanciaLineas" : "tipoUDistanciaLineas";
            valid = false;
        }else if(!(checkDoublePattern(state.distanciaLineas))){
            setErrors(errors => ({...errors, errorDistanciaLineas: true, errorDistanciaLineasMsg: "Debe ser un valor numérico."}));
            inputIds = inputIds ? inputIds +",distanciaLineas" : "tipoUDistanciaLineas";
            valid = false;
        }
        if(state.diametroCopa === undefined || state.diametroCopa === ''){
            setErrors(errors => ({...errors, errorDiametroCopa: true, errorDiametroCopaMsg: ""}));
            inputIds = inputIds ? inputIds +",diametroCopa" : "diametroCopa";
            valid = false;
        }else if(!(checkDoublePattern(state.diametroCopa))){
            setErrors(errors => ({...errors, errorDiametroCopa: true, errorDiametroCopaMsg: "Debe ser un valor numérico."}));
            inputIds = inputIds ? inputIds +",diametroCopa" : "diametroCopa";
            valid = false;
        }
        if(state.tipoPlantacion === "Secano"){
            if(state.superficieSector === undefined || state.superficieSector === ''){
                setErrors(errors => ({...errors, errorSuperficieSector: true, errorSuperficieSectorMsg: ""}));
                inputIds = inputIds ? inputIds +",superficieSector" : "superficieSector";
                valid = false;
            }else if(!(checkDoublePattern(state.superficieSector))){
                setErrors(errors => ({...errors, errorSuperficieSector: true, errorSuperficieSectorMsg: "Debe ser un valor numérico."}));
                inputIds = inputIds ? inputIds +",superficieSector" : "superficieSector";
                valid = false;
            }
        }

        if(inputIds !== ""){
            try{
                let elem = document.getElementById(inputIds.split(",")[0]);
                elem.scrollIntoView({behavior: "smooth"});
                elem.focus();
            }catch(err){

            }
        }
        return valid;
    }

    const formatStateData = () => {
        let data = {
            nombre: state.nombre,
            tipoUsuario: state.tipoUsuario,
            tipoPlantacion: state.tipoPlantacion,
            tipoCultivo: state.tipoCultivo,
            distanciaArboles: parseFloat(state.distanciaArboles?.replace(',', '.')),
            distanciaLineas: parseFloat(state.distanciaLineas?.replace(',', '.')),
            diametroCopa: parseFloat(state.diametroCopa?.replace(',', '.')),
            superficieSector: parseFloat(state.superficieSector?.replace(',', '.')),
            seto: state.seto === "true" ? true : state.seto === "false" ? false : null,
            usuario: state.usuario,
            clave: state.clave,
            codigoEstacion: state.codigoEstacion,
            idEstacion: state.idEstacion,
        };

        return data;
    }

    const handleSend = async (e) => {
        e.preventDefault();
        if(validateForm()){
            try {
                setLoading(true);
                const data = formatStateData();
                instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
                await instance.post(`/sectores/create-sector/${id}`, data).then(() => {
                    setLoading(false);
                    navigate("/listaSectores/" + id, {state: {type: "action-new", text: "Se ha creado el sector correctamente."}});
                });
            } catch (error) {
                if(error.response?.data.message === "error historico"){
                    setErrorMsg((prevErrorMsg) => ({
                        ...prevErrorMsg,
                        content: "Se ha producido un error al intentar recuperar el histórico de datos relacionados con la estación asociada. Esto puede deberse a una indisponibilidad temporal del servicio de la Junta de Andalucía. Por favor, inténtelo de nuevo más tarde."
                    }));
                }else if(error.response?.data.message === "error rosetta"){
                    setErrorMsg((prevErrorMsg) => ({
                        ...prevErrorMsg,
                        content: "Se ha producido un error al intentar realizar el cálculo mediante el análisis manual del suelo. Esto puede deberse a una posible indisposición temporal del servicio externo a la aplicación. Por favor, inténtelo de nuevo más tarde, o elija otra estrategia de captura de datos del suelo."
                    }));
                }else{
                    setErrorMsg((prevErrorMsg) => ({
                        ...prevErrorMsg,
                        content: "Algo ha fallado al intentar crear el sector. Por favor, vuelve a intentarlo más tarde."
                    }));
                }
                setOpen(true);
                setLoading(false);
            }
        }
    }

    return(
        <>
        <Box className={position !== 1 && 'hide'}>
            <h2>Características del Cultivo</h2>
                <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="tipoCultivo">Tipo de cultivo*</InputLabel>
                            <Select
                                className="t4b-finca-sector-inputs"
                                id="tipoCultivo"
                                label="tipoCultivo"
                                value={state.tipoCultivo}
                                onChange={(e) => handleChangeCultivo(e.target.value)}
                                required
                                error={errors.errorTipoCultivo}
                            >
                                <MenuItem value="citricos">Cítricos</MenuItem>
                                <MenuItem value="olivar">Olivar</MenuItem>
                            </Select>
                            {errors.errorTipoCultivoMsg && <div className="errorInput">{errors.errorTipoCultivoMsg}</div>}
                </FormControl>
                <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="distanciaArboles">Marco de Plantación (metros entre árbol)*</InputLabel>
                            <Input
                                id="distanciaArboles"
                                className="t4b-finca-sector-inputs"
                                label="Distancia entre árboles"
                                value={state.distanciaArboles}
                                onChange={(e) => {
                                    if(checkDoublePattern(e.target.value)){
                                        setState({...state, distanciaArboles: e.target.value});
                                    }
                                }}
                                required
                                name="distanciaArboles"
                                error={errors.errorDistanciaArboles}
                            />
                            {errors.errorDistanciaArbolesMsg && <div className="errorInput">{errors.errorDistanciaArbolesMsg}</div>}
                </FormControl>
                <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="distanciaLineas">Marco de Plantación (metros entre líneas de árboles)*</InputLabel>
                            <Input
                                id="distanciaLineas"
                                className="t4b-finca-sector-inputs"
                                label="Distancia entre líneas"
                                value={state.distanciaLineas}
                                onChange={(e) => {
                                    if(checkDoublePattern(e.target.value)){
                                        setState({...state, distanciaLineas: e.target.value});
                                    }
                                }}
                                required
                                name="distanciaLineas"
                                error={errors.errorDistanciaLineas}
                            />
                            {errors.errorDistanciaLineasMsg && <div className="errorInput">{errors.errorDistanciaLineasMsg}</div>}
                </FormControl>
                <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="diametroCopa">Diámetro de Copa (metros)*</InputLabel>
                            <Input
                                id="diametroCopa"
                                className="t4b-finca-sector-inputs"
                                label="Diámetro de Copa"
                                value={state.diametroCopa}
                                onChange={(e) => {
                                    if(checkDoublePattern(e.target.value)){
                                        setState({...state, diametroCopa: e.target.value});
                                    }
                                }}
                                required
                                name="diametroCopa"
                                error={errors.errorDiametroCopa}
                            />
                            {errors.errorDiametroCopaMsg && <div className="errorInput">{errors.errorDiametroCopaMsg}</div>}
                </FormControl>
                {state.tipoCultivo === "olivar" &&
                    <FormControl sx={{width: "100%", marginBottom: "20px"}} className="radio-green" variant="standard">
                        <FormLabel htmlFor="seto">Seto*</FormLabel>
                        <RadioGroup
                            name="seto"
                            value={state.seto}
                            onChange={(e) => setState({...state, seto: e.target.value})}
                            error={errors.errorSeto}
                        >
                            <FormControlLabel value="true" control={<Radio />} label="En seto" />
                            <FormControlLabel value="false" control={<Radio />} label="Sin seto" />
                        </RadioGroup>
                        {errors.errorSetoMsg && <div className="errorMsg">{errors.errorSetoMsg}</div>}
                    </FormControl>
                }

                {state.tipoPlantacion === "Secano" &&
                    <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                        <InputLabel htmlFor="superficieSector">Superficie del Sector (hectáreas)*</InputLabel>
                        <Input
                            id="superficieSector"
                            className="t4b-finca-sector-inputs"
                            label="Superficie Sector"
                            value={state.superficieSector}
                            onChange={(e) => handleChangeSuperficieSector(e.target.value)}
                            required
                            name="superficieSector"
                            error={errors.errorSuperficieSector}
                        />
                        {errors.errorSuperficieSectorMsg && <div className="errorInput">{errors.errorSuperficieSectorMsg}</div>}
                    </FormControl>
                }

                {loading ?
                    <Box align="center" sx={{my: 2}}>
                        <CircularProgress />
                    </Box>
                    :
                    <Stack spacing={2} direction="row" justifyContent="space-between" className="tic4bio-sectores-btns">
                        <button className="btn white" onClick={(e) => handlePrev(e)}>Anterior</button>
                        {state.tipoPlantacion === "Secano" &&
                            <button className="btn green" onClick={(e) => handleSend(e)} style={{margin: "16px 0"}}>Crear</button>
                        }
                        {state.tipoPlantacion !== "Secano" &&
                            <button className="btn green" onClick={(e) => handleNext(e)} style={{margin: "16px 0"}}>Siguiente</button>
                        }
                    </Stack>
                }
            </Box>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.title} contentText={errorMsg.content}/>
        </>
    );
}

export default FormCaracteristicasCultivoComponent;