import { Fragment, useEffect, useState } from "react";
import { instance } from '../utils/axios-config.js';
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useLocation, useParams, Link } from 'react-router-dom';
import ModalInfo from '../components/ModalInfo.js';
import { useAuth } from "../context/Auth.js";
import { convertDate, groupByUnidad } from "../utils/commons.js";
import ActionMessage from "../components/ActionMessage.js";
import DoughnutChart from "../components/charts/DoughnutChat.js";
import Ris from "../components/Ris.js";
import ModalTip from "../components/ModalTip.js";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Alert from '@mui/material/Alert';

const HuellaCultivosPage = () => {

    const {sessionToken} = useAuth();
    const { id } = useParams();
    const {state} = useLocation();
    const [ stateUsed, setStateUsed ] = useState(false);

    const [ unidades, setUnidades ] = useState([]);
    const [ unidad, setUnidad ] = useState({id: ""});
    const [ sectores, setSectores ] = useState([]);
    const [ sector, setSector ] = useState({id: ""});
    const [ huellas, setHuellas ] = useState([]);
    const [ huella, setHuella ] = useState({id: ""});
    const [ campanas, setCampanas ] = useState([]);
    const [ campana, setCampana ] = useState({campana: ""});
    const [ loading, setLoading ] = useState(true);
    const [ loadingSectores, setLoadingSectores ] = useState(false);
    const [ loadingHuellas, setLoadingHuellas ] = useState(false);
    const [ msg, setMsg ] = useState(() => {
        if(state && state.type){
            return state;
        }else{
            return null;
        }
    });
    const [ ris, setRis ] = useState(0);
    const [ openTip, setOpenTip ] = useState(false);
    const [ msgTip, setMsgTip ] = useState({open: false, content: ''});
    const [ open, setOpen ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState({open: false, title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado. Por favor, vuelve a intentarlo más tarde."});
    
    const [valoresHuellasFinca, setValoresHuellasFinca] = useState({
        superficie: "",
        totalPrec: "",
        realAguaCultivo: "",
        produccion: "",
        haVerde: "",
        haAzul: "",
        haTotal: ""
    });
    const [ data, setData ] = useState({
        labels: ['HA Azul', 'HA Verde'],
        datasets: [
            {
              label: 'Huella de agua',
              data: [],
              backgroundColor: [
                'rgba(122,154,200, 0.8)',
                'rgba(128, 198, 6, 0.8)',
              ],
              borderColor: [
                  'rgba(122,154,200, 0.8)',
                  'rgba(128, 198, 6, 0.8)',
              ],
              borderWidth: 2
            },
        ],
    });

    const [ extra, setExtra ] = useState(0);


    useEffect(() => {
        if(state){
            loadAll();
        }else{
            loadPageData();
        }
    }, []);

    


    const loadAll = async () => {
        let buildUnidadesUrl = `/fincas/unidades`;
        let buildSectoresUrl = `/sectores/listall/${id}`;
        let buildHuellasUrl = `/huella/cultivos-list/${id}`;
        instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        try{
            await Promise.all([instance.get(buildUnidadesUrl), instance.get(buildSectoresUrl),instance.get(buildHuellasUrl)]).then(function(responses){

                let unidadesFiltered = filterByCultivos(responses[0].data);
                setUnidades(unidadesFiltered);

                let found = unidadesFiltered.find((obj) => obj.id === +id);
                if (found) {
                    setUnidad(found);
                }

                setSectores(responses[1].data.sectores);
                let sector = responses[1].data.sectores.find((s) => s.id === +state?.sectorId);
                if(sector){
                    setSector(sector);
                }
                if(state?.sectorId === "all"){
                    setSector({id: "all"});
                    calcUnidadSummary(responses[2].data);
                }

                if(state?.campana){
                    let camp = responses[2].data.find((c) => c.campana === state?.campana);
                    setCampanas(responses[2].data);
                    if(camp){
                        setCampana(camp);
                        calcResumenParametrizado(camp.campana, responses[2].data);
                    }
                }else{
                    let huellas = responses[2].data.filter(elemento => elemento.sector_id === state?.sectorId);
                    setHuellas(huellas);
                    let huella = huellas.find((h) => h.id === +state?.huellaId);
                    if(huella){
                        updateDataChart(huella);
                    }
                }
                
            }).catch(function(error){
                setLoading(false);
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar los datos. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });

        }catch(error){
            setLoading(false);
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar los datos. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }


    const loadPageData = async () => {
        let buildUrl = `/fincas/unidades`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await instance.get(buildUrl).then(function(resp) {
                let unidadesFiltered = filterByCultivos(resp.data);
                setUnidades(unidadesFiltered);

                let found = unidadesFiltered.find((obj) => obj.id === +id);
                if (found) {
                    setUnidad(found);
                    loadSectorOnChange(found.id);
                }

            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar la información de las unidades productivas. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setLoading(false);
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar la información de las unidades productivas. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const loadSectorOnChange = async (unidadId) => {
        try{
            let buildUrl = `/sectores/listall/${unidadId}`;
            setLoadingSectores(true);
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
            await instance.get(buildUrl).then(function(resp) {
                //let sectores = resp.data.sectores.filter(elemento => elemento.tipoPlantacion !== "Secano");
                setSectores(resp.data.sectores);
                if(state?.sectorId){
                    let sector = resp.data.sectores.find((s) => s.id === +state?.sectorId);
                    if(sector){
                        setSector(sector);
                        loadHuellasOnChange(sector.id);
                    }else{
                        //Do nothing
                    }
                }
            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar los sectores de la unidad productiva. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoadingSectores(false);
            });
            setLoadingSectores(false);
        }catch(error){
            setLoadingSectores(false);
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar los sectores de la unidad productiva. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const loadHuellasOnChange = async (sectorId) => {
        try{
            let buildUrl = `/huella/cultivos-list/` + unidad.id;
            setLoadingHuellas(true);
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
            await instance.get(buildUrl).then(function(resp) {
                if(sectorId === "all"){
                    setHuellas([]);
                    //let campanas = resp.data.filter(elemento => elemento.campana === huella.id);
                    setCampanas(resp.data);
                    setCampana({campana: ""});
                    calcUnidadSummary(resp.data);
                }else{
                    let huellas = resp.data.filter(elemento => elemento.sector_id === sectorId);
                    setHuellas(huellas);
                    setCampanas([]);
                    if(state?.huellaId && stateUsed === false){
                        let huella = huellas.find((h) => h.id === +state?.huellaId);
                        if(huella){
                            updateDataChart(huella);
                        }
                    }
                }
            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar las campañas de la unidad productiva y el sector. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoadingHuellas(false);
            });
            setLoadingHuellas(false);
        }catch(error){
            setLoadingHuellas(false);
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar los sectores de la unidad productiva y el sector. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const handleLoadSectores = async (event) => {
        let unidad = null;

        let found = unidades.find((obj) => obj.id === event.target.value);
        if (found) {
            unidad = found;
        }

        setUnidad(unidad);
        setSector({id: ""});
        setHuella({id: ""});
        setCampanas([]);
        setCampana({campana: ""});
        loadSectorOnChange(event.target.value);
    }

    const handleSectorSelect = (event) => {
        if(event.target.value === "all"){
            setSector({id: "all"});
            setHuella({id: ""});
        }else{
            let sector = sectores.find((s) => s.id === event.target.value);
            setSector(sector);
            setHuella({id: ""});
        }
        setStateUsed(true);
        loadHuellasOnChange(event.target.value);
    }

    const handleHuellaSelect = (event) => {
        let huella = huellas.find((h) => h.id === event.target.value);
        updateDataChart(huella);
    }

    const handleCampanaSelect = (event) => {
        let camp = campanas.find((c) => c.campana === event.target.value);
        if(camp){
            setCampana(camp);
            calcResumen(camp.campana);
        }
    }

    const calcResumen = (periodo) => {
        let filteredCampanas = campanas.filter((c) => c.campana === periodo);
        /*const sumaHuellaAguaAzul = filteredCampanas.reduce((total, objeto) => {
            return total + objeto.huellaAguaAzul;
        }, 0);
          
        const sumaHuellaAguaVerde = filteredCampanas.reduce((total, objeto) => {
            return total + objeto.huellaAguaVerde;
        }, 0);

        const sumaHuellaAguaAzulRounded = Math.round(sumaHuellaAguaAzul * 100) / 100;
        const sumaHuellaAguaVerdeRounded = Math.round(sumaHuellaAguaVerde * 100) / 100;
        const extraRounded = Math.round((sumaHuellaAguaAzul + sumaHuellaAguaVerde) * 100) / 100;
        setData(prevState => ({
            ...prevState,
            datasets: prevState.datasets.map(dataset => ({
                ...dataset,
                data: [sumaHuellaAguaAzulRounded, sumaHuellaAguaVerdeRounded]
            })),
        }));
        setExtra(extraRounded);*/

        calcHuellaFincaTotal(filteredCampanas);
    }

    const calcResumenParametrizado = (periodo, campanasList) => {
        let filteredCampanas = campanasList.filter((c) => c.campana === periodo);
        /*const sumaHuellaAguaAzul = filteredCampanas.reduce((total, objeto) => {
            return total + objeto.huellaAguaAzul;
        }, 0);
          
        const sumaHuellaAguaVerde = filteredCampanas.reduce((total, objeto) => {
            return total + objeto.huellaAguaVerde;
        }, 0);

        const sumaHuellaAguaAzulRounded = Math.round(sumaHuellaAguaAzul * 100) / 100;
        const sumaHuellaAguaVerdeRounded = Math.round(sumaHuellaAguaVerde * 100) / 100;
        const extraRounded = Math.round((sumaHuellaAguaAzul + sumaHuellaAguaVerde) * 100) / 100;
        setData(prevState => ({
            ...prevState,
            datasets: prevState.datasets.map(dataset => ({
                ...dataset,
                data: [sumaHuellaAguaAzulRounded, sumaHuellaAguaVerdeRounded]
            })),
        }));
        setExtra(extraRounded);*/

        calcHuellaFincaTotal(filteredCampanas);
    }

    const loopCampanas = (listCamp, field) => {
        return listCamp.reduce((acc, camp) => {
            if (!acc.includes(camp.campana)) {
                acc.push(camp);
            }
            return acc;
        }, []).map((camp, index) => {    
            return (
                <>
                    {field !== "aguaVerde" ?
                        <td key={field + "-" + index}>{(camp[field] || camp[field] === 0) ? camp[field]?.toFixed(2) : "-"}</td>
                        :
                        <td key={field + "-" + index}>{(camp[field] || camp[field] === 0) ? (camp[field] / 10000)?.toFixed(2) : "-"}</td>
                    }
                </>
            );
        })
    }

    const AddValueCampanas = (listCamp, field) => {
        let value = listCamp.reduce((acc, camp) => {
            if (!acc.includes(camp.campana)) {
                acc.push(camp);
            }
            return acc;
        }, []).reduce((acc, camp) => {    
            acc = acc + camp[field];
            return acc;
        }, 0)
        if(value === 0){
            value = "-";
        }else{
            value = value?.toFixed(2);
        }
        return value;
    }

    const ArithMeanCampanas = (listCamp, field) => {
        let count = 0;
        let value = listCamp.reduce((acc, camp) => {
            if (!acc.includes(camp.campana)) {
                acc.push(camp);
            }
            return acc;
        }, []).reduce((acc, camp) => {    
            acc = acc + camp[field];
            count++;
            return acc;
        }, 0);

        if(count > 0){
            value = value / count;
        }
        value = value?.toFixed(2);
        return value;
    }

    /*const PrecFinca = (listCamp) => {
        const resultado = listCamp.reduce((acumulador, objeto) => {
            const producto = objeto.totalPrec * objeto.superficieSector;
            acumulador.sumaProductos += producto;
            acumulador.sumaSuperficie += objeto.superficieSector;
            return acumulador;
          }, { sumaProductos: 0, sumaSuperficie: 0 });
        
        return Math.round((resultado.sumaProductos / resultado.sumaSuperficie) * 100) / 100;
    }

    const SumFieldCampanas = (listCamp, field) => {
        const resultado = listCamp.reduce((acumulador, objeto) => {
            const producto = objeto[field] * objeto.superficieSector;
            acumulador.sumaProductos += producto;
            acumulador.sumaSuperficie += objeto.superficieSector;
            return acumulador;
          }, { sumaProductos: 0, sumaSuperficie: 0 });
        
        return Math.round((resultado.sumaProductos / resultado.sumaSuperficie) * 100) / 100;
    }

    const AllHACampanas = (listCamp) => {
        let count = 0;
        let value = listCamp.reduce((acc, camp) => {
            if (!acc.includes(camp.campana)) {
                acc.push(camp);
            }
            return acc;
        }, []).reduce((acc, camp) => {    
            acc = acc + camp.huellaAguaAzul + camp.huellaAguaVerde;
            count++;
            return acc;
        }, 0);
        
        if(count > 0){
            value = value / count;
        }

        return value?.toFixed(2);
    }*/

    const calcUnidadSummary = (camps) => {
        let sumatorioRis = 0;
        let contador = 0;
        camps.forEach(objeto => {
            sumatorioRis += objeto.ris;
            contador++;
        });
        if(contador > 0){
            sumatorioRis = (sumatorioRis/contador)?.toFixed(2);
        }else{
            sumatorioRis = sumatorioRis?.toFixed(2);
        }
        setRis(sumatorioRis);

        calcHuellaFincaTotal(camps)
    }

    const calcHuellaFincaTotal = (camps) => {
        const superficieTotal = camps.reduce((total, obj) => total + (obj.superficieSector || 0), 0);
        let resultado = camps.reduce((acumulador, objeto) => {
            const prec = (objeto.aguaVerde / 10000) * objeto.superficieSector;
            const riego = objeto.realAguaCultivo * objeto.superficieSector;
            const produccion = objeto.produccion * objeto.superficieSector;
            acumulador.totalPrec += prec;
            acumulador.realAguaCultivo += riego;
            acumulador.produccion += produccion;
            acumulador.sumaSuperficie += objeto.superficieSector;
            return acumulador;
          }, { totalPrec: 0, realAguaCultivo: 0, produccion: 0, sumaSuperficie: 0, haVerde: 0, haAzul: 0, haTotal: 0 });

          resultado.totalPrec = parseFloat((resultado.totalPrec / superficieTotal).toFixed(2));
          resultado.realAguaCultivo = parseFloat((resultado.realAguaCultivo / superficieTotal).toFixed(2));
          resultado.produccion = parseFloat((resultado.produccion / superficieTotal).toFixed(2));
          resultado.haVerde = parseFloat(((resultado.totalPrec * 10000) / resultado.produccion).toFixed(2));
          resultado.haAzul = parseFloat(((resultado.realAguaCultivo * 10000) / resultado.produccion).toFixed(2));
          resultado.haTotal = parseFloat((resultado.haVerde + resultado.haAzul).toFixed(2));

        setValoresHuellasFinca(prevData => ({
            ...prevData,
            superficie: superficieTotal,
            totalPrec: resultado.totalPrec,
            realAguaCultivo: resultado.realAguaCultivo,
            produccion: resultado.produccion,
            haVerde: resultado.haVerde,
            haAzul: resultado.haAzul,
            haTotal: resultado.haTotal
        }));

        setData(prevState => ({
            ...prevState,
            datasets: prevState.datasets.map(dataset => ({
                ...dataset,
                data: [resultado.haAzul, resultado.haVerde]
            })),
        }));
        setExtra(resultado.haTotal);
    }

    const updateDataChart = (huella) => {
        huella.fechaCampañaAnterior = convertDate(new Date(huella.fechaCampañaAnterior));
        huella.fechaCampañaActual = convertDate(new Date(huella.fechaCampañaActual));
        setHuella(huella);
        const huellaAguaAzulRounded = Math.round(huella.huellaAguaAzul * 100) / 100;
        const huellaAguaVerdeRounded = Math.round(huella.huellaAguaVerde * 100) / 100;
        const extraRounded = Math.round((huella.huellaAguaAzul + huella.huellaAguaVerde) * 100) / 100;
        setData(prevState => ({
            ...prevState,
            datasets: prevState.datasets.map(dataset => ({
                ...dataset,
                data: [huellaAguaAzulRounded, huellaAguaVerdeRounded]
            })),
        }));
        setExtra(extraRounded);
    }

    const handleOpenModal = () => {
        setOpenTip(true);
        setMsgTip((msg) => ({
            ...msg,
            content: '<div class="modaltip"><h3>Causas de resultados de Ris excesivo o deficitario.</h3><div class="modaltip-box"><div class="modaltip-box-top">RIS &lt; 1 = Déficit de riego</div><ul class="modaltip-box-list"><li>Limitaciones de dotación de riego</li><li>Estrategias de riego deficitario</li></ul></div><div class="modaltip-box"><div class="modaltip-box-top">RIS &gt; 1,2 = Exceso de riego</div><ul class="modaltip-box-list"><li>Mala programación de riego</li><li>Ineficiencias en la red de riego</li></ul></div></div>'
        }));
    }

    const filterByCultivos = (unidades) => {
        let filtered = unidades.filter(u => u.dataType === "finca");
        return filtered;
    }
   
    return(
        <div className="width100">
            <div className="tic4bio-huella-section">CULTIVOS</div>

            <div className="tic4bio-home">
                {msg?.type &&
                    <ActionMessage msg={msg} setMsg={setMsg}/>
                }
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    {loading ? 
                        (
                            <Box p={3} align="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <div>
                                    <h1 className="tic4bio-home-title">Huella de Agua</h1>
                                    {(unidades.length > 0) ?
                                        <>
                                            <p>Seleccione la Unidad productiva de la huella que desea visualizar*</p>
                                            <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                <InputLabel htmlFor="unidad-select">Unidades*</InputLabel>
                                                <Select
                                                    className="t4b-finca-sector-inputs"
                                                    id="unidad-select"
                                                    label="unidad"
                                                    required
                                                    MenuProps={{ disableScrollLock: true }}
                                                    disabled={loadingSectores || loadingHuellas}
                                                    value={unidad.id}
                                                    onChange={(event) => { handleLoadSectores(event); }    
                                                    }
                                                    >
                                                    {unidades.map((unidad, index) => (
                                                    <MenuItem key={index} value={unidad.id}>
                                                        {unidad.unidadProductiva}
                                                    </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {loadingSectores ? (
                                                <Box p={3} align="center">
                                                    <CircularProgress />
                                                </Box>
                                                ) : (
                                                    <>
                                                        {unidad.id &&
                                                            <>
                                                                {(sectores.length > 0) ?
                                                                    <>
                                                                        <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                                            <InputLabel htmlFor="sector-select">Sectores*</InputLabel>
                                                                            <Select
                                                                                className="t4b-finca-sector-inputs"
                                                                                id="sector-select"
                                                                                label="Sector"
                                                                                required
                                                                                MenuProps={{ disableScrollLock: true }}
                                                                                disabled={loadingHuellas}
                                                                                value={sector.id}
                                                                                onChange={(event) => { handleSectorSelect(event) }
                                                                                }>
                                                                            
                                                                                <MenuItem value="all">
                                                                                    Consultar finca completa
                                                                                </MenuItem>
                                                                                {sectores.map((sector, index) => (
                                                                                    <MenuItem key={index} value={sector.id}>
                                                                                        {sector.nombre}
                                                                                    </MenuItem>
                                                                                ))}
                                                                            </Select>
                                                                        </FormControl>
                                                                        {loadingHuellas ? (
                                                                            <Box p={3} align="center">
                                                                                <CircularProgress />
                                                                            </Box>
                                                                            ) : (
                                                                                <>
                                                                                    {sector.id &&
                                                                                        <>
                                                                                            {(huellas.length > 0) ?
                                                                                                <>
                                                                                                    <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                                                                        <InputLabel htmlFor="sector-select">Campañas*</InputLabel>
                                                                                                        <Select
                                                                                                            className="t4b-finca-sector-inputs"
                                                                                                            id="sector-select"
                                                                                                            label="Sector"
                                                                                                            required
                                                                                                            MenuProps={{ disableScrollLock: true }}
                                                                                                            value={huella.id}
                                                                                                            onChange={(event) => { handleHuellaSelect(event) }
                                                                                                            }>
                                                                                                        
                                                                                                            {huellas.map((huella, index) => (
                                                                                                                <MenuItem key={index} value={huella.id}>
                                                                                                                    {huella.campana} - {huella.nombre}
                                                                                                                </MenuItem>
                                                                                                            ))}
                                                                                                        </Select>
                                                                                                    </FormControl>
                                                                                                    {huella.id !== "" &&
                                                                                                        <>
                                                                                                            <div className="chart-container">
                                                                                                                <div className="chart-pos-center">
                                                                                                                    <h3>Huella agua de cultivo</h3>
                                                                                                                    <DoughnutChart data={data} extra={extra}/>
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <h4>Información de la huella de agua:</h4>
                                                                                                                    <ul>
                                                                                                                        <li>Periodo campaña: {huella.fechaCampañaAnterior} - {huella.fechaCampañaActual}</li>
                                                                                                                        <li>Producción: {huella.produccion} kg/ha</li>
                                                                                                                        <li>Precipitación total: {huella.totalPrec?.toFixed(2)} mm</li>
                                                                                                                        {huella.metodoCalculo_id === 1 &&
                                                                                                                            <li>Precipitación efectiva %fijo: {(huella.aguaVerde/10000)?.toFixed(2)} mm</li>
                                                                                                                        }
                                                                                                                        {huella.metodoCalculo_id === 2 &&
                                                                                                                            <li>Precipitación efectiva confiable: {(huella.aguaVerde/10000)?.toFixed(2)} mm</li>
                                                                                                                        }
                                                                                                                        {huella.metodoCalculo_id === 3 &&
                                                                                                                            <li>Precipitación efectiva USDA SCS: {(huella.aguaVerde/10000)?.toFixed(2)} mm</li>
                                                                                                                        }
                                                                                                                        {huella.metodoCalculo_id === 4 &&
                                                                                                                            <li>Precipitación efectiva TIC: {(huella.aguaVerde/10000)?.toFixed(2)} mm</li>
                                                                                                                        }
                                                                                                                        <li>Consumo agua de riego: {huella.realAguaCultivo ? huella.realAguaCultivo?.toFixed(2) : 0} mm</li>
                                                                                                                    </ul>
                                                                                                                </div>

                                                                                                            </div>

                                                                                                            <div className="chart-container">
                                                                                                                <div>
                                                                                                                    <div className="textAlignCenter">
                                                                                                                        <h3>Análisis de adecuación de riego (RIS) <span className="modaltip-icon" onClick={(event) => { handleOpenModal(event) }}>?</span></h3>
                                                                                                                    </div>
                                                                                                                    <Ris valorRis={huella.ris}/>
                                                                                                                    
                                                                                                                    <div>
                                                                                                                        <h4>Información de la huella de agua:</h4>
                                                                                                                        <ul>
                                                                                                                            <li>Periodo campaña: {huella.fechaCampañaAnterior} - {huella.fechaCampañaActual}</li>
                                                                                                                            <li>Requerimientos teóricos del cultivo: {huella.reqTeoricoAguaCultivo?.toFixed(2)} mm</li>
                                                                                                                            <li>Riego Aplicado: {huella.realAguaCultivo?.toFixed(2)} mm</li>
                                                                                                                        </ul>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                                <>
                                                                                                    {sector.id !== "all" && <p>Parece que la unidad productiva seleccionada no tiene Campañas asociadas. Necesitas al menos una campaña para poder visualizar su detalle.</p>}
                                                                                                </>
                                                                                            }
                                                                                            {(campanas.length > 0 && sector.id === "all") &&
                                                                                                <>
                                                                                                    <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                                                                        <InputLabel htmlFor="sector-select">Campañas*</InputLabel>
                                                                                                        <Select
                                                                                                            className="t4b-finca-sector-inputs"
                                                                                                            id="sector-select"
                                                                                                            label="Sector"
                                                                                                            required
                                                                                                            MenuProps={{ disableScrollLock: true }}
                                                                                                            value={campana.campana}
                                                                                                            onChange={(event) => { handleCampanaSelect(event) }
                                                                                                            }>
                                                                                                        
                                                                                                            {campanas.reduce((acc, campana) => {
                                                                                                                if (!acc.includes(campana.campana)) {
                                                                                                                acc.push(campana.campana);
                                                                                                                }
                                                                                                                return acc;
                                                                                                            }, []).map((campana, index) => (
                                                                                                                <MenuItem key={index} value={campana}>
                                                                                                                    {campana}
                                                                                                                </MenuItem>
                                                                                                            ))}
                                                                                                        </Select>
                                                                                                    </FormControl>
                                                                                                    {campana.campana !== "" &&
                                                                                                        <>
                                                                                                            <div className="chart-container">
                                                                                                                <div className="chart-pos-center">
                                                                                                                    <h3>Huella agua Finca {unidad.nombreFinca}</h3>
                                                                                                                    <DoughnutChart data={data} extra={extra}/>
                                                                                                                </div>
                                                                                                                <div className="chart-summary-box">
                                                                                                                    <table className="chart-summary">
                                                                                                                        <thead>
                                                                                                                            <tr className="chart-summary-head">
                                                                                                                                <th className="chart-summary-column">Sectores</th>
                                                                                                                                {campanas.reduce((acc, campana) => {
                                                                                                                                    if (!acc.includes(campana.campana)) {
                                                                                                                                    acc.push(campana);
                                                                                                                                    }
                                                                                                                                    return acc;
                                                                                                                                }, []).map((campana, index) => {    
                                                                                                                                    
                                                                                                                                    return(
                                                                                                                                        <Fragment key={"campananame-" + index}>
                                                                                                                                            <th className="chart-summary-row">{campana.sectorName}</th>
                                                                                                                                        </Fragment>
                                                                                                                                        
                                                                                                                                    )
                                                                                                                                }
                                                                                                                                    
                                                                                                                                )}
                                                                                                                                <th>Finca</th>
                                                                                                                            </tr>
                                                                                                                        </thead>
                                                                                                                        <tbody>
                                                                                                                            <tr>
                                                                                                                                <th className="chart-summary-column">Superficie (ha)</th> 
                                                                                                                                {loopCampanas(campanas, "superficieSector")}
                                                                                                                                <td>{valoresHuellasFinca.superficie}</td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <th className="chart-summary-column">Prec. efectiva (mm)</th> 
                                                                                                                                {loopCampanas(campanas, "aguaVerde")}
                                                                                                                                <td>{valoresHuellasFinca.totalPrec}</td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <th className="chart-summary-column">Riego (mm)</th>
                                                                                                                                {loopCampanas(campanas, "realAguaCultivo")}
                                                                                                                                <td>{valoresHuellasFinca.realAguaCultivo}</td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <th className="chart-summary-column">Producción (kg/ha)</th>
                                                                                                                                {loopCampanas(campanas, "produccion")}
                                                                                                                                <td>{valoresHuellasFinca.produccion}</td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <th className="chart-summary-column">HA Verde (l/kg)</th>
                                                                                                                                {loopCampanas(campanas, "huellaAguaVerde")}
                                                                                                                                <td>{valoresHuellasFinca.haVerde}</td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <th className="chart-summary-column">HA Azul (l/kg)</th>
                                                                                                                                {loopCampanas(campanas, "huellaAguaAzul")}
                                                                                                                                <td>{valoresHuellasFinca.haAzul}</td>
                                                                                                                            </tr>
                                                                                                                            <tr>
                                                                                                                                <th className="chart-summary-column">HA total (l/kg)</th>
                                                                                                                                {campanas.reduce((acc, campana) => {
                                                                                                                                    if (!acc.includes(campana.campana)) {
                                                                                                                                        acc.push(campana);
                                                                                                                                    }
                                                                                                                                    return acc;
                                                                                                                                }, []).map((campana, index) => {    
                                                                                                                                    
                                                                                                                                    return(
                                                                                                                                        <Fragment key={"campana-" + index}>
                                                                                                                                            <td>{(campana.huellaAguaAzul + campana.huellaAguaVerde)?.toFixed(2)}</td>
                                                                                                                                        </Fragment>
                                                                                                                                    )
                                                                                                                                }
                                                                                                                                )}
                                                                                                                                <td>{valoresHuellasFinca.haTotal}</td>
                                                                                                                            </tr>
                                                                                                                            
                                                                                                                        </tbody>
                                                                                                                    </table>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className="chart-container">
                                                                                                                <div>
                                                                                                                    <div className="textAlignCenter">
                                                                                                                        <h3>Adecuación del riego (RIS) <span className="modaltip-icon" onClick={(event) => { handleOpenModal(event) }}>?</span></h3>
                                                                                                                    </div>

                                                                                                                    <div className="chart-summary-box">
                                                                                                                        <table className="chart-summary">
                                                                                                                            <thead>
                                                                                                                                <tr className="chart-summary-head">
                                                                                                                                    <th className="chart-summary-column">Sectores</th>
                                                                                                                                    {campanas.reduce((acc, campana) => {
                                                                                                                                        if (!acc.includes(campana.campana)) {
                                                                                                                                        acc.push(campana);
                                                                                                                                        }
                                                                                                                                        return acc;
                                                                                                                                    }, []).map((campana, index) => {    
                                                                                                                                        
                                                                                                                                        return(
                                                                                                                                            <Fragment key={"campananame-" + index}>
                                                                                                                                                <th className="chart-summary-row">{campana.sectorName}</th>
                                                                                                                                            </Fragment>
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                        
                                                                                                                                    )}
                                                                                                                                    <th>Finca</th>
                                                                                                                                </tr>
                                                                                                                            </thead>
                                                                                                                            <tbody>
                                                                                                                                <tr>
                                                                                                                                    <th className="chart-summary-column">Superficie (ha)</th> 
                                                                                                                                    {loopCampanas(campanas, "superficieSector")}
                                                                                                                                    <td>{AddValueCampanas(campanas, "superficieSector")}</td>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <th className="chart-summary-column">Requerimientos teóricos de agua del cultivo (mm)</th>
                                                                                                                                    {loopCampanas(campanas, "reqTeoricoAguaCultivo")}
                                                                                                                                    <th>{AddValueCampanas(campanas, "reqTeoricoAguaCultivo")}</th>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <th className="chart-summary-column">Riego Aplicado (mm)</th>
                                                                                                                                    {loopCampanas(campanas, "realAguaCultivo")}
                                                                                                                                    <th>{AddValueCampanas(campanas, "realAguaCultivo")}</th>
                                                                                                                                </tr>
                                                                                                                                <tr>
                                                                                                                                    <th className="chart-summary-column">RIS</th>
                                                                                                                                    {loopCampanas(campanas, "ris")}
                                                                                                                                    <th>{ArithMeanCampanas(campanas, "ris")}</th>
                                                                                                                                </tr>
                                                                                                                            </tbody>
                                                                                                                        </table>
                                                                                                                    </div>

                                                                                                                    <Ris valorRis={ris}/>
                                                                                                                    
                                                                                                                    
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    }
                                                                                                </>
                                                                                            }
                                                                                            {(campanas.length === 0 && sector.id === "all") && 
                                                                                                <p>Aún no se han añadido huellas de agua a ningún sector. Necesitas tener al menos una creada para poder visualizar el resumen.</p>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </>
                                                                            )
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <p>Parece que la unidad productiva seleccionada no tiene sectores asociados. Necesitas al menos un sector para poder visualizar sus campañas.</p>
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                )
                                            }
                                        </>
                                        :
                                        <p><Alert severity="error">No se han encontrado Unidades Productivas. Primero debe tener alguna creada. </Alert> <Link to="/nuevaUnidadProductiva">
                                            <button type="button" className="btn green" value="finca"> <AddCircleOutlineIcon className="add" /> Crear Unidad</button></Link></p>
                                    }
                                    
                                </div>
                            </>
                        )
                    }
                </Box>
            </div>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.title} contentText={errorMsg.content}/>
            <ModalTip open={openTip} setOpen={setOpenTip} contentText={msgTip.content}/>
        </div>
    );
} 
export default HuellaCultivosPage;