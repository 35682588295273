import React, { useEffect, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

function BarChartRiegoRequerimientoEvolucion({ startCampana, endCampana, campanas, data, finca }) {

  const chartRef = useRef(null);
  const navigate = useNavigate();
  const [ chartInfo, setChartInfo ] = useState();

  useEffect(() => {
    chooseOption();
  }, [startCampana, endCampana]); 


  useEffect(() => {
    const chart = chartRef.current;

    const handleClickCanvas = (event) => {

      const elements = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);
      if (elements.length === 0) {
        const canvasPosition = {
          x: event.offsetX,
          y: event.offsetY,
        };

        const dataXLabels = chart.scales.x.ticks;
        
        // Calcular la posición de las etiquetas en el eje X
        dataXLabels.forEach((label, index) => {
            const xLabelPosition = chart.scales.x.getPixelForTick(index);
            const labelWidth = 100; // Ancho aproximado de la etiqueta

            // Verificar si el clic está dentro del rango de la etiqueta
            if (canvasPosition.x >= xLabelPosition - labelWidth / 2 && canvasPosition.x <= xLabelPosition + labelWidth / 2) {

              let posElement = chart.data.labels.indexOf(label.label);
              let campana = chart.data.labels[posElement];

              let fincaAux;
              if(finca.fincas.length > 0){
                fincaAux = finca.fincas.find(s => {
                  if(s.dataType === "finca"){
                    return s.id;
                  }
                });
              }
              //chart.datasets[clickedDatasetIndex].huellaId
              navigate(`/huella-cultivos/${fincaAux.id}`, {
                state: { sectorId: "all", campana: campana }
              });
            }
        });
      }
        
    };

    if (chart) {
        chart.canvas.addEventListener('click', handleClickCanvas);
    }

    return () => {
        if (chart?.canvas) {
            chart.canvas.removeEventListener('click', handleClickCanvas);
        }
    };
  }, [chartRef, chartInfo]);

  const getRandomRGBA = () => {
    const random255 = () => Math.floor(Math.random() * 256);
    const red = random255();
    const green = random255();
    const blue = random255();
    const alpha = 1;
    const alphaBack = 0.5;
    return [`rgba(${red}, ${green}, ${blue}, ${alpha})`,`rgba(${red}, ${green}, ${blue}, ${alphaBack})`];
  };

  const generateCampanas = () => {
    const indiceInicio = campanas.indexOf(startCampana);
    const indiceFin = campanas.indexOf(endCampana);

    return campanas.slice(indiceInicio, indiceFin + 1);
  }

  const generateDataSets = (subData) => {

    //const sectores = Object.values(subData).map(c => c.fincas.map(s => s.nombre)).flat();
    const sectores = Object.values(subData).map(c => c.fincas.map(s => {
      const rgba = getRandomRGBA();
      return {nombre: s.nombre, color: rgba[0], colorBack: rgba[1], huellaId: s.id, sectorId: s.sector_id}
    })).flat();
    
    const datasetsRiego = sectores.map((sector, index) => ({
      label: `${sector.nombre} - Riego`,
      data: Object.values(subData).map(d => d.fincas[index]?.realAguaCultivo ? d.fincas[index]?.realAguaCultivo : 0),
      backgroundColor: sector.color,
      borderColor: sector.color,
      borderWidth: 1,
      stack: sector.nombre,
      huellaId: sector.huellaId,
      sectorId: sector.sectorId,
    }));

    const datasetsRequerido = sectores.map((sector, index) => ({
      label: `${sector.nombre} - Requerido`,
      data: Object.values(subData).map(d => d.fincas[index]?.reqTeoricoAguaCultivo ? d.fincas[index]?.reqTeoricoAguaCultivo : 0),
      backgroundColor: sector.colorBack,
      borderColor: sector.colorBack,
      borderWidth: 1,
      stack: sector.nombre,
      huellaId: sector.huellaId,
      sectorId: sector.sectorId,
    }));

    const result = [...datasetsRiego, ...datasetsRequerido]

    return result;
  }

  const chooseOption = () => {
    let subData = data.unidad.infoUnidad;

    const labelsCampanas = generateCampanas();

    const filteredData = Object.keys(subData)
    .filter((key) => labelsCampanas.includes(key))
    .reduce((obj, key) => {
      obj[key] = subData[key];
      return obj;
    }, {});
    
    let someData = Object.values(filteredData).some(c => {

      if(c.fincas.length !== 0){
        return true;
      }
      return false;
    });
    
    if(someData){
      const info = {
        labels: labelsCampanas,
        datasets: generateDataSets(filteredData)
      }

      setChartInfo(info);
    }else{
      setChartInfo();
    }

  }

  const handleClick = (event) => {
    const chart = chartRef.current;
    if (!chart) return;

    const elements = chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);
    if (elements.length > 0) {

      const clickedDatasetIndex = elements[0].datasetIndex;
      const clickedHuella = chartInfo.datasets[clickedDatasetIndex].huellaId;
      const clickedSector = chartInfo.datasets[clickedDatasetIndex].sectorId;
      
      let fincaAux;
      if (finca.fincas.length > 0) {
        fincaAux = finca.fincas.find(s => s.dataType === "finca");
      }
      
      navigate(`/huella-cultivos/${fincaAux.id}`, {
        state: { sectorId: clickedSector, huellaId: clickedHuella }
      });
    } else {
      // Obtener las coordenadas del clic
      /*const { offsetX, offsetY } = event;
      const xScale = chart.scales['x-axis-0'];
      if (xScale) {
        const labelIndex = xScale.getValueForPixel(offsetX);
        if (labelIndex !== undefined && labelIndex !== null) {
          console.log('Label clicked:', chartInfo.labels[labelIndex]);
        }
      }*/
    }
  };
  
  const options = {
    layout: {
      padding: {
          top: 0,
          bottom: 100,
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        beginAtZero: true,
        stacked: false,
      },
    },
    interaction: {
      mode: 'index',
      intersect: false,
    },
    onClick: handleClick
  };

    return (
      <>
        {chartInfo &&
          <>
              <h3 style={{textAlign: "center", textDecoration: "underline", color: "#80C606", fontSize: "26px", marginBottom: "40px"}}>Riego y requerimientos teóricos de agua por sectores (mm)</h3>
              <div style={{minHeight: "600px"}}>
                <Bar data={chartInfo} options={options} ref={chartRef}/>
              </div>
          </>
        }
        {!chartInfo &&
          <p>No existe información disponible para el rango de campañas seleccionadas en el <b>gráfico de Riego y requerimientos teóricos de agua por sectores (mm)</b></p>
        }
      </>
    );
}

export default BarChartRiegoRequerimientoEvolucion;