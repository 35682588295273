import { Box, Dialog, DialogActions, DialogContent, FormControl, TextField, CircularProgress } from "@mui/material";
import { useState } from "react";
import { checkDoublePattern } from "../../utils/commons";
import { useAuth } from "../../context/Auth";
import { instance } from "../../utils/axios-config";

const ModalEditDotacionFinca = ({open, setOpen, finca, setFinca, setMsg, setOpenError, setErrorMsg}) => {

    const {sessionToken} = useAuth();

    const [ loading, setLoading ] = useState(false);
    const [ dotacion, setDotacion ] = useState(finca.dotacionRiego ? finca.dotacionRiego : "");
    const [ errors, setErrors ] = useState({});
    
    const dialogStyle = {
        width: '380px',
    };

    const handleChangeDotacion = (value) => {
        if(checkDoublePattern(value)){
            setDotacion(value);
        }
    }

    const validateForm = () =>{
        setErrors({});
        let inputIds = "";
        let valid = true;

        if(dotacion === ''){
            setErrors(errors => ({...errors, errorDotacion: true, errorDotacionMsg: "La nueva dotación no puede estar vacía."}));
            inputIds = inputIds ? inputIds +",nuevaDotacion" : "nuevaDotacion";
            valid = false;
        }
        if(dotacion <= 0 && dotacion !== ''){
            setErrors(errors => ({...errors, errorDotacion: true, errorDotacionMsg: "La nueva dotación no puede ser menor o igual a cero."}));
            inputIds = inputIds ? inputIds +",nuevaDotacion" : "nuevaDotacion";
            valid = false;
        }
        if(inputIds !== ""){
            let elem = document.getElementById(inputIds.split(",")[0]);
            elem.scrollIntoView({behavior: "smooth"});
            elem.focus();
        }
        return valid;
    }

    const handleClose = (id) => {
        if(id){
            if(validateForm()){
                updateDotacion();
            }
        }else{
            setOpen(false);
        }
    }


    const updateDotacion = async () => {
        let buildUrl = `/fincas/update/dotacion/${finca.id}`;
        try{
            setLoading(true);
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await instance.put(buildUrl, {nuevaDotacion: dotacion}).then(function(resp) {
                setOpen(false);
                setMsg({type: "action-new", text: "Se ha actualizado la dotación correctamente."});
                setFinca(prevData => ({
                    ...prevData,
                    dotacionRiego: dotacion
                }));
            }).catch(function(error) {
                setOpenError(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    contentText: "Algo ha fallado al intentar actualizar la dotación de la finca. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setLoading(false);
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                contentText: "Algo ha fallado al intentar actualizar la dotación de la finca. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }
    

    return ( 
        <>
        <Dialog PaperProps={{ style: dialogStyle }} open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogContent>
                <h4>Editar dotación de la finca: {finca.nombre}</h4>
                <p style={{fontStyle: "italic"}}>Algunos cambios en la programación de riego puede que tarden hasta 24 horas en actualizarse.</p>
                <div>
                    <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                        <TextField
                            className="t4b-finca-sector-inputs no-red-label"
                            label="Nueva dotación de finca (m³/ha)*"
                            id={"nuevaDotacion"}
                            value={dotacion}
                            type="number"
                            variant="standard"
                            InputProps={{
                                inputProps: { 
                                    max: 9999999, min: 0,
                                }
                            }}
                            onChange={(e) => {handleChangeDotacion(e.target.value)}}
                            name="nuevaDotacion"
                            error={errors.errorDotacion}
                        />
                    </FormControl>
                    {errors.errorDotacionMsg && <div className="errorMsg">{errors.errorDotacionMsg}</div>}
                </div>
            </DialogContent>
            <DialogActions>
                {loading ? 
                    (
                        <Box p={3} align="center" justifyContent="center">
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            <button className="btn green" onClick={() => handleClose(finca.id)}>Guardar cambios</button>
                            <button className="btn green" onClick={() => handleClose(null)}>Cerrar</button>
                        </>
                    )
                }
            </DialogActions>
        </Dialog>
        </>
     );
}
 
export default ModalEditDotacionFinca;