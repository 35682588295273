import { Autocomplete, Box, FormControl, IconButton, TextField } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Fragment, useState } from "react";
import { checkIntPattern } from "../../utils/commons";
import ModalCalculatorCultivosLenosos from "../modals/ModalCalculatorCultivosLenosos";

const TableAdvancedNoSamplingComponent = ({stateForm, setStateForm, tipoVegetacion, errors }) => {

    const [open, setOpen] = useState({
        open: false,
        id: ""
    });

    const handleAddEspecie = () => {
        let nuevaEspecie = {
            nombre: "Nueva Especie",
            ni: 1,
            tempId: "tempId-" + new Date().getTime()
        }
        setStateForm(prevState => {
            if(prevState.especies[tipoVegetacion]){
                return {
                    ...prevState,
                    especies: {
                        ...prevState.especies,
                        [tipoVegetacion]: [
                            ...prevState.especies[tipoVegetacion],
                            nuevaEspecie,
                        ],
                    }
                }
            }else{
                return {
                    ...prevState,
                    especies: {
                        ...prevState.especies,
                        [tipoVegetacion]: [
                            nuevaEspecie,
                        ],
                    }
                }
            }
            
        })
    }

    const handleChangeNombre = (e, id) => {
        setStateForm(prevData => {
            const newData = prevData.especies[tipoVegetacion].map(item => {
              if (item.tempId === id) {
                return { ...item, nombre: e };
              }
              return item;
            });
            return {...prevData, especies: {
                ...prevData.especies, 
                [tipoVegetacion]: newData
            }};
          });
    }

    const handleChangeNI = (e, id) => {
        if(checkIntPattern(e)){
            setStateForm(prevData => {
                const newData = prevData.especies[tipoVegetacion].map(item => {
                if (item.tempId === id) {
                    return { ...item, ni: parseInt(e) };
                }
                return item;
                });
                return {...prevData, especies: {
                    ...prevData.especies, 
                    [tipoVegetacion]: newData
                }};
            });
        }
    }

    const deleteEspecie = (e) => {
        let especies = stateForm.especies[tipoVegetacion].filter(objeto => {
            return !(e?.tempId && objeto.tempId === e.tempId);
        });
        setStateForm(prevData => ({
            ...prevData,
            especies: {
                ...prevData.especies,
                [tipoVegetacion]: especies,     
            } 
        }));
    }

    return(
        <>
        <div className="tabla-bio-box">
            <table className="tabla-bio">
                <thead>
                    <tr>
                        <th colSpan="3">Especies</th>
                    </tr>
                </thead>
                <tbody>
                    {stateForm.especies[tipoVegetacion]?.map((esp, index) => (
                        <Fragment key={index}>
                            <tr>
                                <td>
                                    <FormControl sx={{width: "100%"}} variant="standard">
                                        {/*<TextField
                                            className="t4b-finca-sector-inputs no-red-label"
                                            label="Nombre de especie*"
                                            id={"especieNombre-" + tipoVegetacion + "-" + index}
                                            value={esp?.nombre}
                                            variant="standard"
                                            onChange={(e) => handleChangeNombre(e.target.value, esp?.tempId)}
                                            name="especieNombre"
                                            error={errors["errorEspecie-" + tipoVegetacion + "-" + index + "Nombre"]}
                                        />
                                        {errors["errorEspecie-" + tipoVegetacion + "-" + index + "NombreMsg"] && <div className="errorInput">{errors["errorEspecie-"  + tipoVegetacion + "-" + index + "NombreMsg"]}</div>}*/}
                                        <Autocomplete
                                            freeSolo
                                            options={stateForm.diccionario}
                                            renderOption={(props, option) => {
                                                return <Box element="li" sx={{ display: 'flex', alignItems: 'center' }} {...props}> 
                                                    {option}
                                                </Box>
                                            }}
                                            className="t4b-finca-inputs"
                                            getOptionLabel={(option) => (
                                                typeof option === "string" ? option : option.label
                                            )}
                                            id={"especieNombre-" + tipoVegetacion + "-" + index}
                                            onInputChange={(e, newValue) => {
                                                handleChangeNombre(newValue, esp?.tempId)
                                            }}
                                            value={esp?.nombre || ""}
                                            renderInput={(params) => (
                                                <>
                                                <TextField {...params} label="Nombre de especie*" variant="standard" />
                                                </>
                                            )}
                                            sx={{
                                                ...(errors["errorEspecie-" + tipoVegetacion + "-" + index + "Nombre"] && {
                                                    '& .MuiInput-root:before': {
                                                        borderBottom: "1px solid #d32f2f",
                                                    }
                                                })
                                            }}
                                        />
                                    </FormControl>
                                </td>
                                <td className={tipoVegetacion === "cultivoslenosos" ? "cellFlex" : ""}>
                                    <FormControl sx={{width: "100%"}} variant="standard">
                                        <TextField
                                            className="t4b-finca-sector-inputs no-red-label"
                                            label="Número de individuos*"
                                            id={"especieNI-" + tipoVegetacion + "-" + index}
                                            value={esp?.ni}
                                            type="number"
                                            variant="standard"
                                            InputProps={{
                                                inputProps: { 
                                                    max: 99999, min: 1,
                                                }
                                            }}
                                            onChange={(e) => handleChangeNI(e.target.value, esp?.tempId)}
                                            name="especieNI"
                                            error={errors["errorEspecie-" + tipoVegetacion + "-" + index + "NI"]}
                                        />
                                        {errors["errorEspecie-" + tipoVegetacion + "-" + index + "NIMsg"] && <div className="errorInput">{errors["errorEspecie-"  + tipoVegetacion + "-" + index + "NIMsg"]}</div>}
                                    </FormControl>
                                    {tipoVegetacion === "cultivoslenosos" &&
                                        <span className="modaltip-icon pink" onClick={() => { setOpen({open: true, id: esp?.tempId}) }}>?</span>
                                    }
                                </td>
                                <td>
                                    <IconButton
                                    size="large"
                                    edge="start"
                                    className="delete"
                                    aria-label="Eliminar especie"
                                    style={{color: "#fa0000"}}
                                    onClick={() => deleteEspecie(esp)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </td>
                            </tr>
                        </Fragment>
                    ))}
                    <tr>
                        <td colSpan="2" className="tabla-bio-add">
                            <IconButton
                                size="large"
                                edge="start"
                                className="delete"
                                aria-label="Añadir Especie"
                                style={{color: "#80C606"}}
                                onClick={() => {handleAddEspecie()}}
                                >
                                    <AddCircleIcon />
                            </IconButton>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <ModalCalculatorCultivosLenosos open={open} setOpen={setOpen} stateForm={stateForm} setStateForm={setStateForm} tipoVegetacion={tipoVegetacion} />
        </>
    )
}

export default TableAdvancedNoSamplingComponent;