import { Box, CircularProgress, FormControl, FormControlLabel, FormLabel, Input, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/Auth";
import { instance } from "../../utils/axios-config";
import ModalInfo from "../ModalInfo";
import { useNavigate, useParams, Link } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/es';
import { checkDoublePattern, generarListadoCamp } from "../../utils/commons";
import leftarrow from "../../assets/left-arrow-svgrepo-com.svg";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Alert from '@mui/material/Alert';

const FormNuevaHuellaFincaComponent = ({setChoice}) => {

    const navigate = useNavigate();

    const {sessionToken} = useAuth();
    const { id } = useParams();
    const [ loading, setLoading ] = useState(true);
    const [ loadingSectores, setLoadingSectores ] = useState(false);
    const [ loadingSubmit, setLoadingSubmit ] = useState(false);
    const [ unidades, setUnidades ] = useState([]);
    const [ sectores, setSectores ] = useState([]);

    const year_Actual = new Date().getFullYear();
    const year_Final = year_Actual - 1;

    const [ campanas, setCampanas ] = useState(generarListadoCamp(2022, year_Final));
    const [ open, setOpen ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState({title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado. Por favor, vuelve a intentarlo más tarde."});
    const [ errors, setErrors ] = useState({});
    const [ stateForm, setStateForm ] = useState({
        nombre: "",
        campana: "",
        dateCampaignBefore: "",
        dateCampaignCurrent: "",
        production: "",
        metodoCalculo: "",
        sector: "",
        unidadId: ""
    });

    useEffect(() => {
        document.title = "Nueva huella de agua fincas";
        if(id){
            //loadSectoresData(); 
        }else{
            loadUnidades();
        }
    }, []);

    const loadUnidades = async () => {
        let buildUrl = `/fincas/fincasByDataType/finca`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await instance.get(buildUrl).then(function(resp) {
                setUnidades(resp.data);
            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar la información de las unidades productivas. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setLoading(false);
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar la información de las unidades productivas. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const loadSectorOnChange = async (unidadId) => {
        try{
            let buildUrl = `/sectores/listall/${unidadId}`;
            setLoadingSectores(true);
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
            await instance.get(buildUrl).then(function(resp) {
                //let sectores = resp.data.sectores.filter(elemento => elemento.tipoPlantacion !== "Secano");
                setSectores(resp.data.sectores);
            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar los sectores de la unidad productiva. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoadingSectores(false);
            });
            setLoadingSectores(false);
        }catch(error){
            setLoadingSectores(false);
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar los sectores de la unidad productiva. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const handleLoadSectores = async (event) => {
        setStateForm(prevData => ({
            ...prevData,
            unidadId: event.target.value}));
        loadSectorOnChange(event.target.value);
    }

    const handleSectorSelect = (event) => {
        setStateForm(prevData => ({
            ...prevData,
            dateCampaignBefore: "",
            dateCampaignCurrent: "",
            production: "",
            metodoCalculo: "",
            nombre: "",
            sector: event.target.value}));
    }

    const handleChangeProduction = (e) => {
        if(checkDoublePattern(e)){
            setStateForm({...stateForm, production: e});
        }
    }

    const handleChangeCalculoP = (e) => {
        if(e === "1" || e === "2" || e === "3" || e === "4"){
            setStateForm({...stateForm, metodoCalculo: e});
        }else{
            //Do nothing
        }
    }

    const validateForm = () =>{
        setErrors({});
        let inputIds = "";
        let valid = true;
        if(stateForm.nombre === undefined || stateForm.nombre === ''){
            setErrors(errors => ({...errors, errorNombre: true, errorNombreMsg: "El nombre de la campaña no puede estar vacío."}));
            inputIds = inputIds ? inputIds +",nombre" : "nombre";
            valid = false;
        }
        if(stateForm.campana === undefined || stateForm.campana === ''){
            setErrors(errors => ({...errors, errorCampana: true, errorCampanaMsg: "El periodo de la campaña no puede estar vacío."}));
            inputIds = inputIds ? inputIds +",periodo-campana" : "periodo-campana";
            valid = false;
        }
        if(stateForm.metodoCalculo === undefined || stateForm.metodoCalculo === ''){
            setErrors(errors => ({...errors, errorTipoCalculoP: true, errorTipoCalculoPMsg: "Debe elegir un método de cálculo de precipitación."}));
            inputIds = inputIds ? inputIds +",tipoCalculoP" : "tipoCalculoP";
            valid = false;
        }
        if(stateForm.production === undefined || stateForm.production === ""){
            setErrors(errors => ({...errors, errorProduction: true, errorProductionMsg: "Debe introducir la producción obtenida para la campaña."}));
            inputIds = inputIds ? inputIds +",production" : "production";
            valid = false;
        }
        if(stateForm.dateCampaignBefore === undefined || stateForm.dateCampaignBefore === ""){
            setErrors(errors => ({...errors, errorDateCampaignBefore: true, errorDateCampaignBeforeMsg: "Debe introducir una fecha de inicio de la campaña."}));
            inputIds = inputIds ? inputIds +",dateCampaignBefore" : "dateCampaignBefore";
            valid = false;
        }
        if(stateForm.dateCampaignCurrent === undefined || stateForm.dateCampaignCurrent === ""){
            setErrors(errors => ({...errors, errorDateCampaignCurrent: true, errorDateCampaignCurrentMsg: "Debe introducir una fecha de fin de la campaña."}));
            inputIds = inputIds ? inputIds +",dateCampaignCurrent" : "dateCampaignCurrent";
            valid = false;
        }
        let campana = campanas.find((c) => c.nombre === stateForm.campana);
        if(stateForm.campana !== "" && stateForm.dateCampaignBefore !== ""){
            const dateStart = new Date(stateForm.dateCampaignBefore);
            if(campana.firstYear !== dateStart.getFullYear() && campana.lastYear !== dateStart.getFullYear()){
                setErrors(errors => ({...errors, errorDateCampaignBefore: true, errorDateCampaignBeforeMsg: "La fecha de inicio de campaña debe estar incluida en el periodo de la campaña."}));
                inputIds = inputIds ? inputIds +",dateCampaignBefore" : "dateCampaignBefore";
                valid = false;
            }
        }
        if(stateForm.campana !== "" && stateForm.dateCampaignCurrent !== ""){
            const dateEnd = new Date(stateForm.dateCampaignCurrent);
            if(campana.lastYear !== dateEnd.getFullYear() && campana.lastYear !== dateEnd.getFullYear()){
                setErrors(errors => ({...errors, errorDateCampaignCurrent: true, errorDateCampaignCurrentMsg: "La fecha de fin de campaña debe estar incluida en el periodo de la campaña."}));
                inputIds = inputIds ? inputIds +",dateCampaignCurrent" : "dateCampaignCurrent";
                valid = false;
            }
        }
        if(stateForm.dateCampaignCurrent !== ""){
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            const date = new Date(stateForm.dateCampaignCurrent);
            if(date.getFullYear() > currentDate.getFullYear()){
                setErrors(errors => ({...errors, errorDateCampaignCurrent: true, errorDateCampaignCurrentMsg: "La fecha de fin de campaña debe ser menor o igual al año de fin de la campaña."}));
                inputIds = inputIds ? inputIds +",dateCampaignCurrent" : "dateCampaignCurrent";
                valid = false;
            }
        }
        if(stateForm.dateCampaignBefore !== "" && stateForm.dateCampaignCurrent !== ""){
            const startDate = new Date(stateForm.dateCampaignBefore);
            const endDate = new Date(stateForm.dateCampaignCurrent);
            if(startDate > endDate){
                setErrors(errors => ({...errors, errorDates: true, errorDatesMsg: "Las fecha de inicio no puede ser mayor que la fecha fin."}));
                inputIds = inputIds ? inputIds +",dateCampaignBefore" : "dateCampaignBefore";
                valid = false;
            }
        }

        if(inputIds !== ""){
            try{
                let elem = document.getElementById(inputIds.split(",")[0]);
                elem.scrollIntoView({behavior: "smooth"});
                elem.focus();
            }catch(err){
                //Do nothing
            }
        }
        return valid;
    }

    const checkErrorDateBefore = () => {
        let result = false;
        if(errors.errorDates || errors.errorDateCampaignBefore){
            result = true;
        }
        return result;
    }

    const checkErrorDateCurrent = () => {
        let result = false;
        if(errors.errorDates || errors.errorDateCampaignCurrent){
            result = true;
        }
        return result;
    }

    const handleChangeCampana = (event) => {
        setStateForm(prevData => ({
            ...prevData,
            campana: event.target.value}));
    }

    const formatDates = () => {
        let data = { ...stateForm };

        data.dateCampaignBefore = data.dateCampaignBefore.format("YYYY-MM-DD");
        data.dateCampaignCurrent = data.dateCampaignCurrent.format("YYYY-MM-DD");

        return data;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if(validateForm()){
            try {
                setLoadingSubmit(true);
                let data = formatDates();
                instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
                await instance.post(`/huella/sector/${stateForm.sector.id}`, data).then((res) => {
                    setLoadingSubmit(false);
                    navigate("/huella-cultivos/" + stateForm.unidadId, {state: {type: "action-new", text: "Se ha creado la campaña correctamente.", sectorId: stateForm.sector.id, huellaId: res.data.huella_id}});
                });
            } catch (error) {
                if(error.response?.status === 406){
                    setErrors(errors => ({...errors, errorCampana: true, errorCampanaMsg: "Ya existe una huella de agua para ese periodo."}));
                    let elem = document.getElementById("periodo-campana");
                    elem.scrollIntoView({behavior: "smooth"});
                    elem.focus();
                }else if(error.response?.data.message === "error sensores"){
                    setOpen(true);
                    setErrorMsg((prevErrorMsg) => ({
                        ...prevErrorMsg,
                        content: "Los sensores del sector no se encuentran actualmente disponibles y no se puede obtener la información necesaria actualmente para el cálculo, por favor intentelo de nuevo más tarde."
                    }));
                }else{
                    setOpen(true);
                    setErrorMsg((prevErrorMsg) => ({
                        ...prevErrorMsg,
                        content: "Algo ha fallado. Por favor, vuelve a intentarlo más tarde."
                    }));
                }
                setLoadingSubmit(false);
            }
        }
    };


    return(
        <>
            <div className="tic4bio-home">
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    {loading ? 
                        (
                            <Box p={3} align="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <div>
                                    <button className="tic4bio-huella-back" onClick={() => setChoice("")}><img src={leftarrow}></img><span>Cambiar tipo</span></button>
                                </div>
                                <div>
                                    <h1 className="tic4bio-home-title">Nueva Huella de Agua</h1>
                                    {(unidades.length > 0) ?
                                        <>
                                            <p>Seleccione la Unidad productiva y el sector donde desea crear la nueva huella*</p>
                                            <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                <InputLabel htmlFor="unidad-select">Unidades*</InputLabel>
                                                <Select
                                                    className="t4b-finca-sector-inputs"
                                                    id="unidad-select"
                                                    label="unidad"
                                                    required
                                                    MenuProps={{ disableScrollLock: true }}
                                                    disabled={loadingSectores}
                                                    value={stateForm.unidadId}
                                                    onChange={(event) => { handleLoadSectores(event); }    
                                                    }
                                                    >
                                                    {unidades.map((unidad, index) => (
                                                    <MenuItem key={index} value={unidad.id}>
                                                        {unidad.unidadProductiva} - {unidad.nombreFinca} ({unidad.dataType.charAt(0).toUpperCase() + unidad.dataType.slice(1)})
                                                    </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {loadingSectores ? (
                                                <Box p={3} align="center">
                                                    <CircularProgress />
                                                </Box>
                                            ) : (
                                                <>
                                                    {stateForm.unidadId &&
                                                        <>
                                                            {(sectores.length > 0) ? 
                                                                <>
                                                                    <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                                        <InputLabel htmlFor="sector-select">Sectores*</InputLabel>
                                                                        <Select
                                                                            className="t4b-finca-sector-inputs"
                                                                            id="sector-select"
                                                                            label="Sector"
                                                                            required
                                                                            MenuProps={{ disableScrollLock: true }}
                                                                            value={stateForm.sector}
                                                                            onChange={(event) => { handleSectorSelect(event) }
                                                                            }>
                                                                        
                                                                            {sectores.map((sector, index) => (
                                                                            <MenuItem key={index} value={sector}>
                                                                                {sector.nombre}
                                                                            </MenuItem>
                                                                            ))}
                                                                        </Select>

                                                                    </FormControl>
                                                                    {stateForm.sector &&
                                                                        <>
                                                                            <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                                                <InputLabel htmlFor="nombre">Nombre de la campaña*</InputLabel>
                                                                                <Input
                                                                                    id="nombre"
                                                                                    className="t4b-finca-sector-inputs"
                                                                                    label="Nombre de la campaña*"
                                                                                    value={stateForm.nombre}
                                                                                    onChange={(e) => setStateForm({...stateForm, nombre: e.target.value})}
                                                                                    required
                                                                                    name="nombre"
                                                                                    error={errors.errorNombre}
                                                                                />
                                                                                {errors.errorNombre && <p className="errorMsg">{errors.errorNombreMsg}</p>}
                                                                            </FormControl>
                                                                            <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                                                <InputLabel htmlFor="nombre">Periodo de campaña*</InputLabel>
                                                                                <Select
                                                                                    className="t4b-finca-sector-inputs"
                                                                                    id="periodo-campana"
                                                                                    label="Periodo de campaña"
                                                                                    required
                                                                                    MenuProps={{ disableScrollLock: true }}
                                                                                    value={stateForm.campana}
                                                                                    onChange={(event) => { handleChangeCampana(event); }}
                                                                                    error={errors.errorCampana}
                                                                                    >
                                                                                    {campanas.map((campana, index) => (
                                                                                        <MenuItem key={index} value={campana.nombre}>
                                                                                            {campana.nombre}
                                                                                        </MenuItem>
                                                                                    ))}
                                                                                </Select>
                                                                                {errors.errorCampana && <p className="errorMsg">{errors.errorCampanaMsg}</p>}
                                                                            </FormControl>
                                                                            {stateForm.campana &&
                                                                                <div className="tic4bio-huella-dates">
                                                                                    <div className="tic4bio-huella-dates-box">
                                                                                        <FormControl sx={{width: "100%"}} variant="standard">
                                                                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                                                                                <DatePicker
                                                                                                    label="Fecha de inicio*"
                                                                                                    id="dateCampaignBefore"
                                                                                                    value={stateForm.dateCampaignBefore}
                                                                                                    slotProps={{
                                                                                                        textField: {
                                                                                                        error: checkErrorDateBefore(),
                                                                                                        },
                                                                                                    }}
                                                                                                    onChange={(newValue) => {
                                                                                                        setStateForm(prevData => ({
                                                                                                            ...prevData,
                                                                                                            dateCampaignBefore: newValue}
                                                                                                        ));
                                                                                                    }}
                                                                                                />
                                                                                                <p className="tip">Fecha fin de la campaña de recolección del año anterior.</p>
                                                                                            </LocalizationProvider>
                                                                                            {errors.errorDateCampaignBefore && <div className="errorMsg">{errors.errorDateCampaignBeforeMsg}</div>}
                                                                                        </FormControl>

                                                                                        <FormControl sx={{width: "100%"}} variant="standard">
                                                                                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                                                                                                <DatePicker
                                                                                                    label="Fecha fin*"
                                                                                                    id="dateCampaignCurrent"
                                                                                                    value={stateForm.dateCampaignCurrent}
                                                                                                    slotProps={{
                                                                                                        textField: {
                                                                                                        error: checkErrorDateCurrent(),
                                                                                                        },
                                                                                                    }}
                                                                                                    onChange={(newValue) => {
                                                                                                        setStateForm(prevData => ({
                                                                                                            ...prevData,
                                                                                                            dateCampaignCurrent: newValue}
                                                                                                        ));
                                                                                                    }}
                                                                                                />
                                                                                                <p className="tip">Fecha fin de recolección de la campaña actual.</p>
                                                                                            </LocalizationProvider>
                                                                                            {errors.errorDateCampaignCurrent && <div className="errorMsg">{errors.errorDateCampaignCurrentMsg}</div>}
                                                                                        </FormControl>
                                                                                    </div>
                                                                                    {errors.errorDates && <div className="errorMsg">{errors.errorDatesMsg}</div>}
                                                                                </div>
                                                                            }
                                                                            <FormControl sx={{width: "100%", marginBottom: "20px"}} className="radio-green" variant="standard">
                                                                                <FormLabel htmlFor="tipoCalculoP">Método de cálculo de precipitación efectiva*</FormLabel>
                                                                                <RadioGroup
                                                                                    name="tipoCalculoP"
                                                                                    id="tipoCalculoP"
                                                                                    value={stateForm.metodoCalculo}
                                                                                    onChange={(e) => handleChangeCalculoP(e.target.value)}
                                                                                >
                                                                                    <FormControlLabel value="1" control={<Radio />} label="Porcentaje fijo" />
                                                                                    <FormControlLabel value="2" control={<Radio />} label="Precipitación confiable" />
                                                                                    <FormControlLabel value="3" control={<Radio />} label="USDA SCS" />
                                                                                    {stateForm.sector.tipoUsuario_id === 2 &&
                                                                                        <FormControlLabel value="4" control={<Radio />} label="Precipitación efectiva TIC" />
                                                                                    }
                                                                                </RadioGroup>
                                                                                {errors.errorTipoCalculoP && <p className="errorMsg">{errors.errorTipoCalculoPMsg}</p>}
                                                                            </FormControl>
                                                                            <FormControl sx={{width: "100%", marginBottom: "20px"}} className="radio-green" variant="standard">
                                                                                <InputLabel htmlFor="production">Producción (kg/ha)*</InputLabel>
                                                                                <Input
                                                                                    id="production"
                                                                                    className="t4b-finca-sector-inputs"
                                                                                    label="Producción"
                                                                                    value={stateForm.production}
                                                                                    onChange={(e) => handleChangeProduction(e.target.value)}
                                                                                    required
                                                                                    name="production"
                                                                                    error={errors.errorProduction}
                                                                                />
                                                                                {errors.errorProduction && <p className="errorMsg">{errors.errorProductionMsg}</p>}
                                                                            </FormControl>
                                                                            {loadingSubmit ?
                                                                                <Box align="center" sx={{my: 2}}>
                                                                                    <CircularProgress />
                                                                                </Box>
                                                                                :
                                                                                <Stack spacing={2} direction="row" justifyContent="space-between" className="tic4bio-sectores-btns">
                                                                                    <button className="btn green" onClick={(e) => handleSubmit(e)} style={{margin: "16px 0"}}>Añadir</button>
                                                                                </Stack>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                                :
                                                                <p>Parece que la unidad productiva seleccionada no tiene sectores asociados. Necesitas un al menos un sector poder crear una Huella de Agua.</p>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            )}
                                        </>
                                        :
                                        <p><Alert severity="error">No se han encontrado Unidades Productivas. Primero debe tener alguna creada. </Alert> <Link to="/nuevaUnidadProductiva">
                                            <button type="button" className="btn green" value="finca"> <AddCircleOutlineIcon className="add" /> Crear Unidad</button></Link></p>
                                        
                                    }
                                    
                                </div>
                            </>
                        )
                    }
                </Box>
            </div>
                <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.title} contentText={errorMsg.content}/>
        </>
    );
}

export default FormNuevaHuellaFincaComponent;