import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, LineController, BarController, Tooltip, Legend } from 'chart.js';
import { Chart } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    LineController,
    BarController,
    Tooltip,
    Legend
  );

function ChartAdvanced({ fechaInicio, fechaFin, data, typeUser }) {

  const chartRef = useRef(null);
  const [ chartInfo, setChartInfo ] = useState();
  const [state, setState] = useState({
    contenidoVolumetrico: typeUser === 2 ? true : false,
    laminaRiego: true,
    eto: true,
    precipitacion: true,
  });

  useEffect(() => {
    chooseOption();
  }, [state, fechaInicio, fechaFin]); 

  useEffect(() => {

  }, [chartRef, chartInfo]);

  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });


  };

  const chooseOption = () => {
    const labels = data.map(item => item.fechaFormatted);
    const precipitacion = data.map(item => item.p);
    const eto = data.map(item => item.eto);
    const laminaRiego = data.map(item => item.laminaRiego);
    const contenidoVolumetrico = data.map(item => item.contenidoVolumetrico);

    let subData = {
      precipitacion: precipitacion,
      eto: eto,
      laminaRiego: laminaRiego,
      contenidoVolumetrico: contenidoVolumetrico
    };

    const info = generateDataSets(labels, subData);
    setChartInfo(info);
  }

  const generateDataSets = (labels, subData) => {

    let checksSelected = [];

    Object.entries(state).filter(k => {
      if(k[1] === true){
        if(k[0] === "precipitacion"){
          checksSelected.push({key: k[0], valor: 'Precipitación', color: 'rgba(180,229,162,255)'});
        }
        if(k[0] === "eto"){
          checksSelected.push({key: k[0], valor: 'ETo', color: 'rgba(246,198,173,255)'});
        }
        if(k[0] === "laminaRiego"){
          checksSelected.push({key: k[0], valor: 'Lámina de riego', color: 'rgba(131,203,235,255)'});
        }
        if(k[0] === "contenidoVolumetrico"){
          checksSelected.push({key: k[0], valor: 'Contenido volumétrico de humedad', color: 'rgba(255, 0, 0, 0.8)'});
        }
      }
    })

    let result = {
      labels: labels,
      datasets: []
    };

    checksSelected.map((elem, index) => {
      result.datasets.push({
        type: (elem.key === "contenidoVolumetrico" ? 'line' : 'bar'),
        label: elem.valor,
        data: subData[elem.key],
        backgroundColor: elem.color,
        borderColor: elem.color,
        yAxisID: (elem.key === "contenidoVolumetrico" ? 'percentage-axis' : 'mm-axis'),
      });
    });
    return result;
  
  }

  const options4 = {
    layout: {
      padding: {
          top: 0,
          bottom: 100,
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
        'mm-axis': {
          type: 'linear',
          position: 'left',
          title: {
            display: true,
            text: 'Milímetros (mm)',
          },
        },
        'percentage-axis': {
          type: 'linear',
          position: 'right',
          title: {
            display: true,
            text: 'Porcentaje (%)',
          },
          grid: {
            drawOnChartArea: false
          },
        },
    }
  };

  const options = {
    layout: {
      padding: {
          top: 0,
          bottom: 100,
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
        'mm-axis': {
          type: 'linear',
          position: 'left',
          title: {
            display: true,
            text: 'Milímetros (mm)',
          },
        }
    }
  };

    return (
      <>
        {chartInfo &&
          <>
              <div style={{minHeight: "600px"}}>
                {/*<Bar data={chartInfo} options={options} ref={chartRef}/>*/}
                <Chart type='bar' data={chartInfo} options={typeUser === 2 ? options4 : options} />
              </div>
              <>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Opciones de filtrado</FormLabel>
                  <FormGroup>
                    {typeUser === 2 &&
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.contenidoVolumetrico}
                          onChange={handleChange}
                          name="contenidoVolumetrico"
                        />
                      }
                      label="Contenido volumétrico de humedad 20 cm"
                    />
                    }
                
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.laminaRiego}
                          onChange={handleChange}
                          name="laminaRiego"
                        />
                      }
                      label="Lámina de riego"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.eto}
                          onChange={handleChange}
                          name="eto"
                        />
                      }
                      label="ETo"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={state.precipitacion}
                          onChange={handleChange}
                          name="precipitacion"
                        />
                      }
                      label="Precipitación"
                    />
                  </FormGroup>
                </FormControl>
            </>
          </>
        }
        {!chartInfo &&
          <p>No existe información para mostrar para el <b>gráfico avanzados.</b></p>
        }
      </>
    );
}

export default ChartAdvanced;