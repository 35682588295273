import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useEffect, useState } from "react";

const ModalInfoBiodiversidad = ({open, setOpen}) => {

    const [content, setContent] = useState();

    useEffect(() => {
        mainText();
    }, []);

    const mainText = () => {
        let text =
            <ul>
                <li><b>Número de muestreos:</b> Solamente se realizará un análisis de biodiversidad de cada tipo (vegetal, invertebrados y vertebrados) por año.</li>
                <li>
                    <b>Tipo de análisis (básico o avanzado):</b>
                    <ul>
                        <li><span style={{textDecoration: "underline"}}>Básico</span>: se identifican nº de especies.</li>
                        <li><span style={{textDecoration: "underline"}}>Avanzado</span>: los muestreos, se realizan para identificar especies y nº de individuos de cada una de ellas.</li>
                    </ul>
                </li>
                <p style={{fontStyle: "italic",fontSize: "14px",fontWeight: 500}}>Se recomienda usar siempre el mismo nivel de análisis en los muestreos del mismo año en las distintas comunidades, especialmente cuando nos encontramos dentro del mismo grupo. En el caso de no ser así, en el informe de la finca completa para ese grupo (vertebrados, invertebrados o vegetación) sólo mostrará análisis básico.</p>
                <li><b>Época de muestreo:</b> Primavera.</li>
                <li>
                    <b>Material:</b>
                    <ul>
                        <li>Movil para ayudarse de la herramienta Google Lens u otra app para identificar especies (Plant Net o Picture Insect) a partir de una fotografía.</li>
                        <li><a href="#" onClick={vegetalText}>Material muestreo vegetal.</a></li>
                        <li><a href="#" onClick={invertebradosText}>Material muestreo invertebrados.</a></li>
                        <li><a href="#" onClick={vertebradosText}>Material muestreo vertebrados.</a></li>
                    </ul>
                </li>
                <li><b>Persona para el muestreo:</b> Se recomienda que los muestreos los realice la misma persona, con el fin de eliminar distorsión en los resultados derivados de la capacidad de observación o minuciosidad del técnico observador.</li>
            </ul>
        setContent(text);
    }

    const vegetalText = () => {
        let text =
            <div>
                <div style={{backgroundColor: "#80C606", color: "white", padding: "10px", borderRadius: "10px", textAlign: "center", maxWidth: "200px", margin: "0 auto 20px", fontSize: "22px"}}>Vegetal</div>
                <div>
                    <b>Clicando en este punto se accede a una guía interactiva para el análisis de biodiversidad de todo tipo de vegetación: zonas boscosas o de sierra, cultivos leñosos, cubierta vegetal, cultivos anuales, linderos y bosques isla.</b>
                    <ul>
                        <li><b>Época de muestreo:</b> Primavera.</li>
                        <li><b>Material:</b>
                            <ul>
                                <li>Movil para ayudarse de la herramienta Google Lens u otra app para identificar especies (Plant Net) a partir de una fotografía.</li>
                                <li>En caso de muestrearse cultivos anuales/herbáceos, cubierta vegetal o linderos será necesario contar con un cuadrante de 1x1m para lanzar sobre el terreno o en su defecto un metro que ayude a establecer zonas de muestreo de 1m2 sobre el terreno.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>;
        setContent(text);
    }

    const invertebradosText = () => {
        let text =
            <div>
                <div style={{backgroundColor: "#80C606", color: "white", padding: "10px", borderRadius: "10px", textAlign: "center", maxWidth: "200px", margin: "0 auto 20px", fontSize: "22px"}}>Invertebrados</div>
                <div>
                    <p><b>Los invertebrados son todos aquellos animales que carecen de columna vertebral.</b></p>
                    <p><b>Los anélidos (lombrices..), nematodos, moluscos (caracol, pulpo, calamar..), arácnidos (arañas…), crustáceos e insectos (abejas, mariposas, larvas, escarabajos, hormigas, mariquitas, moscas, mosquitos, saltamontes…).</b></p>
                    <p><b>Para el análisis de biodiversidad de este grupo se realizarán muestreos sobre la superficie del suelo y/o en trampas de vuelo para adultos.</b></p>
                    <ul>
                        <li><b>Época de muestreo: </b>Primavera.</li>
                        <li>
                            <b>Material:</b>
                            <ul>
                                <li>Movil para ayudarse de la herramienta Google Lens u otra app para identificar especies (Picture Insect) a partir de una fotografía.</li>
                                <li>Para los muestreos sobre la superficie del suelo será necesario contar con un cuadrante de 1x1m para lanzar sobre el terreno o en su defecto un metro que ayude a establecer zonas de muestreo de 1m2 sobre el terreno.</li>
                                <li>Para la identificación y conteo de invertebrados en los muestreos con trampas de vuelo para adultos será necesario disponer previamente sobre el terreno trampas McPhail o cromáticas   (instalación conforme a criterios de la Guía de Gestión Integrada de Plagas de su cultivo).</li>
                            </ul>
                        </li>
                    </ul>

                </div>
            </div>;
        setContent(text);
    }

    const vertebradosText = () => {
        let text = 
            <div>
                <div style={{backgroundColor: "#80C606", color: "white", padding: "10px", borderRadius: "10px", textAlign: "center", maxWidth: "200px", margin: "0 auto 20px", fontSize: "22px"}}>Vertebrados</div>
                <div>
                    <p><b>Los vertebrados son todos aquellos animales que poseen columna vertebral y huesos internos.</b></p>
                    <p><b>Los reptiles, anfibios, aves, peces y mamíferos son los grupos de vertebrados. </b></p>
                    <p><b>Para el análisis de biodiversidad de este grupo se emplea un método dedicado a la obtención de un índice de abundancia kilométrica (IKA). Para la obtención de este IKA se establecerá un itinerario de distancia conocida (al menos 1 km por cada 100 ha de superficie de la finca conocida). Durante el trayecto se usará la observación directa, de huellas o restos fecales, para identificar especies.</b></p>
                    <ul>
                        <li><b>Época de muestreo: </b>Primavera. Al amanecer o atardecer.</li>
                        <li>
                            <b>Material: </b>
                            Movil para ayudarse de la herramienta Google Lens para identificar especies a partir de una fotografía.
                        </li>
                    </ul>

                </div>
            </div>;
        setContent(text);
    }
    
    const handleClose = () => {
        mainText();
        setOpen(false);
    };

    return ( 
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogContent>
                <div style={{lineHeight: 1.5, letterSpacing: "0.00938em",color: "rgba(0, 0, 0, 0.6)"}}>
                    {content}
                </div>
            </DialogContent>
            <DialogActions>
                <button className="btn green" onClick={handleClose}>Cerrar</button>
            </DialogActions>
        </Dialog>
     );
}
 
export default ModalInfoBiodiversidad;