import { Box, CircularProgress, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import ModalInfo from '../ModalInfo';
import { useAuth } from '../../context/Auth';
import { useNavigate } from 'react-router-dom';
import { instance } from '../../utils/axios-config';

const BioVertebradosEditarResumenComponent = ({stateForm, setPosition}) => {

    const {sessionToken} = useAuth();
    const navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [ totalEspecies, setTotalEspecies ] = useState([]);

    useEffect(() => {
        let especiesTotal = [];
        if(stateForm.tipoAnalisis === 1){
            stateForm.muestreos.forEach((tipoMuestreo) =>{
                tipoMuestreo.muestreos.map(muestreo => {
                    if(muestreo?.eliminar !== true){
                        muestreo.muestras.map(((muestra) => {
                            if(muestra?.eliminar !== true){
                                const upperMuestra = muestra.nombre.charAt(0).toUpperCase() + muestra.nombre.slice(1);
                                muestra.nombre = upperMuestra;
                                if (!especiesTotal.includes(muestra.nombre)) {
                                    especiesTotal.push(muestra.nombre);
                                }
                            }
                        }));
                    }
                });
            });
        }else{
            stateForm.muestreos.forEach((tipoMuestreo) =>{
                tipoMuestreo.muestreos.map((muestreo) => {
                    if(muestreo?.eliminar !== true){
                        muestreo.muestras.map(((muestra) => {
                            if(muestra?.eliminar !== true){
                                muestra.num_individuos = parseInt(muestra.num_individuos);
                                const upperMuestra = muestra.nombre.charAt(0).toUpperCase() + muestra.nombre.slice(1);
                                muestra.nombre = upperMuestra;
                                const index = especiesTotal.findIndex(objeto => objeto.nombre === muestra.nombre);
                                if (index !== -1) {
                                    let objetoClonado = { ...especiesTotal[index] };
                                    objetoClonado.num_individuos += muestra.num_individuos;
                                    especiesTotal[index] = objetoClonado;
                                }else{
                                    especiesTotal.push(muestra);
                                }
                            }
                        }));
                    }
                });
            });
        }
        setTotalEspecies(especiesTotal);
    }, []);

    const handlePrev = (e) => {
        e.preventDefault();
        setPosition(0)
    };

    const handleSend = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
            await instance.put(`/biodiversidad/vertebrados/edicion/${stateForm.id}`, stateForm).then((resp) => {
                setLoading(false);
                navigate("/biodiversidad/", {state: {type: "action-new", text: "Se ha editado la biodiversidad de invertebrados correctamente."}});
                console.log("Enviado OK");
            });
        } catch (error) {
            setOpen(true);
            setLoading(false);
        }
    };
    
    return(
        <>

            <div className="tabla-bio-box">
                <table className="tabla-bio">
                    <thead>
                        <tr>
                            {stateForm.tipoAnalisis === 1 ?
                                <>
                                    <th>Especies en la zona "{stateForm.nombre_comunidad}"</th>
                                </>
                                :
                                <>
                                    <th style={{width: "70%"}}>Especies en la zona "{stateForm.nombre_comunidad}"</th>
                                    <th style={{width: "30%"}}>Nº ind.</th>
                                </>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {stateForm.tipoAnalisis === 1 ?
                            <>
                                {totalEspecies.map((nombre, index) => (
                                    <tr key={"especie-" + index}>
                                        <td>{nombre}</td>
                                    </tr>
                                ))}
                            </>
                            :
                            <>
                                {totalEspecies.map((especie, index) => (
                                    <tr key={"especie-" + index}>
                                        <td>{especie.nombre}</td>
                                        <td>{especie.num_individuos}</td>
                                    </tr>
                                ))}
                            </>
                        }
                    </tbody>
                </table>
            </div>

            {loading ?
                <Box align="center" sx={{my: 2}}>
                    <CircularProgress />
                </Box>
                :
                <Stack spacing={2} direction="row" justifyContent="space-between" className="tic4bio-sectores-btns">
                    <button className="btn green" onClick={(e) => handlePrev(e)}>Anterior</button>
                    <button className="btn green" onClick={(e) => handleSend(e)} style={{margin: "16px 0"}}>Finalizar</button>
                </Stack>
            }

            <ModalInfo open={open} setOpen={setOpen} contentTitle={"¡Vaya! Parace que algo ha ido mal."} contentText={"Algo ha fallado al intentar actualizar la biodiversidad de invertebrados. Por favor, vuelve a intentarlo más tarde."}/>
        
        </>
    );
} 
export default BioVertebradosEditarResumenComponent;