import {Box, FormControl, IconButton, Input, InputAdornment, InputLabel} from '@mui/material';
import { useState } from 'react';
import OjoAbierto from "../../assets/ojo-abierto.svg";
import OjoCerrado from "../../assets/ojo-cerrado.svg";
import HeaderOutNavLogin from '../../components/headerOutNavLogin';
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@mui/icons-material/Lock';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import ModalInfo from '../../components/ModalInfo';
import { useAuth } from '../../context/Auth';
import Footer from '../../components/footer/Footer';

const LoginBackofficePage = () => {

    const { singInBackoffice } = useAuth();
    const navigate = useNavigate();

    const [sessionToken, setSessionToken] = useState();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorLogin, setErrorLogin] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const location = useLocation();
    let prevRoute = location.state;

    const handleClickShowPassword = (event) => {
        event.preventDefault();
        setShowPassword((show) => !show)
    };

    const handleHideErrors = () => {
        setErrors(errors => ({...errors, errorUserMsg: null, errorPasswordMsg: null}));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        await singInBackoffice(username, password).then((response) => {
            if(response?.login === true){
                navigate("/backofficeHome");
            }else if(response?.login === false){
                setErrorLogin("Las credenciales introducidas no corresponde con ningún usuario. Por favor, inténtelo de nuevo.");
                setOpen(true);
                setLoading(false);
            }else{
                setErrorLogin("Se ha producido un error inesperado, vuelve a intentarlo más tarde.");
                setOpen(true);
                setLoading(false);
            }
        }
        ).catch((error) => {
            alert(error);
        });
    };

    return (
        <div className="welcome-main" onClick={handleHideErrors}>
            <div className="welcome-container-backoffice">
             <h1 className="backoffice-login-title">Administración de Tic4Bio</h1>
                <div className="welcome-body">
                    <Box component="form" onSubmit={handleSubmit} noValidate>
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="username">Correo electrónico o Teléfono*</InputLabel>
                            <Input
                                id="username"
                                className=""
                                label="Correo electrónico"
                                required
                                name="username"
                                error={errors.errorUser}
                                onChange={e => 
                                    setUsername(e.target.value)
                                }
                                startAdornment={
                                <InputAdornment position="start">
                                    <PersonIcon />
                                </InputAdornment>
                                }
                            />
                            {errors.errorUserMsg && <div className="errorInput">{errors.errorUserMsg}</div>}
                        </FormControl>
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="password">Contraseña*</InputLabel>
                            <Input
                                id="password"
                                required
                                className=""
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                onChange={e => 
                                    setPassword(e.target.value)
                                }
                                error={errors.errorPassword}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <LockIcon />
                                    </InputAdornment>
                                }
                                endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="Conmuta la visibilidad de la contraseña"
                                    onClick={handleClickShowPassword}
                                    edge="end"><img
                                    title={showPassword ? "Ocultar contraseña" : "Mostrar contraseña"}
                                    src={showPassword ? OjoCerrado : OjoAbierto}
                                    alt={"Icono visibilidad"}
                                    />
                                    </IconButton>
                                </InputAdornment>
                                }
                                label="Contraseña"
                            />
                            {errors.errorPasswordMsg && <div className="errorInput">{errors.errorPasswordMsg}</div>}
                        </FormControl>
                        <div className="login-link-pass">
                            <Link to="/recuperarPassword" state={location.pathname}>
                                ¿Olvidaste la contraseña?
                            </Link>
                        </div>
                        <div className='login-buttons'>
                            <button type="submit" className="btn white">Iniciar sesión</button>
                        </div>
                    </Box>
                </div>
            </div>
            <LoadingBackdrop loading={loading} />
            <ModalInfo open={open} setOpen={setOpen} contentTitle={"Error"} contentText={errorLogin}/>
        </div>
    );
}
 
export default LoginBackofficePage;