import { Autocomplete, Box, FormControl, IconButton, TextField } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Fragment, useEffect } from "react";
import { checkIntPattern } from "../../utils/commons";

const TableAdvancedSamplingComponent = ({stateForm, setStateForm, tipoVegetacion, errors }) => {


    useEffect(() => {
        if(Object.keys(stateForm.muestreos).length === 0 || !stateForm.muestreos[tipoVegetacion]){
            setStateForm(prevData => ({
                ...prevData,
                muestreos: {
                    ...prevData.muestreos,
                    [tipoVegetacion]: [[]],     
                } 
            }));
        }
    }, []);

    const handleAddColumn = () => {
        setStateForm(prevData => ({
            ...prevData,
            muestreos: {
                ...prevData.muestreos,
                [tipoVegetacion]: [
                    ...prevData.muestreos[tipoVegetacion],
                    []
                ],     
            } 
        }));
    }

    const handleAddEspecie = (pos) => {
        let nuevaEspecie = {
            nombre: "Nueva Especie",
            ni: 1,
            tempId: "tempId-" + new Date().getTime()
        }
        setStateForm(prevState => {
            if(prevState.muestreos[tipoVegetacion]){
                return {
                    ...prevState,
                    muestreos: {
                        ...prevState.muestreos,
                        [tipoVegetacion]: prevState.muestreos[tipoVegetacion].map((item, index) => {
                            if (index === pos) {
                                return [
                                    ...item,
                                    nuevaEspecie
                                ];
                            }
                            return item;
                        })
                    }
                }
            }else{
                return {
                    ...prevState,
                    especies: {
                        ...prevState.especies,
                        [tipoVegetacion]: [
                            [nuevaEspecie]
                        ],
                    }
                }
            }
            
        })
    }


    const handleChangeNombre = (e, id) => {
        
        setStateForm(prevData => {
            const newData = stateForm.muestreos[tipoVegetacion].map(subArray => subArray.map(item => {
                if(item.tempId === id){
                    return { ...item, nombre: e};
                }
                return item;
            }));
            return {...prevData, muestreos: {
                ...prevData.muestreos, 
                [tipoVegetacion]: newData
            }};
          });
    }

    const handleChangeNI = (e, id) => {
        if(checkIntPattern(e)){
            setStateForm(prevData => {
                const newData = stateForm.muestreos[tipoVegetacion].map(subArray => subArray.map(item => {
                    if(item.tempId === id){
                        return { ...item, ni: parseInt(e)};
                    }
                    return item;
                }));
                return {...prevData, muestreos: {
                    ...prevData.muestreos, 
                    [tipoVegetacion]: newData
                }};
              });
        }
    }

    const deleteMuestreo = (e) => {
        let muestreos = stateForm.muestreos[tipoVegetacion].map(subArray => subArray.filter(objeto => objeto.tempId !== e?.tempId));
        setStateForm(prevData => ({
            ...prevData,
            muestreos: {
                ...prevData.muestreos,
                [tipoVegetacion]: muestreos,     
            } 
        }));
    }

    const handleDeleteColumn = (pos) => {
        let columnas = stateForm.muestreos[tipoVegetacion].filter((elemento, index) => index !== pos);
        setStateForm(prevData => ({
            ...prevData,
            muestreos: {
                ...prevData.muestreos,
                [tipoVegetacion]: columnas,     
            } 
        }));
    }

    return(
        <div className="tabla-bio-container advanced">
             
            {stateForm.muestreos[tipoVegetacion]?.map((muestreo, index) => (
                <table className="tabla-bio" key={"tabla-muestreo-" + tipoVegetacion + "-" + index}>
                    <thead>
                        <tr style={{ display: index === 0 ? "table-row" : ""}}>
                            <th>
                                Especies Muestreo {index + 1}
                            </th>
                            <th>
                                Nº de ind.
                            </th>
                            {index !== 0 ?
                                <th>
                                    <IconButton
                                    size="large"
                                    edge="start"
                                    className="delete"
                                    aria-label="Eliminar Muestreo"
                                    style={{color: "#fa0000"}}
                                    onClick={() => {handleDeleteColumn(index)}}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </th>
                                :
                                <th></th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {stateForm.muestreos[tipoVegetacion][index]?.map((especimen, j) => (
                            <Fragment key={"muestreo-" + tipoVegetacion + "-" + index + "-" + j}>
                                <tr>
                                    <td>
                                        <FormControl sx={{width: "100%"}} variant="standard">
                                            {/*<TextField
                                                className="t4b-finca-sector-inputs no-red-label"
                                                label="Nombre de especie*"
                                                id={"especieNombre-" + tipoVegetacion + "-" + index + "-" + j}
                                                value={especimen?.nombre}
                                                variant="standard"
                                                onChange={(e) => {handleChangeNombre(e.target.value, especimen?.tempId)}}
                                                name="especieNombre"
                                                error={errors["errorEspecie-" + tipoVegetacion + "-" + index + "-" + j +  "Nombre"]}
                                            />
                                            {errors["errorEspecie-" + tipoVegetacion + "-" + index + "-" + j + "NombreMsg"] && <div className="errorInput">{errors["errorEspecie-"  + tipoVegetacion + "-" + index + "-" + j + "NombreMsg"]}</div>}*/}
                                            <Autocomplete
                                                freeSolo
                                                options={stateForm.diccionario}
                                                renderOption={(props, option) => {
                                                    return <Box element="li" sx={{ display: 'flex', alignItems: 'center' }} {...props}> 
                                                        {option}
                                                    </Box>
                                                }}
                                                className="t4b-finca-inputs"
                                                getOptionLabel={(option) => (
                                                    typeof option === "string" ? option : option.label
                                                )}
                                                id={"especieNombre-" + tipoVegetacion + "-" + index + "-" + j}
                                                onInputChange={(e, newValue) => {
                                                    handleChangeNombre(newValue, especimen?.tempId)
                                                }}
                                                value={especimen?.nombre || ""}
                                                renderInput={(params) => (
                                                    <>
                                                    <TextField {...params} label="Nombre de especie*" variant="standard" />
                                                    </>
                                                )}
                                                sx={{
                                                    ...(errors["errorEspecie-" + tipoVegetacion + "-" + index + "-" + j + "Nombre"] && {
                                                        '& .MuiInput-root:before': {
                                                            borderBottom: "1px solid #d32f2f",
                                                        }
                                                    })
                                                }}
                                            />
                                        </FormControl>
                                    </td>
                                    <td>
                                        <FormControl sx={{width: "100%"}} variant="standard">
                                            <TextField
                                                className="t4b-finca-sector-inputs no-red-label"
                                                label="Número de individuos*"
                                                id={"especieNI-" + tipoVegetacion + "-" + index + "-" + j}
                                                value={especimen?.ni}
                                                type="number"
                                                variant="standard"
                                                InputProps={{
                                                    inputProps: { 
                                                        max: 99999, min: 1,
                                                    }
                                                }}
                                                onChange={(e) => handleChangeNI(e.target.value, especimen?.tempId)}
                                                name="especieNI"
                                                error={errors["errorEspecie-" + tipoVegetacion + "-" + index + "-" + j +  "NI"]}
                                            />
                                            {errors["errorEspecie-" + tipoVegetacion + "-" + index + "-" + j + "NIMsg"] && <div className="errorInput">{errors["errorEspecie-"  + tipoVegetacion + "-" + index + "-" + j + "NIMsg"]}</div>}
                                        </FormControl>
                                    </td>
                                    <td>
                                        <IconButton
                                        size="large"
                                        edge="start"
                                        className="delete"
                                        aria-label="Eliminar especie"
                                        style={{color: "#fa0000"}}
                                        onClick={() => {deleteMuestreo(especimen)}}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </td>
                                </tr>
                            </Fragment>
                        ))}
                        <tr>
                            <td colSpan="3" className="tabla-bio-add">
                                <IconButton
                                    size="large"
                                    edge="start"
                                    className="delete"
                                    aria-label="Añadir Especie"
                                    style={{color: "#80C606"}}
                                    onClick={() => {handleAddEspecie(index)}}
                                    >
                                        <AddCircleIcon />
                                </IconButton>
                            </td>
                        </tr>
                    </tbody>
                </table>
            ))}
            <table className="tabla-bio">
                <thead>
                    <tr style={{display: "table-row"}}>
                        <th colSpan="3">
                            <IconButton
                                size="large"
                                edge="start"
                                className="delete"
                                aria-label="Añadir Muestreo"
                                style={{color: "#80C606"}}
                                onClick={() => {handleAddColumn()}}
                                >
                                    <AddCircleIcon />
                            </IconButton>
                        </th>
                    </tr>
                </thead>
            </table>
        </div>
    )
}

export default TableAdvancedSamplingComponent;