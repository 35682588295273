import { Navigate } from "react-router-dom";
import { useAuth } from "../context/Auth";
import jwt_decode from "jwt-decode";

export const PrivateRoute = ({ children }) => {

    const { currentUser, setCurrentUser, sessionToken, setSessionToken } = useAuth();

    const {exp} = sessionToken ? jwt_decode(sessionToken) : 0;

    if(!currentUser && ((exp * 1000) < Date.now() || !exp)){
        setCurrentUser(null);
        setSessionToken(null);
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('userData');
        return <Navigate to="/" />;
    }
    return children;

}