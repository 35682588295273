import { Box, FormControl, FormControlLabel, FormLabel, Input, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack } from "@mui/material";
import { useState } from "react";
import { checkDoublePattern, checkPercentagePattern, checkTinyPercentagePattern } from "../../utils/commons";

const FormCaracteristicasSueloComponent = ({position, setPosition, state, setState}) => {

    const [errors, setErrors] = useState({});

    const handlePrev = (e) => {
        e.preventDefault();
        setPosition(3);
    }

    const handleNext = (e) => {
        e.preventDefault();
        if(validateForm()){
            setPosition(5);
        }
    };

    const handleChangeAnalisis = (e) => {
        if(e === "analisis"){
            setState({...state, analisis: "analisis", ptArena: "", ptLimo: "", ptArcilla: "", densidad: ""});
        }else if(e === "textura"){
            setState({...state, analisis: "textura", textura: ""});
        }else if(e === "manual"){
            setState({...state, analisis: "manual", pmp: "", cc: ""});
        }
    }

    const handleChangeHumedad = (e) => {
        setState({...state, humedadSueloInicial: e});
        console.log(e);
    }

    const handleChangePtArena = (e) => {
        if(checkPercentagePattern(e)){
            setState({...state, ptArena: e});
        }
    }

    const handleChangePtLimo = (e) => {
        if(checkPercentagePattern(e)){
            setState({...state, ptLimo: e});
        }
    }

    const handleChangePtArcilla = (e) => {
        if(checkPercentagePattern(e)){
            setState({...state, ptArcilla: e});
        }
    }

    const handleChangeDensidad = (e) => {
        if(checkDoublePattern(e)){
            setState({...state, densidad: e});
        }
    }

    const handleChangePMP = (e) => {
        if(checkTinyPercentagePattern(e)){
            setState({...state, pmp: e});
        }
    }

    const handleChangeCC = (e) => {
        if(checkTinyPercentagePattern(e)){
            setState({...state, cc: e});
        }
    }

    const validateForm = () =>{
        setErrors({});
        let inputIds = "";
        let valid = true;
        if(state.analisis === undefined || state.analisis === ''){
            setErrors(errors => ({...errors, errorAnalisis: true, errorAnalisisMsg: "Debe elegir la captura de datos del suelo."}));
            inputIds = inputIds ? inputIds +",analisis" : "analisis";
            valid = false;
        }else if(state.analisis === "analisis"){
            if(state.ptArena === undefined || state.ptArena === ''){
                setErrors(errors => ({...errors, errorPtArena: true, errorPtArenaMsg: ""}));
                inputIds = inputIds ? inputIds +",ptArena" : "ptArena";
                valid = false;
            }
            if(state.ptLimo === undefined || state.ptLimo === ''){
                setErrors(errors => ({...errors, errorPtLimo: true, errorPtLimoMsg: ""}));
                inputIds = inputIds ? inputIds +",ptLimo" : "ptLimo";
                valid = false;
            }
            if(state.ptArcilla === undefined || state.ptArcilla === ''){
                setErrors(errors => ({...errors, errorPtArcilla: true, errorPtArcillaMsg: ""}));
                inputIds = inputIds ? inputIds +",ptArcilla" : "ptArcilla";
                valid = false;
            }
            if(!(state.ptArena === undefined || state.ptArena === '') && !(state.ptLimo === undefined || state.ptLimo === '') && !(state.ptArcilla === undefined || state.ptArcilla === '') && ((parseFloat(state.ptArena?.replace(',', '.')) + parseFloat(state.ptArcilla?.replace(',', '.')) + parseFloat(state.ptLimo?.replace(',', '.'))) !== 100)){
                setErrors(errors => ({...errors, errorPorcentajes: true, errorPorcentajesMsg: "La suma de los procentajes debe ser igual a 100. La suma actual es: " + (parseFloat(state.ptArena) + parseFloat(state.ptArcilla) + parseFloat(state.ptLimo)) + "."}));
                valid = false;
            }
        }else if(state.analisis === "textura"){
            if(state.textura === undefined || state.textura === ''){
                setErrors(errors => ({...errors, errorTextura: true, errorTexturaMsg: ""}));
                inputIds = inputIds ? inputIds +",textura" : "textura";
                valid = false;
            }
        }else if(state.analisis === "manual"){
            if(state.pmp === undefined || state.pmp === ''){
                setErrors(errors => ({...errors, errorPMP: true, errorPMPMsg: ""}));
                inputIds = inputIds ? inputIds +",pmp" : "pmp";
                valid = false;
            }
            if(state.cc === undefined || state.cc === ''){
                setErrors(errors => ({...errors, errorCC: true, errorCCMsg: ""}));
                inputIds = inputIds ? inputIds +",cc" : "cc";
                valid = false;
            }
            if(parseFloat(state.cc?.replace(',', '.')) < parseFloat(state.pmp?.replace(',', '.'))){
                setErrors(errors => ({...errors, errorManual: true, errorManualMsg: "El CC debe ser mayor que el PMP.", errorCC: true, errorCCMsg: "", errorPMP: true, errorPMPMsg: ""}));
                inputIds = inputIds ? inputIds +",pmp" : "pmp";
                valid = false;
            }
        }
        if(state.tipoUsuario !== "Usuario 4.0" && (state.humedadSueloInicial === undefined || state.humedadSueloInicial === '')){
            setErrors(errors => ({...errors, errorHumedadSueloInicial: true, errorHumedadSueloInicialMsg: "Debe elegir el estado inicial del suelo."}));
            inputIds = inputIds ? inputIds +",humedadSueloInicial" : "humedadSueloInicial";
            valid = false;
        }
        if(inputIds !== ""){
            try{
                let elem = document.getElementById(inputIds.split(",")[0]);
                elem.scrollIntoView({behavior: "smooth"});
                elem.focus();
            }catch(err){

            }
        }
        return valid;
    }

    return(
        <>
        <Box className={position !== 4 && 'hide'}>
            <h2>Características del Suelo</h2>
                <FormControl sx={{width: "100%", marginBottom: "20px"}} className="radio-green" variant="standard">
                    <FormLabel htmlFor="analisis">Estrategia de captura de datos de suelo*</FormLabel>
                    <RadioGroup
                        name="analisis"
                        id="analisis"
                        value={state.analisis}
                        onChange={(e) => handleChangeAnalisis(e.target.value)}
                        error={errors.errorAnalisis ? "true" : ""}
                    >
                        <FormControlLabel value="manual" control={<Radio />} label="Manual" />
                        <FormControlLabel value="textura" control={<Radio />} label="Textura del Suelo" />
                        <FormControlLabel value="analisis" control={<Radio />} label="Análisis del Suelo" />
                    </RadioGroup>
                    {errors.errorAnalisisMsg && <div className="errorMsg">{errors.errorAnalisisMsg}</div>}
                </FormControl>
                {state.analisis === "textura" && (
                    <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                        <InputLabel htmlFor="textura">Textura del suelo*</InputLabel>
                        <Select
                            id="textura"
                            value={state.textura}
                            label="Textura del suelo"
                            onChange={(e) => setState({...state, textura: e.target.value})}
                            required
                            error={errors.errorTextura}
                        >
                            <MenuItem value="">-</MenuItem>
                            <MenuItem value="Arenoso">Arenoso</MenuItem>
                            <MenuItem value="Arenoso Franco">Arenoso Franco</MenuItem>
                            <MenuItem value="Franco Arenoso">Franco Arenoso</MenuItem>
                            <MenuItem value="Franco">Franco</MenuItem>
                            <MenuItem value="Franco Limoso">Franco Limoso</MenuItem>
                            <MenuItem value="Limoso">Limoso</MenuItem>
                            <MenuItem value="Franco Arcillo Limoso">Franco Arcillo Limoso</MenuItem>
                            <MenuItem value="Arcillo Limoso">Arcillo Limoso</MenuItem>
                            <MenuItem value="Arcilloso">Arcilloso</MenuItem>
                        </Select>
                        {errors.errorTexturaMsg && <div className="errorInput">{errors.errorTexturaMsg}</div>}
                    </FormControl>
                )} 
                {state.analisis === "analisis" && (
                    <>
                        {errors.errorPorcentajes && <p className="errorMsg">{errors.errorPorcentajesMsg}</p>}
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="ptArena">Porcentaje de Arena*</InputLabel>
                            <Input
                                id="ptArena"
                                className="t4b-finca-sector-inputs"
                                label="Porcentaje de Arena"
                                required
                                name="ptArena"
                                value={state.ptArena}
                                onChange={(e) => handleChangePtArena(e.target.value)}
                                error={errors.errorPtArena}
                            />
                            {errors.errorPtArenaMsg && <div className="errorInput">{errors.errorPtArenaMsg}</div>}
                        </FormControl>
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="ptLimo">Porcentaje de Limo*</InputLabel>
                            <Input
                                id="ptLimo"
                                className="t4b-finca-sector-inputs"
                                label="Porcentaje de Limo"
                                required
                                name="ptLimo"
                                value={state.ptLimo}
                                onChange={(e) => handleChangePtLimo(e.target.value)}
                                error={errors.errorPtLimo}
                            />
                            {errors.errorPtLimoMsg && <div className="errorInput">{errors.errorPtLimoMsg}</div>}
                        </FormControl>
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="ptArcilla">Porcentaje de Arcilla*</InputLabel>
                            <Input
                                id="ptArcilla"
                                className="t4b-finca-sector-inputs"
                                label="Porcentaje de Arcilla"
                                required
                                name="ptArcilla"
                                value={state.ptArcilla}
                                onChange={(e) => handleChangePtArcilla(e.target.value)}
                                error={errors.errorPtArcilla}
                            />
                            {errors.errorPtArcillaMsg && <div className="errorInput">{errors.errorPtArcillaMsg}</div>}
                        </FormControl>
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="densidad">Densidad aparente (g/m<sup>3</sup>)</InputLabel>
                            <Input
                                id="densidad"
                                className="t4b-finca-sector-inputs"
                                label="Densidad aparente"
                                name="densidad"
                                value={state.densidad}
                                onChange={(e) => handleChangeDensidad(e.target.value)}
                                error={errors.errorDensidad}
                            />
                            {errors.errorDensidadMsg && <div className="errorInput">{errors.errorDensidadMsg}</div>}
                        </FormControl>

                    </>
                )}
                {state.analisis === "manual" && (
                    <>
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="pmp">PMP (m<sup>3</sup>/m<sup>3</sup>)*</InputLabel>
                            <Input
                                id="pmp"
                                label="pmp"
                                required
                                name="pmp"
                                value={state.pmp}
                                onChange={(e) => handleChangePMP(e.target.value)}
                                error={errors.errorPMP}
                            />
                            {errors.errorPMPMsg && <div className="errorInput">{errors.errorPMPMsg}</div>}
                        </FormControl>
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="cc">CC (m<sup>3</sup>/m<sup>3</sup>)*</InputLabel>
                            <Input
                                id="cc"
                                label="cc"
                                required
                                name="cc"
                                value={state.cc}
                                onChange={(e) => handleChangeCC(e.target.value)}
                                error={errors.errorCC}
                            />
                            {errors.errorCCMsg && <div className="errorInput">{errors.errorCCMsg}</div>}
                        </FormControl>
                        {errors.errorManualMsg && <div className="errorMsg" style={{marginBottom: "20px"}}>{errors.errorManualMsg}</div>}
                    </>
                )}
                {state.tipoUsuario !== "Usuario 4.0" &&
                    <FormControl sx={{width: "100%", marginBottom: "20px"}} className="radio-green" variant="standard">
                        <FormLabel htmlFor="humedadSueloInicial">Estado inicial de humedad del suelo*</FormLabel>
                        <RadioGroup
                            name="humedadSueloInicial"
                            id="humedadSueloInicial"
                            value={state.humedadSueloInicial}
                            onChange={(e) => handleChangeHumedad(e.target.value)}
                            error={errors.errorHumedadSueloInicial}
                        >
                            <FormControlLabel value="Bajo" control={<Radio />} label="Bajo" />
                            <FormControlLabel value="Medio" control={<Radio />} label="Medio" />
                            <FormControlLabel value="Alto" control={<Radio />} label="Alto" />
                            <FormControlLabel value="MuyAlto" control={<Radio />} label="Muy alto" />
                        </RadioGroup>
                        {errors.errorHumedadSueloInicialMsg && <div className="errorMsg">{errors.errorHumedadSueloInicialMsg}</div>}
                    </FormControl>
                }

                <Stack spacing={2} direction="row" justifyContent="space-between" className="tic4bio-sectores-btns">
                    <button className="btn white" onClick={(e) => handlePrev(e)}>Anterior</button>
                    <button className="btn green" onClick={(e) => handleNext(e)} style={{margin: "16px 0"}}>Siguiente</button>
                </Stack>
            </Box>
             
            </>
    );
}

export default FormCaracteristicasSueloComponent;