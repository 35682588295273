export const checkDoublePattern = (e) => {
    const regex = /^[0-9]\d*([,.]([0-9]{1,2})?)?$/;
    let result = false;
    if (e === "" || regex.test(e)) {
        result = true;
    }
    return result;
};

export const checkIntPattern = (e) => {
    const regex = /^[0-9]*$/;
    let result = false;
    if (e === "" || regex.test(e)) {
        result = true;
    }
    return result;
}

export const checkIntNoZeroPattern = (e) => {
    const regex = /^(?!0$)\d+$/;
    let result = false;
    if (e === "" || regex.test(e)) {
        result = true;
    }
    return result;
}

export const checkMinutesPattern = (e) => {
    const regex = /^([0-9]|[1-5][0-9]|60|all)$/;
    let result = false;
    if (e === "" || regex.test(e)) {
        result = true;
    }
    return result;
}

export const checkMinutesNo60Pattern = (e) => {
    const regex = /^([0-9]|[1-5][0-9]|59|all)$/;
    let result = false;
    if (e === "" || regex.test(e)) {
        result = true;
    }
    return result;
}

export const checkHoursPattern = (e) => {
    const regex = /^([0-9]|[1]\d|[2][0-4])$/;
    let result = false;
    if (e === "" || regex.test(e)) {
        result = true;
    }
    return result;
}

export const checkHoursNo24Pattern = (e) => {
    ///^([0-9]|[1]\d|[2][0-3])$/
    const regex = /^([0-9]|0[0-9]|1\d|2[0-3])$/;
    let result = false;
    if (e === "" || regex.test(e)) {
        result = true;
    }
    return result;
}

export const check100MaxPattern = (e) => {
    const regex = /^(100|[1-9]?[0-9])$/;
    let result = false;
    if (e === "" || regex.test(e)) {
        result = true;
    }
    return result;
}

export const checkPercentagePattern = (e) => {
    const regex = /^(?:100(?:([.,]([0]{1,2})?)?)?|[0-9]{1,2}([.,]([0-9]{1,2})?)?)?$/;
    let result = false;
    if (e === "" || regex.test(e)) {
        result = true;
    }
    return result;
}

export const checkTinyPercentagePattern = (e) => {
    const regex = /^(?:0(?:[.,]([0-9]{1,2})?)?|1)?$/;
    let result = false;
    if (e === "" || regex.test(e)) {
        result = true;
    }
    return result;
}

export const DETALLES_INFO = [
    { id:1 , title: 'Características del Cultivo' },
    { id:2 ,title: 'Características del suelo' },
    { id:3 ,title: 'Sistema de Riego' },
    { id:4 ,title: 'Estrategia de Riego' },
    { id:5 ,title: 'Tarifa Eléctrica' },
    { id:6 ,title: 'Agua de Riego para la Campaña' },
    { id:7 ,title: 'Método de Calculo de la Precipitación Efectiva' },
    { id:8 ,title: 'Horas de Riego Aplicado en el Inicio' },
];

export const convertDate = (date) => {
    const ano = date.getFullYear();
    const mes = String(date.getMonth() + 1).padStart(2, '0'); 
    const dia = String(date.getDate()).padStart(2, '0');
  
    return `${dia}/${mes}/${ano}`;
}

export const convertDateEn = (date) => {
    const ano = date.getFullYear();
    const mes = String(date.getMonth() + 1).padStart(2, '0'); 
    const dia = String(date.getDate()).padStart(2, '0');
  
    return `${ano}-${mes}-${dia}`;
}

export const convertShortDate = (date) => {
    const fecha = new Date(date);

    const dia = fecha.getDate();

    const nombreMes = fecha.toLocaleString('es-ES', { month: 'short' });

    const mesCapitalizado = nombreMes.charAt(0).toUpperCase() + nombreMes.slice(1, 3);

    return `${dia} ${mesCapitalizado}`;
}

export const groupByUnidad = (unidades) => {
    return unidades.reduce(function(rv, u) {
        (rv[u["unidadProductiva"]] = rv[u["unidadProductiva"]] || []).push(u);
        return rv;
    }, {});
}


export function generarListadoCamp(year_Inicial, year_Final) {
    const listadoCampa = [];
    for (let year = year_Inicial; year <= year_Final; year++) {
        const camp = {id: `Campaña ${year}/${year + 1}`, nombre: `Campaña ${year}/${year + 1}`, firstYear: year, lastYear: year + 1};
        listadoCampa.push(camp);
    }
    return listadoCampa;
}

export const getH = (pi) => {
    let res = 0;
    if(pi > 0){
        res = -(pi * Math.log(pi));
    }
    return res;
}

export function partirStringLargo(listaStrings) {
    const nuevaLista = [];
  
    listaStrings.forEach((str) => {
      if (str.length > 21) {
        const palabras = str.split(' ');
        let nuevoStr = '';
        let nuevaListaTemp = [];
        
        palabras.forEach((palabra) => {
          if (nuevoStr.length + palabra.length <= 21) {
            nuevoStr += palabra + ' ';
          } else {
            nuevaListaTemp.push(nuevoStr.trim());
            nuevoStr = palabra + ' ';
          }
        });
  
        nuevaListaTemp.push(nuevoStr.trim());
        nuevaLista.push(nuevaListaTemp);
      } else {
        nuevaLista.push([str]);
      }
    });
  
    return nuevaLista;
  }