import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';

function BarChartHASectoresEvolucion({ startCampana, endCampana, campanas, data, finca }) {

  const navigate = useNavigate();
  const [ chartInfo, setChartInfo ] = useState();

  
  useEffect(() => {
    chooseOption();
  }, [startCampana, endCampana]);


  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const generateCampanas = () => {
    const indiceInicio = campanas.indexOf(startCampana);
    const indiceFin = campanas.indexOf(endCampana);

    return campanas.slice(indiceInicio, indiceFin + 1);
  }

  const generateDataSets = (subData, labelsCampanas) => {
    
    //const sectorNames = Array.from(new Set(labelsCampanas.flatMap(campana => subData[campana].fincas.map(finca => finca.sectorName))));
    const sectores = Array.from(new Set(labelsCampanas.flatMap(campana => subData[campana].fincas)));

    const result = sectores.map(sector => {
      return {
        label: sector.sectorName,
        data: labelsCampanas.map(campaign => {
          const finca = subData[campaign].fincas.find(f => f.sectorName === sector.sectorName);
          return finca ? finca.huellaCultivo : 0;
        }),
        backgroundColor: getRandomColor(),
        sectorId: sector.sector_id,
        huellaId: sector.id,
      };
    });

    return result;
  
  }

  const chooseOption = () => {
    let subData = data.unidad.infoUnidad;

    const labelsCampanas = generateCampanas();

    const filteredData = Object.keys(subData)
    .filter((key) => labelsCampanas.includes(key))
    .reduce((obj, key) => {
      obj[key] = subData[key];
      return obj;
    }, {});

    let someData = Object.values(filteredData).some(c => {

      if(c.fincas.length !== 0){
        return true;
      }
      return false;
    });
    
    if(someData){
      const info = {
        labels: labelsCampanas,
        datasets: generateDataSets(filteredData, labelsCampanas)
      }
  
      setChartInfo(info);
    }else{
      setChartInfo();
    }

  }

  const options = {
    layout: {
      padding: {
          top: 0,
          bottom: 100,
      }
    },
    maintainAspectRatio: false,
    responsive: true,
    onClick: (event, elements) => {
      if (elements && elements.length > 0) {
        // Obtener los datos de la barra en la que se hizo clic
        const clickedBarIndex = elements[0].index;
        const clickedDatasetIndex = elements[0].datasetIndex;
        const clickedData = chartInfo.datasets[clickedDatasetIndex].data[clickedBarIndex];

        let fincaAux;
        if(finca.fincas.length > 0){
          fincaAux = finca.fincas.find(s => {
            if(s.dataType === "finca"){
              return s.id;
            }
          });
        }
        // Realizar alguna acción con los datos de la barra
          navigate(`/huella-cultivos/${fincaAux.id}`, {
            state: { sectorId: chartInfo.datasets[clickedDatasetIndex].sectorId, huellaId: chartInfo.datasets[clickedDatasetIndex].huellaId }
          });
      }
    }
  };

    return (
      <>
        {chartInfo &&
          <>
              <div>
                <h3 style={{textAlign: "center", textDecoration: "underline", color: "#80C606", fontSize: "26px", marginBottom: "40px"}}>Evolución Huella de agua por sectores de riego (l/kg)</h3>
                <div style={{minHeight: "600px"}}>
                  <Bar data={chartInfo} options={options} />
                </div>
              </div>
          </>
        }
        {!chartInfo &&
          <p>No existen sectores con huellas de agua creadas para mostrar en el <b>gráfico de evolución de huella de agua por sectores de riego (l/kg).</b></p>
        }
      </>
    );
}

export default BarChartHASectoresEvolucion;