import { Box, CircularProgress, FormControl, FormControlLabel, FormLabel, Input, InputLabel, Radio, RadioGroup, Stack } from "@mui/material";
import { useState } from "react";
import { instanceEST } from "../../utils/axios-config";
import ModalInfo from "../ModalInfo";
import DoneIcon from '@mui/icons-material/Done';


const FormInicioSectorComponent = ({position, setPosition, state, setState}) => {

    const [errors, setErrors] = useState({});
    const [subErrors, setSubErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [msgModal, setMsgModal] = useState({
        contentTitle: "",
        contentText: ""
    });

    const handleNext = (e) => {
        e.preventDefault();
        if(validateForm()){
            setPosition(1);
        }
    }

    const handleChangeTipo = (e) => {
        if(e === ""){
            setState({...state, tipoUsuario: e, metodoCalculoPE: ""});
        }else if(e === "Usuario estándar"){
            setState({...state, tipoUsuario: e, metodoCalculoPE: "Porcentaje fijo"});
        }else{
            setState({...state, tipoUsuario: e, metodoCalculoPE: "Método déficit de humedad en el suelo"});
        }
    }

    const handleChangeTipoPlantacion = (e) => {
        if(e === "Secano"){
            setState({...state, tipoPlantacion: e, tipoUsuario: "Usuario estándar", metodoCalculoPE: "Porcentaje fijo"});
        }else{
            setState({...state, tipoPlantacion: e});
        }
    }

    const validateForm = () =>{
        setErrors({});
        let inputIds = "";
        let valid = true;
        if(state.nombre === undefined || state.nombre === ''){
            setErrors(errors => ({...errors, errorNombre: true, errorNombreMsg: ""}));
            inputIds = inputIds ? inputIds +",nombre" : "nombre";
            valid = false;
        }
        if(state.tipoPlantacion === undefined || state.tipoPlantacion === ''){
            setErrors(errors => ({...errors, errorTipoPlantacion: true, errorTipoPlantacionMsg: "Debe elegir el tipo de sector."}));
            inputIds = inputIds ? inputIds +",tipoPlantacion" : "tipoPlantacion";
            valid = false;
        }
        if(state.tipoUsuario === undefined || state.tipoUsuario === ''){
            setErrors(errors => ({...errors, errorTipoUsuario: true, errorTipoUsuarioMsg: "Debe elegir el tipo de usuario."}));
            inputIds = inputIds ? inputIds +",tipoUsuario" : "tipoUsuario";
            valid = false;
        }
        if(state.tipoUsuario === "Usuario 4.0" && (state.verificado === undefined || state.verificado === '' || state.verificado === false)){
            setErrors(errors => ({...errors, errorVerificado: true, errorVerificadoMsg: "Debe verificarse el acceso para el uso de sensores."}));
            inputIds = inputIds ? inputIds +",sensorVerificacion" : "sensorVerificacion";
            valid = false;
        }
        if(inputIds !== ""){
            let elem = document.getElementById(inputIds.split(",")[0]);
            elem.scrollIntoView({behavior: "smooth"});
            elem.focus();
        }
        return valid;
    }

    const validateSubForm = () =>{
        setSubErrors({});
        let inputIds = "";
        let valid = true;
        if(state.usuario === undefined || state.usuario === ''){
            setSubErrors(subErrors => ({...subErrors, errorUsuario: true, errorUsuarioMsg: ""}));
            inputIds = inputIds ? inputIds +",usuario" : "usuario";
            valid = false;
        }
        if(state.clave === undefined || state.clave === ''){
            setSubErrors(subErrors => ({...subErrors, errorClave: true, errorClaveMsg: ""}));
            inputIds = inputIds ? inputIds +",clave" : "clave";
            valid = false;
        }
        if(state.codigoEstacion === undefined || state.codigoEstacion === ''){
            setSubErrors(subErrors => ({...subErrors, errorCodigoEstacion: true, errorCodigoEstacionMsg: ""}));
            inputIds = inputIds ? inputIds +",codigoEstacion" : "codigoEstacion";
            valid = false;
        }
        if(inputIds !== ""){
            let elem = document.getElementById(inputIds.split(",")[0]);
            elem.scrollIntoView({behavior: "smooth"});
            elem.focus();
        }
        return valid;
    }

    const getVerificationData = () => {
        let data = {
            nombre: state.usuario,
            clave: state.clave,
        };
        return data;
    }

    const handleCheckUser = async (e) => {
        e.preventDefault();
        if(validateSubForm()){
            try {
                setLoading(true);
                const data = getVerificationData();
                var token = null;
                //instanceEST.defaults.headers.common = {'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ESTACIONES}`}
                await instanceEST.post(`/usuarios/login`, data).then((resp) => {
                    token = resp.data.auth;
                });
                instanceEST.defaults.headers.common = {'Authentication': `Token ${token}`}
                await instanceEST.get(`/ubicaciones`).then((res) => {
                    var nodeFound = res.data.find((i) => i.nodo.numeroSerie === state.codigoEstacion);
                    setLoading(false);
                    if(!nodeFound?.id){
                        throw new Error('ID de estación no encontrada.');
                    }else{
                        setState({...state, verificado: true, idEstacion: nodeFound?.id});
                    }
                });
            } catch (error) {
                if(error?.response?.status === 404 && error?.response?.data.msg === "Datos de acceso no válidos"){
                    setMsgModal({contentTitle: "¡Vaya! La verificación ha fallado.", contentText: "Los datos de acceso proporcionados no son válidos. Por favor, vuelve a intentarlo."});
                }else{
                    setMsgModal({contentTitle: "¡Vaya! Parece que algo ha ido mal.", contentText: "Algo ha fallado al intentar verificar el acceso a los sensores. Por favor, vuelve a intentarlo más tarde."});
                }
                setOpen(true);
                setLoading(false);
            }
        }
    }

    const changeCheckUser = () => {
        setState({...state, verificado: false});
    }

    return(
        <>
            <Box className={position !== 0 && 'hide'}>
            <h1>DATOS SECTOR</h1>
                <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                    <InputLabel htmlFor="nombre">Nombre*</InputLabel>
                    <Input
                        id="nombre"
                        className="t4b-finca-sector-inputs"
                        label="Nombre del Sector"
                        value={state.nombre}
                        onChange={(e) => setState({...state, nombre: e.target.value})}
                        required
                        name="nombre"
                        error={errors.errorNombre}
                    />
                    {errors.errorNombreMsg && <div className="errorInput">{errors.errorNombreMsg}</div>}
                </FormControl>
                <FormControl sx={{width: "100%", marginBottom: "20px"}} className="radio-green" variant="standard">
                    <FormLabel htmlFor="tipoPlantacion">Sector secano o riego*</FormLabel>
                    <RadioGroup
                        name="tipoPlantacion"
                        id="tipoPlantacion"
                        value={state.tipoPlantacion}
                        onChange={(e) => handleChangeTipoPlantacion(e.target.value)}
                        error={errors.errorTipoPlantacion ? "true" : ""}
                    >
                        <FormControlLabel value="Regadío" control={<Radio />} label="Riego" />
                        <FormControlLabel value="Secano" control={<Radio />} label="Secano" />
                    </RadioGroup>
                    {errors.errorTipoPlantacionMsg && <div className="errorMsg">{errors.errorTipoPlantacionMsg}</div>}
                </FormControl>
                <FormControl sx={{width: "100%", marginBottom: "20px"}} className="radio-green" variant="standard">
                    <FormLabel htmlFor="tipoUsuario">Tipo de usuario*</FormLabel>
                    <RadioGroup
                        name="tipoUsuario"
                        id="tipoUsuario"
                        value={state.tipoUsuario}
                        onChange={(e) => handleChangeTipo(e.target.value)}
                        error={errors.errorTipoUsuario ? "true" : ""}
                    >
                        <FormControlLabel value="Usuario estándar" control={<Radio />} label="Usuario estándar" />
                        <FormControlLabel disabled={state.tipoPlantacion === "Secano" ? true : false} value="Usuario 4.0" control={<Radio />} label="Usuario 4.0" />
                    </RadioGroup>
                    {errors.errorTipoUsuarioMsg && <div className="errorMsg">{errors.errorTipoUsuarioMsg}</div>}
                </FormControl>
                {state.tipoUsuario === "Usuario 4.0" && (
                    <div className="t4b-subform">
                        <h2>Datos de acceso uso de sensores</h2>
                        {!state.verificado &&
                            <>
                                {loading ?
                                    <Box className="loading-verification">
                                        <p>Verificando...</p>
                                        <CircularProgress />
                                    </Box>
                                    :
                                    <>
                                        <div id="sensorVerificacion" className="errorMsg" style={{marginBottom: "20px"}}>{errors.errorVerificadoMsg && errors.errorVerificadoMsg}</div>
                                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                            <InputLabel htmlFor="usuario">Usuario*</InputLabel>
                                            <Input
                                                id="usuario"
                                                className="t4b-finca-sector-inputs"
                                                label="Usuario"
                                                value={state.usuario}
                                                onChange={(e) => setState({...state, usuario: e.target.value})}
                                                name="usuario"
                                                error={subErrors.errorUsuario}
                                            />
                                            {subErrors.errorUsuarioMsg && <div className="errorInput">{subErrors.errorUsuarioMsg}</div>}
                                        </FormControl>
                                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                            <InputLabel htmlFor="clave">Clave*</InputLabel>
                                            <Input
                                                id="clave"
                                                className="t4b-finca-sector-inputs"
                                                label="Clave"
                                                value={state.clave}
                                                onChange={(e) => setState({...state, clave: e.target.value})}
                                                name="clave"
                                                error={subErrors.errorClave}
                                            />
                                            {subErrors.errorClaveMsg && <div className="errorInput">{subErrors.errorClaveMsg}</div>}
                                        </FormControl>
                                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                            <InputLabel htmlFor="codigoEstacion">Código de estación*</InputLabel>
                                            <Input
                                                id="codigoEstacion"
                                                className="t4b-finca-sector-inputs"
                                                label="Código de estación"
                                                value={state.codigoEstacion}
                                                onChange={(e) => setState({...state, codigoEstacion: e.target.value})}
                                                name="codigoEstacion"
                                                error={subErrors.errorCodigoEstacion}
                                            />
                                            {subErrors.errorCodigoEstacionMsg && <div className="errorInput">{subErrors.errorCodigoEstacionMsg}</div>}
                                        </FormControl>
                                        <Stack className="tic4bio-sectores-btns right">
                                            <button className="btn green" onClick={(e) => handleCheckUser(e)}>Verificar</button>
                                        </Stack>
                                    </>
                                }
                            </>
                        }
                        {state.verificado &&
                            <>
                                <Box className="loading-verification">
                                    <p style={{fontWeight: "bold"}}>VERIFICADO</p>
                                    <DoneIcon style={{color: "green", fontSize: "50px"}}/>

                                    <Stack className="tic4bio-sectores-btns right">
                                        <button className="btn green" onClick={(e) => changeCheckUser(e)}>Cambiar credenciales de acceso</button>
                                    </Stack>
                                </Box>
                            </>
                        }
                    </div>
                )}
                
                <Stack className="tic4bio-sectores-btns right">
                    <button className="btn green" disabled={loading ? true : false} onClick={(e) => handleNext(e)}>Siguiente</button>
                </Stack>
            </Box>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={msgModal.contentTitle} contentText={msgModal.contentText}/>
        </>
    );
}
export default FormInicioSectorComponent;