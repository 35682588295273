export const CONFIG = {
    pages: [
        { slug: 'home', title: 'Home', breadcrumbs: [{ title: 'Inicio' }] },
        { slug: 'unidadproductiva', title: 'Unidad Productiva', breadcrumbs: [{ title: 'Unidad Productiva', to: '/home' }] },
        { slug: 'listafincas', title: 'ListaFincas', breadcrumbs: [{ title: 'Mis Fincas', to: '/home' }] },
        { slug: 'listaindustrias', title: 'ListaIndustrias', breadcrumbs: [{ title: 'Mis Industrias', to: '/home' }] },
        { slug: 'nuevafinca', title: 'Añadir finca', breadcrumbs: [{ title: 'Añadir finca', to: -1 }] },
        { slug: 'nuevaunidadfinca', title: 'Añadir finca', breadcrumbs: [{ title: 'Añadir finca', to: -1 }] },
        { slug: 'nuevaunidadindustria', title: 'Añadir industria', breadcrumbs: [{ title: 'Añadir industria', to: -1 }] },
        { slug: 'detallesfinca', title: 'Detalles', breadcrumbs: [{ title: 'Detalles', to: -1 }] },
        { slug: 'listasectores', title: 'Lista de Sectores', breadcrumbs: [{ title: 'Mis Sectores', to: "/detallesFinca", id: true }] },
        { slug: 'nuevosector', title: 'Añadir sector', breadcrumbs: [{ title: 'Añadir Sector', to: '/listaSectores', id: true }] },
        { slug: 'sector', title: 'Detalles del Sector', breadcrumbs: [{ title: 'Detalles del Sector', to: -1 }] },
        { slug: 'prediccion', title: 'Predicción Climática', breadcrumbs: [{ title: 'Predicción Climática', to: -1 }] },
        { slug: 'prediccionclimatica', title: 'Predicción Climática', breadcrumbs: [{ title: 'Predicción Climática', to: -1 }] },
        { slug: 'nuevaunidadproductiva', title: 'Nueva Unidad Productiva', breadcrumbs: [{title: 'Nueva Unidad Productiva', to: '/home'}]},
        { slug: 'anadirriego', title: 'Añadir riego manual', breadcrumbs: [{title: 'Añadir riego manual', to: -1}]},
        { slug: 'programacionderiego', title: 'Programación de Riego', breadcrumbs: [{ title: 'Programación de Riego', to: -1 }] },
        { slug: 'huellaagua', title: 'Huella de Agua', breadcrumbs: [{ title: 'Huella de agua', to: -1 }] },
        { slug: 'nuevahuella', title: 'Nueva Huella de Agua', breadcrumbs: [{ title: 'Nueva Huella de agua', to: -1 }] },
        { slug: 'huellaslist', title: 'Mis Huellas de Agua', breadcrumbs: [{ title: 'Mis Huellas de agua', to: -1 }] },
        { slug: 'huellacultivos', title: 'Huella Cultivos', breadcrumbs: [{ title: 'Huella Cultivos', to: -1 }] },
        { slug: 'huellasalmazaralist', title: 'Mis huellas de agua', breadcrumbs: [{ title: 'Mis Huellas de agua', to: -1 }] },
        { slug: 'huellaalmazaras', title: 'Huella Almazaras', breadcrumbs: [{ title: 'Huella Almazaras', to: -1 }] },
        { slug: 'huellaunidades', title: 'Huella Unidades', breadcrumbs: [{ title: 'Huella Unidades', to: -1 }] },
        { slug: 'biodiversidad', title: 'Mi biodiversidad', breadcrumbs: [{ title: 'Mi biodiversidad', to: -1 }] },
        { slug: 'comunidades', title: 'Comunidades Vegetales', breadcrumbs: [{ title: 'Comunidades Vegetales', to: -1 }] },
        { slug: 'biovegetal', title: 'Nueva Biodiversidad Vegetal', breadcrumbs: [{ title: 'Nueva Biodiversidad Vegetal', to: -1 }] },
        { slug: 'biovegetaleditar', title: 'Editar Biodiversidad Vegetal', breadcrumbs: [{ title: 'Editar Biodiversidad Vegetal', to: -1 }] },
        { slug: 'biovegetaldetalles', title: 'Detalle Biodiversidad Vegetal', breadcrumbs: [{ title: 'Detalle Biodiversidad Vegetal', to: -1 }] },
        { slug: 'bioinvertebrados', title: 'Nueva Biodiversidad Invertebrados', breadcrumbs: [{ title: 'Nueva Biodiversidad Invertebrados', to: -1 }] },
        { slug: 'bioinvertebradoseditar', title: 'Editar Biodiversidad Invertebrados', breadcrumbs: [{ title: 'Editar Biodiversidad Invertebrados', to: -1 }] },
        { slug: 'bioinvertebradosdetalles', title: 'Detalle Biodiversidad Invertebrados', breadcrumbs: [{ title: 'Detalle Biodiversidad Invertebrados', to: -1 }] },
        { slug: 'biovertebrados', title: 'Nueva Biodiversidad Vertebrados', breadcrumbs: [{ title: 'Nueva Biodiversidad Vertebrados', to: -1 }] },
        { slug: 'biovertebradoseditar', title: 'Editar Biodiversidad Vertebrados', breadcrumbs: [{ title: 'Editar Biodiversidad Vertebrados', to: -1 }] },
        { slug: 'biovertebradosdetalles', title: 'Detalle Biodiversidad Vertebrados', breadcrumbs: [{ title: 'Detalle Biodiversidad Vertebrados', to: -1 }] },
        { slug: 'evolucion', title: 'Evolución', breadcrumbs: [{ title: 'Evolución', to: -1 }] },
        { slug: 'avanzado', title: 'Avanzado', breadcrumbs: [{ title: 'Avanzado', to: -1 }] },
    ]
}

//Example
// { slug: 'home', title: 'Home', breadcrumbs: [{ title: 'Inicio', to: '/home' }, {title: 'otra pagina'}] }