import {Box, FormControl, Input, InputAdornment, InputLabel} from '@mui/material';
import { useState } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Link, useNavigate } from 'react-router-dom';
import LoadingBackdrop from '../components/LoadingBackdrop';
import logotic4bio from '../assets/lateralmenu/logoheadbio.png';
import logoue from '../assets/logo-UE.png';
import logojunta from '../assets/logo-juntaAndalucia.png';
import Footer from '../components/footer/Footer';
import HeaderOutNavLogin from '../components/headerOutNavLogin';
import { instance } from "../utils/axios-config";
import ModalInfo from '../components/ModalInfo';

const RestorePasswordPage = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState({
        username: ''
      });
    const [errors, setErrors] = useState({});
    const [errorSend, setErrorSend] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        setErrors({});
        if(validateForm()){
            sendForm();
        }
    }

    const validateForm = () =>{
        let valid = true;
        if(formData.username === undefined || formData.username === ''){
            setErrors(errors => ({...errors, errorUsername: true, errorUsernameMsg: "El email no debe estar vacío"}));
            valid = false;
        }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.username)){
            setErrors(errors => ({...errors, errorUsername: true, errorUsernameMsg: "El email no es válido"}));
            valid = false;
        }
        return valid;
    }

    const sendForm = async () => {
        setLoading(true);
        try {
            await instance.post(`/users/restorePasswordRequest/`, formData).then((res) => {
                navigate("/restaurarPassword?email=" + formData.username);
            }).catch( err => {
                if(err.response.data.message.message === "No user find"){
                    setErrorSend("Error en la petición. No se ha encontrado coincidencia con el usuario introducido. Revisa el correo introducido.");
                }else{
                    setErrorSend("Error en la petición. Vuelve a intentarlo más tarde.");
                }
                setOpen(true);
                setLoading(false);
            });
        } catch (error) {
            console.warn(error);
            setErrorSend("Error en la petición. Vuelve a intentarlo más tarde.");
            setOpen(true);
            setLoading(false);
        }
    };

    return(
        <div className="welcome-main">
            <div className="welcome-background" >
                <div className="welcome-logos">
                    <div className="welcome-logos-top">
                        <img className="welcome-logo-tic4bio" src={logotic4bio} alt={"Imagen Logo Tic4Bio"}/>
                    </div>
                    <div className="welcome-logos-bottom">
                        <Box className="financian-text">FINANCIAN:</Box>
                        <div className="welcome-logos-bottom-images">
                            <img className="welcome-logo-ue" src={logoue} alt={"Imagen Logo Tic4Bio"}/>
                            <img className="welcome-logo-junta" src={logojunta} alt={"Imagen Logo Tic4Bio"}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="welcome-container">
                <HeaderOutNavLogin text={"¿Has olvidado tu contraseña?"} url={"/login"}/>
                <div className="welcome-body">
                    <h2 style={{marginBottom: "40px"}}>Ingrese su dirección de correo electrónico para recibir un correo con un código de reestablecimiento.</h2>
                    <Box component="form" onSubmit={handleSubmit} noValidate>
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                            <InputLabel htmlFor="email">Correo electrónico*</InputLabel>
                            <Input
                                id="email"
                                className=""
                                label="Correo electrónico"
                                required
                                name="email"
                                onChange={(e) => {
                                    setFormData({
                                        username: e.target.value,
                                    });
                                }}
                                value={formData.username}
                                startAdornment={
                                <InputAdornment position="start">
                                    <MailOutlineIcon />
                                </InputAdornment>
                                }
                            />
                            {errors.errorUsernameMsg && <div className="errorInput">{errors.errorUsernameMsg}</div>}
                        </FormControl>
                        <div className='login-buttons'>
                            
                            <button className="btn green" type="submit" >
                                Restablecer la contraseña
                            </button>
                            <div className="recover-code">
                                <Link to="/restaurarPassword">Ya tengo un código</Link>
                            </div>
                            
                        </div>
                    </Box>
                </div>
            </div>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={"Error"} contentText={errorSend}/>
            <Footer />
            <LoadingBackdrop loading={loading} />
        </div>
    );
}

export default RestorePasswordPage;