import { Box } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useNavigate } from "react-router-dom";

const HeaderOutNavLogin = ({text, url}) => {

    const navigate = useNavigate();

    const navigateBack = () => {
        if(url !== null && url !== undefined){
            navigate(url);
        }else{
            navigate("/");
        }
        
    }

    return ( 
        <div className="header-out-nav-login">
            <Box className="header-out-nav-flex">
                <ArrowBackIosNewIcon className="header-out-nav-arrow" onClick={navigateBack}/>
                <h1 className="header-out-nav-title">
                    {text}
                </h1>
            </Box>
        </div>
    );
}
 
export default HeaderOutNavLogin;