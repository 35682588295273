import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { MobileStepper } from '@mui/material';

const stepsFull = [
    'Campaña',
    'Consumo agua',
    'Agua de limpieza',
    'Agua de limpieza 2'
];

const stepsShort = [
    'Campaña',
    'Consumo agua',
    'Agua de limpieza'
];

const stepsShort2 = [
    'Campaña',
    'Consumo agua'
];

/* 
NOTAS

Si se selecciona secano --> Es siempre usuario estandar y sólo hay que introducir el los datos del paso 1 (informacion del cultivo)

Al marcar el usuario 4.0 hay que introducir algunos datos (usuario, clave y codigo de estacion) y comprobarlos y si todo ha ido bien se pasa al siguiente paso
En la información del suelo se elimina el estado inicial de humedad
Y en el último paso hay un input nuevo que es la distancia del contador al final del ramal
*/

const StepperHuellaAlmazaraComponent = ({position, setPosition, state}) => {

    //const [steps, setSteps] = useState(state.tipoPlantacion === "Secano" ? stepsShort : stepsFull);
    var steps = (state.aguaDeLimpieza === "4" || state.conoceCaudal === "false") ? stepsShort : stepsFull;

    return(
        <Box sx={{ width: '100%', marginTop: '40px' }}>
            <Stepper className={steps.length > 2 ? "stepper-dsk" : ""} activeStep={position} alternativeLabel>
                {steps.map((label, i) => (
                <Step key={label} onClick={() => setPosition(i)} sx={{
                    '& .MuiStepLabel-root .Mui-completed': {
                      color: '#80C606', // circle color (COMPLETED)
                    },
                    '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel':
                      {
                        color: 'grey.500', // Just text label (COMPLETED)
                      },
                    '& .MuiStepLabel-root .Mui-active': {
                      color: '#80C606', // circle color (ACTIVE)
                    },
                    '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel':
                      {
                        color: 'rgba(0, 0, 0, 0.87)', // Just text label (ACTIVE)
                      },
                    '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                      fill: 'white', // circle's number (ACTIVE)
                    },
                  }}>
                    <StepLabel>{label}</StepLabel>
                </Step>
                ))}
            </Stepper>
            <MobileStepper className={steps.length <= 2 ? "hide" : "stepper-mv"}
            variant="dots"
            steps={steps.length}
            activeStep={position}
            position='static'
            sx={{
              '&.MuiMobileStepper-root .MuiMobileStepper-dots': {
                width: '100%',
                justifyContent: 'space-between',
              },
              '&.MuiMobileStepper-root':
                {
                  padding: '0',
                  margin: '20px 0 40px 0',
                },
              '&.MuiMobileStepper-root .MuiMobileStepper-dot.MuiMobileStepper-dotActive': {
                backgroundColor: '#80C606',
              },
              '&.MuiMobileStepper-root .MuiMobileStepper-dot':{
                width: '15px',
                height: '15px',
              },
            }}
            >

            </MobileStepper>
          </Box>
    );
}
export default StepperHuellaAlmazaraComponent;