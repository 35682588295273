import { Autocomplete, Box, FormControl, IconButton, TextField } from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import { Fragment, useEffect } from "react";
import { checkDoublePattern, checkIntPattern } from "../../utils/commons";

const TableAdvancedEditSamplingComponent = ({stateForm, setStateForm, tipoMuestreo, errors }) => {

    useEffect(() => {
        if(stateForm.muestreos.length === 0){
            setStateForm(prevData => ({
                ...prevData,
                muestreos: []
            }));
        }
    }, []);

    const handleAddColumn = () => {
        let nuevaColumna = {
            nuevo: true,
            tempId: "tempId-" + new Date().getTime(),
            longitudRecorrido: "",
            tipoMuestreo: tipoMuestreo,
            muestras: []
        };

        setStateForm(prevData => {
            const newData = prevData.muestreos.map(item => {
                if(item.tipoMuestreo === tipoMuestreo){
                    item.muestreos.push(nuevaColumna);
                }
                return item;
            });
            return {...prevData, muestreos: newData};
        });
    }

    const handleAddEspecie = (muestreoTo) => {
        let nuevaEspecie = {
            nombre: "Nueva Especie",
            nuevo: true,
            num_individuos: 1,
            tempId: "tempId-" + new Date().getTime()
        }
        setStateForm(prevData => {
            const newData = prevData.muestreos.map(item => {
                if (item.tipoMuestreo === tipoMuestreo) {
                    
                    return {
                        tipoMuestreo: item.tipoMuestreo,
                        muestreos: item.muestreos.map(muestreo => {
                            if (muestreo.tempId === muestreoTo.tempId) {
                                return {...muestreo, muestras: [...muestreo.muestras, nuevaEspecie]};
                            }
                            return muestreo;
                        })
                    };
                    
                } else {
                    return item;
                }
            });
            return {...prevData, muestreos: newData};
        })
    }

    const deleteMuestreo = (e) => {
        let newData = stateForm.muestreos.map(item => {
            if (item.tipoMuestreo === tipoMuestreo) {
                let muestreos = item.muestreos.map((muestreo) => {
                    return{
                        ...muestreo,
                        muestras: muestreo.muestras.filter((muestra) => {
                            if (e?.tempId && muestra.tempId === e.tempId && muestra.id) {
                                muestra.eliminar = true;
                            }
                            return (muestra.id !== undefined || muestra.tempId !== e.tempId);
                        })
                    }
                });
                const newItem = {
                    ...item,
                    muestreos: muestreos
                };
                return newItem;
            }else{
                return item;
            }
        });
        setStateForm(prevData => ({
            ...prevData,
            muestreos: newData
        }));
    }

    const handleDeleteColumn = (muestreoTo) => {
        setStateForm(prevData => {
            const newData = prevData.muestreos.map(item => {
              if (item.tipoMuestreo === tipoMuestreo) {
                let muestreos = item.muestreos.filter((muestreo) => {
                    if(muestreo.id === muestreoTo.id && muestreo?.id !== undefined){
                        muestreo.eliminar = true;
                    }
                    return (!muestreo.id && !muestreoTo.id && !muestreo.tempId && !muestreoTo.tempId) ||
                    ((muestreo.id !== muestreoTo.id || !muestreo.id) && (muestreo.tempId !== muestreoTo.tempId || !muestreo.tempId)) || (muestreo?.eliminar === true);
                });
                const newItem = {
                    ...item,
                    muestreos: muestreos
                };
                return newItem;
              }else{
                return item;
              }
            });
            return { ...prevData, muestreos: newData };
          });
    }

    const handleChangeNombre = (e, id) => {
        setStateForm(prevData => {
            return {
                ...prevData,
                muestreos: prevData.muestreos.map(item => {
                    if (item.tipoMuestreo === tipoMuestreo) {
                        return {
                            ...item,
                            muestreos: item.muestreos.map(muestreo => {
                                return {
                                    ...muestreo,
                                    muestras: muestreo.muestras.map(muestra => {
                                        if (muestra.tempId === id) {
                                            muestra.nombre = e;
                                        }
                                        return muestra;
                                    })
                                };
                            })
                        };
                    }
                    return item;
                })
            };
        });
    }

    const handleChangeNI = (e, id) => {
        if(checkIntPattern(e)){
            setStateForm(prevData => {
                return {
                    ...prevData,
                    muestreos: prevData.muestreos.map(item => {
                        if (item.tipoMuestreo === tipoMuestreo) {
                            return {
                                ...item,
                                muestreos: item.muestreos.map(muestreo => {
                                    return {
                                        ...muestreo,
                                        muestras: muestreo.muestras.map(muestra => {
                                            if (muestra.tempId === id) {
                                                muestra.num_individuos = e;
                                            }
                                            return muestra;
                                        })
                                    };
                                })
                            };
                        }
                        return item;
                    })
                };
            });
        }
    }

    const handleChangeDistancia = (e, id) => {
        if(checkDoublePattern(e)){
            setStateForm(prevData => {
                return {
                    ...prevData,
                    muestreos: prevData.muestreos.map(item => {
                        if (item.tipoMuestreo === tipoMuestreo) {
                            return {
                                ...item,
                                muestreos: item.muestreos.map(muestreo => {
                                    if (muestreo.tempId === id) {
                                        return {
                                            ...muestreo,
                                            longitudRecorrido: e
                                        };
                                    }
                                    return muestreo;
                                })
                            };
                        }
                        return item;
                    })
                };
            });
        }
    }

    return(
        <div className="tabla-bio-container advanced">
             
             {stateForm.muestreos.find(muestreos => muestreos.tipoMuestreo === tipoMuestreo)?.muestreos.filter(muestreo => !muestreo.eliminar).map((muestreo, index) => (
                    <table className="tabla-bio" key={"tabla-muestreo-" + tipoMuestreo + "-" + index}>
                        <thead>
                            <tr style={{ display: index === 0 ? "table-row" : ""}}>
                                <th>
                                    Itinerario {index + 1}
                                </th>
                                <th>
                                    Nº de ind.
                                </th>
                                {index !== 0 ?
                                    <th>
                                        <IconButton
                                        size="large"
                                        edge="start"
                                        className="delete"
                                        aria-label="Eliminar Muestreo"
                                        style={{color: "#fa0000"}}
                                        onClick={() => {handleDeleteColumn(muestreo)}}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </th>
                                    :
                                    <th></th>
                                }
                            </tr>
                            <tr>
                                <th colSpan="3" style={{borderTop: "1px solid #80C606"}}>
                                    <FormControl sx={{width: "100%"}} variant="standard">
                                        <TextField
                                            className="t4b-finca-sector-inputs no-red-label"
                                            label="Distancia (km)*"
                                            id={"distancia-" + tipoMuestreo + "-" + index}
                                            value={muestreo?.longitudRecorrido}
                                            type="number"
                                            variant="standard"
                                            InputProps={{
                                                inputProps: { 
                                                    max: 99999, min: 1,
                                                }
                                            }}
                                            onChange={(e) => {handleChangeDistancia(e.target.value, muestreo?.tempId)}}
                                            name="distancia"
                                            error={errors["errorDistancia-" + tipoMuestreo + "-" + index + "-Distancia"]}
                                        />
                                        {errors["errorDistancia-" + tipoMuestreo + "-" + index + "-DistanciaMsg"] && <div className="errorMsg">{errors["errorDistancia"  + tipoMuestreo + "-" + index + "-DistanciaMsg"]}</div>}
                                    </FormControl>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {muestreo.muestras?.map((especimen, j) => (
                                <Fragment key={"muestreo-" + tipoMuestreo + "-" + index + "-" + j}>
                                    {especimen?.eliminar !== true && 
                                        <tr>
                                            <td>
                                                <FormControl sx={{width: "100%"}} variant="standard">
                                                    {/*<TextField
                                                        className="t4b-finca-sector-inputs no-red-label"
                                                        label="Nombre de especie*"
                                                        id={"especieNombre-" + tipoMuestreo + "-" + index + "-" + j}
                                                        value={especimen?.nombre}
                                                        variant="standard"
                                                        onChange={(e) => {handleChangeNombre(e.target.value, especimen?.tempId)}}
                                                        name="especieNombre"
                                                        error={errors["errorEspecie-" + tipoMuestreo + "-" + index + "-" + j +  "Nombre"]}
                                                    />
                                                    {errors["errorEspecie-" + tipoMuestreo + "-" + index + "-" + j + "NombreMsg"] && <div className="errorMsg">{errors["errorEspecie-"  + tipoMuestreo + "-" + index + "-" + j + "NombreMsg"]}</div>}*/}
                                                    <Autocomplete
                                                        freeSolo
                                                        options={stateForm.diccionario}
                                                        renderOption={(props, option) => {
                                                            return <Box element="li" sx={{ display: 'flex', alignItems: 'center' }} {...props}> 
                                                                {option}
                                                            </Box>
                                                        }}
                                                        className="t4b-finca-inputs"
                                                        getOptionLabel={(option) => (
                                                            typeof option === "string" ? option : option.label
                                                        )}
                                                        id={"especieNombre-" + tipoMuestreo + "-" + index + "-" + j}
                                                        onInputChange={(e, newValue) => {
                                                            handleChangeNombre(newValue, especimen?.tempId)
                                                        }}
                                                        value={especimen?.nombre || ""}
                                                        renderInput={(params) => (
                                                            <>
                                                            <TextField {...params} label="Nombre de especie*" variant="standard" />
                                                            </>
                                                        )}
                                                        sx={{
                                                            ...(errors["errorEspecie-" + tipoMuestreo + "-" + index + "-" + j + "Nombre"] && {
                                                                '& .MuiInput-root:before': {
                                                                    borderBottom: "1px solid #d32f2f",
                                                                }
                                                            })
                                                        }}
                                                    />
                                                </FormControl>
                                            </td>
                                            <td>
                                                <FormControl sx={{width: "100%"}} variant="standard">
                                                    <TextField
                                                        className="t4b-finca-sector-inputs no-red-label"
                                                        label="Número de individuos*"
                                                        id={"especieNI-" + tipoMuestreo + "-" + index + "-" + j}
                                                        value={especimen?.num_individuos}
                                                        type="number"
                                                        variant="standard"
                                                        InputProps={{
                                                            inputProps: { 
                                                                max: 99999, min: 1,
                                                            }
                                                        }}
                                                        onChange={(e) => handleChangeNI(e.target.value, especimen?.tempId)}
                                                        name="especieNI"
                                                        error={errors["errorEspecie-" + tipoMuestreo + "-" + index + "-" + j +  "NI"]}
                                                    />
                                                    {errors["errorEspecie-" + tipoMuestreo + "-" + index + "-" + j + "NIMsg"] && <div className="errorMsg">{errors["errorEspecie-"  + tipoMuestreo + "-" + index + "-" + j + "NIMsg"]}</div>}
                                                </FormControl>
                                            </td>
                                            <td>
                                                <IconButton
                                                size="large"
                                                edge="start"
                                                className="delete"
                                                aria-label="Eliminar especie"
                                                style={{color: "#fa0000"}}
                                                onClick={() => {deleteMuestreo(especimen)}}
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </td>
                                        </tr>
                                    }
                                </Fragment>
                            ))}
                            <tr>
                                <td colSpan="3" className="tabla-bio-add">
                                    <IconButton
                                        size="large"
                                        edge="start"
                                        className="delete"
                                        aria-label="Añadir Especie"
                                        style={{color: "#80C606"}}
                                        onClick={() => {handleAddEspecie(muestreo)}}
                                        >
                                            <AddCircleIcon />
                                    </IconButton>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                ))}
                <table className="tabla-bio">
                    <thead>
                        <tr style={{display: "table-row"}}>
                            <th colSpan="3">
                                <IconButton
                                    size="large"
                                    edge="start"
                                    className="delete"
                                    aria-label="Añadir Muestreo"
                                    style={{color: "#80C606"}}
                                    onClick={() => {handleAddColumn()}}
                                    >
                                        <AddCircleIcon />
                                </IconButton>
                            </th>
                        </tr>
                    </thead>
                </table>
            
        </div>
    )
}

export default TableAdvancedEditSamplingComponent;