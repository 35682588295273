import { Stack, Pagination } from "@mui/material";
import { useState } from "react";

export default function PPagination({setLoading, loadData, totalPages}){

    const [page, setPage] = useState(1);

    const handlePageChange = (event, page) => {
        setLoading(true);
        setPage(page);
        loadData(page);
    };

    return(
        <Stack spacing={6} className="pagination">
            <Pagination count={totalPages} page={page} onChange={handlePageChange}/>
        </Stack>
    );
}