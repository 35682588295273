import { Link, useNavigate } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { instance } from '../utils/axios-config';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import ModalInfo from '../components/ModalInfo';
import ActionMessage from '../components/ActionMessage';
import { useAuth } from '../context/Auth';
import DeleteIcon from '@mui/icons-material/Delete';
import { useLocation } from "react-router-dom";
import ModalConfirm from '../components/ModalConfirm';
import PPagination from '../components/Pagination';
import { groupByUnidad } from '../utils/commons';

const HomePage = () => {

    const { currentUser } = useAuth();
    const {state} = useLocation();

    const [ msg, setMsg ] = useState( state ? state : undefined);
    const [ unidades, setUnidades ] = useState([]);
    //const [ totalPages, setTotalPages ] = useState(1);
    const [ loading, setLoading ] = useState(true);
    const [ open, setOpen ] = useState(false);
    const [ openConfirm, setOpenConfirm ] = useState({
        state: false,
        finca: null
    });
    const [ error, setError ] = useState(false);
    const [ errorConfirm, setErrorConfirm ] = useState(false);

    useEffect(() => {
        document.title = "Mis Unidades Productivas";
        loadUnidadesData();
    }, []);

    const openModalConfirm = (unidad) => {
        setOpenConfirm({state: true, finca: {id: unidad}});
    }

    const loadUnidadesData = async (startPage = 1) => {
        let buildUrl = `/fincas/unidades`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
        
            await instance.get(buildUrl).then(function(resp) {
                //let groupUnidades = groupByUnidad(resp.data.unidades);
                //setTotalPages(resp.data.pages !== 0 ? resp.data.pages : 1);
                //setUnidades(Object.entries(groupUnidades));

                //setTotalPages(Object.keys(groupUnidades).length);
                let groupUnidades = groupByUnidad(resp.data);
                setUnidades(Object.entries(groupUnidades));
            }).catch(function(resp) {
                setOpen(true);
                setError(true);
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setOpen(true);
        }
    } 

    const deleteUnidad = async (name) => {
        let buildUrl = `/fincas/unidad/delete/${name}`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${window.localStorage.getItem("token")}`}
        
            await instance.delete(buildUrl).then(function(resp) {
                setMsg({type: "action-delete", text: "Se ha eliminado la unidad productiva correctamente."});
                setUnidades(unidades.filter((f) => {return f[0] !== name}));
            }).catch(function(resp) {
                setErrorConfirm(true);
            }).finally(()=>{

            });
        }catch(error){
            setErrorConfirm(true);
        }
    }

    return(
        <>
            <div className="tic4bio-home">
                {msg &&
                    <ActionMessage msg={msg} setMsg={setMsg}/>
                }
                <h1 className="tic4bio-home-title mv">¡Bienvenido {currentUser.nombre}!</h1>
                <div className="button green" style={{maxWidth: "400px"}}>
                    <Link to="/nuevaUnidadProductiva"><AddCircleOutlineIcon className="add"/>UNIDAD PRODUCTIVA</Link>
                </div>
                <div>
                    <h1 className="tic4bio-home-title unidades">Mis Unidades Produtivas:</h1>
                </div>
                <ul className="tic4bio-unidades">
                    {loading ? 
                        (
                            <Box p={3} align="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                {unidades.length > 0 ? (
                                     <>
                                        {unidades.map((unidad, index) => {
                                            if(unidad[0] !== null){
                                                return (
                                                    <Fragment key={"unidades-" + index}>
                                                        <li className="tic4bio-unidad">
                                                            <Link className="details" to={"/unidadProductiva/" + unidad[0]}>
                                                                <div>
                                                                    <p className="details-provincia">{unidad[0]}</p>
                                                                    {unidad[1].map((us, i) => {
                                                                        if(us.dataType === "finca"){
                                                                            return(
                                                                                <Fragment key={"us-" + index + "-" + i}>
                                                                                    <p className="details-nombre">Finca: <span className="details-dotacion">{us.nombreFinca}</span></p>
                                                                                </Fragment>
                                                                            )
                                                                        }else if(us.dataType === "industria"){
                                                                            return(
                                                                                <Fragment key={"us-" + index + "-" + i}>
                                                                                    <p className="details-nombre">Industria: <span className="details-dotacion">{us.nombreFinca}</span></p>
                                                                                </Fragment>
                                                                            )
                                                                        }
                                                                    })}
                                                                </div>
                                                            </Link>
                                                            <IconButton
                                                                size="large"
                                                                edge="start"
                                                                className="delete"
                                                                aria-label="Eliminar finca"
                                                                style={{color: "#fa0000"}}
                                                                onClick={() => {openModalConfirm(unidad[0])}}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </li>
                                                    </Fragment>
                                                )
                                            } else{
                                                return null;
                                            } 
                                        })}
                                    </>
                                )
                                :
                                (
                                    <>
                                    {!error ?
                                        <p className="tic4bio-unidades-empty">Aún no se ha añadido ninguna unidad.</p>
                                        :
                                        <p className="tic4bio-unidades-empty">Se ha producido un error al recuperar el listado de unidades.</p>
                                    }
                                    </>
                                )
                                }
                            </>
                        )
                    }
                </ul>
                {/*<Box sx={{mb: 6, mt: 4}} align="center">
                    <PPagination setLoading={setLoading} loadData={loadUnidadesData} totalPages={totalPages}/>
                </Box>*/}
            </div>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={"¡Vaya! Parece que algo ha ido mal."} contentText={"Algo ha fallado al intentar recuperar el listado de unidades productivas. Por favor, vuelve a intentarlo más tarde."}/>
            <ModalInfo open={errorConfirm} setOpen={setErrorConfirm} contentTitle={"¡Vaya! Parece que algo ha ido mal."} contentText={"Algo ha fallado al intentar eliminar la unidad productiva. Por favor, vuelve a intentarlo más tarde."}/>
            <ModalConfirm open={openConfirm} setOpen={setOpenConfirm} func={deleteUnidad} contentTitle={"Eliminar " + openConfirm.finca?.id + "."} contentText={"¿Está seguro que desea eliminar esta unidad productiva?"}/>
        </>
    );
}

export default HomePage;