import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

function DoughnutEspeciesComunidad({ data }) {

  ChartJS.register(ArcElement, Tooltip, Legend);

    const options = {
        layout: {
            padding: {
                top: 50,
                bottom: 50,
                left: 100,
                right: 100
            }
        },
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            datalabels: {
              color: '#000',
              formatter: (value, ctx) => {
                let dataLabels = ctx.chart.data.labels;
                let display = dataLabels[ctx.dataIndex] + ": " + value;

                return display;
              },
              font: {
                weight: 'bold',
                size: 18
              }
            }
          }
    };

    return (
      <div style={{maxWidth: "800px", margin: "0 auto", minHeight: "600px"}}>
        <Doughnut
            data={data}
            options={options}
            plugins={[ChartDataLabels]}
        />
      </div>
    );
}

export default DoughnutEspeciesComunidad;