import { Box, CircularProgress, Stack } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import redsquare from "../../assets/Iconos_biodiversidad/redsquare.png";
import bluesquare from "../../assets/Iconos_biodiversidad/bluesquare.png";
import terrain from "../../assets/Iconos_biodiversidad/terrain.jpg";
import tinyterrain from "../../assets/Iconos_biodiversidad/tinyterrain.png";
import trampa1 from "../../assets/Iconos_biodiversidad/trampas1.jpg";
import trampa2 from "../../assets/Iconos_biodiversidad/trampas2.jpg";
import googlelens from "../../assets/Iconos_biodiversidad/googlelens.png";
import TableBasicSamplingComponent from "./TableBasicSampling";
import TableAdvancedSamplingComponent from "./TableAdvancedSampling";
import { useAuth } from "../../context/Auth";
import { instance } from "../../utils/axios-config";


const FormBioStepThreeComponent = ({position, setPosition, stateForm, setStateForm}) => {

    const {sessionToken} = useAuth();

    const [activeDrops, setActiveDrops] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if(stateForm.diccionario.length === 18){
            loadDictionary();
        }
    }, []);

    const loadDictionary = async () => {
        let buildUrl = `/diccionario/invertebrados`;
        try{
            setLoading(true);
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await instance.get(buildUrl).then(function(resp) {
                const elementosAAgregar = resp.data.map(elemento => ({ label: elemento }));
                setStateForm((prevData) => ({...prevData, diccionario: [...prevData.diccionario, ...elementosAAgregar]}));
            }).catch(function(error) {
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setLoading(false);
        }
    }

    const handleClickDrops = (element) => {
        const isActive = activeDrops.includes(element);
        if (isActive) {
          setActiveDrops(activeDrops.filter(id => id !== element));
        } else {
          setActiveDrops([...activeDrops, element]);
        }
    };

    const printNameText = (text) => {
        let res = "";
        switch (text) {
            case "muestreosuperficiesuelo":
                res = "Muestreo superficie de suelo";
                break;
            default:
                res = "Trampas para adultos";
                break;
          }
        return res;
    }

    const validateForm = () =>{
        setErrors({});
        let inputIds = "";
        let valid = true;


        Object.keys(stateForm.muestreos).forEach((key) =>{
            stateForm.muestreos[key].map((muestreo, index) => {
                muestreo.map(((object, j) => {
                    if(object.nombre === "" || object.nombre === undefined){
                        setErrors(errors => ({...errors, ["errorEspecie-" + key + "-" + index + "-" + j + "Nombre"]: true, ["errorEspecie-" + key + "-" + index + "-" + j + "NombreMsg"]: ""}));
                        inputIds = inputIds ? inputIds +(",especieNombre-" + key + "-" + index + "-" + j) : ("especieNombre-" + key + "-" + index + "-" + j);
                        valid = false;
                    }
                    if(object.ni === "" && stateForm.tipoAnalisis !== "1"){
                        setErrors(errors => ({...errors, ["errorEspecie-" + key + "-" + index + "-" + j + "NI"]: true, ["errorEspecie-" + key + "-" + index + "-" + j + "NIMsg"]: ""}));
                        inputIds = inputIds ? inputIds +(",especieNI-" + key + "-" + index + "-" + j) : ("especieNI-" + key + "-" + index + "-" + j);
                        valid = false;
                    }
                }))
            });
        });

        Object.entries(stateForm.metodoMuestreo).forEach(([clave, valor]) => {

            if((stateForm.muestreos[clave] === undefined || stateForm.muestreos[clave][0]?.length === 0) && valor === true){
                setErrors(errors => ({...errors, ["errorMetodoMuestreo-" + clave  + "-Vacio"]: true, ["errorMetodoMuestreo-" + clave + "-VacioMsg"]: "Debe incluir al menos una especie o muestreo"}));
                inputIds = inputIds ? inputIds +(",metodoMuestreoVacio-" + clave ) : ("metodoMuestreoVacio-" + clave);
                valid = false;
            }
        });

        if(inputIds !== ""){
            try{
                let metodoMuestreoList = Object.keys(stateForm.metodoMuestreo).filter(key => {
                    if(stateForm.metodoMuestreo[key] === true){
                        return key;
                    }
                });
                setActiveDrops(metodoMuestreoList);
                let elem = document.getElementById(inputIds.split(",")[0]);
                elem.scrollIntoView({behavior: "smooth"});
                elem.focus();
            }catch(err){

            }
        }
        return valid;
    }

    const handlePrev = (e) => {
        e.preventDefault();
        setPosition(1);
    }

    const handleNext = (e) => {
        e.preventDefault();
        if(validateForm()){
            setPosition(3);
            console.log(stateForm);
        }
    };

    return(
        <>
        <Box className={position !== 2 && 'hide'}>
            {loading ?
                <Box p={3} align="center">
                    <CircularProgress />
                </Box>
                :
                <>
                    <h1 className="tic4bio-home-title">Nueva Biodiversidad Invertebrados</h1>
                    <p style={{color: "rgba(0, 0, 0, 0.6)", margin: "42px 0px"}}>Finca seleccionada: <span style={{color: "#80C606", fontSize: "20px", fontWeight: "700"}}>{stateForm.finca.nombre}</span></p>
                    <p style={{color: "rgba(0, 0, 0, 0.6)", margin: "42px 0px"}}>Comunidad vegetal seleccionada: <span style={{color: "#80C606", fontSize: "20px", fontWeight: "700"}}>{stateForm.comunidad.nombre}</span></p>
                    
                    <p style={{color: "rgba(0, 0, 0, 0.6)", margin: "42px 0px"}}>Protocolos de muestreo según tipo de muestreo:</p>

                    <div>
                        {Object.entries(stateForm.metodoMuestreo).map(([fieldName, fieldValue], index) => {
                            if (fieldValue) {
                                return (
                                    <Fragment key={fieldName + "-" + index} >
                                        <button id={"metodoMuestreoVacio-" + fieldName} className={"dropdown-box green " + (activeDrops.includes(fieldName) ? 'active' : '')} onClick={() => handleClickDrops(fieldName)}>{printNameText(fieldName)}</button>
                                        {(fieldName === "muestreosuperficiesuelo" && activeDrops.includes(fieldName)) &&
                                            <>
                                                
                                                <p>Debe indicar el orden de invertebrados que visualice en el muestreo.</p>
                                                <p>El icono <a href="https://lens.google/intl/es/" target="_blank" style={{verticalAlign: 'sub', display: 'inline-block', height: '20px'}}><img src={googlelens} alt="Icono Google Lens" /></a> le redirigirá a Google Lens, que le proporcionará ayuda para identificar especies a partir de una foto.</p>
                                                <ol>
                                                    <li>Sobre la cubierta o zona de cultivo (<img src={tinyterrain} style={{width: "40px", display: "inline-block", verticalAlign: "middle"}} alt=""/>) lance o establezca un cuadrante de 1 x 1 m (<img src={redsquare} style={{width: "20px", display: "inline-block", verticalAlign: "middle"}} alt=""/>)</li>
                                                    <div style={{position: "relative", display: "inline-block"}}>
                                                        <img src={terrain} style={{maxWidth: "300px", width: "100%"}} alt=""/>
                                                        <img src={redsquare} style={{width: "34px", position: "absolute", top: "40%", left: "36%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                    </div>
                                                    {stateForm.tipoAnalisis === "1" ? 
                                                        <>
                                                            <li>Observe y anote el orden de los invertebrados que aviste. Puede ayudarse de la herramienta Google Lens.</li>
                                                        </>
                                                        :
                                                        <>
                                                            <li>Observe y anote el <b>orden</b> de los invertebrados que aviste, así como <b>el número individuos de cada tipo</b>. Ayúdese de la herramienta Google Lens.</li>
                                                        </>
                                                    }
                                                    <li>Repita el proceso estableciendo 2 o 3 puntos de muestreos adicionales. Distribuya los puntos de muestreo aleatoriamente sobre el terreno y en zonas representativas de la biodiversidad de la finca.</li>
                                                    <div style={{position: "relative", display: "inline-block"}}>
                                                        <img src={terrain} style={{maxWidth: "300px", width: "100%"}} alt=""/>
                                                        <img src={redsquare} style={{width: "22px", position: "absolute", top: "40%", left: "32%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                        <img src={bluesquare} style={{width: "22px", position: "absolute", top: "32%", left: "52%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                        <img src={bluesquare} style={{width: "22px", position: "absolute", top: "50%", left: "48%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                        <img src={bluesquare} style={{width: "22px", position: "absolute", top: "40%", left: "64%", transform: "translate(-50%, -50%)"}} alt=""/>
                                                    </div>
                                                    {stateForm.tipoAnalisis === "1" ? 
                                                        <>
                                                            <li>Anotar el nombre de las NUEVAS órdenes de invertebrados que visualice en cada nuevo cuadrante.</li>
                                                        </>
                                                        :
                                                        <>
                                                            <li>Anotar el nombre de los <b>órdenes de invertebrados y número de individuos de cada tipo</b> que visualice en cada nuevo cuadrante.</li>
                                                        </>
                                                    }
                                                </ol>
                                                {stateForm.tipoAnalisis === "1" ?
                                                    <>
                                                        <TableBasicSamplingComponent stateForm={stateForm} setStateForm={setStateForm} metodoMuestreo={fieldName} errors={errors} />
                                                    </>
                                                    :
                                                    <>
                                                        <TableAdvancedSamplingComponent stateForm={stateForm} setStateForm={setStateForm} metodoMuestreo={fieldName} errors={errors} />
                                                    </>
                                                }
                                            </>
                                        }
                                        {(fieldName === "trampasparaadultos" && activeDrops.includes(fieldName)) &&
                                            <>
                                                
                                                <p>Debe indicar el orden de invertebrados que visualice en el muestreo.</p>
                                                <p>El icono <a href="https://lens.google/intl/es/" target="_blank" style={{verticalAlign: 'sub', display: 'inline-block', height: '20px'}}><img src={googlelens} alt="Icono Google Lens" /></a> le redirigirá a Google Lens, que le proporcionará ayuda para identificar especies a partir de una foto.</p>
                                                {stateForm.tipoAnalisis === "1" ? 
                                                    <>
                                                        <ol>
                                                            <li>Si instala o cuenta con trampas McPhail o cromáticas (instalación conforme a criterios de la Guía de Gestión Integrada de Plagas de su cultivo) puede utilizar estos elementos  para identificar insectos capturados.</li>
                                                            <div className="flex-align-box" style={{justifyContent: "space-around", margin: "20px 0"}}>
                                                                <img src={trampa1} alt=""/>
                                                                <img src={trampa2} alt=""/>
                                                            </div>
                                                            <li>Anotar el nombre de los NUEVOS órdenes de invertebrados que visualice en cada trampeo.</li>
                                                        </ol>
                                                        
                                                        <TableBasicSamplingComponent stateForm={stateForm} setStateForm={setStateForm} metodoMuestreo={fieldName} errors={errors} />
                                                    
                                                    </>
                                                    :
                                                    <>
                                                        <ol>
                                                            <li>Si instala o cuenta con trampas McPhail o cromáticas (instalación conforme a criterios de la Guía de Gestión Integrada de Plagas de su cultivo) puede utilizar estos elementos  para <b>identificar insectos capturados</b> y estimar la proporción y <b>nº de individuos de cada uno.</b></li>
                                                            <div className="flex-align-box" style={{justifyContent: "space-around", margin: "20px 0"}}>
                                                                <img src={trampa1} alt=""/>
                                                                <img src={trampa2} alt=""/>
                                                            </div>
                                                            <li>Anotar el nombre de los órdenes de invertebrados que visualice en cada trampeo y el <b>nº de individuos de cada uno.</b></li>
                                                        </ol>

                                                        <TableAdvancedSamplingComponent stateForm={stateForm} setStateForm={setStateForm} metodoMuestreo={fieldName} errors={errors} />
                                                    
                                                    </>
                                                }
                                            </>
                                        }
                                        {errors["errorMetodoMuestreo-" + fieldName + "-Vacio"] && <p className="errorMsg">{errors["errorMetodoMuestreo-" + fieldName + "-VacioMsg"]}</p>}
                                    </Fragment>
                                );
                            }
                        })}
                    </div>

                    <Stack spacing={2} direction="row" justifyContent="space-between" className="tic4bio-sectores-btns">
                        <button className="btn green" onClick={(e) => handlePrev(e)}>Anterior</button>
                        <button className="btn green" onClick={(e) => handleNext(e)} style={{margin: "16px 0"}}>Siguiente</button>
                    </Stack>
                </>
            }         

        </Box>
        </>
    )
}

export default FormBioStepThreeComponent;