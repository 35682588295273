import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

function DoughnutRiquezaBasico({ data }) {

  ChartJS.register(ArcElement, Tooltip, Legend);

    const options = {
        layout: {
            padding: {
                top: 50,
                bottom: 50,
                left: 100,
                right: 100
            }
        },
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            datalabels: {
              color: '#fff',
              formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map(data => {
                  sum += data;
                });
                let percentage = (value * 100 / sum).toFixed(2) + "%";
                return percentage;
              },
              font: {
                weight: 'bold',
                size: 18
              }
            }
          }
    };

    return (
      <div style={{maxWidth: "800px", margin: "0 auto", minHeight: "600px"}}>
        <Doughnut
            data={data}
            options={options}
            plugins={[ChartDataLabels]}
        />
      </div>
    );
}

export default DoughnutRiquezaBasico;