import { FormControl, InputLabel, MenuItem, Select, CircularProgress, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/Auth";
import { instance } from "../../utils/axios-config";
import BarChartEvolucion from "../charts/BarChartEvolucion";
import { getH } from "../../utils/commons";

const EvoBiodiversidadComponent = ({finca, setError, setOpen, loadingEvoBio, setLoadingEvoBio}) => {

    const {sessionToken} = useAuth();

    const [ option, setOption ] = useState("");
    const [ bioYears, setBioYears ] = useState([]);
    const [ bioStartYear, setBioStartYear ] = useState("");
    const [ bioEndYear, setBioEndYear ] = useState("");
    const [ errorYears, setErrorYears ] = useState(false);
    const [ data, setData ] = useState({
        vegetal: {
            check: false,
            tipoAnalisis: "",
            groupByYears: {}
        },
        invertebrados: {
            check: false,
            tipoAnalisis: "",
            groupByYears: {}
        },
        vertebrados: {
            check: false,
            tipoAnalisis: "",
            groupByYears: {}
        }
    });

    useEffect(() => {
        setOption("");
        setBioYears([]);
        setBioStartYear("");
        setBioEndYear("");
        setErrorYears(false);
        setData({
            vegetal: {
                check: false,
                tipoAnalisis: "",
                groupByYears: {}
            },
            invertebrados: {
                check: false,
                tipoAnalisis: "",
                groupByYears: {}
            },
            vertebrados: {
                check: false,
                tipoAnalisis: "",
                groupByYears: {}
            }
        });
    }, [finca]); 

    const handleOptionSelection = (opt) => {
        if(opt !== option && loadingEvoBio === false){
            setOption(opt);

            if(opt === "vegetal" && data.vegetal.check === false){
                loadVegetalesData(opt);
            }
            if(opt === "invertebrados" && data.invertebrados.check === false){
                loadInvertebradosData(opt);
            }

            if(opt === "vertebrados" && data.vertebrados.check === false){
                loadVertebradosData(opt);
            }
        }
    }

    const loadVegetalesData = async (opt) => {
        setLoadingEvoBio(true);
        let buildUrl = `/biodiversidad/comunidades-biovegetal-list/${finca.id}`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}

            await instance.get(buildUrl).then(function(resp) {
                //let newData = addYears(resp.data);
                let newData = groupDataByYear(resp.data);
                newData = calcRiquezaEspecifica(newData);
                let isBasic = checkTipoAnalisis(newData);
                //calcAdvanced(newData);
                if(isBasic === 0){
                    newData = calcAdvanced(newData, opt);
                }
                let ys = getYears(resp.data);
                setBioYears(ys);
                setBioEndYear("");
                setBioStartYear("");
                setData(prevData => ({
                    ...prevData,
                    vegetal: {
                        check: true,
                        tipoAnalisis: isBasic,
                        groupByYears: newData
                    }
                }));
            }).catch(function(resp) {
                setOpen(true);
                setError(true);
            }).finally(()=>{
                setLoadingEvoBio(false);
            });
        }catch(error){
            setLoadingEvoBio(false);
            setOpen(true);
            setError(true);
        }
    }

    const loadInvertebradosData = async (opt) => {
        setLoadingEvoBio(true);
        let buildUrl = `/biodiversidad/comunidades-bioinvertebrados-list/${finca.id}`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}

            await instance.get(buildUrl).then(function(resp) {
                //let newData = addYears(resp.data);
                let newData = groupDataByYear(resp.data);
                newData = calcRiquezaEspecifica(newData);
                let isBasic = checkTipoAnalisis(newData);
                //calcAdvanced(newData);
                if(isBasic === 0){
                    newData = calcAdvanced(newData, opt);
                }
                let ys = getYears(resp.data);
                setBioYears(ys);
                setBioEndYear("");
                setBioStartYear("");
                setData(prevData => ({
                    ...prevData,
                    invertebrados: {
                        check: true,
                        tipoAnalisis: isBasic,
                        groupByYears: newData
                    }
                }));
            }).catch(function(resp) {
                setOpen(true);
                setError(true);
            }).finally(()=>{
                setLoadingEvoBio(false);
            });
        }catch(error){
            setLoadingEvoBio(false);
            setOpen(true);
            setError(true);
        }
    }

    const loadVertebradosData = async (opt) => {
        setLoadingEvoBio(true);
        let buildUrl = `/biodiversidad/comunidades-biovertebrados-list/${finca.id}`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}

            await instance.get(buildUrl).then(function(resp) {
                //let newData = addYears(resp.data);
                let newData = groupDataByYear(resp.data);
                newData = calcRiquezaEspecifica(newData);
                let isBasic = checkTipoAnalisis(newData);
                //calcAdvanced(newData);
                if(isBasic === 0){
                    newData = calcAdvanced(newData, opt);
                }
                let ys = getYears(resp.data);
                setBioYears(ys);
                setBioEndYear("");
                setBioStartYear("");
                setData(prevData => ({
                    ...prevData,
                    vertebrados: {
                        check: true,
                        tipoAnalisis: isBasic,
                        groupByYears: newData
                    }
                }));
            }).catch(function(resp) {
                setOpen(true);
                setError(true);
            }).finally(()=>{
                setLoadingEvoBio(false);
            });
        }catch(error){
            setLoadingEvoBio(false);
            setOpen(true);
            setError(true);
        }
    }

    const handleStartYearSelect = (sy, ey) => {
        setBioStartYear(sy);
        //Meter comprobacion de si ambos estan seleccionados
        yearValidation(sy, ey);
    }

    const handleEndYearSelect = (sy, ey) => {
        setBioEndYear(ey);
        //Meter comprobacion de si ambos estan seleccionados
        yearValidation(sy, ey);
    }

    const getYears = (objects) => {
        const fechas = objects.map(item => item.fecha);

        const anos = fechas.map(fecha => {
            const [dia, mes, ano] = fecha.split('/');
            return parseInt(ano, 10);
        });

        const anosUnicos = [...new Set(anos)];

        const anosOrdenados = anosUnicos.sort((a, b) => a - b);

        return anosOrdenados;
    }

    const yearValidation = (startYear, endYear) => {
        if(endYear && startYear && (endYear < startYear)){
            setErrorYears(true);
        }else{
            setErrorYears(false);
        }
    }

    const groupDataByYear = (objects) => {
        const grouped = {};
        objects.forEach(obj => {
          const year = obj.fecha.split("/")[2];
          if (!grouped[year]) {
            grouped[year] = {
                riquezaEspecifica: "",
                list: []
            };
          }
          grouped[year].list.push(obj);
        });
        return grouped;
    };

    const calcRiquezaEspecifica = (items) => {
        let newData = Object.entries(items).map(([year, content]) => {
            let rqEsp = new Set();
            content.list.forEach(object => {
                object.muestreos.forEach(muestreo => {
                    muestreo.muestras.forEach(muestra => {
                        rqEsp.add(muestra.nombre);
                    });
                });
            });
            return {
                ...content,
                riquezaEspecifica: rqEsp.size
            };
        });
        
        let updatedItems = Object.fromEntries(
            Object.entries(items).map(([year, content], index) => {
                return [year, newData[index]];
            })
        );

        return updatedItems;
    }

    const checkTipoAnalisis = (items) => {
        let isBasic = 0;
    
        for (const year in items) {
            for (const item of items[year].list) {
                if (item.tipoAnalisis === 1) {
                    isBasic = 1;
                    break;
                }
            }
            if (isBasic === 1) {
                break;
            }
        }

        return isBasic;
    }


    const calcAdvanced = (items, opt) => {
        
        let newData = Object.keys(items).map((year) => {
            let resultYear = {};
            let listadoBV = [];
            //let resultListYear = [];
            let total = 0;
            let yearIndiceSimpson = 0;
            let yearIndiceDiversidadSimpson = 0;
            let yearIndicePielou = 0;
            let yearIndiceShannonWiener = 0;
            items[year].list.forEach(bv => {
                let sumasPorNombre = {};
                let sumaTotal = 0;
                bv.muestreos.forEach(muestreo => {
                    muestreo.muestras.forEach(muestra => {
                        const nombre = muestra.nombre;
                        let numIndividuos;
                        if(opt === "vegetal"){
                            numIndividuos = muestra.num_individuos_com;
                        }else if(opt === "invertebrados"){
                            numIndividuos = muestra.num_individuos;
                        }else{
                            numIndividuos = muestra.ika; 
                        }

                        if (sumasPorNombre[nombre]) {
                            sumasPorNombre[nombre].suma += numIndividuos;
                            sumasPorNombre[nombre].cantidad++;
                        } else {
                            sumasPorNombre[nombre] = {
                            suma: numIndividuos,
                            cantidad: 1
                            };
                        }
                    });
                });

                for (const nombre in sumasPorNombre) {
                    const { suma, cantidad } = sumasPorNombre[nombre];

                    let sumaCalc = 0;
                    if (cantidad > 1) {
                        sumaCalc = suma / cantidad;
                        sumaTotal += sumaCalc;
                    } else {
                        sumaCalc = suma;
                        sumaTotal += suma;
                    }
                    listadoBV.push({nombre: nombre, suma: sumaCalc})
                }
            });

            let sinRepetir = listadoBV.reduce((acumulador, actual) => {
                const encontrado = acumulador.find(item => item.nombre === actual.nombre);
                if (encontrado) {
                    encontrado.nIndividuos += actual.suma;
                } else {
                    acumulador.push({ nombre: actual.nombre, nIndividuos: actual.suma });
                }
                return acumulador;
            }, []);

            total = sinRepetir.reduce((acumulador, actual) => acumulador + actual.nIndividuos, 0);

            sinRepetir.forEach(item => {
                let pi;
                let pisquare;
                let h;

                pi = item.nIndividuos / total;
                pisquare = pi ** 2;
                h = getH(pi);

                yearIndiceSimpson += pisquare;
                yearIndiceShannonWiener += h;
                //resultListYear.push({nombre: item.nombre, nIndividuos: item.nIndividuos, pi: pi, pisquare: pisquare, h: h});
            });

            yearIndiceDiversidadSimpson = 1 - yearIndiceSimpson;
            yearIndicePielou = yearIndiceShannonWiener / (Math.log(items[year].riquezaEspecifica));

            //resultFinca.muestrasFiltradas = resultListFinca;
            resultYear.numEspeciesTotales = Math.round(total);
            resultYear.riquezaEspecifica = items[year].riquezaEspecifica;
            resultYear.indiceDiversidadSimpson = parseFloat(yearIndiceDiversidadSimpson.toFixed(2));
            resultYear.indicePielou = parseFloat(yearIndicePielou.toFixed(2));
            //resultFinca.indiceShannonWiener = parseFloat(fincaIndiceShannonWiener.toFixed(2));
            
            return {
                ...items[year],
                numEspeciesTotales: resultYear.numEspeciesTotales,
                indiceDiversidadSimpson: resultYear.indiceDiversidadSimpson,
                indicePielou: resultYear.indicePielou
            };
        });

        let updatedItems = Object.fromEntries(
            Object.entries(items).map(([year, content], index) => {
                return [year, newData[index]];
            })
        );

        return updatedItems;
        
    }

    return(
        <>
            <div className="evolucion-options-container">
                <div className={"evolucion-options vegetal" + (option === "vegetal" ? " selected" : "")} onClick={() => handleOptionSelection("vegetal")}>Vegetal</div>
                <div className={"evolucion-options vertebrados" + (option === "vertebrados" ? " selected" : "")} onClick={() => handleOptionSelection("vertebrados")}>Vertebrados</div>
                <div className={"evolucion-options invertebrados" + (option === "invertebrados" ? " selected" : "")} onClick={() => handleOptionSelection("invertebrados")}>Invertebrados</div>
            </div>

            {loadingEvoBio ? 
                (
                    <Box p={3} align="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <>
                        {option &&
                            <>
                                {bioYears.length > 0 ?
                                    <>
                                        <div className="evolucion-dates">
                                            <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                <InputLabel htmlFor="fecha-inicio-select">Fecha de inicio*</InputLabel>
                                                <Select
                                                    className="t4b-finca-sector-inputs"
                                                    id="fecha-inicio-select"
                                                    label="Fecha de inicio*"
                                                    required
                                                    MenuProps={{ disableScrollLock: true }}
                                                    value={bioStartYear}
                                                    onChange={(event) => { handleStartYearSelect(event.target.value, bioEndYear) }
                                                    }>
                                                
                                                    {bioYears.map((y, index) => (
                                                        <MenuItem key={"startyear-" + index} value={y}>
                                                            {y}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                <InputLabel htmlFor="fecha-fin-select">Fecha de fin*</InputLabel>
                                                <Select
                                                    className="t4b-finca-sector-inputs"
                                                    id="fecha-fin-select"
                                                    label="Fecha de fin*"
                                                    required
                                                    MenuProps={{ disableScrollLock: true }}
                                                    value={bioEndYear}
                                                    onChange={(event) => { handleEndYearSelect(bioStartYear, event.target.value) }
                                                    }>
                                                
                                                    {bioYears.map((y, index) => (
                                                        <MenuItem key={"endyear-" + index} value={y}>
                                                            {y}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        {errorYears && <p className="errorMsg">La fecha de fin no puede ser menor que la fecha de inicio.</p>}

                                        {bioStartYear && bioEndYear && errorYears === false &&
                                            <BarChartEvolucion bioStartYear={bioStartYear} bioEndYear={bioEndYear} option={option} data={data} finca={finca}/>
                                        }
                                    </>
                                    :
                                    <>
                                        <p>No existen biodiversidades asociadas para el tipo seleccionado.</p>
                                    </>
                                }
                            </>
                        }
                    </>
                )
            }
            
        </>
    );
}

export default EvoBiodiversidadComponent;