import { Stack } from "@mui/material";
import FormNuevaHuellaFincaComponent from "../components/formHuella/FormNuevaHuellaFinca";
import { useState, useEffect } from "react";
import FormNuevaHuellaAlmazaraComponent from "../components/formHuella/FormNuevaHuellaAlmazara";
import { useLocation } from "react-router-dom";


const NuevaHuellaComponentPage = () => {

    const {state} = useLocation();
    const [ choice, setChoice ] = useState(null);

    const handleChangeTab = (event) => {
        setChoice(event.target.value);
    }

    useEffect(() => {
        document.title = "Nueva huella de agua";
        if(state?.type){
            setChoice(state.type);
        }else{
            setChoice("");
        }
    }, []);


    return(
        <>
            {choice === "" &&
                <div className="tic4bio-home">
                    <div>
                        <h1 className="tic4bio-home-title">Elige qué tipo de huella de agua quieres crear:</h1>
                    </div>
                    <Stack spacing={2} direction="row" justifyContent="flex-start" className="tic4bio-sectores-btns">
                        <button className="btn green" value="finca" onClick={(e) => handleChangeTab(e)}>Finca</button>
                        <button className="btn green" value="industria" onClick={(e) => handleChangeTab(e)} style={{margin: "16px 0"}}>Industria</button>
                    </Stack>
                </div>
            }
            {choice === "finca" &&
                <>
                    <FormNuevaHuellaFincaComponent setChoice={setChoice}/>
                </>
            }
            {choice === "industria" &&
                <>
                    <FormNuevaHuellaAlmazaraComponent setChoice={setChoice}/>
                </>
            }
        </>
    );
}
export default NuevaHuellaComponentPage;