import { useEffect, useState } from "react";
import { instance } from '../utils/axios-config.js';
import { Box, CircularProgress, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Link } from 'react-router-dom';
import ModalInfo from '../components/ModalInfo.js';
import { useAuth } from "../context/Auth.js";
import { groupByUnidad } from "../utils/commons.js";
import cultivo from '../assets/cultivo.png';
import industria from '../assets/industria.jpg';
import unidadproductiva from '../assets/unidad-productiva.png';
import Mensajes from "../utils/mensajes";

const HuellaAguaPage = () => {

    const {sessionToken} = useAuth();

    const [ unidades, setUnidades ] = useState([]);
    const [ unidad, setUnidad ] = useState({id: "", coincidencias: []});
    const [ loading, setLoading ] = useState(true);
    const [ open, setOpen ] = useState(false);
    const [ errorMsg, setErrorMsg ] = useState({open: false, title: "¡Vaya! Parace que algo ha ido mal.", content: "Algo ha fallado. Por favor, vuelve a intentarlo más tarde."});
    

    useEffect(() => {
        document.title = "Huella de Agua";
        loadPageData();
        
    }, []);


    const loadPageData = async () => {
        let buildUrl = `/fincas/unidades`;
        try{
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
        
            await instance.get(buildUrl).then(function(resp) {
                let groupUnidades = groupByUnidad(resp.data);
                setUnidades(Object.entries(groupUnidades));
            }).catch(function(resp) {
                setOpen(true);
                setErrorMsg((prevErrorMsg) => ({
                    ...prevErrorMsg,
                    content: "Algo ha fallado al intentar recuperar la información de las unidades productivas. Por favor, vuelve a intentarlo más tarde."
                }));
            }).finally(()=>{
                setLoading(false);
            });
        }catch(error){
            setLoading(false);
            setOpen(true);
            setErrorMsg((prevErrorMsg) => ({
                ...prevErrorMsg,
                content: "Algo ha fallado al intentar recuperar la información de las unidades productivas. Por favor, vuelve a intentarlo más tarde."
            }));
        }
    }

    const handleLoadOptions = async (event) => {
        //let unidad = unidades[0][1].find((u) => u.id === event.target.value);
        let unidad = null;
        unidades.forEach((u) => {
            const objects = u[1];
            const found = objects.find((obj) => obj.id === event.target.value);
            if (found) {
            unidad = found;
            }
        });
        let coincidencias = [];
        unidades.forEach(([_, objetos]) => {
            objetos.forEach(objeto => {
                if (objeto.unidadProductiva === unidad.unidadProductiva) {
                    coincidencias.push(objeto.dataType);
                }
            });
        });

        setUnidad({...unidad, coincidencias: coincidencias});
    }

    const findUnidadByType = (unidad, type) => {
        let id = null;
        unidades.forEach(([_, objetos]) => {
            let found = objetos.find(objeto => objeto.unidadProductiva === unidad.unidadProductiva && objeto.dataType === type);
            if (found) {
                id = found.id;
            }
        });
        return id;
    }

   
    return(
        <>
            <div className="tic4bio-home">
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    {loading ? 
                        (
                            <Box p={3} align="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <>
                                <div>
                                    <h1 className="tic4bio-home-title">Huella de Agua</h1>
                                    {(unidades.length > 0) ?
                                        <>
                                            <p>Seleccione la Unidad productiva de la huella que desea visualizar*</p>
                                            <FormControl sx={{width: "100%", marginBottom: "40px"}} variant="standard">
                                                <InputLabel htmlFor="unidad-select">Unidades*</InputLabel>
                                                <Select
                                                    className="t4b-finca-sector-inputs"
                                                    id="unidad-select"
                                                    label="unidad"
                                                    required
                                                    MenuProps={{ disableScrollLock: true }}
                                                    value={unidad.id}
                                                    onChange={(event) => { handleLoadOptions(event); }    
                                                    }
                                                    >
                                                    {unidades.map((unidad, index) => (
                                                    <MenuItem key={index} value={unidad[1][0].id}>
                                                        {unidad[0]}
                                                    </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {unidad.id &&
                                                <ul className="nodots">
                                                    {unidad.coincidencias.includes("finca") &&
                                                        <li>
                                                            <Link className="enlace" to={"/lista-huellas/" + findUnidadByType(unidad, "finca")}>
                                                                <div className="tic4bio-seccion tic4bio-huella-img">
                                                                    <img src={cultivo} alt={"Imagen cultivo"}/>
                                                                    <h3 className="sectores">Cultivo</h3>
                                                                </div> 
                                                            </Link>
                                                        </li>
                                                    }
                                                    {unidad.coincidencias.includes("industria") &&
                                                        <li>
                                                            <Link className="enlace" to={"/lista-huellas-almazara/" + findUnidadByType(unidad, "industria")}> {/** to={"/huellas-industrias/" + ""} */}
                                                                <div className="tic4bio-seccion tic4bio-huella-img">
                                                                    <img src={industria} alt={"Imagen industria"}/>
                                                                    <h3 className="sectores">Industria</h3>
                                                                </div> 
                                                            </Link>
                                                        </li>
                                                    }
                                                    {(unidad.coincidencias.includes("industria") && unidad.coincidencias.includes("finca")) &&
                                                        <li>
                                                            <Link className="enlace" to={"/huella-unidades/" + unidad.id}>
                                                                <div className="tic4bio-seccion tic4bio-huella-img">
                                                                    <img src={unidadproductiva} alt={"Imagen unidad"}/>
                                                                    <h3 className="sectores">Unidad productiva</h3>
                                                                </div> 
                                                            </Link>
                                                        </li>
                                                    }
                                                    {(!unidad.coincidencias.includes("industria") && !unidad.coincidencias.includes("finca")) &&
                                                        <li>
                                                            <p>Actualmente no tienes fincas o industrias añadidas en esta unidad productiva. Primero debe añadir alguna.</p>
                                                        </li>
                                                    }
                                                </ul>
                                            }
                                        </>
                                        :
                                        <p><Mensajes type="unidades" /></p>
                                    }
                                    
                                </div>
                            </>
                        )
                    }
                </Box>
            </div>
            <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.title} contentText={errorMsg.content}/>
        </>
    );
} 
export default HuellaAguaPage;
