import { Box, FormControl, FormControlLabel, CircularProgress, Radio, RadioGroup, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { check100MaxPattern, checkIntPattern } from "../../utils/commons";
import ModalInfo from "../ModalInfo";
import ModalTip from "../ModalTip";
import { instance } from "../../utils/axios-config";
import { useAuth } from "../../context/Auth";
import { useNavigate } from "react-router-dom";


const FormLimpiezaComponent = ({position, setPosition, state, setState}) => {


    const {sessionToken} = useAuth();
    const navigate = useNavigate();

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [ openTip, setOpenTip ] = useState(false);
    const [ msgTip, setMsgTip ] = useState({open: false, content: ''});
    const [ errorMsg, setErrorMsg ] = useState({
        title: "¡Vaya! Parace que algo ha ido mal.", 
        content: "Algo ha fallado. Por favor, vuelve a intentarlo más tarde."
    });
    

    const validateForm = () =>{
        setErrors({});
        let inputIds = "";
        let valid = true;

        if(state.aguaDeLimpieza === ""){
            setErrors(errors => ({...errors, errorAguaDeLimpieza: true, errorAguaDeLimpiezaMsg: "Por favor, seleccione una frecuencia de limpieza."}));
            inputIds = inputIds ? inputIds +",aguaDeLimpieza" : "aguaDeLimpieza";
            valid = false;
        }

        if(state.aguaDeLimpieza !== "" && state.aguaDeLimpieza !== "4"){
            if(state.conoceCaudal === ""){
                setErrors(errors => ({...errors, errorConoceCaudal: true, errorConoceCaudalMsg: "Por favor, indique si conoce el caudal de los equipos/dispositivos de limpieza."}));
                inputIds = inputIds ? inputIds +",conoceCaudal" : "conoceCaudal";
                valid = false;
            }else if(state.conoceCaudal !== "" && state.conoceCaudal !== "false"){
                if(state.nZonas === "" || state.nZonas === "0"){
                    setErrors(errors => ({...errors, errorNZonas: true, errorNZonasMsg: "El número de zonas de limpieza no puede ser cero, ni estar vacío."}));
                    inputIds = inputIds ? inputIds +",nZonas" : "nZonas";
                    valid = false;
                }
            }
        }
        
        if(inputIds !== ""){
            let elem = document.getElementById(inputIds.split(",")[0]);
            elem.scrollIntoView({behavior: "smooth"});
            elem.focus();
        }
        return valid;
    }

    const handlePrev = (e) => {
        e.preventDefault();
        setPosition(1);
    }

    const handleNext = (e) => {
        e.preventDefault();
        if(validateForm()){
            setPosition(3);
        }
    }

    const handleOpenTip = () => {
        setOpenTip(true);
        setMsgTip((msg) => ({
            ...msg,
            content: '<div class="modaltip"><h4>Si no conoce el caudal el siguiente paso finalizará el proceso de creación de nueva Huella de Agua</h4></div>'
        }));
    }

    const handleSend = async (e) => {
        e.preventDefault();
        if(validateForm()){
            try {
                setLoading(true);
                const data = formatStateData();
                instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
                await instance.post(`/huellaalmazara/almazara`, data).then(() => {
                    setLoading(false);
                    navigate("/lista-huellas-almazara/" + state.almazara.id, {state: {type: "action-new", text: "Se ha creado la huella de la almazara correctamente."}});
                });
                console.log(data);
                setLoading(false);
            } catch (error) {
                if(error.response?.status === 406){
                    setPosition(0);
                    setState(prevState => ({
                        ...prevState,
                        exists: true
                    }));
                }else{
                    setOpen(true);
                }
                setLoading(false);
            }
        }
    }

    const checkEquipos = () => {
        if(state.almazara.contadorGeneral !== "1"){
            return Object.entries(state.equipos).reduce((acc, [key, value]) => {
                if (value.consumo !== "") {
                  acc[key] = value;
                }
                return acc;
              }, {});
        }else{
            return [];
        }
    }

    const formatStateData = () => {
        let data = {
            nombre: state.nombre,
            campana: state.campana,
            nDiasMolturacion: parseInt(state.nDiasMolturacion),
            kgAceitunaMolturado: parseFloat(state.kgAceitunaMolturado.replace(",", ".")),
            rendimientoMedio: parseFloat(state.rendimientoMedio.replace(",", ".")),
            precioAceite: parseFloat(state.precioAceite.replace(",", ".")),
            consumoAguaTotal: state.consumoAguaTotal !== "" ? parseFloat(state.consumoAguaTotal.replace(",", ".")) : "",
            almazara: state.almazara,
            equipos: checkEquipos(),
            aguaDeLimpieza: state.aguaDeLimpieza,
            conoceCaudal: state.aguaDeLimpieza === "4" ? "" : state.conoceCaudal,
            nZonas: state.conoceCaudal === "true" ? parseInt(state.nZonas) : "",
            zonas: {},
            zonasMantenimiento: {}
        };

        return data;
    }

    const handleChangeAguaLimpieza = (e) => {
        setState({...state, aguaDeLimpieza: e});
    }

    const handleChangeConoceCaudal = (e) => {
        setState({...state, conoceCaudal: e});
    }

    /*const eliminarZonas = (numero) => {
        const nuevasZonas = { ...state.zonas };
        for (let key in nuevasZonas) {
          if (parseInt(key) >= parseInt(numero)) {
            delete nuevasZonas[key];
          }
        }
        return nuevasZonas;
    };
    
    const eliminarZonasMantenimiento = (numero) => {
        const nuevasZonas = { ...state.zonasMantenimiento };
        for (let key in nuevasZonas) {
          if (parseInt(key) >= parseInt(numero)) {
            delete nuevasZonas[key];
          }
        }
        return nuevasZonas;
    };
    
    */
    const eliminarZonas = (numero) => {
        const num = parseInt(numero, 10);
        const nuevasZonas = {};
    
        for (let key in state.zonas) {
            if (parseInt(key, 10) < num) {
                nuevasZonas[key] = state.zonas[key];
            }
        }
    
        return nuevasZonas;
    };

    const eliminarZonasMantenimiento = (numero) => {
        const num = parseInt(numero, 10);
        const nuevasZonas = {};

        for (let key in state.zonasMantenimiento) {
            if (parseInt(key, 10) < num) {
                nuevasZonas[key] = state.zonasMantenimiento[key];
            }
        }

        return nuevasZonas;
    };

    const handleChangeNZonas = (e) => {
        if(checkIntPattern(e) && check100MaxPattern(e)){
            let zonas = eliminarZonas(e);
            let zonasMantenimiento = eliminarZonasMantenimiento(e);
            setState({...state, nZonas: e, zonas: zonas, zonasMantenimiento: zonasMantenimiento});
        }
    }

    return(
        <>
            <Box className={position !== 2 && 'hide'}>

                <p>Frecuencia de limpieza:</p>
                <FormControl sx={{width: "100%", marginBottom: "20px"}} className="radio-green disabled-opacity" variant="standard">
                    <RadioGroup
                        name="aguaDeLimpieza"
                        id="aguaDeLimpieza"
                        value={state.aguaDeLimpieza}
                        onChange={(e) => handleChangeAguaLimpieza(e.target.value)}
                    >
                        <FormControlLabel value="1" control={<Radio />} label="Limpieza diaria" />
                        <FormControlLabel value="2" control={<Radio />} label="Limpieza al final de campaña" />
                        <FormControlLabel value="3" control={<Radio />} label="Limpieza mantenimiento y fin de campaña" />
                        <FormControlLabel className="red-option" value="4" control={<Radio />} label="Omitir este apartado" />
                    </RadioGroup>
                    {errors.errorAguaDeLimpieza && <div className="errorMsg">{errors.errorAguaDeLimpiezaMsg}</div>}
                </FormControl>

                {(state.aguaDeLimpieza !== "4" && state.aguaDeLimpieza !== "") &&
                    <>
                        <p>¿Conoce el caudal de los equipos/dispositivos de limpieza?*: <span className="modaltip-icon" onClick={() => { handleOpenTip() }}>?</span></p>
                        <FormControl sx={{width: "100%", marginBottom: "20px"}} className="radio-green disabled-opacity" variant="standard">
                            <RadioGroup
                                name="conoceCaudal"
                                id="conoceCaudal"
                                value={state.conoceCaudal}
                                onChange={(e) => handleChangeConoceCaudal(e.target.value)}
                            >
                                <FormControlLabel value="true" control={<Radio />} label="Sí" />
                                <FormControlLabel className="red-option" value="false" control={<Radio />} label="No" />
                            </RadioGroup>
                            {errors.errorConoceCaudal && <div className="errorMsg">{errors.errorConoceCaudalMsg}</div>}
                        </FormControl>

                        {(state.conoceCaudal !== "false" && state.conoceCaudal !== "") &&
                            <>
                                <p>Defina el número de zonas de limpieza de su almazara en función de los dispositivos/puntos/tomas de agua de limpieza de los que conozca caudal*:</p>
                                <FormControl sx={{width: "100%", marginBottom: "20px"}} variant="standard">
                                    <TextField
                                        className="t4b-finca-sector-inputs no-red-label"
                                        label="Nº de zonas*"
                                        id="nZonas"
                                        type="number"
                                        variant="standard"
                                        InputProps={{
                                            inputProps: { 
                                                max: 100, min: 0,
                                            }
                                        }}
                                        name="nZonas"
                                        value={state.nZonas}
                                        onChange={(e) => handleChangeNZonas(e.target.value)}
                                        error={errors.errorNZonas}
                                    />
                                    {errors.errorNZonasMsg && <div className="errorMsg"><p>{errors.errorNZonasMsg}</p></div>}
                                </FormControl>
                            </>
                        }
                    </>
                }
            
                {loading ?
                    <Box align="center" sx={{my: 2}}>
                        <CircularProgress />
                    </Box>
                    :
                    <Stack spacing={2} direction="row" justifyContent="space-between" className="tic4bio-sectores-btns">
                        <button className="btn white" onClick={(e) => handlePrev(e)}>Anterior</button>
                        {(state.conoceCaudal === "false" || state.aguaDeLimpieza === "4") ?
                            <button className="btn green" onClick={(e) => handleSend(e)} style={{margin: "16px 0"}}>Calcular</button>
                            :
                            <button className="btn green" onClick={(e) => handleNext(e)} style={{margin: "16px 0"}}>Siguiente</button>
                        }
                    </Stack>
                }
            </Box>

            <ModalInfo open={open} setOpen={setOpen} contentTitle={errorMsg.title} contentText={errorMsg.content}/>
            <ModalTip open={openTip} setOpen={setOpenTip} contentText={msgTip.content}/>
        </>
    );
}
export default FormLimpiezaComponent;