import { Box, CircularProgress, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/Auth";
import { instance } from "../../utils/axios-config";
import ModalInfo from "../ModalInfo";
import { useNavigate } from "react-router-dom";

const FormBioStepFourComponent = ({position, setPosition, stateForm, setStateForm}) => {

    const {sessionToken} = useAuth();
    const navigate = useNavigate();

    const [totalEspecies, setTotalEspecies] = useState([]);
    const [ errors, setErrors ] = useState({});
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        let especiesTotal = [];
        if(stateForm.tipoAnalisis === "1"){
            Object.keys(stateForm.muestreos).forEach((key) =>{
                stateForm.muestreos[key].map((muestreo) => {
                    muestreo.especies.map(((muestra) => {
                        const upperMuestra = muestra.nombre.charAt(0).toUpperCase() + muestra.nombre.slice(1);
                        muestra.nombre = upperMuestra;
                        if (!especiesTotal.includes(muestra.nombre)) {
                            especiesTotal.push(muestra.nombre);
                        }
                    }));
                });
            });
        }else{
            Object.keys(stateForm.muestreos).forEach((key) =>{
                stateForm.muestreos[key].map((muestreo) => {
                    muestreo.especies.map(((muestra) => {
                        const upperMuestra = muestra.nombre.charAt(0).toUpperCase() + muestra.nombre.slice(1);
                        muestra.nombre = upperMuestra;
                        const index = especiesTotal.findIndex(objeto => objeto.nombre === muestra.nombre);
                        if (index !== -1) {
                            let objetoClonado = { ...especiesTotal[index] };
                            objetoClonado.ni += muestra.ni;
                            especiesTotal[index] = objetoClonado;
                        }else{
                            especiesTotal.push(muestra);
                        }
                    }));
                });
            });
        }
        setTotalEspecies(especiesTotal);
    }, []);


    const handlePrev = (e) => {
        e.preventDefault();
        setPosition(2);
    }

    const handleSend = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            instance.defaults.headers.common = {'Authorization': `Bearer ${sessionToken}`}
            await instance.post(`/biodiversidad/vertebrados/create`, stateForm).then((resp) => {
                setLoading(false);
                navigate("/biodiversidad/vertebrados/detalles/" + resp.data.id, {state: {type: "action-new", text: "Se ha creado la biodiversidad de vertebrados correctamente.", comunidadId: resp.data.comunidadId, fincaId: resp.data.fincaId}});
                console.log("Enviado OK");
            });
        } catch (error) {
            if(error.response?.status === 406){
                setErrors(errors => ({...errors, errorFecha: true, errorFechaMsg: "Ya existe una una biodiversidad de vertebrados para el año de la fecha seleccionada."}));
                let elem = document.getElementById("fecha-bioVertebrados");
                elem.scrollIntoView({behavior: "smooth"});
                elem.focus();
            }else{
                setOpen(true);
            }
            setLoading(false);
        }
    };

    return(
        <>
        <Box className={position !== 3 && 'hide'}>
                       
            <h1 className="tic4bio-home-title">Nueva Biodiversidad Vertebrados</h1>
            <p style={{color: "rgba(0, 0, 0, 0.6)", margin: "42px 0px"}}>Finca seleccionada: <span style={{color: "#80C606", fontSize: "20px", fontWeight: "700"}}>{stateForm.finca.nombre}</span></p>
            <p style={{color: "rgba(0, 0, 0, 0.6)", margin: "42px 0px"}}>Comunidad seleccionada: <span style={{color: "#80C606", fontSize: "20px", fontWeight: "700"}}>{stateForm.comunidad.nombre}</span></p>
            

            <div className="tabla-bio-box">
                <table className="tabla-bio">
                    <thead>
                        <tr>
                            {stateForm.tipoAnalisis === "1" ?
                                <>
                                    <th>Especies en la zona "{stateForm.comunidad.nombre}"</th>
                                </>
                                :
                                <>
                                    <th style={{width: "70%"}}>Especies en la zona "{stateForm.comunidad.nombre}"</th>
                                    <th style={{width: "30%"}}>Nº ind.</th>
                                </>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {stateForm.tipoAnalisis === "1" ?
                            <>
                                {totalEspecies.map((nombre, index) => (
                                    <tr key={"especie-" + index}>
                                        <td>{nombre}</td>
                                    </tr>
                                ))}
                            </>
                            :
                            <>
                                {totalEspecies.map((especie, index) => (
                                    <tr key={"especie-" + index}>
                                        <td>{especie.nombre}</td>
                                        <td>{especie.ni}</td>
                                    </tr>
                                ))}
                            </>
                        }
                    </tbody>
                </table>
            </div>

            <p id="fecha-bioVertebrados" className="errorMsg">{errors.errorFecha &&
                <>
                {errors.errorFechaMsg}
                </>
            }
            </p>

            {loading ?
                <Box align="center" sx={{my: 2}}>
                    <CircularProgress />
                </Box>
                :
                <Stack spacing={2} direction="row" justifyContent="space-between" className="tic4bio-sectores-btns">
                    <button className="btn green" onClick={(e) => handlePrev(e)}>Anterior</button>
                    <button className="btn green" onClick={(e) => handleSend(e)} style={{margin: "16px 0"}}>Finalizar</button>
                </Stack>
            }
        </Box>
        <ModalInfo open={open} setOpen={setOpen} contentTitle={"¡Vaya! Parace que algo ha ido mal."} contentText={"Algo ha fallado al intentar crear la biodiversidad de vertebrados. Por favor, vuelve a intentarlo más tarde."}/>
        </>
    )
}

export default FormBioStepFourComponent;