import React, { useEffect, useRef } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

function DoughnutUnidad({ data, extra }) {

    ChartJS.register(ArcElement, Tooltip, Legend);

    const chartRef = useRef(null);

    const wrapText = (ctx, text, x, y, lineHeight) => {
        const lines = text.split('\n');
        lines.forEach((line, index) => {
            ctx.fillText(line, x, y + (index * lineHeight));
        });
    };

    const doughnutLabelLine = {
        id: 'doughnutLabelsLine',
        afterDraw(chart, args, options){
            const {ctx, chartArea: {top, bottom, left, right, width, height}} = chart;

            chart.data.datasets.forEach((dataset, i) => {
                chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
                    const { x, y } = datapoint.tooltipPosition();

                    const halfwidth = width / 2;
                    const halfheight = height / 2;

                    const xLine = x >= halfwidth ? x + 30 : x - 30;
                    const yLine = y >= halfheight ? y + 30 : y - 30;
                    
                    const extraLine = x >= halfwidth ? 30 : -30;

                    ctx.beginPath();
                    ctx.moveTo(x,y);
                    ctx.lineTo(xLine, yLine);
                    ctx.lineTo(xLine + extraLine, yLine);
                    ctx.strokeStyle = dataset.borderColor[index];
                    ctx.stroke();
                    
                    ctx.font = '16px';

                    const textXPosition = x >= halfwidth ? 'left' : 'right';
                    const plusFivePx = x >= halfwidth ? 5 : -5;
                    ctx.textAlign = textXPosition;
                    ctx.textBaseLine = 'middle';
                    ctx.strokeStyle = dataset.borderColor[index];
                    //ctx.fillText(`${chart.data.datasets[0].data[index]} ${extra.labelText}`, xLine + extraLine + plusFivePx, yLine);
                    const labelText = `${chart.data.datasets[0].data[index]} \n${extra.labelText}`;
                    wrapText(ctx, labelText, xLine + extraLine + plusFivePx, yLine, 20);
                })
            });

            const xCord = chart.getDatasetMeta(0).data[0].x;
            const yCord = chart.getDatasetMeta(0).data[0].y;
            ctx.font = 'bold 20px sans-serif';
            ctx.fillStyle = 'rgba(54, 162, 235, 1)';
            ctx.textAlign = 'center';
            ctx.textBaseLine = 'middle';
            ctx.fillText(extra.value + extra.text, xCord, yCord);

            ctx.save();
        },
    }

    const options = {
        cutout: "80%",
        layout: {
            padding: {
                top: 50,
                bottom: 50,
                left: 100,
                right: 100
            }
        }
    };

    useEffect(() => {
        const chartInstance = chartRef.current.chartInstance;
        return () => {
            if (chartInstance) {
                chartInstance.destroy();
            }
        };
    }, []); 

    return <Doughnut ref={chartRef} data={data} options={options} plugins={[doughnutLabelLine]}/>;

}

export default DoughnutUnidad;