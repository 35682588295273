import { FormControl, TextField } from "@mui/material";
import { Fragment, useEffect } from "react";
import { checkDoublePattern, checkIntPattern } from "../../utils/commons";

const InputZonaComponent = ({state, setState, errors }) => {

    useEffect(() => {
        const nZonas = parseInt(state.nZonas, 10);
        for (let pos = 0; pos < nZonas; pos++) {
          handleChangeNombre(`Zona ${pos}`, pos);
          handleChangeCaudal("0", pos);
          handleChangeTiempo("0", pos);
        }
    }, [state.nZonas]);


    const handleChangeNombre = (v, pos) => {
        setState(prevState => ({
            ...prevState,
            zonas: {
                ...prevState.zonas,
                [pos]: {
                ...prevState.zonas[pos],
                nombre: v,
                },
            },
        }));
    }

    const handleChangeCaudal = (v, pos) => {
        if(checkDoublePattern(v)){
            setState(prevState => ({
                ...prevState,
                zonas: {
                    ...prevState.zonas,
                    [pos]: {
                    ...prevState.zonas[pos],
                    caudal: v,
                    },
                },
            }));
        }
    }

    const handleChangeTiempo = (v, pos) => {
        if(checkIntPattern(v)){
            setState(prevState => ({
                ...prevState,
                zonas: {
                    ...prevState.zonas,
                    [pos]: {
                    ...prevState.zonas[pos],
                    tiempo: v,
                    },
                },
            }));
        }
    }


    return(
        <>
            {Array.from({ length: parseInt(state.nZonas) }, (v, pos) => (
                <Fragment key={pos}>
                    <tr>
                        <td>
                            <FormControl sx={{width: "100%"}} variant="standard">
                                {state.aguaDeLimpieza !== "3" ? 
                                    <TextField
                                        className="t4b-finca-sector-inputs no-red-label"
                                        label="Nombre de zona*"
                                        id={"zonaNombre-" + pos}
                                        value={state.zonas[pos]?.nombre ? state.zonas[pos]?.nombre : ""}
                                        variant="standard"
                                        onChange={(e) => handleChangeNombre(e.target.value, pos)}
                                        name="zonaNombre"
                                        error={errors["errorZona" + pos + "Nombre"]}
                                    />
                                    :
                                    <TextField
                                        className="t4b-finca-sector-inputs no-red-label"
                                        label="Nombre de zona*"
                                        id={"zonaNombre-" + pos}
                                        value={state.zonas[pos]?.nombre ? state.zonas[pos]?.nombre : ""}
                                        variant="standard"
                                        disabled
                                        name="zonaNombre"
                                        error={errors["errorZona" + pos + "Nombre"]}
                                    />
                                }
                                {errors["errorZona" + pos + "NombreMsg"] && <div className="errorInput">{errors["errorZona" + pos + "NombreMsg"]}</div>}
                            </FormControl>
                        </td>
                        <td>
                            <FormControl sx={{width: "100%"}} variant="standard">
                                <TextField
                                    className="t4b-finca-sector-inputs no-red-label"
                                    label="Caudal*"
                                    id={"zonaCaudal-" + pos}
                                    type="number"
                                    variant="standard"
                                    InputProps={{
                                        inputProps: { 
                                            max: 99999, min: 0,
                                            step: 0.5,
                                        }
                                    }}
                                    name={"zonaCaudal-" + pos}
                                    value={state.zonas[pos]?.caudal ? state.zonas[pos]?.caudal : ""}
                                    onChange={(e) => handleChangeCaudal(e.target.value, pos)}
                                    error={errors["errorZona" + pos + "Caudal"]}
                                />
                                {errors["errorZona" + pos + "CaudalMsg"] && <div className="errorInput">{errors["errorZona" + pos + "CaudalMsg"]}</div>}
                            </FormControl>
                        </td>
                        <td>
                            <FormControl sx={{width: "100%"}} variant="standard">
                                <TextField
                                    className="t4b-finca-sector-inputs no-red-label"
                                    label="Tiempo*"
                                    id={"zonaTiempo-" + pos}
                                    type="number"
                                    variant="standard"
                                    InputProps={{
                                        inputProps: { 
                                            max: 99999, min: 0,
                                        }
                                    }}
                                    name={"zonaTiempo-" + pos}
                                    value={state.zonas[pos]?.tiempo ? state.zonas[pos]?.tiempo : ""}
                                    onChange={(e) => handleChangeTiempo(e.target.value, pos)}
                                    error={errors["errorZona" + pos + "Tiempo"]}
                                />
                                {errors["errorZona" + pos + "TiempoMsg"] && <div className="errorInput">{errors["errorZona" + pos + "TiempoMsg"]}</div>}
                            </FormControl>
                        </td>
                    </tr>
                </Fragment>
            ))}
            
        </>
    );
}
export default InputZonaComponent;